import React, { Component }  from 'react'
import {
  withRouter
} from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
import Image from 'react-bootstrap/Image'

import Col from 'react-bootstrap/Col'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

const api = require('../../utils/api')

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      currentUser: { can: [] },
      error: null,
      formEmail: '',
      
      currentUser: {
        isLoggedIn: false,
        dataLetters: '',
        name: '',
        scholarity: '',
        intendedCourse: ''
      },
    }
    
  }

  async componentDidMount() {
    const currentUser = await api.currentUser()
    api.userRegister(currentUser || null, { type: 'page', to: '/obrigado/' })

    const ilustracaoBackground = require('../../assets/background.svg')

    this.setState({
      loading: false,
      ilustracaoBackground
    })

    AOS.init({
      duration: 300
    })

    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  render() {
    return (
      <div className={`${styles.all}`} style={{
        backgroundImage: `url(${this.state.ilustracaoBackground })`,
        backgroundPosition: "right bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}>
        <Col className={styles.containerRegister} sm={12} md={{ span: 4, offset: 4 }}>
          <Image fluid src={require('../../assets/logo_lampejo.png')} href="/" className={`${styles.imgLogo} align-top d-block d-md-none`} alt='lampejo.app'/>
          <h2>Sua solicitação foi enviada com sucesso!</h2>
          <p style={{ maxWidth: "400px", margin: "10px auto 40px auto" }}>Iremos te responder o mais rápido possível 😄</p>
        </Col>
      </div>
    )
  }
}

export default withRouter(Register)
import React, { Component }  from 'react'
import {
  withRouter,
  // Link,
} from 'react-router-dom'

import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import TaskMiniWidget from '../../components/TaskMiniWidget'
import Loader from 'react-loader-spinner'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

// import { MdSubtitles } from 'react-icons/md'

import { BiCalendar, BiCalendarWeek } from 'react-icons/bi'

const api = require('../../utils/api')
const schedules = require('../../controllers/schedules')
const tasksController = require('../../controllers/tasks')
const auth = require('../../utils/auth')

class CalendarPage extends Component {
  constructor(props) {
    super(props)
    let now = new Date()

    this.state = {
      tasks: [],
      loading: true,
      currentUser: { name: '' },
      rest: null,
      showWhyModal: false,
      date: now,
      isToday: false,
      weekView: true,
      emptyBox: null,
      showWhyModal: false
    }

    // this.toNextCell= this.toNextCell.bind(this)
  }

  portugueseMonth (date) {
    if (date.getMonth() == 0){ return "Janeiro" }
    if (date.getMonth() == 1){ return "Fevereiro" }
    if (date.getMonth() == 2){ return "Março" }
    if (date.getMonth() == 3){ return "Abril" }
    if (date.getMonth() == 4){ return "Maio" }
    if (date.getMonth() == 5){ return "Junho" }
    if (date.getMonth() == 6){ return "Julho" }
    if (date.getMonth() == 7){ return "Agosto" }
    if (date.getMonth() == 8){ return "Setembro" }
    if (date.getMonth() == 9){ return "Outubro" }
    if (date.getMonth() == 10){ return "Novembro" }
    if (date.getMonth() == 11){ return "Dezembro" }
  }

  portugueseDay (date) {
    if (date.getDay() == 0){ return "dom" }
    if (date.getDay() == 1){ return "seg" }
    if (date.getDay() == 2){ return "ter" }
    if (date.getDay() == 3){ return "qua" }
    if (date.getDay() == 4){ return "qui" }
    if (date.getDay() == 5){ return "sex" }
    if (date.getDay() == 6){ return "sab" }
  }

  renderCalendar(studyDays) {
    let center = this.state.date
    let calendarArray = []
    for (let i = -3; i <= 3; i++) {
      let date = new Date(center.getTime() + i * (1000 * 3600 * 24))
      let active = studyDays?.includes(date.getDay())
      calendarArray.push({ date, active })
    }
    let width = window.innerWidth
    return (
      <Row>
        { calendarArray.map(e => { return (
          <Col
            className={
              (width < 400 && calendarArray.indexOf(e) == 0) || (width < 400 && calendarArray.indexOf(e) == 6)?
              `d-none` :
              calendarArray.indexOf(e) == 3? `${styles.primary} ${styles.textCenter}`: `${styles.weekDay} ${styles.textCenter}`
            }
            as={ e.active? 'a' : 'div' }
            href={ e.active? `/calendar/${e.date.toISOString().slice(0, 10)}` : null }>
            {this.portugueseDay(e.date)}<br/>
            <h3>{e.date.getDate()}</h3>
            { e.active? <span className={styles.circle}></span>: <></>}
          </Col>
          )})
        }
      </Row>
    )
  }

  getDates (startDate, stopDate) {
    var dateArray = new Array()
    var currentDate = startDate
    while (currentDate <= stopDate) {
      dateArray.push(new Date (currentDate))
      currentDate = new Date((currentDate.getTime() + (1000 * 3600 * 24)))
    }
    return dateArray
  }

  renderDay(value, actualMonth, studyDays) {
    if (studyDays.includes(value.getDay())) {
      return (<td style={{ textAlign: 'center', padding: '5px' }}>
        <a className={value.getMonth() === actualMonth? `${styles.calendarDay}` : `${styles.desactivate} ${styles.calendarDay}`} href={`/calendar/${value.getFullYear()}-${('0' + (value.getMonth() + 1)).slice(-2)}-${('0' + value.getDate()).slice(-2)}`}>
          {value.getDate()}<br/>
          <span className={styles.circle}></span>
        </a></td>)
    } else {
      return (<td style={{ textAlign: 'center', padding: '5px' }} className={value.getMonth() === actualMonth? '' : `${styles.desactivate}`}>{value.getDate()}</td>)
    }
    
  }

  renderMonth(month, studyDays) {
    let now = new Date()
    let firstMonthDay = new Date(now.getFullYear(), month, 1)
    let firstDay = firstMonthDay

    let lastMonthDay = new Date(new Date(now.getFullYear(), month + 1, 1).getTime() - (1000 * 3600 * 24))
    let lastDay = lastMonthDay

    while (firstDay.getDay() != 0) {
      firstDay = new Date(firstDay.getTime() - (1000 * 3600 * 24))
    }

    while (lastDay.getDay() != 6) {
      lastDay = new Date(lastDay.getTime() + (1000 * 3600 * 24))
    }

    let dates = this.getDates(firstDay, lastDay)

    let weeks = [[]]
    let j = -1
    for (let i = 0; dates[i] != undefined; i++ ) {
      if (dates[i].getDay() == 0) {
        j += 1
        weeks.push([])
        weeks[j].push(dates[i])
      } else {
        weeks[j].push(dates[i])
      }
    }

    return (<>
      <h3>{this.portugueseMonth(firstMonthDay)}</h3><br/>
      <table style={{ width: '100%', marginBottom: '4rem' }}>
      <tr style={{ textAlign: 'center' }}>
        <th>dom</th><th>seg</th><th>ter</th><th>qua</th><th>qui</th><th>sex</th><th>sab</th>
      </tr>
        { weeks.map(week => {
          return (
            <tr>
            { week.map(day => { return this.renderDay(day, month, studyDays) }) }
            </tr>
          )
        })}
      </table>
    </>)

  }

  renderYearCalendar(studyDays) {
    let today = new Date()
    let months = []
    for (let i = today.getMonth(); i < 10; i++) {
      months.push(i)
    }
    return (
      <Row>
        { months.map(month => { return this.renderMonth(month, studyDays)} ) }
      </Row>
    )
  }

  async componentDidMount() {
    let initialTime = new Date();
    let currentUser = await api.currentUser()
    const emptyBox = require('../../assets/empty_box.svg')

    this.setState({
      initialTime,
      currentUser,
      emptyBox
    })

    if (this.props.match.params.date) {
      const date = this.props.match.params.date
      let day = await this.addHours(new Date(date), 3)
      let today = new Date()

      if (! await this.sameDay(day, today) && day.getTime() - today.getTime() > 0) {
        const reqSchedule = await schedules.getCalendarByDay(day.toISOString(), auth.getToken())
        if (reqSchedule.ok) {
          const getSchedule = reqSchedule.body.schedule
          if (getSchedule.tasksIds) {
            if (getSchedule.tasksIds.length > 0) {
              let getTasks = []
              getSchedule.tasks = await getSchedule.tasksIds.map(async taskId => {
                const reqTask = await tasksController.get(taskId.id)
                if (reqTask.ok) {
                  reqTask.body.task.order = taskId.order
                  reqTask.body.task.isComplete = taskId.isComplete
                  getTasks.push(reqTask.body.task)
                  getTasks.sort((a, b) => { return a.order - b.order })
                  
                  this.setState({
                    tasks: getTasks,
                    loading: false,
                    date: await this.addHours(new Date(date), 3)
                  })
                }
              })
            } else {
              this.setState({
                schedule: getSchedule,
                loading: false,
                date: await this.addHours(new Date(date), 3)
              })
            }
          }
        }
      } else {
        let today = new Date().toISOString()
        const reqSchedule = await schedules.getCalendarByDay(today, auth.getToken())
        if (reqSchedule.ok) {
          const getSchedule = reqSchedule.body.schedule
          if (getSchedule.tasksIds) {
            if (getSchedule.tasksIds.length > 0) {
              let getTasks = []
              await getSchedule.tasksIds.map(async taskId => {
                const reqTask = await tasksController.get(taskId.id)
                if (reqTask.ok) {
                  reqTask.body.task.order = taskId.order
                  reqTask.body.task.isComplete = taskId.isComplete
                  getTasks.push(reqTask.body.task)
                  getTasks.sort((a, b) => { return a.order - b.order })

                  this.setState({
                    schedule: getSchedule,
                    tasks: getTasks,
                    loading: false,
                    date: await this.addHours(new Date(), 3),
                    isToday: true
                  })
                }
              })
          } else {
            this.setState({
              schedule: getSchedule,
              loading: false,
              date: await this.addHours(new Date(), 3),
              isToday: true
            })
          }
        } else {
          this.setState({
            schedule: getSchedule,
            loading: false,
            date: await this.addHours(new Date(), 3),
            isToday: true
          })
        }}
      }
    } else {
      let today = new Date().toISOString()
      const reqSchedule = await schedules.getCalendarByDay(today, auth.getToken())
      if (reqSchedule.ok) {
        const getSchedule = reqSchedule.body.schedule
        if (getSchedule.tasksIds) {
          if (getSchedule.tasksIds.length > 0) {
            let getTasks = []
            await getSchedule.tasksIds.map(async taskId => {
              const reqTask = await tasksController.get(taskId.id)
              if (reqTask.ok) {
                reqTask.body.task.order = taskId.order
                reqTask.body.task.isComplete = taskId.isComplete
                getTasks.push(reqTask.body.task)
                getTasks.sort((a, b) => { return a.order - b.order })

                this.setState({
                  schedule: getSchedule,
                  tasks: getTasks,
                  loading: false,
                  date: await this.addHours(new Date(), 3),
                  isToday: true
                })
              }
            })
          } else {
            this.setState({
              loading: false,
              schedule: getSchedule,
              date: await this.addHours(new Date(), 3),
              isToday: true
            })
          }
        }
      }
    }
  }

  async addHours (date, h) {
    date.setHours(date.getHours()+h)
    return date
  }

  async sameDay (date, otherDate) {
    return (date.getDate() === otherDate.getDate() &&
      date.getMonth() === otherDate.getMonth() &&
      date.getYear() === otherDate.getYear())
  }

  async openYearView() {
    this.setState({
      weekView: false
    })
  }

  async openWeekView() {
    this.setState({
      weekView: true
    })
  }

  async openWhyModal() {
    this.setState({
      showWhyModal: true
    })
  }

  async closeWhyModal() {
    this.setState({
      showWhyModal: false
    })
  }

  render() {
    const { currentUser, tasks, loading, isToday, date, weekView, emptyBox, showWhyModal } = this.state
    return (
      <>
        { loading? 
          <Container className={`${styles.container} pt-4`}>
            <Row className={`justify-content-md-center`}>
              <Col md={6} xs={12} className={`mx-auto text-center pt-5`}>
                <h3>Estamos consultando o seu calendário...</h3>
                <Loader type="TailSpin" color="#c7ef00" height={100} width={100} timeout={10000} />
              </Col>
            </Row>
          </Container>
          : weekView?
            <Container className={`${styles.container} pt-4`}>
              <Row>
                <Col md={6} xs={12} className={`mx-auto`}>
                  <span>{`${date.getDate()} de ${this.portugueseMonth(date)}`}</span>
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={12} className={`mx-auto pt-3`}>
                  <Row>
                    <Col>
                      <h2>{ isToday? `Hoje` :
                      parseInt((date.getTime() - new Date().getTime())  / (1000 * 3600 * 24)) == 0?
                      `em 1 dia...` :
                      `em ${parseInt((date.getTime() - new Date().getTime())  / (1000 * 3600 * 24)) + 1} dias...`}
                      </h2>
                    </Col>
                    <Col className={`ml-auto mr-0 d-flex`}>
                      <Button className={`mr-0 ml-auto ${styles.darkButton}`} variant="link" onClick={(e) => { return this.openYearView() }}><BiCalendar/></Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={12} className={`mx-auto pt-3`}>
                  { this.renderCalendar(currentUser.studyDays) }
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={12} className={`mx-auto`}>
                  { !loading && tasks.length > 0 ?
                    tasks.map(task => { return (<TaskMiniWidget task={task} md={12}/>) }) : 
                    <Col className={`text-center pt-5`}>
                      <Image src={emptyBox} style={{ minHeight: '135px' }}/>
                      <p>Não há nenhuma tarefa no seu calendário para esse dia.</p>
                      <p><Button variant="link" className={`${styles.primaryLink} ${styles.centered}`} onClick={(e) => { this.openWhyModal() }}>por quê?</Button></p>
                    </Col>
                  }
                </Col>
              </Row>
            </Container>
          : (
            <Container className={`${styles.container} pt-4`}>
              <Row>
                <Col md={6} xs={12} className={`mx-auto`}>
                  <span>Cronograma</span>
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={12} className={`mx-auto pt-3`}>
                  <Row>
                    <Col>
                      <h2>{`${date.getUTCFullYear()}`}</h2>
                    </Col>
                    <Col className={`ml-auto mr-0 d-flex`}>
                      <Button className={`mr-0 ml-auto ${styles.darkButton}`} variant="link" onClick={(e) => { return this.openWeekView() }}><BiCalendarWeek/></Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={12} className={`mx-auto pt-3`}>
                  { this.renderYearCalendar(currentUser.studyDays) }
                </Col>
              </Row>
            </Container>
          ) }
          <Modal className={`${''}`} show={showWhyModal} backdrop={"static"} keyboard={false} >
            <Modal.Header
              style={{
                padding: "30px",
                justifyContent: "center",
                color: "#292f36",
                backgroundColor: "white",
                border: 0,
                borderRadius: "25px",
              }}
            >
              <Modal.Title style={{ fontWeight: "bolder", textAlign: "center"}}>Como funciona o cronograma personalizado?</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                padding: "0 25px 50px 25px",
                justifyContent: "left",
                textAlign: "left",
                color: "#292f36",
                backgroundColor: "white",
                borderRadius: 25,
                border: 0
                }}>
                <Col sm={12}>
                  O seu cronograma é criado a partir das configurações de <strong>tempo de estudos por semana</strong> e <strong>dias estudados na semana</strong>.<br/><br/>
                  Caso você queira alterar essas configurações, acesse o <strong>seu perfil</strong> e altere os dias de estudo do seu cronograma.<br/><br/>
                </Col>
                <Col sm={12} style={{ textAlign: 'center', display: 'flex', margin: '0 auto' }}>
                  <Button variant="link" className={`${styles.primaryButton} ${styles.centered}`} onClick={(e) => { this.closeWhyModal() }}>entendi</Button>
                </Col>
            </Modal.Body>
          </Modal>
      </>
    )
  }
}

export default withRouter(CalendarPage)
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import styles from './index.module.css'
import Image from 'react-bootstrap/Image'
import Badge from 'react-bootstrap/Badge'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { BsCheckCircle, BsCircle, BsArrowRight, BsLockFill } from 'react-icons/bs'

import { CgChevronRightO, CgTime } from 'react-icons/cg'

const unity = require('../../controllers/unities')
const api = require('../../utils/api')

class TaskWidgetB extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      title: null,
      defaultIcon: null,
      type: ''
    }
  }

  async componentDidMount() {
    this.setState({
      loading: true,
      unity: {},
      icon: null,
      title: null,
      type: ''
    })

    const defaultIcon = await require('../../assets/ilustracao_task.svg')
    
    let currentUser = await api.currentUser()
    let type = ''
    if (!this.props.task.unityId && this.props.task.questionsIds) {
      if (this.props.task.questionsIds.lenght > 0) {
        type = 'questions'
      }
    } else if (this.props.task.unityId) {
      type = 'content'
    }

    if (this.props.task.unityId != null) {
      const reqUnity = await unity.get(this.props.task.unityId, currentUser.id)
      if (reqUnity.ok) {
        const getUnity = reqUnity.body.unity
      
        if (getUnity != null) {
          this.setState({
            unity: getUnity,
            icon: getUnity.icon,
            title: getUnity.title,
            type: 'content',
            loading: false
          })
        }
      } else {
        this.setState({
          error: 'Houve um problema...',
          type: 'content'
        })
      }
    } else {
      this.setState({
        icon: this.props.task.icon,
        type,
        loading: false,
        defaultIcon
      })
    }
  }

  async componentWillReceiveProps(nextProps) {
    let currentUser = await api.currentUser()

    if (nextProps.task) {
      this.setState({
        loading: true,
        unity: {},
        icon: null,
        title: null,
        type: ''
      })
      if (nextProps.task.unityId != null) {
        const reqUnity = await unity.get(nextProps.task.unityId, currentUser.id)
        if (reqUnity.ok) {
          const getUnity = reqUnity.body.unity
        
          if (getUnity != null) {
            this.setState({
              unity: getUnity,
              icon: getUnity.icon,
              title: getUnity.title,
              type: 'content',
              loading: false
            })
          }
        }
      } else {
        let type = null
        if (nextProps.task.questionsIds.length > 0) {
          type = 'questions'
        } else {
          type = ''
        }
        this.setState({
          icon: nextProps.task.icon || null,
          type,
          loading: false
        })
      }
    }
  }

  shorten(title) {
    if (title) {
      if (title.length > 15) {
        return title.substring(0, 15).toLowerCase()
      } else {
        return title.toLowerCase()
      }
    } else {
      return ''
    }
  }

  shortenSubject(subject) {
    if (subject) {
      if (subject == 'Sociologia e Filosofia') {
        return 's+f'
      } else {
        return subject.substring(0, 3).toLowerCase()
      }
    } else {
      return ''
    }
  }

  render() { 
    var { type, defaultIcon, loading } = this.state
    var { task, actual, md } = this.props

    const notAvailable = !task.available // task.questionsIds.length == 0 && !task.unityId
    return (
      <>
        <div className={task.isComplete? `${styles.nanoTask} ${styles.completedNanoTask}` : `${styles.nanoTask}`}
          style={{ backgroundColor: task.subject ? `#${task.subject.color}` : '#f1f1f1' }}
          >
          <Row className={`m-0`}>
            <Col className={`${styles.nanoCol}`}>
              { task.title ? (
                <span className={`mb-0`}><span className={styles.subjectTitle}>{task.subject? this.shortenSubject(task.subject.title)? `${this.shortenSubject(task.subject.title)}`: '' : '' }</span><span className={styles.taskTitle}>{this.shorten(task.title)}</span></span>
              ) : (
                <span><strong><Skeleton width={200}/></strong></span>
              ) }
            </Col>
          </Row>
        </div>
        { task.isComplete?
          <div className={styles.checkSignDiv}>
            <Image src={require('../../assets/check_sign.svg')} className={styles.checkSign}/>
          </div>
          : <></>
        }
      </>
    )
  }
}

export default withRouter(TaskWidgetB)
import React, { Component }  from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
import Image from 'react-bootstrap/Image'
import { default as DefaultButton } from 'react-bootstrap/Button'
import { Button } from '../../lampejo-styles/components/Button/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Media from 'react-bootstrap/Media'
import Loader from 'react-loader-spinner'
import TaskWidgetBFinish from '../../components/TaskWidgetBFinish'
import DatePicker from 'react-datepicker'
import styles from './index.module.css'
import Lottie from 'react-lottie'
import AOS from 'aos'
import 'aos/dist/aos.css'

const api = require('../../utils/api')
const taskConclusion = require('../../controllers/taskConclusion')
const streakEvents = require('../../controllers/streakEvents')
const auth = require('../../utils/auth')

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      currentUser: { can: [] },
      progress1: null,
      progress2: null,
      progress3: null,
      error: null,
      formEmail: '',
      formPassword: '',
      formScholarity: '',
      formIntendedCourse: '',
      formStudyTime: 18,
      selectedScholarity: null,
      selectedIntendedCourse: null,
      formStudyDays: [],
      currentUser: {
        isLoggedIn: false,
        dataLetters: '',
        name: '',
        scholarity: '',
        intendedCourse: ''
      },
      calendarIcon: null,
      shceduleIcon: null,
      hashtagLampejo: null,
      showFinishModal: false,
      showFinishModalB: false,
      showEnd: false,
      checkImage: null,
      chooseNotifyTime: false,
      selectedNotificationTime: new Date().setMinutes(0,0,0),
      task: {
        id: "register-schedule",
        subject: {
          title: "⏰",
          color: "FB3874",
          id: "-"
        },
        questionsIds: [],
        unityId: "register-schedule",
        title: "Definir meu Cronograma"
      },
      publicVapidKey: 'BNsQcZc_CWg45AGGRNImba_8pLAzQrbwCYgeJLH66zETqRgPXLWCmtEMEghIe_tbqzRNBhu1gk-GCWD9lhoafoY',
      weekStreak: [],
      showStreakUpdate: false,
      streakStatus: null,
      glowEffectImage: null,
      actualStreak: { value: null }
    }
    
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  congratsAudio = new Audio(require('../../assets/congrats.mp3'))

  async componentDidMount() {
    const currentUser = await api.currentUser()
    let step = parseInt(this.props.match.params.step)
    if (!step || step > 3) {
      step = 1
    }

    const calendarIcon = require('../../assets/calendar.svg')
    const scheduleIcon = require('../../assets/schedule.svg')
    const hashtagLampejo = require('../../assets/hashtagLampejo.svg')
    const checkImage = require('../../assets/check-circle.gif')
    const lightStreakVideo = await require('../../assets/videos/StreakLight.json')
    const glowEffectImage = await require("../../assets/glow_streak_effect.png")
    const starVideo = await require('../../assets/videos/congrats.json')

    this.setState({
      loading: false,
      calendarIcon,
      scheduleIcon,
      currentUser,
      hashtagLampejo,
      checkImage,
      step,
      lightStreakVideo,
      glowEffectImage,
      starVideo
    })
    AOS.init({
      duration: 300
    })

    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  addToFormStudyDays(value) {
    let studyDays = this.state.formStudyDays
    if (!studyDays.includes(value)) {
      studyDays.push(value)
    } else {
      studyDays.splice(studyDays.indexOf(value), 1)
    }
    this.setState({ formStudyDays: studyDays })
  }

  /**
   * Handles form submission
   * @param {object} evt 
   */
  async handleFormSubmit(evt) {
    window.scrollTo(0, 0)
    evt.preventDefault()

    this.setState({ loading: true })

    if (this.state.step === 1) {
      const response = await api.userUpdate({ 
        "studyDays": this.state.formStudyDays,
      }, auth.getToken())
      if (!response.ok) {
        this.setState({
          formError: response.error,
          loading: false
        })
      } else {
        if (this.isPushNotificationSupported()) {
          let step = parseInt(this.state.step) + 1
          window.location.replace("/task/register-schedule/" + step)
        } else {
          let step = parseInt(this.state.step) + 2
          window.location.replace("/task/register-schedule/" + step)
        }
      }
    } else if (this.state.step === 2) { 
      const response = await api.userUpdate({ 
        "notificationTime": this.state.selectedNotificationTime,
        "notificationSubscription": this.state.subscription
      }, auth.getToken())
      if (!response.ok) {
        this.setState({
          formError: response.error,
          loading: false
        })
      } else {
        let step = parseInt(this.state.step) + 1
        window.location.replace("/task/register-schedule/" + step)
      }
    } else {
      const resp = await taskConclusion.register({
        taskId: 'register-schedule',
        time: 200
      }, auth.getToken())
      if (resp.ok) {
        this.setState({
          loading: false
        })
        window.scrollTo(0, 0)
        this.openFinishModalB(resp.body.streakStatus)
      }
    }
  }

  showFinishModal() {
    this.setState({
      showFinishModal: true
    })
  }

  openFinishModalB(streakStatus) {
    this.setState({
      showFinishModalB: true,
      streakStatus: streakStatus || null
    })
  }

  checkTask() {
    this.congratsAudio.play()

    this.setState({
      showEnd: true
    })
  }

  isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window
  }

  async askUserPermissionToNotify() {
    return await Notification.requestPermission()
  }

  async onClickAskUserPermission (e) {
    this.askUserPermissionToNotify().then(async consent => {
      if (consent == "granted") {
        this.setState({
          chooseNotifyTime: true
        })
        
        const register = await navigator.serviceWorker.register('/worker.js', { scope: '/' })
        const subscription = await register.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: this.state.publicVapidKey })

        this.setState({
          subscription
        })

      } else {
        this.handleFormSubmit(e)
      }
    });
  };

  setNotificationTime (date) {
    this.setState({
      selectedNotificationTime: date
    })
  }

  async checkForStreak() {
    if (this.state.streakStatus == 'complete') {
      const reqWeekStreak = await streakEvents.getWeekStreak(auth.getToken())
      const weekStreak = reqWeekStreak.body.weekStreak
      const actualStreak = reqWeekStreak.body.actualStreak

      this.setState({
        actualStreak,
        weekStreak,
        showStreakUpdate: true,
      })

      setTimeout(() => {
        window.location.replace("/schedule/tutorial-crono")
      }, 4000)
    } else {
      window.location.replace("/schedule/tutorial-crono")
    }
  }

  portugueseDay (date) {
    if (date.getDay() == 0){ return "d" }
    if (date.getDay() == 1){ return "s" }
    if (date.getDay() == 2){ return "t" }
    if (date.getDay() == 3){ return "q" }
    if (date.getDay() == 4){ return "q" }
    if (date.getDay() == 5){ return "s" }
    if (date.getDay() == 6){ return "s" }
  }

  renderStreakDay(day) {
    return (
      day.status == 'complete'?
        <Col className={"px-0"}>
          <Image className={styles.glowEffectImage} src={this.state.glowEffectImage}/>
          <Col className={styles.completedStreakCircle}>{this.portugueseDay(new Date(day.date))}</Col>
          <Image className={styles.glowEffectImageRotate} src={this.state.glowEffectImage}/>
        </Col>
      : day.status == 'freeze'?
        <Col className={"px-0"}>
          <Col className={styles.glowEffectSpace} />
          <Col className={styles.freezeStreakCircle}>{this.portugueseDay(new Date(day.date))}</Col>
          <Col className={styles.glowEffectSpace}/>
        </Col>
      : day.status == 'freeze-item'?
        <Col className={"px-0"}>
          <Col className={styles.glowEffectSpace}/>
          <Col className={styles.freezeItemStreakCircle}>{this.portugueseDay(new Date(day.date))}</Col>
          <Col className={styles.glowEffectSpace}/>
        </Col>
      :  day.status == 'active'?
        <Col className={"px-0"}>
          <Col className={styles.glowEffectSpace}/>
          <Col className={styles.activeStreakCircle}>{this.portugueseDay(new Date(day.date))}</Col>
          <Col className={styles.glowEffectSpace}/>
        </Col>
      : <></>
    )
  }

  render() {
    const { actualStreak, weekStreak, lightStreakVideo, showStreakUpdate, step, formStudyDays, hashtagLampejo, showFinishModal, showFinishModalB, showEnd, task, chooseNotifyTime, selectedNotificationTime, starVideo } = this.state

    return (
      <>
        <Col sm={12} md={{ span: 4, offset: 4 }} className={`${styles.scheduleProgress} py-5`}>
        { step === 1 && !this.state.loading ? (
          <div className={styles.containerRegister}>
            <style type="text/css">
              {`
                .bg-custom {
                  background-color: #c7ef00;
                  background-image: linear-gradient(45deg,rgba(0,0,0,.1) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.1) 50%,rgba(0,0,0,.1) 75%,transparent 75%,transparent);
                }
              `}
            </style>
            <ProgressBar animated className={`${styles.progressBar} mb-5`} now={30} variant="custom" />
            <h2>Definir meu cronograma</h2>
            <p>Selecione os dias que você irá estudar:</p>
              <Form className={`${styles.form}`} onSubmit={this.handleFormSubmit}>
                <div className={`${styles.card} text-inline`}>
                  <Form.Group className={`${styles.formField}`}>
                    { [ { label: 'dom', value: 0 },
                        { label: 'seg', value: 1 },
                        { label: 'ter', value: 2 },
                        { label: 'qua', value: 3 },
                        { label: 'qui', value: 4 },
                        { label: 'sex', value: 5 },
                        { label: 'sab', value: 6 }].map(day => { return (
                          <div className={styles.checkForm} onClick={(e) => { this.addToFormStudyDays(day.value) }}>
                            {  formStudyDays.includes(day.value)?
                            <span className={styles.activeCheck}></span> : <span className={styles.check}></span>
                          }
                          <span className={`pl-5`}>{day.label}</span><br/>
                          </div>
                        )
                    }) }
                    {/* <Form.Label className={styles.formLabel}><FaBook style={{marginRight: '0.5em', color: 'white' }}/>escolaridade</Form.Label> */}
                    
                  </Form.Group> 
                </div>

              {this.state.formError && (
                <div className={styles.formError}>{this.state.formError}</div>
              )}

              <Button
                centered
                value='Register'
                onClick={(e) => { if (formStudyDays.length == 0) {
                    return;
                  }
                  this.handleFormSubmit(e);
                }}
                disabled={ formStudyDays.length == 0 ? true : false }
                label="próximo"
              />
            </Form>
          </div>
        ) : (<></>)}

        { step === 2 && !this.state.loading ? (
          <div className={styles.containerRegister}>
            <style type="text/css">
              {`
                .bg-custom {
                  background-color: #c7ef00;
                  background-image: linear-gradient(45deg,rgba(0,0,0,.1) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.1) 50%,rgba(0,0,0,.1) 75%,transparent 75%,transparent);
                }
              `}
            </style>
            <ProgressBar animated className={`${styles.progressBar} mb-5`} now={50} variant="custom" />
            <h2 style={{ marginBottom: "30px" }}>Não se esqueça nunca ;)</h2>

            { chooseNotifyTime? (
              <><Form className={`${styles.form}`} onSubmit={this.handleFormSubmit}>
              <div className={`${styles.card} text-inline py-5`}>
                <style>
                {`
                  .react-datepicker__time-container {
                    background: #292F36;
                    width: 100%;
                    padding: 0 30px;
                    max-height: 300px;
                    overflow-y: scroll;
                    border: solid 1px #ffffff30;
                    border-radius: 12px;
                  }
                  .react-datepicker__time-list-item {
                    display: flex;
                    margin: 20px auto;
                    cursor: pointer;
                  }
                  .react-datepicker__time-list-item:hover {
                    color: #c7ef00;
                  }
                  .react-datepicker__time-list {
                    padding: 0;
                  }
                  .react-datepicker-time__header {
                    font-weight: bolder;
                    padding: 10px;
                    text-align: center;
                    border-bottom: solid 1px #ffffff30;
                  }
                  .react-datepicker-popper {
                    width: 50%;
                  }
                  .react-datepicker-wrapper {
                    display: flex;
                  }
                  .react-datepicker__input-container {
                    display: flex;
                  }
                  .react-datepicker__input-container input {
                    background: transparent;
                    border: solid 1px #c7ef00;
                    border-radius: 6px;
                    padding: 5px;
                    color: white;
                  }
                `}


                </style>
                <p>Selecione o horário que você quer ser notificad@:</p>
                <DatePicker
                  selected={selectedNotificationTime}
                  onChange={(date) => this.setNotificationTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Hora"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                />
              </div>

            {this.state.formError && (
              <div className={styles.formError}>{this.state.formError}</div>
            )}

              <Button
                centered
                value='Register'
                onClick={(e) => {
                  this.handleFormSubmit(e);
                }}
                label="próximo"
              />
          </Form></>

            ) : (
              <>
              <p>Nós podemos te enviar notificações em horários específicos para lembrar que está na hora dos estudos.</p>
              <Image style={{ display: 'flex', margin: '25px auto' }} src={require('../../assets/notify.png')}/>

              <Button
                centered
                onClick={(e) => {
                  this.onClickAskUserPermission(e)
                }}
                label="vamos lá!"
              />
              <br/><br/>
              <Button
                centered
                variant="tertiary"
                onClick={(e) => {
                  this.handleFormSubmit(e)
                }}
                label="pular"
              />
              <br/><br/><br/><br/><br/>
              </>
            )}
            
          </div>
        ) : (<></>)}

        { step === 3 && !this.state.loading ? (
          <div className={styles.containerRegister}>
            <style type="text/css">
              {`
                .bg-custom {
                  background-color: #c7ef00;
                  background-image: linear-gradient(45deg,rgba(0,0,0,.1) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.1) 50%,rgba(0,0,0,.1) 75%,transparent 75%,transparent);
                }
              `}
            </style>
            <ProgressBar animated className={`${styles.progressBar} mb-5`} now={70} variant="custom" />
            <h2 style={{ marginBottom: "30px" }}>Como funciona?</h2>
                
            <p>Seu cronograma irá contemplar o conteúdo que mais cai no ENEM e nos principais vestibulares, como FUVEST, UNICAMP e UNESP.</p>
            <Image style={{ display: 'flex', margin: '25px auto' }} src={require('../../assets/subjects_color.svg')}/>
            <p>Nós separamos todas as matérias em <strong>tarefas de 30 minutos</strong> e distribuimos conforme o tempo de estudo e os dias escolhidos por você.</p>
            <p>Bora estudar!</p>
            <Image height="32" src={hashtagLampejo}/>
            <br/><br/>

            <Button
              centered
              onClick={(e) => {
                this.handleFormSubmit(e)
              }}
              label="próximo"
            />
            <br/><br/><br/><br/><br/>
          </div>
        ) : (<></>)}

        {this.state.loading? (
          <div className={styles.containerRegister} style={{ paddingTop: "100px" }}>
            <Loader type="TailSpin" color="#c7ef00" height={100} width={100} timeout={10000} />
          </div>
        ) : (<></>)}
      </Col>
      <style>
        { `.modal-100w {
          max-width: 100% !important;
          width: 100% !important;
          min-height: 100vh !important;
          height: 100% !important;
          z-index: 11 !important;
        }
          .modal-100w .modal-content {
            min-height: calc(100vh) !important;
            height: 100% !important;
          }
        `}
      </style>
      <Modal
        bsPrefix={'finish-modal'}
        className={`${styles.finishModal}`}
        show={showFinishModal}
        backdrop={"static"}
        keyboard={false}
        dialogClassName='modal-100w'
        contentClassName='modal-100w'
      >
        <Modal.Header
          style={{
            paddingTop: "100px",
            justifyContent: "center",
            color: "#292f36",
            backgroundColor: "white",
            border: 0,
            borderRadius: 0,
          }}
        >
          <Modal.Title style={{ fontWeight: "bolder", textAlign: "center", fontSize: "35px"}}>
            <Image style={{
              maxWidth: "110px",
              display: "flex",
              margin: "0 auto 30px auto"
            }} src={require("../../assets/confete.svg")}></Image>
            Parabéns!</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            padding: "40px",
            justifyContent: "center",
            textAlign: "center",
            color: "#292f36",
            backgroundColor: "white",
            borderRadius: 0,
            border: 0
            }}>
            <Col xs={12} md={{ offset: 4, span: 4 }}>
              <p>Você finalizou a tarefa:</p>
              <Card className={`${styles.examBanner} ${styles.borderRadius25} p-5 mt-4 text-left`}>
                <Row>
                  <Col sm={8}>
                    <Card.Title style={{ lineHeight: '40px', marginBottom: 0 }}>Definir meu Cronograma</Card.Title>
                  </Col>
                  <Col sm={2} style={{ verticalAlign: "middle", margin: "auto" }}>
                    <img style={{ height: '40px', minWidth: '40px', margin: '0 auto', display: 'flex' }} src={this.state.checkImage}/>
                  </Col>
                </Row>
              </Card>
            </Col>
        </Modal.Body>
      </Modal>
      <Modal
          bsPrefix={'finish-modal'}
          className={`${styles.finishModal}`}
          show={showFinishModalB}
          backdrop={"static"}
          keyboard={false}
          dialogClassName='modal-100w'
          contentClassName='modal-100w'
        >
          <Modal.Body
            style={{
              paddingTop: '70px',
              justifyContent: "center",
              textAlign: "center",
              color: "white",
              backgroundColor: "#292f36",
              borderRadius: 0,
              border: 0,
              maxHeight: '100vh',
              height: '100vh'
              }}>
              <Col xs={12} md={{ offset: 4, span: 4 }} className={`${styles.verticalCenter} p-0`}>
                { !showStreakUpdate?
                  <>
                    <p data-aos-duration='800'>Conclua a tarefa:</p>
                    <TaskWidgetBFinish data-aos-duration='800' task={task} callback={() => { this.checkTask() }}/>
                    <style>
                    {`@keyframes wobble {
                        33% {
                          transform: rotate(5deg);
                        }
                        66% {
                          transform: rotate(-5deg);
                        }
                      }`}
                    </style>
                    <Image className={showEnd? 'd-none': 'd-block'} data-aos-delay="2000" data-aos='fade-zoom-in' style={{ marginLeft: '-25px', top: '-30px', position: 'relative', animation: 'wobble 5s ease-in-out infinite alternate' }} src={require('../../assets/to_finish_task.svg')}/>
                    <div className={showEnd? `d-block ${styles.starDiv}`: 'd-none'}>
                      <Lottie options={{ loop: true, autoplay: true, animationData: starVideo, rendererSettings: { preserveAspectRatio: 'xMidYMid slice'}}} height={320} width={320} isStopped={!showEnd}/>
                    </div>
                    { showEnd? 
                    <Card data-aos='fade-up' data-aos-duration='800' className={styles.pointsCard}>
                      <Row>
                        <Col className={'d-none d-md-block'} style={{ maxWidth: 'max-content', paddingLeft: '20px' }}><Image height="30" width="30" src={require('../../assets/star_green.svg')}/></Col>
                        <Col style={{ minWidth: 'max-content', margin: 'auto 0', paddingLeft: '15px' }}>Pontos pela tarefa:</Col>
                        <Col style={{ color: '#c7ef00', margin: 'auto 0', textAlign: 'right', paddingRight: '25px', fontWeight: 'bolder' }}>+10</Col>
                      </Row>
                    </Card> :
                    <></> }
                    { showEnd?
                      <Button
                        data-aos='fade-up'
                        data-aos-delay="800"
                        data-aos-duration='800'
                        centered
                        marginTop='md'
                        onClick={() => this.checkForStreak() }
                        label="continuar"
                      /> : <></> }
                  </>
                : <Col>
                    <Lottie options={{
                      loop: false,
                      autoplay: true,
                      animationData: lightStreakVideo,
                      rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
                    }} height={320} width={320}/>
                    <Row style={{ justifyContent: 'space-evenly', maxWidth: '100%', margin: '0 auto' }}>
                      { weekStreak.map((day) => { return this.renderStreakDay(day) }) }
                    </Row>  
                    <Row style={{ justifyContent: 'space-around', width: '100%', margin: '1rem auto 0 auto' }}>
                      <p style={{ fontSize: '24px', textAlign: 'center', fontWeight: 'bolder' }}>{ actualStreak.value }{ actualStreak.value == 1 ? ' dia' : ' dias' } de corrida!</p>
                    </Row>
                    <Row style={{ justifyContent: 'space-around', width: '100%', margin: '0 auto'}}>
                      <p style={{ maxWidth: '300px' }}>Finalize pelo menos uma tarefa nos <strong>dias do seu cronograma</strong> para continuar a sua corrida!</p>
                    </Row>
                    <Row style={{ justifyContent: 'space-around', width: '100%', margin: '0 auto' }}>
                      <Button
                        marginTop="md" 
                        onClick={() => window.location.replace("/schedule/tutorial-crono") }
                        label="continuar"
                      />
                    </Row>
                  </Col> }
              </Col>
              
          </Modal.Body>
        </Modal>
    </>
    )
  }
}

export default withRouter(Register)
import React, { Component }  from 'react'
import {
  withRouter,
  // Link,
} from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import ReactPixel from 'react-facebook-pixel'
import Breadcrumb from '../../components/Breadcrumb'
import Question from '../../components/Question'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

const exams = require('../../controllers/exams')
const api = require('../../utils/api')
const questions = require('../../controllers/questions')

class QuestionPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      question: { title: '' },
      exam: { id: '', name: '', pediod: ''},
      currentUser: { can: [] },
      loading: true,
      showResolution: props.showResolution || false
    }
  }

  async componentDidMount() {
    let CurrentUser = await api.currentUser()
    let userId = ''
    if (CurrentUser) {
      userId = CurrentUser.id

      this.setState({
        currentUser: CurrentUser
      })
    }

    const id = this.props.match.params.id
    const request = await questions.get(id, userId)
    if (request.ok) {
      const getQuestion = request.body.question

      let getExam
      if (getQuestion) {
        if (getQuestion.examId) {
          const requestExam = await exams.getById(getQuestion.examId, userId)
          getExam = requestExam.body.exam
        }
      }
      
      this.setState({
        id: id,
        question: getQuestion,
        exam: getExam || {},
        loading: false
      })
    }

    AOS.init({
      duration: 300
    })

    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  openResolution() {
    return true
  }


  render() {
    const { question, exam, loading, currentUser, showResolution } = this.state
    const meta = {
      title: showResolution ? `Resolução da ${question? question.title : ''} - ${exam? exam.name: ''} - ${exam? exam.period: ''} | lampejo.app - Plataforma gratuita para o ENEM` : `${question? question.title: ''} - ${exam? exam.name: ''} - ${exam? exam.period: ''} | lampejo.app - Plataforma gratuita para o ENEM` ,
      description: `Somos uma comunidade de estudantes e professores que valorizam o compartilhamento do conhecimento. Você vai aprender praticando e ajudando os outros alunos.`,
      meta: {
        charset: 'utf-8',
        lang: 'pt-br',
        amp: true,
        name: {
          keywords: 'educação, vestibular, enem, questões, provas'
        }
      }
    }
    const metaNoExam = {
      title: showResolution ? `Resolução da ${question? question.title: ''} | lampejo.app - Plataforma gratuita para o ENEM` : `${question? question.title: ''} - ${exam? exam.name: ''} - ${exam? exam.period: ''} | lampejo.app - Plataforma gratuita para o ENEM` ,
      description: `Somos uma comunidade de estudantes e professores que valorizam o compartilhamento do conhecimento. Você vai aprender praticando e ajudando os outros alunos.`,
      meta: {
        charset: 'utf-8',
        lang: 'pt-br',
        amp: true,
        name: {
          keywords: 'educação, vestibular, enem, questões, provas'
        }
      }
    }
    
    return (
      <Container className={styles.container}>
        { exam.id? (<DocumentMeta {...meta}/>) : (<DocumentMeta {...metaNoExam}/>)}
        { exam.id ?
        <Breadcrumb links={[
          { label: "Provas", to: "/exams" },
          { label: exam.name + " - " + exam.period, to: "/exams/" + exam.id },
          { label: question? question.title: '', active: true }
        ]}/>
        :
        <Breadcrumb links={[
          { label: question? question.title: '', active: true }
        ]}/>
        }
        
        <Row>
          <Col sm={{offset: 6, span: 6}} className={`${styles.toRight}`}>
            { currentUser.can.includes('register-edit-question') ? 
            <Button variant="link" className={`${styles.primaryLink} ${styles.toRight}`} href={`/question/${question.id}/edit/`}>editar questão</Button>
            :
            <></>
            }
            { currentUser.can.includes('register-edit-resolution') ? 
            <Button variant="link" className={`${styles.primaryLink} ${styles.toRight}`} href={`/question/${question.id}/resolution/edit`}>adicionar resolução</Button>
            :
            <></>
            }
          </Col>
        </Row>
        <Row>
          <Question md={{offset: 3, span: 6}} question={question} exam={exam} showResolution={showResolution} currentUser={currentUser}></Question>
        </Row>
      </Container>
    )
  }
}

export default withRouter(QuestionPage)
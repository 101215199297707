import React, { Component }  from 'react'

class RedirectComponent extends Component {
  constructor(props) {
    super(props)
  }

  async componentDidMount(){
    if (this.props.url == 'blog/artigo') {
      window.location.href = 'https://www.lampejo.app/blog/' + this.props.match.params.article
    } else {
      window.location.href = this.props.url
    }
  }

  render() {
    return (
      <div>
       <p>Redirecionando...</p>
      </div>
    )
  }
}

export default RedirectComponent;

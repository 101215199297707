import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
/**
 * Utils: Back-end
 */
const { domains } = require('../config')
const auth = require('./auth')

/**
 * Register a new user
 */
export const userRegister = async (user) => {
  const response = await requestApi('/students', 'POST', user)
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response
  }
  
  if (response.token) {
    auth.saveToken(response.token)
    auth.saveSession(response.user)
    return {
      status: 200,
      ok: true,
      body: {
        message: response.message,
        token: response.token
      }
    }
  }
  
}

export const getAvailableInvites = async (inviterId) => {
  const response = await requestApi(`/invitesByUserId/${inviterId}`, 'GET')
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response
  } else {
    return  {
      status: 200,
      ok: true,
      body: {
        invites: response.invites,
        inviterName: response.inviterName
      }
    }
  }
}

/**
 * Register a new user
 */
export const userPasswordRecover = async (user) => {
  const response = await requestApi('/passwordrecover', 'POST', { user: user })
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response
  } else {
    return  {
      status: 200,
      ok: true,
      body: {
        message: response.message,
      }
    }
  }
}

/**
 * Register a new user
 */
export const userEditPassword = async (body) => {
  const response = await requestApi('/users/passwordrecover', 'POST', body)
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response
  } else {
    return  {
      status: 200,
      ok: true,
      body: {
        message: response.message,
        token: response.token
      }
    }
  }
}

/**
 * Register a new user
 */
export const userUpdate = async (user, authToken) => {
  const response = await requestApi('/students', 'PUT', user,  { Authorization: `Bearer ${authToken}`})
  
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response
  } else {
    response.ok = true
  }

  if (response.token) {
    const token = response.token
    const responseUser = response.user

    if (!auth.getToken()) {
      auth.saveToken(token)
      Cookies.remove('eq-user')
      auth.saveSession(responseUser)
    } else {
      Cookies.remove('eq-user')
      auth.saveSession(responseUser)
    }
    return {
      status: 200,
      ok: true,
      body: {
        message: response.message,
        token: token
      }
    }
  }
  
  if (response.student) {
    const responseStudent = response.student.props
    Cookies.remove('eq-user')
    auth.saveSession(responseStudent)
    return {
      status: 200,
      ok: true,
      body: {
        message: response.message,
        user: responseStudent
      }
    }
  }
  
  return response
}

/**
 * Register a new visit
 */
export const visitRegister = async (user, visit = {}) => {
  if (user.id) {
    visit.userId = user.id
  }
  const response = await requestApi('/visits', 'POST', visit)
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  } else {
    return null
  }
}

/**
 * Login a new user
 */
export const userLogin = async (user) => {
  if (!user.email) {
    return { error: "Email é um campo obrigatório" }
  }
  if (!user.password) {
    return { error: "Senha é um campo obrigatório" }
  }
  const response = await requestApi('/users/login', 'POST', { "email": user.email, "password": user.password })
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response
  }

  const token = response.token
  const responseUser = response.user
  auth.saveToken(token)
  auth.saveSession(responseUser)
  
  return {
    status: 200,
    ok: true,
    body: {
      message: response.message,
      token: token
    }
  }
}

export const currentUser = async () => {
  let token = await auth.getToken()
  let user = await auth.getSession()

  if (!token || !user) {
    // auth.logout()
    return {
      id: '',
      role: 'Estudante',
      isLoggedIn: false,
      can: []
    }
  }

  user.dataLetters = getDataLetters(user.name)
  user.can = []
  if (
    user.scholarity && user.intendedCourse && user.dailyStudyTime &&
    (user.email || user.googleId || user.facebookId || user.appleId)) {
    user.isLoggedIn = true
  }

  return user 
}

/**
 * Register a answer
 */
export const userUpdateNotify = async (authToken) => {
  if (!authToken) {
    return { status: "401", error: "Não autorizado. Necessário token de autenticação." }
  }
  
  const response = await requestApi('/users/updateNotifyMe', 'POST', null, { Authorization: `Bearer ${authToken}`})
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response
  } else {
    response.ok = true
    return response
  }
}

export const getDataLetters = (name) => {
  let dataLetters = ''
  if (name) {
    if (name.split(' ')[0]) {
      dataLetters = dataLetters + name.split(' ')[0].charAt(0)
    }
    if (name.split(' ')[1]) {
      dataLetters = dataLetters + name.split(' ')[1].charAt(0)
    }  
  }
  return dataLetters
}

// testing function for google session
export const getTokenPassportSocialSession = async () => {
  try {
    const response = await fetch(`${domains().api}/login/success`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json'
      },
    })
    
    if (response.status < 200 || response.status >= 300) {
      let res = await response.json()
      res.status = response.status
      return await res
    }  
  
    const data = await response.json();
    const user = data.student.props;
    auth.saveToken(data.token);
    auth.saveSession(user);
  
    return data;
  } catch (error) {
    console.log(error)
  }
  
}

/**
 * API request to call the backend
 */
const requestApi = async (
  path = '',
  method = 'GET',
  data = null,
  headers = {}) => {
  if (!domains().api) {
    throw new Error(`Error: Missing API Domain – Please add the API domain from your serverless Express.js back-end to this front-end application.  You can do this in the "./config.js" file.  Instructions are listed there and in the documentation.`)
  }

  if (!path.startsWith('/')) {
    path = `/${path}`
  }
  const url = `${domains().api}${path}`

  headers = Object.assign(
    { 'Content-Type': 'application/json' },
    headers
  )

  const response = await fetch(url, {
    method: method.toUpperCase(),
    mode: 'cors',
    cache: 'no-cache',
    headers,
    body: data ? JSON.stringify(data) : null
  })

  if (response.status < 200 || response.status >= 300) {
    let res = await response.json()
    res.status = response.status
    return await res
  }

  return await response.json()
}
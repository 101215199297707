import React, { Component } from 'react'
import { Redirect } from 'react-router'
import {
//   // Link,
 withRouter
} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import { FiSearch } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'
import Form from 'react-bootstrap/Form'
// import Image from 'react-bootstrap/Image'
import styles from './index.module.css'

// const api = require('../../utils/api')

class SearchBar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showBar: false,
      formSearchQuery: '',
      go: false
    }
  }

  async componentDidMount() {
    if (this.props.showBar) {
      this.setState({
        showBar: true,
      })
    }
    // let currentUser = await api.currentUser()
    // this.setState({
    //   currentUser: currentUser
    // })
  }

  /**
   * Handle text changes within form fields
   */
  handleFormInput(field, value) {
    value = value.trim()

    const nextState = {}
    nextState[field] = value

    this.setState(Object.assign(this.state, nextState))
  }

  openBar = () => {
    this.setState({
      showBar: true
    })
  }
  
  closeBar = () => {
    this.setState({
      showBar: false
    })
  }

  searchSubmit = () => {
    if (this.state.formSearchQuery) {
      this.setState({
        go: true
      })
    }
  }

  render() {
    return (
      <>
        { !this.state.showBar ?
          <Button variant="link" className={styles.searchButtonMobile} onClick={() => { this.openBar() }}><FiSearch color="white"/></Button>
        : null }
        <Form className={`${styles.searchForm}`}> {/* @submit="searchSubmit(searchQuery)" */}
          <Form.Group className={styles.searchWrapper}>
            { this.state.showBar ?
            <>
              <Button variant="link" className={styles.searchButton} onClick={() => { this.closeBar() }}><MdClose color="white"/></Button>
              <Form.Control
                className={styles.searchInput}
                value={this.state.formSearchQuery}
                onChange={(e) => { this.handleFormInput('formSearchQuery', e.target.value) }}
                placeholder="buscar questões, matérias, provas, ..."
              ></Form.Control>
              <Button variant="link" type="submit" className={styles.searchButton} onClick={() => { this.searchSubmit() }}><FiSearch color="white"/></Button>
            </>
            : <Button variant="link" className={styles.searchButtonBigScreen} onClick={() => { this.openBar() }}><FiSearch color="white"/></Button>
            }
          </Form.Group>
        </Form>
        { this.state.go?
          <Redirect to={{
            pathname: '/search/' + this.state.formSearchQuery,
          }}/>
          : (<></>)
        }
      </>
    )
  }
}

export default withRouter(SearchBar)
import React, { Component } from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import styles from './index.module.css'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Loader from 'react-loader-spinner'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share"

const feedbacks = require('../../controllers/feedbacks')
const shareEvents = require('../../controllers/shareEvents')
const ratings = require('../../controllers/ratings')
// const api = require('../../utils/api')
const auth = require('../../utils/auth')

class Rating extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      chosenOne: {},
      resolutionList: [],
      currentUser: { can: [] },
      filledStar: null,
      notFilledStar: null,
      feedbackOrShare: false,
      loadingForm: false,
      rate: 0
    }

    this.handleFormInput = this.handleFormInput.bind(this)
    this.sendFeedback = this.sendFeedback.bind(this)
  }

  async componentDidMount() {
    const filledStar = require('../../assets/filled_star.svg')
    const notFilledStar = require('../../assets/star.svg')
    
    this.setState({
      loading: false,
      filledStar,
      notFilledStar
    })
  }

  starRating (value) {
    if(this.state.rate >= value) {
      return this.state.filledStar
    } else {
      return this.state.notFilledStar
    }
  }

  chooseRating (value) {
    this.setState({
      rate: value
    })
  }

  async skipRating () {
    this.setState({
      loading: true
    })
    
    const request = await ratings.register({ skip: true, unityId: this.props.unityId }, auth.getToken())
    if (request.ok) {
      this.setState({
        loading: false
      })
      if (this.props.callback) {
        this.props.callback()
      }
    }
  }

  async sendRating () {
    this.setState({
      loading: true
    })
    
    const request = await ratings.register({ value: this.state.rate, unityId: this.props.unityId }, auth.getToken())
    if (request.ok) {
      this.setState({
        loading: false,
        feedbackOrShare: true
      })
      // if (this.props.callback) {
      //   this.props.callback()
      // }
    }
  }

  async sendFeedback (evt) {
    evt.preventDefault()
    this.setState({ loadingForm: true })

    
    const request = await feedbacks.register({ content: this.state.formContent, origin: window.location.href }, auth.getToken())
    if (request.ok) {
      this.setState({
        loading: false,
      })
      if (this.props.callback) {
        this.props.callback()
      }
    }
  }

  async sendShareEvent (shareEvent) {
    this.setState({
      loading: true
    })
    
    const request = await shareEvents.register({ type: shareEvent.type, origin: window.location.href }, auth.getToken())
    if (request.ok) {
      this.setState({
        loading: false,
      })
      if (this.props.callback) {
        this.props.callback()
      }
    }
  }

  async skipFeedbackOrShare () {
    this.setState({
      loading: true
    })

    if (this.props.callback) {
      this.props.callback()
    }
  }

   /**
   * Handle text changes within form fields
   */
    handleFormInput(field, value) {
      const nextState = {}
      nextState[field] = value
  
      this.setState(Object.assign(this.state, nextState))
    }

  render() { 
    var { loading, rate, feedbackOrShare, loadingForm } = this.state
    var { show } = this.props
    var shareUrl = 'https://www.lampejo.app/'
    var title = 'Acabei de completar uma tarefa na Lampejo.app!'
    return (
      <>
        <Modal
          className={`${''}`}
          show={show}
          backdrop={"static"}
          keyboard={false}
          // data-aos='fade-up'
          // data-aos-duration="500"
        >
        { feedbackOrShare == false ?
          <>
            <Modal.Header
              style={{
                padding: "30px",
                justifyContent: "center",
                color: "#292f36",
                backgroundColor: "white",
                border: 0,
                borderRadius: "25px",
              }}
              >
              <Modal.Title style={{ fontWeight: "bolder", textAlign: "center", fontSize: "35px"}}>O que está achando da plataforma?</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                padding: "40px",
                justifyContent: "center",
                textAlign: "center",
                color: "#292f36",
                backgroundColor: "white",
                borderRadius: 25,
                border: 0
              }}>
              <Col sm={12}>
                <ButtonGroup aria-label="Basic example">
                  {[{ label: 'Péssima', value: 1 },
                    { label: 'Ruim', value: 2 },
                    { label: 'Ok', value: 3 },
                    { label: 'Bom', value: 4 },
                    { label: 'Excelente!', value: 5 }].map(option => {
                      return (
                        <OverlayTrigger
                          key={option.value}
                          placement={'top'}
                          overlay={
                            <Tooltip id={`tooltip-${option.value}`}>
                              {option.label}
                            </Tooltip>
                          }
                        >
                          <Button variant="link" onClick={(e) => { this.chooseRating(option.value) }}><Image src={this.starRating(option.value)}></Image></Button>
                        </OverlayTrigger>
                      )
                  })}
                </ButtonGroup>
              </Col>
              <Col sm={12} >
                <ButtonGroup aria-label="Basic example" className={`pt-5 w-100`}>
                  <Button variant="link" className={`${styles.secondaryLink}`} onClick={(e) => { this.skipRating() }}>pular</Button>
                  <Button variant="link" className={`${styles.primaryButton}`} disabled={this.state.rate == 0} onClick={(e) => { this.sendRating() }}>enviar</Button>
                </ButtonGroup>
              </Col>
            </Modal.Body>
          </>
        :
        rate >= 4 ?
          <>
            <Modal.Header
              style={{
                padding: "30px",
                justifyContent: "center",
                color: "#292f36",
                backgroundColor: "white",
                border: 0,
                borderRadius: "25px",
              }}
            >
              <Modal.Title style={{ fontWeight: "bolder", textAlign: "center", fontSize: "35px"}}>Obrigado pela avaliação 😊</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                padding: "40px",
                justifyContent: "center",
                textAlign: "center",
                color: "#292f36",
                backgroundColor: "white",
                borderRadius: 25,
                border: 0
              }}>
              <Col sm={12}>
                <h3>Compartilhe com seus amigos!</h3>
                <Row className={'justify-content-center'}>
                  <div className={`px-1`}>
                    <FacebookShareButton
                      url={shareUrl}
                      quote={title}
                      beforeOnClick={() => { this.sendShareEvent({type: 'facebook'}) }}
                      className="Demo__some-network__share-button"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                  </div>

                  <div className={`px-1`}>
                    <TwitterShareButton
                      url={shareUrl}
                      title={title}
                      beforeOnClick={() => { this.sendShareEvent({type: 'twitter'}) }}
                      className="Demo__some-network__share-button"
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                  </div>

                  <div className={`px-1`}>
                    <WhatsappShareButton
                      url={shareUrl}
                      title={title}
                      beforeOnClick={() => { this.sendShareEvent({type: 'whatsapp'}) }}
                      separator=":: "
                      className="Demo__some-network__share-button"
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </div>

                  <div className={`px-1`}>
                    <EmailShareButton
                      url={shareUrl}
                      subject={title}
                      beforeOnClick={() => { this.sendShareEvent({type: 'email'}) }}
                      body="body"
                      className="Demo__some-network__share-button"
                    >
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                  </div>
                </Row>
              </Col>
              <Col sm={12}>
                <ButtonGroup aria-label="Basic example" className={`pt-5 w-100`}>
                  <Button variant="link" className={`${styles.secondaryLink}`} onClick={(e) => { this.skipRating() }}>outra hora...</Button>
                </ButtonGroup>
              </Col>
            </Modal.Body>
          </> :
          <>
            <Modal.Header
              style={{
                padding: "30px",
                justifyContent: "center",
                color: "#292f36",
                backgroundColor: "white",
                border: 0,
                borderRadius: "25px",
              }}
            >
              <Modal.Title style={{ fontWeight: "bolder", textAlign: "center", fontSize: "35px"}}>O que podemos melhorar?</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                padding: "0 40px 40px 40px",
                justifyContent: "center",
                textAlign: "center",
                color: "#292f36",
                backgroundColor: "white",
                borderRadius: 25,
                border: 0
              }}>
              <Col sm={12}>
                <Form className={`${styles.form}`} onSubmit={this.sendFeedback}>
                  <Form.Group className={styles.formField}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className={styles.formInput}
                      value={this.state.formContent}
                      onChange={(e) => { this.handleFormInput('formContent', e.target.value) }}
                    />
                  </Form.Group>
                  {this.state.formError && (
                    <div className={styles.formError}>{this.state.formError}</div>
                  )}

                  <Button variant="link" className={`${styles.primaryButton} ${styles.centered} mt-3`} type="submit" value='Register'>
                    enviar { loadingForm? <Loader type="TailSpin" className={`ml-2`} color="#666" height={20} width={20} timeout={10000} /> : <></> }
                  </Button>
                </Form>
              </Col>
              <Col sm={12}>
                <ButtonGroup aria-label="Basic example" className={`pt-5 w-100`}>
                  <Button variant="link" className={`${styles.secondaryLink}`} onClick={(e) => { this.skipFeedbackOrShare() }}>pular</Button>
                </ButtonGroup>
              </Col>
            </Modal.Body>
          </>
          }
        </Modal>
      </>
    )
  }
}

export default withRouter(Rating)
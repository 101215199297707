
/**
 * Utils: Back-end
 */
import { requestApi } from '../utils'

export const getByUserId = async (id, authToken) => {
  const response = await requestApi('/stickers/byUserId/' + id, 'GET', null, { Authorization: `Bearer ${authToken}`})
  
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}

export const getById = async (id, authToken) => {
  const response = await requestApi('/stickers/' + id, 'GET', null, { Authorization: `Bearer ${authToken}`})
  
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}

export const get = async (authToken) => {
  const response = await requestApi('/stickers/', 'GET', null, { Authorization: `Bearer ${authToken}`})
  
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import styles from './index.module.css'
import Image from 'react-bootstrap/Image'
import Badge from 'react-bootstrap/Badge'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { BsCheckCircle, BsCircle, BsArrowRight, BsLockFill } from 'react-icons/bs'
import Modal from 'react-bootstrap/Modal'
import { Button } from '../../lampejo-styles/components/Button/Button'

import { CgChevronRightO, CgTime } from 'react-icons/cg'

const unity = require('../../controllers/unities')
const api = require('../../utils/api')

class TaskWidgetB extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      title: null,
      defaultIcon: null,
      type: '',
      showTutorial: false
    }
  }

  async componentDidMount() {
    this.setState({
      loading: true,
      unity: {},
      icon: null,
      title: null,
      type: ''
    })

    const defaultIcon = await require('../../assets/ilustracao_task.svg')
    
    let currentUser = await api.currentUser()
    let type = ''
    if (!this.props.task.unityId && this.props.task.questionsIds) {
      if (this.props.task.questionsIds.lenght > 0) {
        type = 'questions'
      }
    } else if (this.props.task.unityId) {
      type = 'content'
    }

    if (this.props.task.unityId != null) {
      const reqUnity = await unity.get(this.props.task.unityId, currentUser.id)
      if (reqUnity.ok) {
        const getUnity = reqUnity.body.unity
      
        if (getUnity != null) {
          this.setState({
            unity: getUnity,
            icon: getUnity.icon,
            title: getUnity.title,
            type: 'content',
            loading: false
          })
        }
      } else {
        this.setState({
          error: 'Houve um problema...',
          type: 'content'
        })
      }
    } else {
      this.setState({
        icon: this.props.task.icon,
        type,
        loading: false,
        defaultIcon
      })
    }
  }

  async componentWillReceiveProps(nextProps) {
    let currentUser = await api.currentUser()

    if (nextProps.task) {
      this.setState({
        loading: true,
        unity: {},
        icon: null,
        title: null,
        type: ''
      })
      if (nextProps.task.unityId != null) {
        const reqUnity = await unity.get(nextProps.task.unityId, currentUser.id)
        if (reqUnity.ok) {
          const getUnity = reqUnity.body.unity
        
          if (getUnity != null) {
            this.setState({
              unity: getUnity,
              icon: getUnity.icon,
              title: getUnity.title,
              type: 'content',
              loading: false
            })
          }
        }
      } else {
        let type = null
        if (nextProps.task.questionsIds) {
          if (nextProps.task.questionsIds.length > 0) {
            type = 'questions'
          } else {
            type = ''
          }
        } else {
          type = ''
        }
        
        this.setState({
          icon: nextProps.task.icon || null,
          type,
          loading: false
        })
      }
    }
  }

  render() { 
    var { type, defaultIcon, loading, showTutorial } = this.state
    var { task, actual, md } = this.props

    const notAvailable = task.questionsIds? task.questionsIds.length == 0 && !task.unityId : false
    return (
      <>
        <Col sm={12} md={md}>
          <Card className={notAvailable ? `${styles.borderRadius15} p-1 mt-4 ${styles.notClickableCard}`: task.isComplete? `${styles.borderRadius15} p-1 mt-4 ${styles.completedCard}` : `${styles.borderRadius15} p-1 mt-4 ${styles.clickableCard}`} as={notAvailable? 'div': `a`} href={notAvailable? '#': `/task/${task.id}`}
            style={{ backgroundColor: task.subject ? `#${task.subject.color}` : '#f1f1f1' }}
            >
            <Row style={{ minHeight: "62px" }}>
              <Col style={{ verticalAlign: "middle", margin: "auto", maxWidth: "fit-content", paddingLeft: "25px" }}>
                { task.isComplete? <Image src={require('../../assets/completed_task.svg')} size={"30px"} className={styles.buttonIcon}/> : <Image src={require('../../assets/not_completed_task.svg')} size={"30px"} className={styles.buttonIcon}/> }
              </Col>
              <Col className={`d-flex align-items-center`}>
                { task.title ? (
                  <Card.Title className={task.isComplete? `${styles.completedTaskTitle} mb-0` : `mb-0`} style={{ padding: '15px 0' }}><strong>{task.subject? task.subject.title? `${task.subject.title} - `: '' : '' }{task.title}</strong></Card.Title>
                ) : (
                  <Card.Title><strong><Skeleton width={200}/></strong></Card.Title>
                ) }
              </Col>
              <Col style={{ verticalAlign: "middle", margin: "auto", maxWidth: "fit-content", paddingRight: "25px" }}>
                { notAvailable? 
                  <BsLockFill size={"30px"} className={styles.buttonIcon}/> :
                  task.isComplete? <></> : <BsArrowRight size={"30px"} className={styles.buttonIcon}/>
                }
              </Col>
            </Row>
          </Card>
          {task.isComplete? <></> : <span className={`${styles.alwaysGrey} my-1 ml-auto mr-0 d-table`}><CgTime style={{ margin: ' auto 5px 4px auto' }}/> {task.spectedTime}</span> }
        </Col>
        <style>
          {
          `.content-border-radius-25 .modal-content {
              border-radius: 25px !important;
          }` 
          }
        </style>
        <Modal className={`${''}`} show={showTutorial === '1'} backdrop={"static"} keyboard={false} dialogClassName='content-border-radius-25'>
          <Image src={require('../../assets/progress_1_4.svg')} style={{ padding: "30px 10px 10px 10px", maxWidth: '80px', margin: "0 auto" }}/>
          <Image src={require('../../assets/logo_tipo_colors.svg')} style={{ padding: "20px 30px 20px 30px", maxWidth: '300px', margin: "0 auto" }}/>
          <Modal.Header
            style={{
              padding: "10px 30px",
              justifyContent: "center",
              color: "#292f36",
              backgroundColor: "white",
              border: 0,
              borderRadius: "25px",
            }}
          >
            <Modal.Title style={{ fontWeight: "bolder", textAlign: "center"}}>Seja bem-vind@ a Lampejo!</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              padding: "0 10px 50px 10px",
              justifyContent: "left",
              textAlign: "left",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 25,
              border: 0
              }}>
              <Col sm={12} style={{ textAlign: "center"}}>
                <p>Você está em uma plataforma de ensino focada no ENEM.<br/>Somos gratuitos. Agora e sempre.<br/></p>
              </Col>
              <Col sm={12} style={{ textAlign: 'center', display: 'flex', margin: '0 auto', justifyContent: 'center' }}>
                <Button
                  centered
                  onClick={(e) => { this.showTutorialValue('2') }}
                  label="próximo"
                />
              </Col>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default withRouter(TaskWidgetB)
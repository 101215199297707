import React, { Component }  from 'react'
import {
  // Link,
  withRouter
} from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import ReactPixel from 'react-facebook-pixel'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import PublicNavBar from '../../components/PublicNavBar'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

class Home extends Component {

  constructor(props) {
    super(props)
    this.state = {
      width: window.innerWidth,
      logo: null
    }
  }

  async componentDidMount() {
    const logo = require('../../assets/logo_colors.svg')
    AOS.init({duration: 300})
    this.setState({
      logo
    })
    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  render() {
    const { width, logo } = this.state
    const meta = {
      title: `Sobre nós | lampejo.app - Plataforma gratuita para o ENEM` ,
      description: `Somos uma comunidade de estudantes e professores que valorizam o compartilhamento do conhecimento. Você vai aprender praticando e ajudando os outros alunos.`,
      meta: {
        charset: 'utf-8',
        lang: 'pt-br',
        amp: true,
        name: {
          keywords: 'educação, vestibular, enem, questões, provas'
        }
      }
    }
    return (
      <>
        <DocumentMeta {...meta}/>
        { width < 780 ? 
          <></>  
        :
          <PublicNavBar active="about-us"></PublicNavBar>
        }
        <Container className={styles.homeContainer}>
          <br/><br/><br/><br/>
          <h2>A Plataforma</h2>
          <br/><br/>
          <Row className={styles.homePrincipal}>
            <Col sm={12} data-aos='fade-up'>
              <Card className={`${styles.missionCard}`}>
                <Row>
                  <Col sm={12} md={6}>
                    <Card.Title className={`${styles.missionTextTitle}`}>
                      Plataforma
                    </Card.Title>
                    <Card.Text>
                      <p>Estamos construindo uma plataforma online, inteligente e gratuita com todos os conteúdos do Ensino Médio.</p>
                      <p>Grande parte dos alunxs que finalizam essa fase em suas vidas tem o desejo de prestar o ENEM e outros vestibulares. Estamos juntos com elxs, dando as ferramentas certas para ultrapassar esse obstáculo.</p>
                    </Card.Text>
                    <br/>
                    <Card.Text>
                      <Button variant="link" className={styles.primaryButton} href={'/register'}>acessar agora!</Button>
                    </Card.Text>
                    <Card.Text>
                      <br/>
                      <hr/>
                      <p>Estamos contratando!</p>
                      <Button variant="link" className={styles.secondaryButton} href={'/jobs'}>ver carreiras</Button>
                    </Card.Text>
                  </Col>
                  <Col sm={12} md={6}>
                    <Image src={logo} style={{ paddingLeft: "40px", width: "100%" }}/>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default withRouter(Home)
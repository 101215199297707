import React, { Component }  from 'react'
import {
  withRouter,
  // Link,
} from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Breadcrumb from '../../../components/Breadcrumb'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import Skeleton from 'react-loading-skeleton'
import { MdChevronRight, MdTimer } from 'react-icons/md'
import { CgChevronRightO } from 'react-icons/cg'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import QuestionWidget from '../../../components/QuestionWidget'


import { secondsToHm } from '../../../utils/converter'

const exams = require('../../../controllers/exams')
const areas = require('../../../controllers/areas')
const questionsListsCtrl = require('../../../controllers/questionsLists')
const questionsCtrl = require('../../../controllers/questions')
const attempts = require('../../../controllers/attempts')
const api = require('../../../utils/api')
const auth = require('../../../utils/auth')

class Area extends Component {
  constructor(props) {
    super(props)

    this.state = {
      area: { title: '', questionsLists: [], questions: [] },
      exam: {},
      currentUser: { can: [] },
      numTags: 10,
      loading: true,
      loadingUpdate: false
    }

    this.runSimulation = this.runSimulation.bind(this)
    // this.renderExam = this.renderExam.bind(this)
  }

  async componentDidMount() {
    const id = this.props.match.params.id
    const examId = this.props.match.params.examId
    const CurrentUser = await api.currentUser()
    const request = await areas.getById(id, CurrentUser.id || '')
    let getArea = {}
    let getExam = { questionsLists: [] }
    if (request.ok) {
      getArea = request.body.cluster

      if (examId) {
        const reqExam = await exams.getById(examId)
        if (reqExam.ok) {
          getExam = reqExam.body.exam
        }
      }

      const reqQuestionsLists = await questionsListsCtrl.getByAreaByExam({ areaId: id, examId: examId })
      if (reqQuestionsLists.ok) {
        getArea.questionsLists = reqQuestionsLists.body.content
      }

      const reqQuestions = await questionsCtrl.getByAreaByExam({ areaId: id, examId: examId })
      if (reqQuestions.ok) {
        getArea.questions = reqQuestions.body.questions
      }
      
      // getExam.tags = getExam.tags.sort((a,b) => { return b.count - a.count })
      
      // const getQuestionsLists = await questionsListsCtrl.getByAreaId(id)      
      // getExam.questionsLists = getQuestionsLists.body.content

      this.setState({
        id: id,
        area: getArea,
        exam: getExam,
        loading: false
      })
    }

    if (CurrentUser) {
      this.setState({
        currentUser: CurrentUser
      })
    }

    AOS.init({
      duration: 300
    })
  }

  renderQuestionWidgetSkeleton() {
    let arrayWith10Elements = ['','','','','','','','','','']
    return arrayWith10Elements.map(() => {
      return (
        <div className={styles.questionWidget} data-aos="fade-up">
          <span><Skeleton width={200} height={16}/></span>
          <Button className={`${styles.roundButton}`}><MdChevronRight/></Button>
        </div>
      )
    })
  }

  renderTags(tag) {
    if (tag) {
      return (
        <Badge pill variant="light" className={`mx-2 ${styles.textDecorationNone}`} as="a" href={`/tag/${tag.id}`}>
          {tag.title}
        </Badge>
      )
    }
  }

  renderQuestionWidget(question) { 
    return (
      <QuestionWidget question={question} isResume={true} md={6}/>
    )
  }

  renderQuestionsListsWidget(questionsList) {
    return (
      <Col sm={12} md={4}>
        <Card className={`${styles.bgWhite} ${styles.borderRadius25} ${styles.toCenter} p-5 mt-3 w-100`} data-aos="fade-up">
          <Card.Title><strong>{questionsList.title}</strong></Card.Title>
          { !questionsList.questionsIds ?
          <Card.Title><Skeleton width={30}/> questões</Card.Title>
          :
          <>
            <Card.Text><MdTimer/> tempo: <strong>{secondsToHm(questionsList.maxTime)}</strong></Card.Text>
            <Card.Title>{questionsList.questionsIds.length} questões</Card.Title>
          </>
          }
          <Card.Text className={styles.dFlex}><Button variant="link" className={`${styles.primaryButton} ${styles.toBottomCenter}`} onClick={() => { this.runSimulation(questionsList.id)}}>iniciar</Button></Card.Text>
        </Card>
      </Col>
    )
  }

  async runSimulation(id) {
    const request = await attempts.register({
      userId: this.state.currentUser.id || null,
      questionsListId: id
    })

    this.props.history.push(`/attempt/${request.body.id}/${id}`)
  }

  render() {
    const { area, exam, loading, currentUser, loadingUpdate, numTags } = this.state
    return (
      <Container className={styles.container}>
        <Breadcrumb links={[
          { label: "Provas", to: "/exams" },
          { label: exam.name + " - " + exam.period },
          { label: area.title, active: true }
        ]}/>
        <Row className="justify-content-md-center">
          <Col sm={{ span: 7 }} className={'pt-5'}>
            { loading ? 
            <h2><Skeleton width={400}/></h2>
            :
            <h2>{area.title}</h2>
            }
          </Col>
          <Col sm={3} className={`pt-5 ${styles.toRight}`}>
          { currentUser.can.includes('see-admin-dashboard') ?
            <></>
            // <Dropdown>
            //   <Dropdown.Toggle variant="link" className={styles.primaryButton} id="dropdown-basic">
            //     área admin
            //   </Dropdown.Toggle>

            //   <Dropdown.Menu>
            //     <Dropdown.Item href={`/exam/${exam.id}/edit`}>editar prova</Dropdown.Item>
            //     <Dropdown.Item href={`/questions/byExam/${exam.id}`}>ver questões</Dropdown.Item>
            //     <Dropdown.Item href={`/question/edit/${exam.id}`}>adicionar questão</Dropdown.Item>
            //     <Dropdown.Item href={`/questionsLists/byExam/${exam.id}`}>ver listas/simulados</Dropdown.Item>
            //     <Dropdown.Item onClick={() => { this.updateExam() }}>
            //       { loadingUpdate?
            //         <Loader type="TailSpin" color="#292F36" height={20} width={20} timeout={10000} />
            //         : (<>atualizar prova</>)
            //       }
            //     </Dropdown.Item>  
            //     <Dropdown.Item href={`/questionsLists/edit/${exam.id}`}>adicionar simulado/lista</Dropdown.Item>
            //   </Dropdown.Menu>
            // </Dropdown>
            : <></>}
          </Col>
        </Row>
        <Row className={'pt-5'}>
          <Col sm={12} md={{ span: 10, offset: 1 }}>
          { loading ? (
            <>
              {/* {this.renderQuestionWidgetSkeleton()} */}
            </>
            ) :
              area.questionsLists ? (
                <>
                  <Row>
                    <Col><h3>Listas de Exercícios <small>(Questões {`${exam.name} - ${exam.period}`})</small></h3><br/></Col>
                  </Row>
                  <Row>
                    {area.questionsLists.length > 0 ?
                    area.questionsLists.map(questionsList => this.renderQuestionsListsWidget(questionsList)) :
                    <Col><p>ops... parece que não tem nenhuma lista disponível :( mas estamos trabalhando nisso!</p></Col>
                    }
                  </Row>
                  <Row>
                    <Col sm={12} md={6} data-aos="fade-up" data-aos-duration='500'>
                      <Card className={`${styles.tagBanner} ${styles.borderRadius25} p-5 mt-4`} as="a" href={`/area/${area.id}`}>
                        <Row>
                          <Col sm={10}>
                            <Card.Title>Quer ver todas as listas de <strong>{area.title}</strong>?</Card.Title>
                          </Col>
                          <Col sm={2} style={{ verticalAlign: "middle", margin: "auto" }}>
                            <CgChevronRightO size={"30px"}/>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </>
              ) : (<></>)
            }
          </Col>
        </Row>
        <Row className={'pt-5'}>
          <Col sm={12} md={{ span: 10, offset: 1 }}>
          { loading ? (
            <>
              {/* {this.renderQuestionWidgetSkeleton()} */}
            </>
            ) :
              area.questions ? (
                <>
                  <Row>
                    <Col><h3>Questões</h3><br/></Col>
                  </Row>
                  <Row>
                    {area.questions.map(question => this.renderQuestionWidget(question))}
                  </Row>
                </>
              ) : (<></>)
            }
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(Area)
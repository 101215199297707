import React, { Component } from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import Loader from 'react-loader-spinner'
import ButtonModuleRoad from '../ButtonModuleRoad'

const modules = require('../../controllers/modules')
const unities = require('../../controllers/unities')

// talvez não faça sentido.. já que quem determina qual unidade do módulo irá aparecer é o RoadPage
class Module extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  async componentDidMount() {
    const reqModule = await modules.get(this.props.module.id)
    const getModule = reqModule.body

    if (reqModule.ok) {
      const reqUnity = await unities.get(getModule.module.unitiesIds[0])
      const unity = reqUnity.body.unity

      let progress = 0
      this.props.module.isComplete? progress = 100 : progress = 0
      
      this.setState({ unity, loading: false, progress })
    }
  }

  render() { 
    var { unity, loading, progress } = this.state
    return (
      <>
        { loading?
          <Loader type="TailSpin" color="#c7ef00" height={20} width={20} timeout={10000} /> :
          <ButtonModuleRoad module={unity} href={`/road/${this.props.roadId}/unity/${unity.id}`} blocked={this.props.blocked} actual={this.props.actual} roadTo={this.props.roadTo} style={{ textAlign: "center", margin: "0 15px" }} progress={progress}/>
        }
      </>
    )
  }
}

export default withRouter(Module)
import React, { Component }  from 'react'
import { withRouter } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Unauthorized from '../Unauthorized'
import StickersWidget from '../../components/StickersWidget'
import { default as DefaultButton } from 'react-bootstrap/Button'
import { Button } from '../../lampejo-styles/components/Button/Button';
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Loader from 'react-loader-spinner'
import styles from './index.module.css'
import { toast } from 'react-toastify'
import { AiOutlineEdit } from 'react-icons/ai'
import { FiCopy, FiMail } from 'react-icons/fi'
import AOS from 'aos'
import 'aos/dist/aos.css'

import {
  BiBook,
  BiRocket,
  BiStar,
  BiLogOut
} from 'react-icons/bi'
import Card from 'react-bootstrap/esm/Card'

const users = require('../../controllers/users')
const api = require('../../utils/api')
const auth = require('../../utils/auth')
const roads = require('../../controllers/roads')

class User extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {
      },
      attempts: [],
      currentUser: { can: [] },
      numTags: 10,
      loading: true,
      loadingUpdate: false,
      dataLetters: null,
      road: { levels: [] },
      authorized: false
    }
  }

  async componentDidMount() {
    const id = this.props.match.params.id
    const CurrentUser = await api.currentUser()

    if (CurrentUser.id === id) {
      const request = await users.getById(id, auth.getToken())
      let getUser = {}
      if (request.ok) {
        getUser = request.body.user
        const dataLetters = await api.getDataLetters(getUser.name)

        let actualStickerUrl = null
        if (getUser.stickers) {
          const actualSticker = await getUser.actualSticker
          if (actualSticker) {
            actualStickerUrl = actualSticker.imgUrl
          }
        }
  
        this.setState({
          id: id,
          user: getUser,
          loading: false,
          dataLetters: dataLetters,
          authorized: true,
          actualStickerUrl
        })
      } else {
        this.setState( {
          authorized: false,
          loading: false
        })
      }
  
      if (CurrentUser) {
        this.setState({
          currentUser: CurrentUser
        })
      }
    } else {
      this.setState({
        authorized: false,
        loading: false
      })
    }
    
    AOS.init({
      duration: 300
    })

    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  async logout () {
    const loggedOut = auth.logout()
    if (loggedOut) {
      toast('Até logo!')
      setTimeout(() => {
        window.location.replace('/')
      }, 3000)
    }
    return loggedOut
  }

  render() {
    const { user, authorized, attempts, loading, currentUser, loadingUpdate, numTags, dataLetters, actualStickerUrl } = this.state
    if (authorized) {
      return (
        <Container className={styles.container}>
          <Row className="justify-content-md-center">
            <Col sm={12} md={{ span: 6 }} className={`d-none d-md-block mx-auto ${styles.userCard}`}>
              <Row sm={12} md={12}>
                <Col sm={4} style={{ alignSelf: 'center', paddingLeft: '25px' }}>
                  <span className={`${styles.userCircle}`}>
                    <span className={`${styles.userLettersName}`}>{dataLetters}</span>
                    {!!actualStickerUrl &&
                    <span>
                      <Image src={actualStickerUrl} style={{ position: 'absolute', top: '90px', right: '-15px', width: '80px' }}/>
                    </span>}
                  </span>
                </Col>
                <Col sm={8} style={{ alignSelf: 'center' }}>
                  <h3 className={'mb-3'}><strong>{user.name}</strong>{user.role === 'Estudante-beta' ? <span className={styles.betaTag}>β</span> : '' }</h3>
                  <p>
                    <BiRocket style={{marginRight: '0.5em', color: "white" }}/>{user.intendedCourse}<br/>
                    <BiBook style={{marginRight: '0.5em', color: "white" }}/>{user.scholarity}
                  </p>
                  <p style={{ color: "#c7ef00" }}><span style={{ fontSize: "40px", fontWeight: "bolder" }}><BiStar style={{ color: "#c7ef00", marginBottom: "10px", marginRight: "6px", fontSize: "30px" }}/>{user.points}</span> pts</p>
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={{ span: 6 }} className={`d-xs-block d-md-none mx-auto ${styles.userCardMobile}`}>
              <Row sm={12}>
                <Col xs={4} style={{ alignSelf: 'center' }}>
                  <span className={`${styles.userCircleMobile}`}>
                    <span className={`${styles.userLettersNameMobile}`}>{dataLetters}</span>
                  </span>
                  {!!actualStickerUrl &&
                    <span>
                      <Image src={actualStickerUrl} style={{ position: 'absolute', top: '70px', left: '90px', width: '60px' }}/>
                    </span>}
                </Col>
                <Col xs={8} style={{ alignSelf: 'center' }}>
                  <h3 className={'mb-3'}><strong>{user.name}</strong></h3>
                  <p>
                    <BiRocket style={{marginRight: '0.5em', color: "white" }}/>{user.intendedCourse}<br/>
                    <BiBook style={{marginRight: '0.5em', color: "white" }}/>{user.scholarity}
                  </p>
                  <p style={{ color: "#c7ef00" }}><span style={{ fontSize: "40px", fontWeight: "bolder" }}><BiStar style={{ color: "#c7ef00", marginBottom: "10px", marginRight: "6px", fontSize: "30px" }}/>{user.points}</span> pts</p>
                </Col>
              </Row>
            </Col>          
          </Row>

          {user.role === 'Estudante-beta' ? 
            <Row className="justify-content-md-center">
              <Col sm={12} md={{ span: 6 }} className={`mt-5 mx-auto`}>
                <h5><strong>Convites</strong></h5>
                <Card className={styles.bgDark}>
                  { user.invites > 0 ?
                  <>
                    <Card.Text>
                      <FiMail/> Você tem <strong>{user.invites}</strong> convites disponíveis
                    </Card.Text>
                    <Card.Text>
                      <Form.Group className={styles.formField}>
                        <Form.Label className={styles.formLabel}>Compartilhe o link abaixo para usar os seus convites:</Form.Label>
                        <Row>
                          <Col>
                            <Form.Control
                              type='text'
                              className={styles.formInput}
                              value={`https://www.lampejo.app/invite/${user.id}`}
                              onChange={(e) => { return }}
                            />
                          </Col>
                          <Col xs={2}>
                            <DefaultButton className={styles.copyBytton} onClick={(e) => { navigator.clipboard.writeText(`https://www.lampejo.app/invite/${user.id}`) }}><FiCopy/></DefaultButton>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Card.Text>
                  </>
                  :
                  <Card.Text>
                    👏 🎉 <strong>Parabéns!!!</strong> Você usou todos os seus convites! 🤩
                  </Card.Text>
                  }
                </Card>
              </Col>
            </Row>
          : <></> }
          <Row className="justify-content-md-center">
            <Col sm={12} md={{ span: 6 }} className={`mt-5 mx-auto`}>
              <h5><strong>Stickers</strong></h5>
              <StickersWidget userStickers={user.stickers || []}/>
            </Col>
          </Row>
          <Row className="justify-content-md-center mt-5">
            <Col sm={12} md={{ span: 6 }} className={`mx-auto d-flex mt-1`}>
              <Button
                centered
                label="editar informações e cronograma"
                rightIcon="edit"
                variant="secondary"
                onClick={() => window.location.replace(`/user/${user.id}/edit`)}
              />
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col sm={12} md={{ span: 6 }} className={`mt-5 mx-auto`}>
              <Button
                centered
                variant="danger"
                onClick={(e) => { return this.logout() }}
                rightIcon="logout"
                label="sair"
              />
            </Col>
          </Row>
        </Container>
      )
    } else if (!loading) {
      return (
        <Unauthorized/>
      )
    } else {
      return (
        <Container className={styles.container}>
          <Row className="justify-content-md-center">
            <Col sm={12} md={{ span: 6 }} className={`mx-auto`}>
              <Loader type="TailSpin" color="#c7ef00" height={100} width={100} timeout={10000} />
            </Col>
          </Row>
        </Container>            
      )
    }
  }
}

export default withRouter(User)
import React, { Component } from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import styles from './index.module.css'
// import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { AiOutlineSave } from 'react-icons/ai' 
// import Skeleton from 'react-loading-skeleton'
// import Select from 'react-select'
import { GrUser, GrBook, GrNavigate, GrMailOption, GrLicense, GrLogin, GrLogout, GrContactInfo } from 'react-icons/gr'
import { toast } from 'react-toastify'

const ratings = require('../../controllers/ratings')
const api = require('../../utils/api')
const auth = require('../../utils/auth')

class LoginModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      currentUser: { can: [] },
      state: 'login',
      error: null,
      formEmail: '',
      formPassword: '',
      formScholarity: '',
      formIntendedCourse: '',
      fromSchoolType: '',
      selectedScholarity: null,
      selectedIntendedCourse: null,
      selectedSchoolType: null,
      currentUser: {
        isLoggedIn: false,
        dataLetters: '',
        name: '',
        scholarity: '',
        intendedCourse: ''
      },
      scholarityOptions: [
        {value: 'Ensino Fundamental Incompleto', label: 'Ensino Fundamental Incompleto'},
        {value: 'Ensino Fundamental Completo', label: 'Ensino Fundamental Completo'},
        {value: '1º Ano do Ensino Médio', label: '1º Ano do Ensino Médio'},
        {value: '2º Ano do Ensino Médio', label: '2º Ano do Ensino Médio'},
        {value: '3º Ano do Ensino Médio', label: '3º Ano do Ensino Médio'},
        {value: 'Ensino Médio Completo', label: 'Ensino Médio Completo'},
        {value: 'Ensino Superior Incompleto', label: 'Ensino Superior Incompleto'},
        {value: 'Ensino Superior Completo', label: 'Ensino Superior Completo'}
      ],
      intendedCourseOptions: [
        {value: 'Administração', label: 'Administração'},
        {value: 'Administração Pública', label: 'Administração Pública'},
        {value: 'Agronegócios e Agropecuária', label: 'Agronegócios e Agropecuária'},
        {value: 'Ciências Aeronáuticas', label: 'Ciências Aeronáuticas'},
        {value: 'Ciências Atuariais', label: 'Ciências Atuariais'},
        {value: 'Ciências Contábeis', label: 'Ciências Contábeis'},
        {value: 'Ciências Econômicas', label: 'Ciências Econômicas'},
        {value: 'Comércio Exterior', label: 'Comércio Exterior'},
        {value: 'Defesa e Gestão Estratégica Internacional', label: 'Defesa e Gestão Estratégica Internacional'},
        {value: 'Gastronomia', label: 'Gastronomia'},
        {value: 'Gestão Comercial', label: 'Gestão Comercial'},
        {value: 'Gestão de Recursos Humanos', label: 'Gestão de Recursos Humanos'},
        {value: 'Gestão de Segurança Privada', label: 'Gestão de Segurança Privada'},
        {value: 'Gestão de Seguros', label: 'Gestão de Seguros'},
        {value: 'Gestão de Turismo', label: 'Gestão de Turismo'},
        {value: 'Gestão Financeira', label: 'Gestão Financeira'},
        {value: 'Gestão Pública', label: 'Gestão Pública'},
        {value: 'Hotelaria', label: 'Hotelaria'},
        {value: 'Logística', label: 'Logística'},
        {value: 'Marketing', label: 'Marketing'},
        {value: 'Negócios Imobiliários', label: 'Negócios Imobiliários'},
        {value: 'Pilotagem profissional de aeronaves', label: 'Pilotagem profissional de aeronaves'},
        {value: 'Processos Gerenciais', label: 'Processos Gerenciais'},
        {value: 'Segurança Pública', label: 'Segurança Pública'},
        {value: 'Turismo', label: 'Turismo'},
        {value: 'Animação', label: 'Animação'},
        {value: 'Arquitetura e Urbanismo', label: 'Arquitetura e Urbanismo'},
        {value: 'Artes Visuais', label: 'Artes Visuais'},
        {value: 'Comunicação das Artes do Corpo', label: 'Comunicação das Artes do Corpo'},
        {value: 'Conservação e Restauro', label: 'Conservação e Restauro'},
        {value: 'Dança', label: 'Dança'},
        {value: 'Design', label: 'Design'},
        {value: 'Design de Games', label: 'Design de Games'},
        {value: 'Design de Interiores', label: 'Design de Interiores'},
        {value: 'Design de Moda', label: 'Design de Moda'},
        {value: 'Fotografia', label: 'Fotografia'},
        {value: 'História da Arte', label: 'História da Arte'},
        {value: 'Jogos Digitais', label: 'Jogos Digitais'},
        {value: 'Luteria', label: 'Luteria'},
        {value: 'Música', label: 'Música'},
        {value: 'Produção Cênica', label: 'Produção Cênica'},
        {value: 'Produção Fonográfica', label: 'Produção Fonográfica'},
        {value: 'Teatro', label: 'Teatro'},
        {value: 'Agroecologia', label: 'Agroecologia'},
        {value: 'Agronomia', label: 'Agronomia'},
        {value: 'Alimentos', label: 'Alimentos'},
        {value: 'Biocombustíveis', label: 'Biocombustíveis'},
        {value: 'Biotecnologia', label: 'Biotecnologia'},
        {value: 'Biotecnologia e Bioquímica', label: 'Biotecnologia e Bioquímica'},
        {value: 'Ciência e Tecnologia de Alimentos', label: 'Ciência e Tecnologia de Alimentos'},
        {value: 'Ciências Agrárias', label: 'Ciências Agrárias'},
        {value: 'Ciências Biológicas', label: 'Ciências Biológicas'},
        {value: 'Ciências Naturais e Exatas', label: 'Ciências Naturais e Exatas'},
        {value: 'Ecologia', label: 'Ecologia'},
        {value: 'Geofísica', label: 'Geofísica'},
        {value: 'Geologia', label: 'Geologia'},
        {value: 'Gestão Ambiental', label: 'Gestão Ambiental'},
        {value: 'Medicina Veterinária', label: 'Medicina Veterinária'},
        {value: 'Meteorologia', label: 'Meteorologia'},
        {value: 'Oceanografia', label: 'Oceanografia'},
        {value: 'Produção de Bebidas', label: 'Produção de Bebidas'},
        {value: 'Produção Sucroalcooleira', label: 'Produção Sucroalcooleira'},
        {value: 'Rochas Ornamentais', label: 'Rochas Ornamentais'},
        {value: 'Zootecnia', label: 'Zootecnia'},
        {value: 'Ciências Exatas e Informática', label: 'Ciências Exatas e Informática'},
        {value: 'Astronomia', label: 'Astronomia'},
        {value: 'Banco de Dados', label: 'Banco de Dados'},
        {value: 'Ciência da Computação', label: 'Ciência da Computação'},
        {value: 'Ciência e Tecnologia', label: 'Ciência e Tecnologia'},
        {value: 'Computação', label: 'Computação'},
        {value: 'Estatística', label: 'Estatística'},
        {value: 'Física', label: 'Física'},
        {value: 'Gestão da Tecnologia da Informação', label: 'Gestão da Tecnologia da Informação'},
        {value: 'Informática Biomédica', label: 'Informática Biomédica'},
        {value: 'Matemática', label: 'Matemática'},
        {value: 'Nanotecnologia', label: 'Nanotecnologia'},
        {value: 'Química', label: 'Química'},
        {value: 'Redes de Computadores', label: 'Redes de Computadores'},
        {value: 'Segurança da Informação', label: 'Segurança da Informação'},
        {value: 'Sistemas de Informação', label: 'Sistemas de Informação'},
        {value: 'Sistemas para Internet', label: 'Sistemas para Internet'},
        {value: 'Arqueologia', label: 'Arqueologia'},
        {value: 'Ciências do Consumo', label: 'Ciências do Consumo'},
        {value: 'Ciências Humanas', label: 'Ciências Humanas'},
        {value: 'Ciências Sociais', label: 'Ciências Sociais'},
        {value: 'Cooperativismo', label: 'Cooperativismo'},
        {value: 'Direito', label: 'Direito'},
        {value: 'Escrita Criativa', label: 'Escrita Criativa'},
        {value: 'Estudos de Gênero e Diversidade', label: 'Estudos de Gênero e Diversidade'},
        {value: 'Filosofia', label: 'Filosofia'},
        {value: 'Geografia', label: 'Geografia'},
        {value: 'Gestão de Cooperativas', label: 'Gestão de Cooperativas'},
        {value: 'História', label: 'História'},
        {value: 'Letras', label: 'Letras'},
        {value: 'Libras', label: 'Libras'},
        {value: 'Linguística', label: 'Linguística'},
        {value: 'Museologia', label: 'Museologia'},
        {value: 'Pedagogia', label: 'Pedagogia'},
        {value: 'Psicopedagogia', label: 'Psicopedagogia'},
        {value: 'Relações Internacionais', label: 'Relações Internacionais'},
        {value: 'Serviço Social', label: 'Serviço Social'},
        {value: 'Serviços Judiciários e Notariais', label: 'Serviços Judiciários e Notariais'},
        {value: 'Teologia', label: 'Teologia'},
        {value: 'Tradutor e Intérprete', label: 'Tradutor e Intérprete'},
        {value: 'Arquivologia', label: 'Arquivologia'},
        {value: 'Biblioteconomia', label: 'Biblioteconomia'},
        {value: 'Cinema e Audiovisual', label: 'Cinema e Audiovisual'},
        {value: 'Comunicação em Mídias Digitais', label: 'Comunicação em Mídias Digitais'},
        {value: 'Comunicação Institucional', label: 'Comunicação Institucional'},
        {value: 'Comunicação Organizacional', label: 'Comunicação Organizacional'},
        {value: 'Educomunicação', label: 'Educomunicação'},
        {value: 'Estudos de Mídia', label: 'Estudos de Mídia'},
        {value: 'Eventos', label: 'Eventos'},
        {value: 'Gestão da Informação', label: 'Gestão da Informação'},
        {value: 'Jornalismo', label: 'Jornalismo'},
        {value: 'Produção Audiovisual', label: 'Produção Audiovisual'},
        {value: 'Produção Cultural', label: 'Produção Cultural'},
        {value: 'Produção Editorial', label: 'Produção Editorial'},
        {value: 'Produção Multimídia', label: 'Produção Multimídia'},
        {value: 'Produção Publicitária', label: 'Produção Publicitária'},
        {value: 'Publicidade e Propaganda', label: 'Publicidade e Propaganda'},
        {value: 'Rádio, TV e Internet', label: 'Rádio, TV e Internet'},
        {value: 'Relações Públicas', label: 'Relações Públicas'},
        {value: 'Secretariado', label: 'Secretariado'},
        {value: 'Secretariado Executivo', label: 'Secretariado Executivo'},
        {value: 'Agrimensura', label: 'Agrimensura'},
        {value: 'Aquicultura', label: 'Aquicultura'},
        {value: 'Automação Industrial', label: 'Automação Industrial'},
        {value: 'Construção Civil', label: 'Construção Civil'},
        {value: 'Construção Naval', label: 'Construção Naval'},
        {value: 'Eletrônica Industrial', label: 'Eletrônica Industrial'},
        {value: 'Eletrotécnica Industrial', label: 'Eletrotécnica Industrial'},
        {value: 'Energias Renováveis', label: 'Energias Renováveis'},
        {value: 'Engenharia Acústica', label: 'Engenharia Acústica'},
        {value: 'Engenharia Aeronáutica', label: 'Engenharia Aeronáutica'},
        {value: 'Engenharia Agrícola', label: 'Engenharia Agrícola'},
        {value: 'Engenharia Ambiental e Sanitária', label: 'Engenharia Ambiental e Sanitária'},
        {value: 'Engenharia Biomédica', label: 'Engenharia Biomédica'},
        {value: 'Engenharia Bioquímica, de Bioprocessos e Biotecnologia', label: 'Engenharia Bioquímica, de Bioprocessos e Biotecnologia'},
        {value: 'Engenharia Cartográfica e de Agrimensura', label: 'Engenharia Cartográfica e de Agrimensura'},
        {value: 'Engenharia Civil', label: 'Engenharia Civil'},
        {value: 'Engenharia da Computação', label: 'Engenharia da Computação'},
        {value: 'Engenharia de Alimentos', label: 'Engenharia de Alimentos'},
        {value: 'Engenharia de Biossistemas', label: 'Engenharia de Biossistemas'},
        {value: 'Engenharia de Controle e Automação', label: 'Engenharia de Controle e Automação'},
        {value: 'Engenharia de Energia', label: 'Engenharia de Energia'},
        {value: 'Engenharia de Inovação', label: 'Engenharia de Inovação'},
        {value: 'Engenharia de Materiais', label: 'Engenharia de Materiais'},
        {value: 'Engenharia de Minas', label: 'Engenharia de Minas'},
        {value: 'Engenharia de Pesca', label: 'Engenharia de Pesca'},
        {value: 'Engenharia de Petróleo', label: 'Engenharia de Petróleo'},
        {value: 'Engenharia de Produção', label: 'Engenharia de Produção'},
        {value: 'Engenharia de Segurança no Trabalho', label: 'Engenharia de Segurança no Trabalho'},
        {value: 'Engenharia de Sistemas', label: 'Engenharia de Sistemas'},
        {value: 'Engenharia de Software', label: 'Engenharia de Software'},
        {value: 'Engenharia de Telecomunicações', label: 'Engenharia de Telecomunicações'},
        {value: 'Engenharia de Transporte e da Mobilidade', label: 'Engenharia de Transporte e da Mobilidade'},
        {value: 'Engenharia Elétrica', label: 'Engenharia Elétrica'},
        {value: 'Engenharia Eletrônica', label: 'Engenharia Eletrônica'},
        {value: 'Engenharia Física', label: 'Engenharia Física'},
        {value: 'Engenharia Florestal', label: 'Engenharia Florestal'},
        {value: 'Engenharia Hídrica', label: 'Engenharia Hídrica'},
        {value: 'Engenharia Industrial Madeireira', label: 'Engenharia Industrial Madeireira'},
        {value: 'Engenharia Mecânica', label: 'Engenharia Mecânica'},
        {value: 'Engenharia Mecatrônica', label: 'Engenharia Mecatrônica'},
        {value: 'Engenharia Metalúrgica', label: 'Engenharia Metalúrgica'},
        {value: 'Engenharia Naval', label: 'Engenharia Naval'},
        {value: 'Engenharia Nuclear', label: 'Engenharia Nuclear'},
        {value: 'Engenharia Química', label: 'Engenharia Química'},
        {value: 'Engenharia Têxtil', label: 'Engenharia Têxtil'},
        {value: 'Fabricação Mecânica', label: 'Fabricação Mecânica'},
        {value: 'Geoprocessamento', label: 'Geoprocessamento'},
        {value: 'Gestão da Produção Industrial', label: 'Gestão da Produção Industrial'},
        {value: 'Gestão da Qualidade', label: 'Gestão da Qualidade'},
        {value: 'Irrigação e Drenagem', label: 'Irrigação e Drenagem'},
        {value: 'Manutenção de aeronaves', label: 'Manutenção de aeronaves'},
        {value: 'Manutenção Industrial (T/L)', label: 'Manutenção Industrial (T/L)'},
        {value: 'Materiais', label: 'Materiais'},
        {value: 'Mecatrônica Industrial', label: 'Mecatrônica Industrial'},
        {value: 'Mineração', label: 'Mineração'},
        {value: 'Papel e Celulose', label: 'Papel e Celulose'},
        {value: 'Petróleo e Gás', label: 'Petróleo e Gás'},
        {value: 'Processos Metalúrgicos', label: 'Processos Metalúrgicos'},
        {value: 'Processos Químicos', label: 'Processos Químicos'},
        {value: 'Produção Têxtil', label: 'Produção Têxtil'},
        {value: 'Saneamento Ambiental', label: 'Saneamento Ambiental'},
        {value: 'Segurança no Trabalho', label: 'Segurança no Trabalho'},
        {value: 'Silvicultura', label: 'Silvicultura'},
        {value: 'Sistemas Biomédicos', label: 'Sistemas Biomédicos'},
        {value: 'Sistemas de Telecomunicações', label: 'Sistemas de Telecomunicações'},
        {value: 'Sistemas Elétricos', label: 'Sistemas Elétricos'},
        {value: 'Sistemas Embarcados', label: 'Sistemas Embarcados'},
        {value: 'Transporte', label: 'Transporte'},
        {value: 'Biomedicina', label: 'Biomedicina'},
        {value: 'Educação Física', label: 'Educação Física'},
        {value: 'Enfermagem', label: 'Enfermagem'},
        {value: 'Esporte', label: 'Esporte'},
        {value: 'Estética e Cosmética', label: 'Estética e Cosmética'},
        {value: 'Farmácia', label: 'Farmácia'},
        {value: 'Fisioterapia', label: 'Fisioterapia'},
        {value: 'Fonoaudiologia', label: 'Fonoaudiologia'},
        {value: 'Gerontologia', label: 'Gerontologia'},
        {value: 'Gestão Desportiva e de Lazer', label: 'Gestão Desportiva e de Lazer'},
        {value: 'Gestão em Saúde', label: 'Gestão em Saúde'},
        {value: 'Gestão Hospitalar', label: 'Gestão Hospitalar'},
        {value: 'Medicina', label: 'Medicina'},
        {value: 'Musicoterapia', label: 'Musicoterapia'},
        {value: 'Naturologia', label: 'Naturologia'},
        {value: 'Nutrição', label: 'Nutrição'},
        {value: 'Obstetrícia', label: 'Obstetrícia'},
        {value: 'Odontologia', label: 'Odontologia'},
        {value: 'Oftálmica', label: 'Oftálmica'},
        {value: 'Optometria', label: 'Optometria'},
        {value: 'Psicologia', label: 'Psicologia'},
        {value: 'Quiropraxia', label: 'Quiropraxia'},
        {value: 'Radiologia', label: 'Radiologia'},
        {value: 'Saúde Coletiva', label: 'Saúde Coletiva'},
        {value: 'Terapia Ocupacional', label: 'Terapia Ocupacional'},
        {value: 'Outro', label: 'Outro'}
      ],
      schoolTypeOptions: [
        { value: 'Escola Pública', label: 'Escola Pública' },
        { value: 'Bolsista (Escola Particular)', label: 'Bolsista (Escola Particular)' },
        { value: 'Escola Particular', label: 'Escola Particular' }
      ]
    }
  
    // Bindings
    this.handleFormInput = this.handleFormInput.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleFormTypeChange = this.handleFormTypeChange.bind(this)
    this.handleSelectIntendedCourseChange = this.handleSelectIntendedCourseChange.bind(this)
    this.handleSelectScholarityChange = this.handleSelectScholarityChange.bind(this)
    this.handleSelectSchoolTypeChange = this.handleSelectSchoolTypeChange.bind(this)
  }
  
  async componentDidMount() {
    let currentUser = await api.currentUser()
    this.setState({
      loading: false,
      currentUser: currentUser
    })
  }
  
    /**
   * Handles a form change
   */
  handleFormTypeChange(type) {
    this.setState({
      state: type
    })
  }
  
  /**
   * Handle text changes within form fields
   */
  handleFormInput(field, value) {
    value = value.trim()

    const nextState = {}
    nextState[field] = value

    this.setState(Object.assign(this.state, nextState))
  }

  handleSelectScholarityChange(selectedOption) {
    this.setState({ selectedScholarity: selectedOption, formScholarity: selectedOption.value })
  }
  handleSelectIntendedCourseChange(selectedOption) {
    this.setState({ selectedIntendedCourse: selectedOption, formIntendedCourse: selectedOption.value })
  }
  handleSelectSchoolTypeChange(selectedOption) {
    this.setState({ selectedSchoolType: selectedOption, fromSchoolType: selectedOption.value })
  }

  /**
   * Handles form submission
   * @param {object} evt 
   */
  async handleFormSubmit(evt) {
    evt.preventDefault()

    this.setState({ loading: true })

    // Validate email
    if (!this.state.formEmail) {
      return this.setState({
        loading: false,
        formError: 'email é obrigatório'
      })
    }

    // Validate password
    if (!this.state.formPassword) {
      return this.setState({
        loading: false,
        formError: 'senha é obrigatória'
      })
    }

    // Validate name
    if (!this.state.formName && this.state.state === 'register') {
      return this.setState({
        loading: false,
        formError: 'nome é obrigatório'
      })
    }

    let response
      if (this.state.state === 'register') {
        response = await api.userRegister({
          "email": this.state.formEmail,
          "password": this.state.formPassword,
          "name": this.state.formName,
          "intendedCourse": this.state.formIntendedCourse || null,
          "scholarity": this.state.formScholarity || null,
          "schoolType": this.state.fromSchoolType || null
        })
        if (!response.ok) {
          console.log(response.error)
          this.setState({
            formError: response.error,
            loading: false
          })
        } else {
          toast.success('Cadastro realizado com sucesso!')
          if (this.props.callback) {
            this.props.callback()
          }
        }
      } else {
        response = await api.userLogin({ "email": this.state.formEmail, "password": this.state.formPassword })
        if (!response.ok) {
          this.setState({
            formError: response.error,
            loading: false
          })
        } else {
          this.setState({
            loding: false
          })
          this.props.closeLogin()
          toast.success('Logado com sucesso!')
          setTimeout(() => {
            if (this.props.callback) {
              this.props.callback()
            }
          }, 3000)
        }
      }
  }

  getOnlyFirstAndLast(name) {
    let result = ''
    if (name) {
      let nameSplit = name.split(' ')
      if (nameSplit[0]) {
        result += nameSplit[0]
      }
      if (nameSplit.length > 1) {
        result += ' ' + nameSplit[nameSplit.length - 1]
      }  
    }
    return result
  }

  async logout () {
    const loggedOut = auth.logout()
    if (loggedOut) {
      toast('Até logo!')
      this.props.closeLogin()
      setTimeout(() => {
        if (this.props.callback) {
          this.props.callback()
        }
      }, 3000)
    }
    return loggedOut
  }

  async skipRating () {
    this.setState({
      loading: true
    })
    
    const request = await ratings.register({ skip: true, unityId: this.props.unityId }, auth.getToken())
    if (request.ok) {
      this.setState({
        loading: false
      })
      if (this.props.callback) {
        this.props.callback()
      }
    }
  }

  formTypeChange (type) {
    this.setState({
      state: type
    })
  }

  render() { 
    var { loading, selectedIntendedCourse, selectedScholarity } = this.state
    var { content, md, isPreview, questionId, show } = this.props
    return (
      <>
        <Modal
          show={show}
          backdrop={"static"}
          keyboard={false}
          onHide={() => this.props.closeLogin()}
        >
        <Modal.Header closeButton></Modal.Header>
          <Modal.Body
            style={{
              justifyContent: "center",
              textAlign: "center",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 25,
              border: 0
              }}>
            <div className={`${styles.container}`}>
              <div className={styles.containerInner}>

              {this.state.state === 'login' && !this.state.loading && !this.state.currentUser.isLoggedIn && (
                <div className={styles.containerSignIn}>
                  <div className={`${styles.loginIcon}`}>
                    <AiOutlineSave/>
                  </div>
                  <h4>Para salvar o seu progresso,<br/> faça o <strong>login</strong> ou <strong>cadastre-se</strong></h4>
                  <Form className={styles.form} onSubmit={this.handleFormSubmit}>
                    <Form.Group className={styles.formField}>
                      <Form.Label className={styles.formLabel}><GrMailOption style={{marginRight: '0.5em'}}/>email</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='seu-email@exemplo.com'
                        className={styles.formInput}
                        value={this.state.formEmail}
                        onChange={(e) => { this.handleFormInput('formEmail', e.target.value) }}
                      />
                    </Form.Group>
                    <Form.Group className={styles.formField}>
                      <Form.Label className={styles.formLabel}><GrLicense style={{marginRight: '0.5em'}}/>senha</Form.Label>
                      <Form.Control
                        type='password'
                        placeholder='sua senha'
                        className={styles.formInput}
                        value={this.state.formPassword}
                        onChange={(e) => { this.handleFormInput('formPassword', e.target.value) }}
                      />
                    </Form.Group>

                    {this.state.formError && (
                      <div className={styles.formError}>{this.state.formError}</div>
                    )}

                    <Button variant="link" className={`${styles.secButton} ${styles.centered}`} type="submit" value='Sign In'>
                      Entrar
                    </Button>

                  </Form>
                  <p><Link className={styles.primaryLink} to={'/passwordrecover'}>esqueceu sua senha?</Link></p>

                  <hr/>
                  <p>ainda não se cadastrou? <Button variant="link" className={styles.secondaryLink} href="/register">cadastre-se agora!</Button></p>
                </div>
              )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default withRouter(LoginModal)
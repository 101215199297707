export const secondsToHm = (d) => {
  d = Number(d)
  var h = Math.floor(d / 3600)
  var m = Math.floor(d % 3600 / 60)

  var hDisplay = h > 0 ? h + 'h' : ''
  var mDisplay = m > 0 ? m + 'min' : ''
  return hDisplay + mDisplay
}

export const toDate = (d) => {
  const day = new Date(d).toLocaleDateString()
  const time = new Date(d).toLocaleTimeString({hour: '2-digit', minute: '2-digit'})
  return day + " " + time.substring(0, time.length - 3)
}

export const timeDiff = (d1, d2) => {
  const day1 = new Date(d1)
  const day2 = new Date(d2)
  return secondsToHm((day2 - day1) / 1000) || " - "
}
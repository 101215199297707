import Cookies from 'js-cookie'

export const saveToken = (token) => {
  localStorage.setItem('eq-token', token)
}

export const getToken = () => {
  const token = localStorage.getItem('eq-token')
  return token;
}

export const logout = () => {
  localStorage.removeItem('eq-token')
  Cookies.remove('eq-user')
  Cookies.remove('eq-user-stickers')
  return true
}

export const saveSession = (user) => {
  Cookies.set('eq-user', user)
}

export const saveStickers = (stickers) => {
  Cookies.set('eq-user-stickers', stickers)
}

export const getSession = () => {
  const data = Cookies.get('eq-user')
  return data ? JSON.parse(data) : null
}

export const getStickers = () => {
  const data = Cookies.get('eq-user-stickers')
  return data ? JSON.parse(data) : []
}
import React, { Component }  from 'react'
import {
  withRouter,
  // Link,
} from 'react-router-dom'
import Container from 'react-bootstrap/Container'
// import Breadcrumb from '../../../components/Breadcrumb'
import { toast } from 'react-toastify'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button } from '../../../lampejo-styles/components/Button/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import { AiFillLock } from 'react-icons/ai'
import DatePicker from 'react-datepicker'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

const users = require('../../../controllers/users')
const auth = require('../../../utils/auth')
const api = require('../../../utils/api')

class ExamEdit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {},
      state: 'edit',
      isLoading: false,
      selectedScholarity: null,
      selectedIntendedCourse: null,
      selectedSchoolType: null,
      formStudyDays: [],
      formSchoolType: null,
      chooseNotifyTime: false,
      scholarityOptions: [
        {value: 'Ensino Fundamental Incompleto', label: 'Ensino Fundamental Incompleto'},
        {value: 'Ensino Fundamental Completo', label: 'Ensino Fundamental Completo'},
        {value: '1º Ano do Ensino Médio', label: '1º Ano do Ensino Médio'},
        {value: '2º Ano do Ensino Médio', label: '2º Ano do Ensino Médio'},
        {value: '3º Ano do Ensino Médio', label: '3º Ano do Ensino Médio'},
        {value: 'Ensino Médio Completo', label: 'Ensino Médio Completo'},
        {value: 'Ensino Superior Incompleto', label: 'Ensino Superior Incompleto'},
        {value: 'Ensino Superior Completo', label: 'Ensino Superior Completo'}
      ],
      intendedCourseOptions: [
        {value: 'Administração', label: 'Administração'},
        {value: 'Administração Pública', label: 'Administração Pública'},
        {value: 'Agronegócios e Agropecuária', label: 'Agronegócios e Agropecuária'},
        {value: 'Ciências Aeronáuticas', label: 'Ciências Aeronáuticas'},
        {value: 'Ciências Atuariais', label: 'Ciências Atuariais'},
        {value: 'Ciências Contábeis', label: 'Ciências Contábeis'},
        {value: 'Ciências Econômicas', label: 'Ciências Econômicas'},
        {value: 'Comércio Exterior', label: 'Comércio Exterior'},
        {value: 'Defesa e Gestão Estratégica Internacional', label: 'Defesa e Gestão Estratégica Internacional'},
        {value: 'Gastronomia', label: 'Gastronomia'},
        {value: 'Gestão Comercial', label: 'Gestão Comercial'},
        {value: 'Gestão de Recursos Humanos', label: 'Gestão de Recursos Humanos'},
        {value: 'Gestão de Segurança Privada', label: 'Gestão de Segurança Privada'},
        {value: 'Gestão de Seguros', label: 'Gestão de Seguros'},
        {value: 'Gestão de Turismo', label: 'Gestão de Turismo'},
        {value: 'Gestão Financeira', label: 'Gestão Financeira'},
        {value: 'Gestão Pública', label: 'Gestão Pública'},
        {value: 'Hotelaria', label: 'Hotelaria'},
        {value: 'Logística', label: 'Logística'},
        {value: 'Marketing', label: 'Marketing'},
        {value: 'Negócios Imobiliários', label: 'Negócios Imobiliários'},
        {value: 'Pilotagem profissional de aeronaves', label: 'Pilotagem profissional de aeronaves'},
        {value: 'Processos Gerenciais', label: 'Processos Gerenciais'},
        {value: 'Segurança Pública', label: 'Segurança Pública'},
        {value: 'Turismo', label: 'Turismo'},
        {value: 'Animação', label: 'Animação'},
        {value: 'Arquitetura e Urbanismo', label: 'Arquitetura e Urbanismo'},
        {value: 'Artes Visuais', label: 'Artes Visuais'},
        {value: 'Comunicação das Artes do Corpo', label: 'Comunicação das Artes do Corpo'},
        {value: 'Conservação e Restauro', label: 'Conservação e Restauro'},
        {value: 'Dança', label: 'Dança'},
        {value: 'Design', label: 'Design'},
        {value: 'Design de Games', label: 'Design de Games'},
        {value: 'Design de Interiores', label: 'Design de Interiores'},
        {value: 'Design de Moda', label: 'Design de Moda'},
        {value: 'Fotografia', label: 'Fotografia'},
        {value: 'História da Arte', label: 'História da Arte'},
        {value: 'Jogos Digitais', label: 'Jogos Digitais'},
        {value: 'Luteria', label: 'Luteria'},
        {value: 'Música', label: 'Música'},
        {value: 'Produção Cênica', label: 'Produção Cênica'},
        {value: 'Produção Fonográfica', label: 'Produção Fonográfica'},
        {value: 'Teatro', label: 'Teatro'},
        {value: 'Agroecologia', label: 'Agroecologia'},
        {value: 'Agronomia', label: 'Agronomia'},
        {value: 'Alimentos', label: 'Alimentos'},
        {value: 'Biocombustíveis', label: 'Biocombustíveis'},
        {value: 'Biotecnologia', label: 'Biotecnologia'},
        {value: 'Biotecnologia e Bioquímica', label: 'Biotecnologia e Bioquímica'},
        {value: 'Ciência e Tecnologia de Alimentos', label: 'Ciência e Tecnologia de Alimentos'},
        {value: 'Ciências Agrárias', label: 'Ciências Agrárias'},
        {value: 'Ciências Biológicas', label: 'Ciências Biológicas'},
        {value: 'Ciências Naturais e Exatas', label: 'Ciências Naturais e Exatas'},
        {value: 'Ecologia', label: 'Ecologia'},
        {value: 'Geofísica', label: 'Geofísica'},
        {value: 'Geologia', label: 'Geologia'},
        {value: 'Gestão Ambiental', label: 'Gestão Ambiental'},
        {value: 'Medicina Veterinária', label: 'Medicina Veterinária'},
        {value: 'Meteorologia', label: 'Meteorologia'},
        {value: 'Oceanografia', label: 'Oceanografia'},
        {value: 'Produção de Bebidas', label: 'Produção de Bebidas'},
        {value: 'Produção Sucroalcooleira', label: 'Produção Sucroalcooleira'},
        {value: 'Rochas Ornamentais', label: 'Rochas Ornamentais'},
        {value: 'Zootecnia', label: 'Zootecnia'},
        {value: 'Ciências Exatas e Informática', label: 'Ciências Exatas e Informática'},
        {value: 'Astronomia', label: 'Astronomia'},
        {value: 'Banco de Dados', label: 'Banco de Dados'},
        {value: 'Ciência da Computação', label: 'Ciência da Computação'},
        {value: 'Ciência e Tecnologia', label: 'Ciência e Tecnologia'},
        {value: 'Computação', label: 'Computação'},
        {value: 'Estatística', label: 'Estatística'},
        {value: 'Física', label: 'Física'},
        {value: 'Gestão da Tecnologia da Informação', label: 'Gestão da Tecnologia da Informação'},
        {value: 'Informática Biomédica', label: 'Informática Biomédica'},
        {value: 'Matemática', label: 'Matemática'},
        {value: 'Nanotecnologia', label: 'Nanotecnologia'},
        {value: 'Química', label: 'Química'},
        {value: 'Redes de Computadores', label: 'Redes de Computadores'},
        {value: 'Segurança da Informação', label: 'Segurança da Informação'},
        {value: 'Sistemas de Informação', label: 'Sistemas de Informação'},
        {value: 'Sistemas para Internet', label: 'Sistemas para Internet'},
        {value: 'Arqueologia', label: 'Arqueologia'},
        {value: 'Ciências do Consumo', label: 'Ciências do Consumo'},
        {value: 'Ciências Humanas', label: 'Ciências Humanas'},
        {value: 'Ciências Sociais', label: 'Ciências Sociais'},
        {value: 'Cooperativismo', label: 'Cooperativismo'},
        {value: 'Direito', label: 'Direito'},
        {value: 'Escrita Criativa', label: 'Escrita Criativa'},
        {value: 'Estudos de Gênero e Diversidade', label: 'Estudos de Gênero e Diversidade'},
        {value: 'Filosofia', label: 'Filosofia'},
        {value: 'Geografia', label: 'Geografia'},
        {value: 'Gestão de Cooperativas', label: 'Gestão de Cooperativas'},
        {value: 'História', label: 'História'},
        {value: 'Letras', label: 'Letras'},
        {value: 'Libras', label: 'Libras'},
        {value: 'Linguística', label: 'Linguística'},
        {value: 'Museologia', label: 'Museologia'},
        {value: 'Pedagogia', label: 'Pedagogia'},
        {value: 'Psicopedagogia', label: 'Psicopedagogia'},
        {value: 'Relações Internacionais', label: 'Relações Internacionais'},
        {value: 'Serviço Social', label: 'Serviço Social'},
        {value: 'Serviços Judiciários e Notariais', label: 'Serviços Judiciários e Notariais'},
        {value: 'Teologia', label: 'Teologia'},
        {value: 'Tradutor e Intérprete', label: 'Tradutor e Intérprete'},
        {value: 'Arquivologia', label: 'Arquivologia'},
        {value: 'Biblioteconomia', label: 'Biblioteconomia'},
        {value: 'Cinema e Audiovisual', label: 'Cinema e Audiovisual'},
        {value: 'Comunicação em Mídias Digitais', label: 'Comunicação em Mídias Digitais'},
        {value: 'Comunicação Institucional', label: 'Comunicação Institucional'},
        {value: 'Comunicação Organizacional', label: 'Comunicação Organizacional'},
        {value: 'Educomunicação', label: 'Educomunicação'},
        {value: 'Estudos de Mídia', label: 'Estudos de Mídia'},
        {value: 'Eventos', label: 'Eventos'},
        {value: 'Gestão da Informação', label: 'Gestão da Informação'},
        {value: 'Jornalismo', label: 'Jornalismo'},
        {value: 'Produção Audiovisual', label: 'Produção Audiovisual'},
        {value: 'Produção Cultural', label: 'Produção Cultural'},
        {value: 'Produção Editorial', label: 'Produção Editorial'},
        {value: 'Produção Multimídia', label: 'Produção Multimídia'},
        {value: 'Produção Publicitária', label: 'Produção Publicitária'},
        {value: 'Publicidade e Propaganda', label: 'Publicidade e Propaganda'},
        {value: 'Rádio, TV e Internet', label: 'Rádio, TV e Internet'},
        {value: 'Relações Públicas', label: 'Relações Públicas'},
        {value: 'Secretariado', label: 'Secretariado'},
        {value: 'Secretariado Executivo', label: 'Secretariado Executivo'},
        {value: 'Agrimensura', label: 'Agrimensura'},
        {value: 'Aquicultura', label: 'Aquicultura'},
        {value: 'Automação Industrial', label: 'Automação Industrial'},
        {value: 'Construção Civil', label: 'Construção Civil'},
        {value: 'Construção Naval', label: 'Construção Naval'},
        {value: 'Eletrônica Industrial', label: 'Eletrônica Industrial'},
        {value: 'Eletrotécnica Industrial', label: 'Eletrotécnica Industrial'},
        {value: 'Energias Renováveis', label: 'Energias Renováveis'},
        {value: 'Engenharia Acústica', label: 'Engenharia Acústica'},
        {value: 'Engenharia Aeronáutica', label: 'Engenharia Aeronáutica'},
        {value: 'Engenharia Agrícola', label: 'Engenharia Agrícola'},
        {value: 'Engenharia Ambiental e Sanitária', label: 'Engenharia Ambiental e Sanitária'},
        {value: 'Engenharia Biomédica', label: 'Engenharia Biomédica'},
        {value: 'Engenharia Bioquímica, de Bioprocessos e Biotecnologia', label: 'Engenharia Bioquímica, de Bioprocessos e Biotecnologia'},
        {value: 'Engenharia Cartográfica e de Agrimensura', label: 'Engenharia Cartográfica e de Agrimensura'},
        {value: 'Engenharia Civil', label: 'Engenharia Civil'},
        {value: 'Engenharia da Computação', label: 'Engenharia da Computação'},
        {value: 'Engenharia de Alimentos', label: 'Engenharia de Alimentos'},
        {value: 'Engenharia de Biossistemas', label: 'Engenharia de Biossistemas'},
        {value: 'Engenharia de Controle e Automação', label: 'Engenharia de Controle e Automação'},
        {value: 'Engenharia de Energia', label: 'Engenharia de Energia'},
        {value: 'Engenharia de Inovação', label: 'Engenharia de Inovação'},
        {value: 'Engenharia de Materiais', label: 'Engenharia de Materiais'},
        {value: 'Engenharia de Minas', label: 'Engenharia de Minas'},
        {value: 'Engenharia de Pesca', label: 'Engenharia de Pesca'},
        {value: 'Engenharia de Petróleo', label: 'Engenharia de Petróleo'},
        {value: 'Engenharia de Produção', label: 'Engenharia de Produção'},
        {value: 'Engenharia de Segurança no Trabalho', label: 'Engenharia de Segurança no Trabalho'},
        {value: 'Engenharia de Sistemas', label: 'Engenharia de Sistemas'},
        {value: 'Engenharia de Software', label: 'Engenharia de Software'},
        {value: 'Engenharia de Telecomunicações', label: 'Engenharia de Telecomunicações'},
        {value: 'Engenharia de Transporte e da Mobilidade', label: 'Engenharia de Transporte e da Mobilidade'},
        {value: 'Engenharia Elétrica', label: 'Engenharia Elétrica'},
        {value: 'Engenharia Eletrônica', label: 'Engenharia Eletrônica'},
        {value: 'Engenharia Física', label: 'Engenharia Física'},
        {value: 'Engenharia Florestal', label: 'Engenharia Florestal'},
        {value: 'Engenharia Hídrica', label: 'Engenharia Hídrica'},
        {value: 'Engenharia Industrial Madeireira', label: 'Engenharia Industrial Madeireira'},
        {value: 'Engenharia Mecânica', label: 'Engenharia Mecânica'},
        {value: 'Engenharia Mecatrônica', label: 'Engenharia Mecatrônica'},
        {value: 'Engenharia Metalúrgica', label: 'Engenharia Metalúrgica'},
        {value: 'Engenharia Naval', label: 'Engenharia Naval'},
        {value: 'Engenharia Nuclear', label: 'Engenharia Nuclear'},
        {value: 'Engenharia Química', label: 'Engenharia Química'},
        {value: 'Engenharia Têxtil', label: 'Engenharia Têxtil'},
        {value: 'Fabricação Mecânica', label: 'Fabricação Mecânica'},
        {value: 'Geoprocessamento', label: 'Geoprocessamento'},
        {value: 'Gestão da Produção Industrial', label: 'Gestão da Produção Industrial'},
        {value: 'Gestão da Qualidade', label: 'Gestão da Qualidade'},
        {value: 'Investigação Forense e Perícia Criminal', label: 'Investigação Forense e Perícia Criminal'},
        {value: 'Irrigação e Drenagem', label: 'Irrigação e Drenagem'},
        {value: 'Manutenção de aeronaves', label: 'Manutenção de aeronaves'},
        {value: 'Manutenção Industrial (T/L)', label: 'Manutenção Industrial (T/L)'},
        {value: 'Materiais', label: 'Materiais'},
        {value: 'Mecatrônica Industrial', label: 'Mecatrônica Industrial'},
        {value: 'Mineração', label: 'Mineração'},
        {value: 'Papel e Celulose', label: 'Papel e Celulose'},
        {value: 'Petróleo e Gás', label: 'Petróleo e Gás'},
        {value: 'Processos Metalúrgicos', label: 'Processos Metalúrgicos'},
        {value: 'Processos Químicos', label: 'Processos Químicos'},
        {value: 'Produção Têxtil', label: 'Produção Têxtil'},
        {value: 'Saneamento Ambiental', label: 'Saneamento Ambiental'},
        {value: 'Segurança no Trabalho', label: 'Segurança no Trabalho'},
        {value: 'Silvicultura', label: 'Silvicultura'},
        {value: 'Sistemas Biomédicos', label: 'Sistemas Biomédicos'},
        {value: 'Sistemas de Telecomunicações', label: 'Sistemas de Telecomunicações'},
        {value: 'Sistemas Elétricos', label: 'Sistemas Elétricos'},
        {value: 'Sistemas Embarcados', label: 'Sistemas Embarcados'},
        {value: 'Transporte', label: 'Transporte'},
        {value: 'Biomedicina', label: 'Biomedicina'},
        {value: 'Educação Física', label: 'Educação Física'},
        {value: 'Enfermagem', label: 'Enfermagem'},
        {value: 'Esporte', label: 'Esporte'},
        {value: 'Estética e Cosmética', label: 'Estética e Cosmética'},
        {value: 'Farmácia', label: 'Farmácia'},
        {value: 'Fisioterapia', label: 'Fisioterapia'},
        {value: 'Fonoaudiologia', label: 'Fonoaudiologia'},
        {value: 'Gerontologia', label: 'Gerontologia'},
        {value: 'Gestão Desportiva e de Lazer', label: 'Gestão Desportiva e de Lazer'},
        {value: 'Gestão em Saúde', label: 'Gestão em Saúde'},
        {value: 'Gestão Hospitalar', label: 'Gestão Hospitalar'},
        {value: 'Medicina', label: 'Medicina'},
        {value: 'Musicoterapia', label: 'Musicoterapia'},
        {value: 'Naturologia', label: 'Naturologia'},
        {value: 'Nutrição', label: 'Nutrição'},
        {value: 'Obstetrícia', label: 'Obstetrícia'},
        {value: 'Odontologia', label: 'Odontologia'},
        {value: 'Oftálmica', label: 'Oftálmica'},
        {value: 'Optometria', label: 'Optometria'},
        {value: 'Psicologia', label: 'Psicologia'},
        {value: 'Quiropraxia', label: 'Quiropraxia'},
        {value: 'Radiologia', label: 'Radiologia'},
        {value: 'Saúde Coletiva', label: 'Saúde Coletiva'},
        {value: 'Terapia Ocupacional', label: 'Terapia Ocupacional'},
        {value: 'Outro', label: 'Outro'}
      ],
      schoolTypeOptions: [
        {value: 'Maior parte feito em ensino público', label: 'Maior parte feito em ensino público'},
        {value: 'Maior parte feito em ensino particular como bolsista', label: 'Maior parte feito em ensino particular como bolsista'},
        {value: 'Maior parte feito em ensino particular', label: 'Maior parte feito em ensino particular'}
      ],
      selectedNotificationTime: null,
      publicVapidKey: 'BNsQcZc_CWg45AGGRNImba_8pLAzQrbwCYgeJLH66zETqRgPXLWCmtEMEghIe_tbqzRNBhu1gk-GCWD9lhoafoY'
    }

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleSelectIntendedCourseChange = this.handleSelectIntendedCourseChange.bind(this)
    this.handleSelectScholarityChange = this.handleSelectScholarityChange.bind(this)
    this.handleSelectSchoolTypeChange = this.handleSelectSchoolTypeChange.bind(this)
    this.setNotificationTime = this.setNotificationTime.bind(this)
  }

  async componentDidMount() {
    const currentUser = await api.currentUser()

    if (currentUser.id === this.props.match.params.id) {
      const id = this.props.match.params.id
      const request = await users.getById(id, auth.getToken())
      if (request.ok) {
        const getUser = request.body.user

        this.handleSelectIntendedCourseChange({value: getUser.intendedCourse, label: getUser.intendedCourse})
        this.handleSelectScholarityChange({value: getUser.scholarity, label: getUser.scholarity})
        this.handleSelectSchoolTypeChange({value: getUser.schoolType, label: getUser.schoolType})

        this.setState({
          id: id,
          formName: getUser.name,
          formEmail: getUser.email, 
          formIntendedCourse: getUser.intendedCourse,
          formScholarity: getUser.scholarity,
          formIntendedCourse: getUser.intendedCourse,
          formSchoolType: getUser.schoolType,
          formStudyTime: getUser.dailyStudyTime,
          formStudyDays: getUser.studyDays,
        })
      }
    } else {
      window.location.replace(`/user/${this.props.match.params.id}`)
    }

    AOS.init({
      duration: 300
    })
  }

  handleSelectScholarityChange(selectedOption) {
    this.setState({ selectedScholarity: selectedOption, formScholarity: selectedOption.value })
  }
  handleSelectIntendedCourseChange(selectedOption) {
    this.setState({ selectedIntendedCourse: selectedOption, formIntendedCourse: selectedOption.value })
  }
  handleSelectSchoolTypeChange(selectedOption) {
    this.setState({ selectedSchoolType: selectedOption, formSchoolType: selectedOption.value })
  }

  selectStudyTime(selectedOption) {
    this.setState({ formStudyTime: selectedOption })
  }

  addToFormStudyDays(value) {
    let studyDays = this.state.formStudyDays
    if (!studyDays.includes(value)) {
      studyDays.push(value)
    } else {
      studyDays.splice(studyDays.indexOf(value), 1)
    }
    this.setState({ formStudyDays: studyDays })
  }

  setNotificationTime (date) {
    this.setState({
      selectedNotificationTime: date
    })
  }

  handleFormInput(field, value) {
    // value = value.trim()

    const nextState = {}
    nextState[field] = value

    this.setState(Object.assign(this.state, nextState))
  }

  isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window
  }

  async askUserPermissionToNotify() {
    return await Notification.requestPermission()
  }

  async onClickAskUserPermission () {
    this.askUserPermissionToNotify().then(async consent => {
      if (consent == "granted") {
        this.setState({
          chooseNotifyTime: true
        })
        
        const register = await navigator.serviceWorker.register('/worker.js', { scope: '/' })
        const subscription = await register.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: this.state.publicVapidKey })

        this.setState({
          subscription
        })
      }
    });
  };

  /**
   * Handles form submission
   * @param {object} evt 
   */
  async handleFormSubmit(evt) {
    evt.preventDefault()

    this.setState({ isLoading: true })

    // Validate name
    if (!this.state.formName) {
      return this.setState({
        isLoading: false,
        formError: 'nome é obrigatório'
      })
    }

    if (!this.state.formScholarity) {
      return this.setState({
        isLoading: false,
        formError: 'escolaridade é obrigatório'
      })
    }

    if (!this.state.formIntendedCourse) {
      return this.setState({
        isLoading: false,
        formError: 'curso desejado é obrigatório'
      })
    }

    if (!this.state.formStudyDays) {
      return this.setState({
        isLoading: false,
        formError: 'dias de estudo é obrigatório'
      })
    }

    if (!this.state.formStudyTime) {
      return this.setState({
        isLoading: false,
        formError: 'tempo de estudos por dia é obrigatório'
      })
    }

    let response
    response = await api.userUpdate({
      id: this.props.match.params.id,
      name: this.state.formName,
      intendedCourse: this.state.formIntendedCourse,
      scholarity: this.state.formScholarity,
      intendedCourse: this.state.formIntendedCourse,
      schoolType: this.state.formSchoolType || null,
      dailyStudyTime: this.state.formStudyTime,
      studyDays: this.state.formStudyDays,
      notificationTime: this.state.selectedNotificationTime || '-',
      notificationSubscription: this.state.subscription || null
    },
    auth.getToken()
    )
    if (!response.ok) {
      this.setState({
        formError: response.error,
        isLoading: false
      })
    } else {
      toast.success('Alterações salvas!')
      setTimeout(() => {
        window.location.replace(`/user/${this.props.match.params.id}`)
      }, 3000)
    }
  }

  render() {
    const { selectedScholarity, selectedIntendedCourse, selectedSchoolType, formStudyDays, chooseNotifyTime, selectedNotificationTime, isLoading } = this.state 
    return (
      <Container className={styles.container}>
        <Row>
          <Col sm={12} md={{ span: 6, offset: 3 }}>
            <h1>Editar informações</h1>
            <Card className={`${styles.bgDark} ${styles.borderRadius25} p-5 mt-4`} data-aos="fade-up">
              <Form className={styles.form} onSubmit={this.handleFormSubmit}>
                <Form.Group className={styles.formField}>
                  <Form.Label className={`${styles.formLabel} pr-3 pt-2`}>email</Form.Label><br/>
                  <span>{this.state.formEmail}<AiFillLock/></span>
                </Form.Group>
                <Form.Group className={styles.formField}>
                  <Form.Label className={`${styles.formLabel} pr-3`}>senha</Form.Label><br/>
                  <Button
                    variant="secondary"
                    onClick={(e) => { window.location.replace('/passwordrecover') }}
                    label="editar senha"
                  />
                </Form.Group>
                <Form.Group className={styles.formField}>
                  <Form.Label className={styles.formLabel}>nome</Form.Label>
                  <Form.Control
                    type='text'
                    className={styles.formInput}
                    value={this.state.formName}
                    onChange={(e) => { this.handleFormInput('formName', e.target.value) }}
                  />
                </Form.Group>
                <Form.Group className={styles.formField}>
                  <Form.Label className={styles.formLabel}>escolaridade</Form.Label>
                  <Select
                    className={styles.formSelect}
                    placeholder={'onde você está hoje?'}
                    value={selectedScholarity}
                    options={this.state.scholarityOptions}
                    onChange={this.handleSelectScholarityChange}
                    styles={{
                      control: (styles, { data, isFocused, isSelected, isActive }) => {
                        return {
                          ...styles,
                          backgroundColor: '#292F36',
                          borderColor: isFocused
                          ? '#c7ef00'
                          : "rgba(255, 255, 255, 0.2)",
                          boxShadow: isFocused
                          ? '#c7ef00'
                          : null,
                          color: "white",
                          ':hover': {
                            borderColor: '#c7ef00',
                          },
                          }
                        },
                        menu: (styles, { data }) => {
                          return {
                            ...styles,
                            backgroundColor: '#292F36'
                          }
                        },
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                          const color = '#292F36';
                          return {
                            ...styles,
                            backgroundColor: isDisabled
                              ? null
                              : isSelected
                              ? data.color
                              : isFocused
                              ? "#c7ef00"
                              : null,
                            color: isDisabled
                              ? '#ccc'
                              : isSelected
                              ? data.color
                              : isFocused
                              ? "#292F36"
                              : null,
                            cursor: isDisabled ? 'not-allowed' : 'default',
                            ':active': {
                              ...styles[':active'],
                              backgroundColor: !isDisabled && (isSelected ? data.color : "#292F36"),
                            },
                          };
                        },
                        singleValue: (styles, { data }) => ({ ...styles, color: "white" })
                      }}
                    ></Select>
                </Form.Group>
                <Form.Group className={styles.formField}>
                  <Form.Label className={styles.formLabel}>curso desejado</Form.Label>
                  <Select
                    className={styles.formSelect}
                    placeholder={'busque pelo seu curso!'}
                    value={selectedIntendedCourse}
                    options={this.state.intendedCourseOptions}
                    onChange={this.handleSelectIntendedCourseChange}
                    styles={{
                      control: (styles, { data, isFocused, isSelected, isActive }) => {
                        return {
                          ...styles,
                          backgroundColor: '#292F36',
                          borderColor: isFocused
                          ? '#c7ef00'
                          : "rgba(255, 255, 255, 0.2)",
                          boxShadow: isFocused
                          ? '#c7ef00'
                          : null,
                          color: "white",
                          ':hover': {
                            borderColor: '#c7ef00',
                          },
                          }
                        },
                        menu: (styles, { data }) => {
                          return {
                            ...styles,
                            backgroundColor: '#292F36'
                          }
                        },
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                          const color = '#292F36';
                          return {
                            ...styles,
                            backgroundColor: isDisabled
                              ? null
                              : isSelected
                              ? data.color
                              : isFocused
                              ? "#c7ef00"
                              : null,
                            color: isDisabled
                              ? '#ccc'
                              : isSelected
                              ? data.color
                              : isFocused
                              ? "#292F36"
                              : null,
                            cursor: isDisabled ? 'not-allowed' : 'default',
                            ':active': {
                              ...styles[':active'],
                              backgroundColor: !isDisabled && (isSelected ? data.color : "#292F36"),
                            },
                          };
                        },
                        singleValue: (styles, { data }) => ({ ...styles, color: "white" })
                      }}
                    ></Select>
                </Form.Group>
                { this.state.formScholarity == 'Ensino Fundamental Incompleto' || this.state.formScholarity == 'Ensino Fundamental Completo' ?
                  <></> :
                  <Form.Group className={styles.formField}>
                    <Form.Label className={styles.formLabel}>sobre o seu ensino médio:</Form.Label>
                    <Select
                      className={styles.formSelect}
                      placeholder={'escolha uma das opções'}
                      value={selectedSchoolType}
                      options={this.state.schoolTypeOptions}
                      onChange={this.handleSelectSchoolTypeChange}
                      styles={{
                        control: (styles, { data, isFocused, isSelected, isActive }) => {
                          return {
                            ...styles,
                            backgroundColor: '#292F36',
                            borderColor: isFocused
                            ? '#c7ef00'
                            : "rgba(255, 255, 255, 0.2)",
                            boxShadow: isFocused
                            ? '#c7ef00'
                            : null,
                            color: "white",
                            ':hover': {
                              borderColor: '#c7ef00',
                            },
                            }
                          },
                          menu: (styles, { data }) => {
                            return {
                              ...styles,
                              backgroundColor: '#292F36'
                            }
                          },
                          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            const color = '#292F36';
                            return {
                              ...styles,
                              backgroundColor: isDisabled
                                ? null
                                : isSelected
                                ? data.color
                                : isFocused
                                ? "#c7ef00"
                                : null,
                              color: isDisabled
                                ? '#ccc'
                                : isSelected
                                ? data.color
                                : isFocused
                                ? "#292F36"
                                : null,
                              cursor: isDisabled ? 'not-allowed' : 'default',
                              ':active': {
                                ...styles[':active'],
                                backgroundColor: !isDisabled && (isSelected ? data.color : "#292F36"),
                              },
                            };
                          },
                          singleValue: (styles, { data }) => ({ ...styles, color: "white" })
                        }}
                      ></Select>
                  </Form.Group>
                }
                <Form.Group controlId="formBasicRange">
                  <Form.Label className={styles.formLabel}>tempo de estudos por dia</Form.Label><br/>
                  <div className={`${styles.card} text-center`}>

                    <style>
                      {
                      `
                      .custom-range::-webkit-slider-thumb {
                        background: #c7ef00;
                        color: #c7ef00;
                        background-color: #c7ef00;
                      }
                      
                      .custom-range::-moz-range-thumb {
                        background: #c7ef00;
                        color: #c7ef00;
                        background-color: #c7ef00;
                      }
                      
                      .custom-range::-ms-thumb {
                        background: #c7ef00;
                        color: #c7ef00;
                        background-color: #c7ef00;
                      }
                      `
                    }
                    </style>
                    <Form.Label><h5>{this.state.formStudyTime} {this.state.formStudyTime > 1? 'horas' : 'hora'}</h5></Form.Label>
                    <Form.Control className={`custom-range`} type="range" min="1" max="12" onChange={e => this.selectStudyTime(e.target.value)} /><br/>
                    {this.state.formStudyTime == 1? <span>caso você possa estudar menos do que 1h por dia, selecione 1h.</span>: <></> }
                  </div>
                </Form.Group>
                <Form.Label className={styles.formLabel}>dias de estudo</Form.Label><br/>
                <div className={`${styles.card} text-inline`}>
                  <Form.Group className={`${styles.formField}`}>
                    { [ { label: 'dom', value: 0 },
                        { label: 'seg', value: 1 },
                        { label: 'ter', value: 2 },
                        { label: 'qua', value: 3 },
                        { label: 'qui', value: 4 },
                        { label: 'sex', value: 5 },
                        { label: 'sab', value: 6 }].map(day => { return (
                          <div className={styles.checkForm} onClick={(e) => { this.addToFormStudyDays(day.value) }}>
                            {  formStudyDays.includes(day.value)?
                            <span className={styles.activeCheck}></span> : <span className={styles.check}></span>
                          }
                          <span className={`pl-5`}>{day.label}</span><br/>
                          </div>
                        )
                    }) }
                    
                  </Form.Group> 
                </div>

                <Form.Label className={styles.formLabel}>notificações</Form.Label><br/>
                { chooseNotifyTime? (
                <div className={`${styles.card} text-inline py-5`}>
                  <style>
                  {`
                    .react-datepicker__time-container {
                      background: #292F36;
                      width: 100%;
                      padding: 0 30px;
                      max-height: 300px;
                      overflow-y: scroll;
                      border: solid 1px #ffffff30;
                      border-radius: 12px;
                    }
                    .react-datepicker__time-list-item {
                      display: flex;
                      margin: 20px auto;
                      cursor: pointer;
                    }
                    .react-datepicker__time-list-item:hover {
                      color: #c7ef00;
                    }
                    .react-datepicker__time-list {
                      padding: 0;
                    }
                    .react-datepicker-time__header {
                      font-weight: bolder;
                      padding: 10px;
                      text-align: center;
                      border-bottom: solid 1px #ffffff30;
                    }
                    .react-datepicker-popper {
                      width: 50%;
                    }
                    .react-datepicker-wrapper {
                      display: flex;
                    }
                    .react-datepicker__input-container {
                      display: flex;
                    }
                    .react-datepicker__input-container input {
                      background: transparent;
                      border: solid 1px #c7ef00;
                      border-radius: 6px;
                      padding: 5px;
                      color: white;
                    }
                  `}


                  </style>
                  <p>Selecione o horário que você quer ser notificad@:</p>
                  <DatePicker
                    selected={selectedNotificationTime}
                    onChange={(date) => this.setNotificationTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Hora"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                  />
                </div>
                ) : (
                  <div className={`${styles.card} text-inline py-5`}>
                    <Button
                      variant="tertiary"
                      centered
                      onClick={() => { this.onClickAskUserPermission() }}
                      label="clique aqui para permitir notificações"
                      />
                  </div>
                ) }

                {this.state.formError && (
                  <div className={styles.formError}>{this.state.formError}</div>
                )}

                <Button
                  centered
                  type="submit"
                  value='Register'
                  label="salvar"
                  isLoading={isLoading}
                />
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(ExamEdit)
import React, { Component }  from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import Select from 'react-select'
import ReactPixel from 'react-facebook-pixel'
import { FaBook, FaMapSigns, FaKey, FaRegEnvelope, FaRegUser } from 'react-icons/fa'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Loader from 'react-loader-spinner'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

const api = require('../../utils/api')
const auth = require('../../utils/auth')

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      currentUser: { can: [] },
      error: null,
      formEmail: '',
      loadingForm: false,
      
      currentUser: {
        isLoggedIn: false,
        dataLetters: '',
        name: '',
        scholarity: '',
        intendedCourse: ''
      },
    }
    
    this.handleFormInput = this.handleFormInput.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  async componentDidMount() {
    const currentUser = await api.currentUser()
    let origin = this.props.match.params.origin

    api.userRegister(currentUser || null, { type: 'page', to: '/leads/' + origin })

    let title = ""
    let message = ""

    if (origin == 'estudantes-convidados-para-o-beta') {
      title = "Insira seu email abaixo"
      message = "para ter acesso ao cronograma"
    }

    if (origin == 'seja-beta-home') {
      title = "Insira seu email abaixo"
      message = "para solicitar o convite"
    }

    if (origin == 'maze-cronograma') {
      title = "Insira seu email abaixo"
      message = "para receber o link"
    }

    const ilustracaoBackground = require('../../assets/background.svg')

    this.setState({
      loading: false,
      origin,
      title,
      message,
      ilustracaoBackground
    })

    AOS.init({
      duration: 300
    })

    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  /**
   * Handle text changes within form fields
   */
  handleFormInput(field, value) {
    const nextState = {}
    nextState[field] = value

    this.setState(Object.assign(this.state, nextState))
  }

  /**
   * Handles form submission
   * @param {object} evt 
   */
  async handleFormSubmit(evt) {
    evt.preventDefault()
    this.setState({ loadingForm: true })

    const response = await api.leadRegister({
      "email": this.state.formEmail,
      "origin": this.state.origin
    })
    if (!response.ok) {
      this.setState({
        formError: response.error,
        loading: false
      })
    } else {
      window.location.replace(`/obrigado`)
    }
  }

  render() {
    const { title, message, loadingForm } = this.state

    return (
      <div className={`${styles.all}`} style={{
        backgroundImage: `url(${this.state.ilustracaoBackground })`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}>
        <Col className={styles.containerRegister} sm={12} md={{ span: 4, offset: 4 }}>
          <Image fluid src={require('../../assets/logo_lampejo.png')} href="/" className={`${styles.imgLogo} align-top d-block d-md-none`} alt='lampejo.app'/>
          <h2>{title}</h2>
          <p style={{ maxWidth: "400px", margin: "10px auto 40px auto" }}>{message}</p>
            <Form className={`${styles.form}`} onSubmit={this.handleFormSubmit}>
            <div className={`${styles.card}`}>
              <Form.Group className={styles.formField}>
                <Form.Label className={styles.formLabel}><FaRegEnvelope style={{marginRight: '0.5em'}}/>email</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='seu-email@exemplo.com'
                  className={styles.formInput}
                  value={this.state.formEmail}
                  onChange={(e) => { this.handleFormInput('formEmail', e.target.value) }}
                />
              </Form.Group>
              {this.state.formError && (
                <div className={styles.formError}>{this.state.formError}</div>
              )}

              <Button variant="link" className={`${styles.primaryButton} ${styles.centered} mt-3`} type="submit" value='Register'>
                vamos lá { loadingForm? <Loader type="TailSpin" className={`ml-2`} color="#666" height={20} width={20} timeout={10000} /> : <></> }
              </Button>
            </div>

           
          </Form>
        </Col>
      </div>
    )
  }
}

export default withRouter(Register)
import React, { Component }  from 'react'
import {
  withRouter,
  // Link,
} from 'react-router-dom'

import Cell from '../../components/Cell'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import Rating from '../../components/Rating'
import LoginModal from '../../components/LoginModal'
import { IoIosArrowBack, IoMdClose } from 'react-icons/io'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

const api = require('../../utils/api')
const unities = require('../../controllers/unities')
const cellsCtrl = require('../../controllers/cells')
const cellConclusion = require('../../controllers/cellConclusion')
const progress = require('../../controllers/progress')
const auth = require('../../utils/auth')

class Unity extends Component {
  constructor(props) {
    super(props)

    this.state = {
      order: 1,
      actualCell: {}, 
      unity: { cells: [] },
      questionsList: { questionsIds: [] },
      progress: '',
      showFinishModal: false,
      showRatingModal: false,
      showLoginModal: false,
      checkImage: null
    }

    this.toNextCell= this.toNextCell.bind(this)
  }

  async componentDidMount() {
    let initialTime = new Date();
    this.setState({
      initialTime
    })

    let CurrentUser = await api.currentUser()

    if (!CurrentUser.isLoggedIn) {
      api.userRegister(null, { type: 'page', to: `/road/${this.props.match.params.roadId}/unity/${this.props.match.params.id}/${this.props.match.params.order}` })
      this.setState({
        currentUser: CurrentUser,
        unity: { id: this.props.match.params.id }
      })
      this.openLoginModal()
    }
    this.props.match.params.order?
    api.visitRegister(CurrentUser, { type: 'page', to: `/road/${this.props.match.params.roadId}/unity/${this.props.match.params.id}/${this.props.match.params.order}` }):
    api.visitRegister(CurrentUser, { type: 'page', to: `/road/${this.props.match.params.roadId}/unity/${this.props.match.params.id}/` })
    
    const id = this.props.match.params.id
    const reqUnity = await unities.get(id, CurrentUser.id)
    if (reqUnity.ok) {
      const getUnity = reqUnity.body.unity
      
      const reqCells = await cellsCtrl.getByUnityId(getUnity.id)
      getUnity.cells = reqCells.body.cells.sort((a, b) => { return a.order - b.order }) || []
      
      let order = 1
      let progress
      let actualCell = {}
      if (this.props.match.params.order) {
        order = this.props.match.params.order
        actualCell = await getUnity.cells.filter(e => { return e.order === this.props.match.params.order })[0]
        progress = (getUnity.cells.indexOf(actualCell)) * 100 / getUnity.cells.length
      } else {
        actualCell = getUnity.cells[0]
        progress = 10
      }
  
      this.setState({
        unity: getUnity,
        actualCell: actualCell,
        progress: progress,
        order
      })

      const checkImage = require('../../assets/check-circle.gif')

      this.setState({
        currentUser: CurrentUser,
        checkImage: checkImage
      })

      AOS.init({
        duration: 300
      })
    }
  }

  openTutorial(type) {
    if (type === 'blind') {
      this.setState({
        showModalTutorialBlindTest: true
      })
    } else {
      this.setState({
        showModalTutorial: true
      })
    }
  }

  closeTutorial() {
    this.setState({
      showModalTutorialBlindTest: false,
      showModalTutorial: false
    })
  }

  openResolution() {
    return true
  }

  async openFinishModal() {
    if (this.state.isBlindList) {
      this.setState({
        showFinishModalBlindList: true
      })
    } else {
      this.setState({
        showFinishModal: true
      })       
    }
  }

  async openLoginModal() {
    this.setState({
      showLoginModal: true
    })
  }

  async closeLoginModal() {
    this.setState({
      showLoginModal: false
    })
  }


  async toNextCell() {
    let { unity, actualCell } = this.state
    unity.roadId = this.props.match.params.roadId

    const sortedCells = await unity.cells.sort((a, b) => { return parseInt(a.order) - parseInt(b.order) })
    const nextCell = sortedCells[sortedCells.indexOf(actualCell) + 1]
    let nextCellOrder = null
    if (nextCell) {
      nextCellOrder = nextCell.order
    }
    
    if (nextCellOrder) {
      let now = new Date();
      let time = (now.getTime() - this.state.initialTime.getTime()) / 1000
      const registerCellConclusion = await cellConclusion.register({
        cellId: this.state.actualCell.id,
        time: time
      }, auth.getToken())
      if (registerCellConclusion.ok) {
        window.location.replace(`/road/${unity.roadId}/unity/${unity.id}/${nextCellOrder}`)
      }

    } else {
      let now = new Date();
      let time = (now.getTime() - this.state.initialTime.getTime()) / 1000
      
      const token = auth.getToken()
      if (token) {
        const registerCellConclusion = await cellConclusion.register({
          cellId: this.state.actualCell.id,
          time: time
        }, auth.getToken())

        if (registerCellConclusion.ok) {
          const resp = await progress.completeUnity(unity, auth.getToken())
          if (resp.ok) {
            window.scrollTo(0, 0)
            this.showFinishModal()
            setTimeout(() => {
              this.showRatingModal()
            }, 4000)
          }
        }
      } else {
        window.scrollTo(0, 0)
        this.showFinishModal()
        setTimeout(() => {
          this.toRoadPage()
        }, 4000)
      }
    }
  }

  async toPreviousCell() {
    let { unity, actualCell, order } = this.state

    if (order > 1) {
      const sortedCells = await unity.cells.sort((a, b) => { return parseInt(a.order) - parseInt(b.order) })
      const previousCell = sortedCells[sortedCells.indexOf(actualCell) - 1]
      let previousCellOrder = null
      if (previousCell) {
        previousCellOrder = previousCell.order
      }
      
      if (previousCellOrder) {
        window.location.replace(`/road/${unity.roadId}/unity/${unity.id}/${previousCellOrder}`)
      }
    } else {
      window.location.replace(`/road/${unity.roadId}`)
    }
  }

  async goBack() {
    if (window.confirm('Tem certeza que quer sair? Seu progresso será perdido.')) {
      this.props.history.goBack()
    }
  }

  async reloadCell() {
    let { unity, actualCell } = this.state
    unity.roadId = this.props.match.params.roadId
    window.location.replace(`/road/${unity.roadId}/unity/${unity.id}/`)
  }

  showFinishModal() {
    this.setState({
      showFinishModal: true
    })
  }

  showRatingModal() {
    this.setState({
      showFinishModal: false,
      showRatingModal: true
    })
  }

  toRoadPage() {
    window.location.replace(`/road/${this.props.match.params.roadId}`)
  }

  render() {
    const { actualCell, progress, unity, loading, currentUser, isBlindList, isList, showModalTutorialBlindTest, showFinishModal, showRatingModal, showLoginModal, showFinishModalBlindList, attemptId } = this.state
    return (
      <>
        <Container className={`${styles.container} pt-4`}>
          <Row className={`justify-content-md-center`}>
            <style type="text/css">
              {`
                .bg-custom {
                  background-color: #c7ef00;
                  background-image: linear-gradient(45deg,rgba(0,0,0,.1) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.1) 50%,rgba(0,0,0,.1) 75%,transparent 75%,transparent);
                }
              `}
            </style>
            <Col sm={12} md={6} className={`${styles.toRight}`}>
              <Row>
                <Col xs={2} md={1} className={`px-0`}><Button variant="link" className={`${styles.secButton}`} onClick={() => this.toPreviousCell()}><IoIosArrowBack/></Button></Col>
                <Col xs={8} md={10} className={`px-0 my-auto`}><ProgressBar animated className={`${styles.progressBar} p-0`} now={progress} variant="custom" /></Col>
                <Col xs={2} md={1} className={`px-0`}><Button variant="link" className={`${styles.secButton}`} onClick={() => this.goBack()}><IoMdClose/></Button></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Cell md={{ offset: 3, span: 6 }} content={actualCell.content} questionId={actualCell.questionId} toNextCell={() => this.toNextCell()}/>
          </Row>
        </Container>
        <Modal
          bsPrefix={'tutorialModal modal'}
          show={showModalTutorialBlindTest}
          backdrop={"static"}
          keyboard={false}
          centered
        >
          <Modal.Title style={{ fontWeight: "bolder", textAlign: "center", fontSize: "35px", color: '#292f36', paddingTop: "30px"}}>
            Instruções
          </Modal.Title>
          <Modal.Body
            style={{
              padding: "40px",
              justifyContent: "center",
              textAlign: "left",
              color: "#292f36",
              backgroundColor: "white",
              border: 0,
              borderRadius: 0
              }}>
            <p>_ você irá realizar um simulado de uma prova;</p>
            {/* <p>_ esse simulado tem a duração de {secondsToHm(this.state.questionsList.maxTime)};</p> */}
            <p>_ encontre um local calmo, sem distrações;</p>
            <p>_ coloque o celular em modo "Não perturbe";</p>
            <p>_ tenha um lápis e algumas folhas para utilizar de rascunho.</p>
            <Button className={`${styles.primaryButton} mx-auto d-flex`} variant="link" onClick={() => this.closeTutorial()}>vamos lá!</Button><br/>
          </Modal.Body>
        </Modal>
        <style>
          { `.modal-100w {
            max-width: 100% !important;
            width: 100% !important;
            min-height: 100vh !important;
            height: 100% !important;
          }
            .modal-100w .modal-content {
              min-height: calc(100vh + 100px) !important;
              height: 100% !important;
            }
          `}
        </style>
        <Modal
          bsPrefix={'finish-modal'}
          className={`${styles.finishModal}`}
          show={showFinishModal}
          backdrop={"static"}
          keyboard={false}
          dialogClassName='modal-100w'
          contentClassName='modal-100w'
          data-aos='fade-up'
          data-aos-duration="500"
        >
          <Modal.Header
            style={{
              paddingTop: "100px",
              justifyContent: "center",
              color: "#292f36",
              backgroundColor: "white",
              border: 0,
              borderRadius: 0,
            }}
          >
            <Modal.Title style={{ fontWeight: "bolder", textAlign: "center", fontSize: "35px"}}>
              <Image style={{
                maxWidth: "110px",
                display: "flex",
                margin: "0 auto 30px auto"
              }} src={require("../../assets/confete.svg")}></Image>
              Parabéns!</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              padding: "40px",
              justifyContent: "center",
              textAlign: "center",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 0,
              border: 0
              }}>
              <Col md={{ offset: 4, span: 4 }}>
                <p>Você finalizou a unidade:</p>
                <Card className={`${styles.examBanner} ${styles.borderRadius25} p-5 mt-4`} data-aos='fade-up' data-aos-duration="1000" data-aos-delay="1000">
                  <Row>
                    <Col sm={2} style={{ verticalAlign: "middle", margin: "auto" }}>
                      <img src={unity.icon}/>
                    </Col>
                    <Col sm={8}>
                      <Card.Title>{unity.title}</Card.Title>
                    </Col>
                    <Col sm={2} style={{ verticalAlign: "middle", margin: "auto" }}>
                      <img src={this.state.checkImage}/>
                    </Col>
                  </Row>
                </Card>
              </Col>
          </Modal.Body>
        </Modal>
        <Rating show={showRatingModal} callback={() => { this.toRoadPage() }} unityId={unity.id}/>
        <LoginModal show={showLoginModal} callback={() => { this.reloadCell() }} closeLogin={() => { this.closeLoginModal() }}/>
      </>
    )
  }
}

export default withRouter(Unity)
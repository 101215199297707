// >> How it works:
//  if deployed to AWS, NODE_ENV = production (even for stage = development)

const domains = () => {
  
  // ⚡️ serverless stage == prod
  const apiUrl = 'https://api.lampejo.link';

  // ⚡️ serverless stage == development
  // const apiUrl = 'https://bgp0qwdpfg.execute-api.us-east-1.amazonaws.com'

  if (process.env.NODE_ENV === 'development' || process.env.stage === 'development') {
    return {
      api: 'http://localhost:3002' || apiUrl
    }
  }

  return {
    api: apiUrl
  }
}

module.exports = { domains }
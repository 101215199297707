import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import styles from './index.module.css'

class PublicNavBar extends Component {
  render() {
    const { active } = this.props
    return (
      <Navbar className={`${styles.navbar} justify-content-between d-none d-md-block`}>
        <Container>
          <Button variant="link" className={active === 'mission' ? styles.primaryButton : styles.secondaryButton} href={'/mission'}>Missão</Button>
          <Button variant="link" className={active === 'about-us' ? styles.primaryButton : styles.secondaryButton} href={'/about-us'}>A Plataforma</Button>
          <Button variant="link" className={active === 'jobs' ? styles.primaryButton : styles.secondaryButton} href={'/jobs'}>Carreiras</Button>
          <Button variant="link" className={active === 'method' ? styles.primaryButton : styles.secondaryButton} href={'/method'}>Metodologia</Button>
          <Button variant="link" className={active === 'terms' ? styles.primaryButton : styles.secondaryButton} href={'/terms'}>Termos de Uso</Button>
          <Button variant="link" className={active === 'press' ? styles.primaryButton : styles.secondaryButton} href={'/press'}>Imprensa</Button>
        </Container>
      </Navbar>
    )
  }
}

export default withRouter(PublicNavBar)
import React, { Component }  from 'react'
import { withRouter } from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import ReactPixel from 'react-facebook-pixel'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import UserArea from '../../components/UserArea'
import PublicNavBar from '../../components/PublicNavBar'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

class Home extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showLogin: false,
      width: window.innerWidth
    }

    this.closeLogin = this.closeLogin.bind(this)
  }

  async componentDidMount() {
    AOS.init({duration: 300})
    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  openLogin() {
    this.setState({
      showLogin: true
    })
  }

  closeLogin() {
    this.setState({
      showLogin: false
    })
  }

  render() {
    const { width } = this.state
    const meta = {
      title: `Nossa missão | lampejo.app - Plataforma gratuita para o ENEM` ,
      description: `Somos uma comunidade de estudantes e professores que valorizam o compartilhamento do conhecimento. Você vai aprender praticando e ajudando os outros alunos.`,
      meta: {
        charset: 'utf-8',
        lang: 'pt-br',
        amp: true,
        name: {
          keywords: 'educação, vestibular, enem, questões, provas'
        }
      }
    }
    return (
      <>
        <DocumentMeta {...meta}/>
        { width < 780 ? 
          <></>  
        :
          <PublicNavBar active="mission"></PublicNavBar>
        }
        <Container className={styles.homeContainer}>
          { this.state.showLogin ?
            <UserArea closeLogin={this.closeLogin}/>
            :
            <></>
          }
          <br/><br/><br/><br/>
          <h2>Missão</h2>
          <br/><br/>
          <Row className={styles.homePrincipal}>
            <Col sm={12} data-aos='fade-up'>
              <Card className={`${styles.missionCard}`}>
                <Card.Title className={`${styles.missionTextTitle}`}>
                  Nossa missão é <span className={styles.strong}>reconstruir o ensino do Brasil</span> e garantir que ele seja <span className={styles.strong}>acessível para todos os brasileiros</span>. 
                </Card.Title>
                <br/>
                <Card.Text>
                  <p>Acreditamos na <strong>melhora</strong> dos <strong>indivíduos</strong> e de suas <strong>comunidades</strong> através da <strong>educação</strong>.</p>
                  <p>O pensamento crítico, as habilidades literárias e artísticas, a lógica, o método científico, a compreensão geopolítica, a capacidade de diferenciar notícias verdadeiras de falsas, o conhecimento sobre eventos passados para entender o presente, entre outros, são <strong>ferramentas</strong> que serão cada vez mais presentes no nosso dia-a-dia e precisam estar sempre à mão, disponíveis nessa <strong>fantástica caixa de mil e uma utilidades</strong> que temos dentro das nossas cabeças.</p>
                  <p>Também acreditamos que a <strong>educação</strong> no Brasil tem um rumo certo: <strong>ser diferente</strong>.</p>
                  <p>A educação brasileira, hoje, é presa a uma <strong>metodologia antiga</strong>, analógica, que inibe a curiosidade, super-direciona e coloca <strong>milhões de indivíduos</strong> em um mesmo<strong>molde padronizado</strong>. O mundo do futuro é muito diferente disso. Com a expansão da digitalização, o aumento da velocidade das automatizações e a melhora exponencial das inteligências artificiais, o destino da educação é claro: ser <strong>acessível</strong>, <strong>universal</strong> e <strong>personalizada</strong>.</p>
                  <p>A <strong>mudança precisa ser rápida</strong>. Temos que nos adaptar a novos tipos de trabalho que ainda não existem, novos conhecimentos ainda a serem descobertos, novas expectativas a serem cumpridas e <strong>novos sonhos a serem realizados</strong>.</p>
                  <p>A Lampejo está aqui! Presente nessa mudança. Seremos parte importante da adaptação. Queremos dar poder a todos, com foco na principal parte da nossa sociedade, <strong>você</strong>.</p>
                </Card.Text>
                <Card.Text>
                  Rafael Miller, Founder
                </Card.Text>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default withRouter(Home)
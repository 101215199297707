
/**
 * Utils: Back-end
 */
const { domains } = require('../config.js')
// const auth = require('../utils/auth')

/**
 * Register a new exan
 */
export const register = async (schedule, authToken) => {  
  if (!authToken) {
    return { status: "401", error: "Não autorizado. Necessário token de autenticação." }
  }
  
  const response = await requestApi('/schedules/register', 'POST', schedule, { Authorization: `Bearer ${authToken}`})
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response
  }
  return response
}

/**
 * Register a new exan
 */
export const edit = async (schedule, authToken) => {
  if (!authToken) {
    return { status: "401", error: "Não autorizado. Necessário token de autenticação." }
  }
  
  const response = await requestApi('/schedules/edit', 'POST', schedule, { Authorization: `Bearer ${authToken}`})
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response
  }
  return response
}


/**
 * Remove question
 */
export const remove = async (schedule, authToken) => {
  if (!schedule.id) {
    return { error: "id é um campo obrigatório" }
  }

  
  const response = await requestApi('/schedules/remove', 'POST', schedule, { Authorization: `Bearer ${authToken}`})
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response
  }
  return response
}


/**
 * Get a Question
 */
export const getByDay = async (date, authToken) => {
  let response
  if (!date || !authToken) {
    return { error: "date e authToken são campos obrigatórios"}
  } else {
    response = await requestApi('/schedules/byDay/' + date, 'GET', null, { Authorization: `Bearer ${authToken}`})
  }
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}

/**
 * Get a Question
 */
 export const getByMonth = async (month, authToken) => {
  let response
  if (!month || !authToken) {
    return { error: "month e authToken são campos obrigatórios"}
  } else {
    response = await requestApi('/schedules/byMonth/' + month, 'GET', null, { Authorization: `Bearer ${authToken}`})
  }
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}

/**
 * Get a Question
 */
 export const getBetweenDates = async (startAndFinish, authToken) => {
  if (!startAndFinish || !authToken) {
    return { error: "startAndFinish e authToken são campos obrigatórios"}
  }
  if (!authToken) {
    return { status: "401", error: "Não autorizado. Necessário token de autenticação." }
  }
  const response = await requestApi('/schedules/betweenDates', 'POST', startAndFinish, { Authorization: `Bearer ${authToken}`})
  
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}

export const getNextTask = async (authToken) => {
  let response
  if (!authToken) {
    return { error: "authToken é obrigatório"}
  } else {
    response = await requestApi('/schedules/nextTask/', 'GET', null, { Authorization: `Bearer ${authToken}`})
  }
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}

/**
 * Get a Question
 */
 export const getCalendarByDay = async (date, authToken) => {
  let response
  if (!date || !authToken) {
    return { error: "date e authToken são campos obrigatórios"}
  } else {
    response = await requestApi('/schedules/calendarByDay/' + date, 'GET', null, { Authorization: `Bearer ${authToken}`})
  }
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}

/**
 * Get a Question
 */
 export const get = async (id, authToken) => {
  let response
  if (!id || !authToken) {
    return { error: "id e authToken são campos obrigatórios"}
  } else {
    response = await requestApi('/schedules/' + id, 'GET', null, { Authorization: `Bearer ${authToken}`})
  }
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}

/**
 * API request to call the backend
 */
const requestApi = async (
  path = '',
  method = 'GET',
  data = null,
  headers = {}) => {

  // Check if API URL has been set
  if (!domains().api) {
    throw new Error(`Error: Missing API Domain – Please add the API domain from your serverless Express.js back-end to this front-end application.  You can do this in the "./config.js" file.  Instructions are listed there and in the documentation.`)
  }

  // Prepare URL
  if (!path.startsWith('/')) {
    path = `/${path}`
  }
  const url = `${domains().api}${path}`

  // Set headers
  headers = Object.assign(
    { 'Content-Type': 'application/json' },
    headers
  )

  // Default options are marked with *
  const response = await fetch(url, {
    method: method.toUpperCase(),
    mode: 'cors',
    cache: 'no-cache',
    headers: headers,
    body: data ? JSON.stringify(data) : null
  })

  if (response.status < 200 || response.status >= 300) {
    let res = await response.json()
    res.status = response.status
    return await res
  }


  return {
    status: 200,
    ok: true,
    body: await response.json()
  }
}
import React, { Component }  from 'react'
import { withRouter } from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import ReactPixel from 'react-facebook-pixel'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import UserArea from '../../components/UserArea'
import PublicNavBar from '../../components/PublicNavBar'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

class Home extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showLogin: false,
      width: window.innerWidth
    }

    this.closeLogin = this.closeLogin.bind(this)
  }

  async componentDidMount() {
    AOS.init({duration: 300})
    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  openLogin() {
    this.setState({
      showLogin: true
    })
  }

  closeLogin() {
    this.setState({
      showLogin: false
    })
  }

  render() {
    const { width } = this.state
    const meta = {
      title: `Termos de Uso e Política de Privacidade | lampejo.app - Plataforma gratuita para o ENEM` ,
      description: `Somos uma comunidade de estudantes e professores que valorizam o compartilhamento do conhecimento. Você vai aprender praticando e ajudando os outros alunos.`,
      meta: {
        charset: 'utf-8',
        lang: 'pt-br',
        amp: true,
        name: {
          keywords: 'educação, vestibular, enem, questões, provas'
        }
      }
    }
    return (
      <>
        <DocumentMeta {...meta}/>
        { width < 780 ? 
          <></>  
        :
          <PublicNavBar active="terms"></PublicNavBar>
        }
        <Container className={styles.homeContainer}>
          { this.state.showLogin ?
            <UserArea closeLogin={this.closeLogin}/>
            :
            <></>
          }
          <br/><br/><br/><br/>
          <h2>Termos de Uso e Política de Privacidade</h2>
          <br/><br/>
          <Row className={styles.homePrincipal}>
            <Col sm={12} data-aos='fade-up'>
              <Card className={`${styles.missionCard}`}>
                <Card.Text>
                  <p>Somos a Lampejo Educação. Nós respeitamos a sua privacidade e quaisquer informações pessoais que precisamos para operar. Essa Política de Privacidade se aplica ao nosso site www.lampejo.app (“Website”), nosso aplicativo (“App”) e aos serviços que oferecemos (“Serviços”).</p>
                  <br/>
                  <h2>Dados pessoais</h2>
                  <p>Para oferecer a você nosso Website, App e Serviços nós solicitamos informações pessoais apenas quando realmente precisamos delas.</p>
                  <br/>
                  <h3>Permissão</h3>

                  <p>Nós podemos armazenar os seus dados em diversos momentos, por exemplo, quando você visita o nosso Website, cria uma conta no nosso Website ou App, utiliza nossos serviços ou entra em contato conosco. Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados. Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.</p>
                  
                  <br/>
                  <h3>Quais dados coletamos e como usamos esses dados?</h3>
                  <p>Para utilizar o nosso Website, App e Serviços, nós precisamos dos seguintes dados:</p>
                  
                  <ul>
                    <li>Nome</li>
                    <li>Escolaridade</li>
                    <li>Curso superior desejado</li>
                    <li>Tempo e dias de estudos</li>
                    <li>Email</li>
                    <li>Endereço de IP (anonimizado)</li>
                  </ul>

                  <p>Nós também poderemos armazenar os seguintes dados não-pessoais se você utilizar nosso Website ou App:</p>
                  
                  <ul>
                    <li>O tipo do seu navegador</li>
                    <li>O sistema operacional que você utiliza</li>
                  </ul>
                  <p>Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.</p>

                  <p>O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.</p>
                  <br/>
                  <h3>Por quanto tempo nós armazenamos o seus dados?</h3>
                  <p>Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado.</p>
                  <br/>
                  <h3>Como excluir sua conta da Lampejo permanentemente?</h3>
                  <h4>Pelo aplicativo</h4>
                  <ul>
                    <li>Acesse o aplicativo pelo seu celular.</li>
                    <li>Clique no ícone do perfil no canto inferior direito.</li>
                    <li>Ao final da tela, abaixo dos Stickers, clique em "editar informações e cronograma".</li>
                    <li>Ao final da tela, na Área Perigosa, clique em "apagar a minha conta e remover todos os meus dados".</li>
                    <li>Por fim, clique em "apagar conta e dados para sempre" para excluir sua conta permanentemente.</li>
                  </ul>
                  <h4>Por email</h4>

                  <p>Caso não tenha o aplicativo da Lampejo, envie um email para contato@lampejo.app com o assunto "Excluir conta". Para garantir a integridade do processo, é importante que você envie utilizando o e-mail cadastrado na Lampejo. O processo de exclusão por email pode levar até 30 dias.</p>
                  
                  <h4>O que acontecerá se você excluir sua conta permanentemente?</h4>
                  <ul>
                    <li>Não poderá reativá-la;</li>
                    <li>Perderá todo o seu histórico de tarefas concluídas, simulados, pontos e <em>stickers</em>;</li>
                    <li>Todos os seus dados pessoais serão excluídos.</li>
                  </ul>
                  <br/>

                  <h3>Política de Cookies da Lampejo Educação</h3>

                  <h4>O que são cookies?</h4>
                  <p>Como é prática comum em quase todos os sites profissionais, este site usa cookies, que são pequenos arquivos baixados no seu computador, para melhorar sua experiência. Esta página descreve quais informações eles coletam, como as usamos e por que às vezes precisamos armazenar esses cookies. Também compartilharemos como você pode impedir que esses cookies sejam armazenados, no entanto, isso pode 'quebrar' certos elementos da funcionalidade do site.</p>

                  <h4>Como usamos os cookies?</h4>
                  <p>Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site. É recomendável que você deixe todos os cookies se não tiver certeza se precisa ou não deles, caso sejam usados para fornecer um serviço que você usa.</p>

                  <h4>Desativar cookies</h4>
                  <p>Você pode impedir a configuração de cookies ajustando as configurações do seu navegador (consulte a Ajuda do navegador para saber como fazer isso). Esteja ciente de que a desativação de cookies afetará a funcionalidade deste e de muitos outros sites que você visita. A desativação de cookies geralmente resultará na desativação de determinadas funcionalidades e recursos deste site. Portanto, é recomendável que você não desative os cookies.</p>

                  <h4>Cookies que definimos</h4>
                  <ul>
                    <li>Cookies relacionados à conta. Se você criar uma conta, usaremos cookies para o gerenciamento do processo de inscrição e administração geral. Esses cookies geralmente serão excluídos quando você sair do sistema.</li>
                    <li>Cookies relacionados ao login. Utilizamos cookies quando você está logado, para que possamos lembrar dessa ação. Isso evita que você precise fazer login sempre que visitar uma nova página. Esses cookies são normalmente removidos ou limpos quando você efetua logout para garantir que você possa acessar apenas a recursos e áreas restritas ao efetuar login.</li>
                    <li>Cookies de preferências do site. Para proporcionar uma ótima experiência neste site, fornecemos a funcionalidade para definir suas preferências de como esse site é executado quando você o usa. Para lembrar suas preferências, precisamos definir cookies para que essas informações possam ser chamadas sempre que você interagir com uma página for afetada por suas preferências.</li>
                  </ul>
                  
                  <h4>Cookies de Terceiros</h4>
                  <p>Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. A seção a seguir detalha quais cookies de terceiros você pode encontrar através deste site.</p>

                  <ul>
                    <li>Este site usa o Google Analytics, que é uma das soluções de análise mais difundidas e confiáveis da Web, para nos ajudar a entender como você usa o site e como podemos melhorar sua experiência. Esses cookies podem rastrear itens como quanto tempo você gasta no site e as páginas visitadas, para que possamos continuar produzindo conteúdo atraente.</li>
                  </ul>

                  <p>Para mais informações sobre cookies do Google Analytics, consulte a página oficial do Google Analytics.</p>

                  <ul>
                    <li>As análises de terceiros são usadas para rastrear e medir o uso deste site, para que possamos continuar produzindo conteúdo atrativo. Esses cookies podem rastrear itens como o tempo que você passa no site ou as páginas visitadas, o que nos ajuda a entender como podemos melhorar o site para você.</li>
                  </ul>

                  <ul>
                    <li>Periodicamente, testamos novos recursos e fazemos alterações sutis na maneira como o site se apresenta. Quando ainda estamos testando novos recursos, esses cookies podem ser usados para garantir que você receba uma experiência consistente enquanto estiver no site, enquanto entendemos quais otimizações os nossos usuários mais apreciam.</li>
                  </ul>

                  <ul>
                    <li>O serviço Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para veicular anúncios mais relevantes em toda a Web e limitar o número de vezes que um determinado anúncio é exibido para você. Para mais informações sobre o Google AdSense, consulte as FAQs oficiais sobre privacidade do Google AdSense.</li>
                    <li>Utilizamos anúncios para compensar os custos de funcionamento deste site e fornecer financiamento para futuros desenvolvimentos. Os cookies de publicidade comportamental usados por este site foram projetados para garantir que você forneça os anúncios mais relevantes sempre que possível, rastreando anonimamente seus interesses e apresentando coisas semelhantes que possam ser do seu interesse.</li>
                    <li>Vários parceiros anunciam em nosso nome e os cookies de rastreamento de afiliados simplesmente nos permitem ver se nossos clientes acessaram o site através de um dos sites de nossos parceiros, para que possamos creditá-los adequadamente e, quando aplicável, permitir que nossos parceiros afiliados ofereçam qualquer promoção que pode fornecê-lo para fazer uma compra.</li>
                  </ul>
                  <br/>
                  <h3>Compromisso do Usuário</h3>

                  <p>O usuário se compromete a fazer uso adequado dos conteúdos e da informação que a Lampejo Educação oferece no site e com caráter enunciativo, mas não limitativo:</p>
                  <ul>
                    <li> A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;</li>
                    <li> B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, ou casas de apostas legais, jogos de sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;</li>
                    <li> C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) da Lampejo Educação, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.</li>
                  </ul>
                  <br/>

                  <h3>Informações de Contato</h3>
                  <p>Rua M.m.d.c., 539 <br/> Butantã - São Paulo - SP <br/>CEP 05510-021</p>

                  <p><strong>CEO</strong><br/>
                  Rafael Miller<br/>
                  rafael@lampejo.app</p>

                  <p>Esta política é efetiva a partir de Setembro/2021.</p>
                </Card.Text>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default withRouter(Home)
import React, { Component } from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import styles from './index.module.css'
import Row from 'react-bootstrap/esm/Row'

class Footer extends Component {
  render() {
    return (
      <Container fixed="top" className={`${styles.footer} justify-content-between pb-5`}>
        <hr style={{ borderTop: "1px solid rgba(255,255,255,.3)", marginBottom: "50px" }}/>
        <Row>
          <Col md={3} className={styles.topSpace}>
            <h4>Institucional</h4>
            <br/>
            <Link className={`${styles.footerLink}`} to="/mission">Missão</Link><br/>
            <Link className={`${styles.footerLink}`} to="/about-us">A Plataforma</Link><br/>
            <Link className={`${styles.footerLink}`} to="/jobs">Carreiras</Link><br/>
            <Link className={`${styles.footerLink}`} to="/blog">Blog</Link><br/>
            {/* <Link className={`${styles.footerLink}`} to="/method">Metodologia</Link><br/>
            <Link className={`${styles.footerLink}`} to="/press">Imprensa</Link> */}
          </Col>
          <Col md={3} className={styles.topSpace}>
            <h4>Ajuda</h4>
            <br/>
            <Link className={`${styles.footerLink}`} to="/report">Informar Problemas</Link><br/>
          </Col>
          <Col md={3} className={styles.topSpace}>
            <h4>Privacidade e Termos</h4>
            <br/>
            <Link className={`${styles.footerLink}`} to="/terms">Termos de Uso</Link><br/>
          </Col>
          <Col md={3} className={`${styles.topSpace} mt-auto`}>
            <Link to="/">
              <Image fluid src={require('../../assets/logo.png')} className={`${styles.imgLogo} d-inline-block align-top`} alt='lampejo.app'/>
            </Link>
            <p><small>lampejo.app ®</small><br/><small>v1.6.0</small></p>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(Footer)
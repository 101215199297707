import React, { Component }  from 'react'
import {
  withRouter,
  // Link,
} from 'react-router-dom'
// import { Action } from 'history'
// import { useBlocker } from 'react-router'

import Cell from '../../components/Cell'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { default as DefaultButton } from 'react-bootstrap/Button'
import { Button } from '../../lampejo-styles/components/Button/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import Rating from '../../components/Rating'
import LoginModal from '../../components/LoginModal'
import styles from './index.module.css'
import Task from '../../components/Task'
// import AOS from 'aos'
import 'aos/dist/aos.css'
// import TaskWidget from '../../components/TaskWidget'
import Lottie from 'react-lottie';
import { IoIosArrowBack, IoMdClose } from 'react-icons/io'
import TaskWidgetBFinish from '../../components/TaskWidgetBFinish'
import GoogleAdsWidget from '../../components/GoogleAdsWidget'

const api = require('../../utils/api')
const unities = require('../../controllers/unities')
const tasks = require('../../controllers/tasks')
const taskConclusion = require('../../controllers/taskConclusion')
const cellsCtrl = require('../../controllers/cells')
const cellConclusion = require('../../controllers/cellConclusion')
const streakEvents = require('../../controllers/streakEvents')
const auth = require('../../utils/auth')


class TaskPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      order: 1,
      actualCell: {}, 
      unity: { cells: [] },
      questionsList: { questionsIds: [] },
      progress: '',
      showFinishModal: false,
      showFinishModalB: false,
      showRatingModal: false,
      showLoginModal: false,
      showEnd: false,
      checkImage: null,
      isFirstTodaysTask: false,
      task: {},
      showAds: false,
      weekStreak: [],
      showStreakUpdate: false,
      streakStatus: null,
      glowEffectImage: null,
      actualStreak: { value: null },
      loadingCheckForStreak: false
    }
    this.toNextCell= this.toNextCell.bind(this)
  }

  congratsAudio = new Audio(require('../../assets/congrats.mp3'))

  async componentDidMount() {
    let initialTime = new Date()
    const defaultIcon = await require('../../assets/ilustracao_task.svg')
    const checkImage = await require('../../assets/check-circle.gif')
    const starVideo = await require('../../assets/videos/congrats.json')
    const lightStreakVideo = await require('../../assets/videos/StreakLight.json')
    const glowEffectImage = await require("../../assets/glow_streak_effect.png")

    this.setState({
      initialTime,
      defaultIcon,
      checkImage,
      starVideo,
      lightStreakVideo,
      glowEffectImage
    })

    let currentUser = await api.currentUser()
    if (currentUser.id) {
      const reqNumOfTodaysTasks = await tasks.numTodaysTask(auth.getToken()) // ajustar aqui
      if (reqNumOfTodaysTasks.ok) {
        let getNumOfTodaysTasks = reqNumOfTodaysTasks.body.numFinishedTasksToday
        this.setState({
          isFirstTodaysTask: getNumOfTodaysTasks > 0 ? false : true
        })
      }      
    }
    
    const id = this.props.match.params.id
    const reqTask = await tasks.get(id, currentUser.id)
    if (reqTask.ok) {
      const getTask = await reqTask.body.task

      if (getTask.unityId) {
        const reqUnity = await unities.get(getTask.unityId, currentUser.id)
        if (reqUnity.ok) {
          const getUnity = reqUnity.body.unity
          const reqCells = await cellsCtrl.getByUnityId(getUnity.id)
          getUnity.cells = reqCells.body.cells.sort((a, b) => { return a.order - b.order }) || []
          
          let order = 1
          let progress
          let actualCell = {}
          if (this.props.match.params.order) {
            order = this.props.match.params.order
            actualCell = await getUnity.cells.filter(e => { return e.order === this.props.match.params.order })[0]
            progress = (getUnity.cells.indexOf(actualCell)) * 100 / getUnity.cells.length
          } else {
            actualCell = getUnity.cells[0]
            progress = 10
          }
      
          this.setState({
            icon: getTask.icon || null,
            unity: getUnity,
            task: getTask,
            actualCell: actualCell,
            progress: progress,
            order
          })
        }
      } else if (getTask.questionsIds.length > 0) {
        let order = 0
        let progress
        let actualCell = {}
        if (this.props.match.params.order) {
          order = this.props.match.params.order
          actualCell.questionId = getTask.questionsIds[order - 1]
          progress = (order * 100) / getTask.questionsIds.length
        } else {
          actualCell.questionId = getTask.questionsIds[0]
          progress = 10
        }
      
        this.setState({
          icon: getTask.icon || null,
          task: getTask,
          actualCell: actualCell,
          progress: progress,
          order
        })
      }

      this.setState({
        task: getTask,
      })
    }
  }

  openTutorial(type) {
    if (type === 'blind') {
      this.setState({
        showModalTutorialBlindTest: true
      })
    } else {
      this.setState({
        showModalTutorial: true
      })
    }
  }

  closeTutorial() {
    this.setState({
      showModalTutorialBlindTest: false,
      showModalTutorial: false
    })
  }

  openResolution() {
    return true
  }

  async openFinishModal() {
    if (this.state.isBlindList) {
      this.setState({
        showFinishModalBlindList: true
      })
    } else {
      this.setState({
        showFinishModal: true
      })       
    }
  }

  async openLoginModal() {
    this.setState({
      showLoginModal: true
    })
  }

  async closeLoginModal() {
    this.setState({
      showLoginModal: false
    })
  }


  async toNextCell() {
    let { task, unity, actualCell, order } = this.state

    const sortedCells = await unity.cells.sort((a, b) => { return parseInt(a.order) - parseInt(b.order) })
    const nextCell = sortedCells[sortedCells.indexOf(actualCell) + 1]
    let nextCellOrder = null
    if (nextCell) {
      nextCellOrder = nextCell.order
    } else if (task.questionsIds.length > order) {
      nextCellOrder = parseInt(order) + 1
    }
    
    if (nextCellOrder) {
      let now = new Date()
      let time = (now.getTime() - this.state.initialTime.getTime()) / 1000
      const registerCellConclusion = await cellConclusion.register({
        cellId: this.state.actualCell.id,
        time: time
      }, auth.getToken())
      if (registerCellConclusion.ok) {
        window.location.replace(`/task/${task.id}/${nextCellOrder}`)
      } else {
        window.location.replace(`/task/${task.id}/${nextCellOrder}`)
      }

    } else {
      let now = new Date();
      let time = (now.getTime() - this.state.initialTime.getTime()) / 1000
      
      const token = auth.getToken()
      if (token) {
        const registerCellConclusion = await cellConclusion.register({
          cellId: this.state.actualCell.id,
          time: time
        }, auth.getToken())

        if (registerCellConclusion.ok) {
          const resp = await taskConclusion.register({
            taskId: this.props.match.params.id,
            time: time
          }, auth.getToken())
          if (resp.ok) {
            window.scrollTo(0, 0)
            this.openFinishModalB(resp.body.streakStatus)
          }
        } else {
          const resp = await taskConclusion.register({
            taskId: this.props.match.params.id,
            time: time
          }, auth.getToken())
          if (resp.ok) {
            window.scrollTo(0, 0)
            this.openFinishModalB(resp.body.streakStatus)
          }
        }
      } else {
        window.scrollTo(0, 0)
        this.showFinishModal()
        setTimeout(() => {
          this.toSchedulePage()
        }, 4000)
      }
    }
  }

  async toPreviousCell() {
    let { task, unity, actualCell, order } = this.state

    if (order > 1) {
      const sortedCells = await unity.cells.sort((a, b) => { return parseInt(a.order) - parseInt(b.order) })
      const previousCell = sortedCells[sortedCells.indexOf(actualCell) - 1]
      let previousCellOrder = null
      if (previousCell) {
        previousCellOrder = previousCell.order
      }
      
      if (previousCellOrder) {
        window.location.replace(`/task/${task.id}/${previousCellOrder}`)
      }
    } else {
      window.location.replace(`/schedule`)
    }
  }

  async goBack() {
    if (window.confirm('Tem certeza que quer sair? Seu progresso será perdido.')) {
      this.props.history.goBack()
    }
  }

  async reloadCell() {
    let { unity, actualCell } = this.state
    unity.roadId = this.props.match.params.roadId
    // window.location.replace(`/road/${unity.roadId}/unity/${unity.id}/`)
  }

  showFinishModal() {
    this.setState({
      showFinishModal: true
    })
  }

  openFinishModalB(streakStatus) {
    this.setState({
      showFinishModalB: true,
      streakStatus: streakStatus || null
    })
  }

  showRatingModal() {
    this.setState({
      showFinishModal: false,
      showRatingModal: true
    })
  }
  
  checkTask() {
    this.congratsAudio.play()

    this.setState({
      showEnd: true
    })
  }

  toSchedulePage() {
    if (window.innerWidth <= 500 && !this.state.isFirstTodaysTask) {
      this.setState({
        showAds: true,
        showEnd: false,
        showStreakUpdate: false
      })
    } else {
      window.location.replace(`/schedule`)
    }
  }

  async checkForStreak() {
    this.setState({
      loadingCheckForStreak: true
    })
    if (this.state.streakStatus == 'complete') {
      const reqWeekStreak = await streakEvents.getWeekStreak(auth.getToken())
      const weekStreak = reqWeekStreak.body.weekStreak
      const actualStreak = reqWeekStreak.body.actualStreak

      this.setState({
        actualStreak,
        weekStreak,
        showStreakUpdate: true,
      })

      setTimeout(() => {
        this.toSchedulePage()
      }, 4000)
    } else {
      this.toSchedulePage()
    }
  }

  portugueseDay (date) {
    if (date.getDay() == 0){ return "d" }
    if (date.getDay() == 1){ return "s" }
    if (date.getDay() == 2){ return "t" }
    if (date.getDay() == 3){ return "q" }
    if (date.getDay() == 4){ return "q" }
    if (date.getDay() == 5){ return "s" }
    if (date.getDay() == 6){ return "s" }
  }

  renderStreakDay(day) {
    return (
      day.status == 'complete'?
        <Col className={"px-0"}>
          <Image className={styles.glowEffectImage} src={this.state.glowEffectImage}/>
          <Col className={styles.completedStreakCircle}>{this.portugueseDay(new Date(day.date))}</Col>
          <Image className={styles.glowEffectImageRotate} src={this.state.glowEffectImage}/>
        </Col>
      : day.status == 'freeze'?
        <Col className={"px-0"}>
          <Col className={styles.glowEffectSpace} />
          <Col className={styles.freezeStreakCircle}>{this.portugueseDay(new Date(day.date))}</Col>
          <Col className={styles.glowEffectSpace}/>
        </Col>
      : day.status == 'freeze-item'?
        <Col className={"px-0"}>
          <Col className={styles.glowEffectSpace}/>
          <Col className={styles.freezeItemStreakCircle}>{this.portugueseDay(new Date(day.date))}</Col>
          <Col className={styles.glowEffectSpace}/>
        </Col>
      :  day.status == 'active'?
        <Col className={"px-0"}>
          <Col className={styles.glowEffectSpace}/>
          <Col className={styles.activeStreakCircle}>{this.portugueseDay(new Date(day.date))}</Col>
          <Col className={styles.glowEffectSpace}/>
        </Col>
      : <></>
    )
  }

  render() {
    const { actualCell, progress, congratsVideoStopped, task, showFinishModal, showFinishModalB, showRatingModal, showLoginModal, showEnd, isFirstTodaysTask, showAds, order, showStreakUpdate, starVideo, lightStreakVideo, weekStreak, actualStreak, loadingCheckForStreak } = this.state
    return (
      <>
        <Container className={`${styles.container} py-5`}>
          <Row className={`justify-content-md-center`}>
            <style type="text/css">
              {`
                .bg-custom {
                  background-color: #c7ef00;
                  background-image: linear-gradient(45deg,rgba(0,0,0,.1) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.1) 50%,rgba(0,0,0,.1) 75%,transparent 75%,transparent);
                }
              `}
            </style>
            <Col sm={12} md={6} className={`${styles.toRight}`}>
              { !showAds?
                <Row>
                  <Col xs={2} md={1} className={`px-0`}><DefaultButton variant="link" className={`${styles.secDefaultButton}`} onClick={() => this.toPreviousCell()}><IoIosArrowBack/></DefaultButton></Col>
                  <Col xs={8} md={10} className={`px-0 my-auto`}><ProgressBar animated className={`${styles.progressBar} p-0`} now={progress} variant="custom" /></Col>
                  <Col xs={2} md={1} className={`px-0`}><DefaultButton variant="link" className={`${styles.secDefaultButton}`} onClick={() => this.goBack()}><IoMdClose/></DefaultButton></Col>
                </Row>
                : <></>
              }
            </Col>
          </Row>
          <Row>
            { actualCell.questionId && order == 0 && !showAds?
              <Task md={{ offset: 3, span: 6 }} task={task} nextOrder={order + 1} />  : !showAds ?
              <Cell md={{ offset: 3, span: 6 }} content={actualCell.content} questionId={actualCell.questionId} toNextCell={() => this.toNextCell()}/>
              : <></>
            }
          </Row>
        </Container>
        <style>
          { `.modal-100w {
            max-width: 100% !important;
            width: 100% !important;
            min-height: 100vh !important;
            height: 100% !important;
            z-index: 11 !important;
          }
            .modal-100w .modal-content {
              min-height: calc(100vh + 100px) !important;
              height: 100% !important;
              border-color: transparent !important;
            }
          `}
        </style>
        <Modal
          bsPrefix={'finish-modal'}
          className={`${styles.finishModal}`}
          show={showFinishModal}
          backdrop={"static"}
          keyboard={false}
          dialogClassName='modal-100w'
          contentClassName='modal-100w'
          // data-aos='fade-up'
          // data-aos-duration="500"
        >
          <Modal.Header
            style={{
              paddingTop: "100px",
              justifyContent: "center",
              color: "#292f36",
              backgroundColor: "white",
              border: 0,
              borderRadius: 0,
            }}
          >
            <Modal.Title style={{ fontWeight: "bolder", textAlign: "center", fontSize: "35px"}}>
              <Image style={{
                maxWidth: "110px",
                display: "flex",
                margin: "0 auto 30px auto"
              }} src={require("../../assets/confete.svg")}></Image>
              Parabéns!</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              padding: "40px",
              justifyContent: "center",
              textAlign: "center",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 0,
              border: 0
              }}>
              <Col xs={12} md={{ offset: 4, span: 4 }}>
                <p>Você finalizou a tarefa:</p>
                <Card className={`${styles.examBanner} ${styles.borderRadius25} p-5 mt-4 text-left`}>
                  <Row>
                    <Col sm={8}>
                      <Card.Title style={{ lineHeight: '40px', marginBottom: 0 }}>{task.title}</Card.Title>
                      <Card.Text style={{ lineHeight: '40px', marginBottom: 0 }}>{task.subtitle}</Card.Text>
                    </Col>
                    <Col sm={2} style={{ verticalAlign: "middle", margin: "auto" }}>
                      <img style={{ height: '40px', minWidth: '40px', margin: '0 auto', display: 'flex' }} src={this.state.checkImage}/>
                    </Col>
                  </Row>
                </Card>
              </Col>
          </Modal.Body>
        </Modal>
        <Modal
          bsPrefix={'finish-modal'}
          className={`${styles.finishModal}`}
          show={showFinishModalB}
          backdrop={"static"}
          keyboard={false}
          dialogClassName='modal-100w'
          contentClassName='modal-100w'
        >
          <Modal.Body
          style={{
            padding: "100px 0 0 0",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            backgroundColor: "#292f36",
            borderRadius: 0,
            border: 0
            }}>
              { showAds?
                <Row className={'justify-content-around'} style={{ marginTop: '-120px' }}>
                  <h4 className={'pb-5'} data-aos='fade-up' data-aos-duration='800'>Os anúncios nos ajudam a manter a educação de graça para todos</h4><br/><br/>
                  <GoogleAdsWidget/><br/>
                  <Button
                    centered
                    marginTop='md'
                    variant="secondary"
                    label="ir para o cronograma"
                    onClick={() => window.location.replace(`/schedule`) }
                  />
                </Row>
              :
              <Row className={'justify-content-around'}>
                { isFirstTodaysTask ?
                  !showStreakUpdate?
                    <Col xs={12} md={4} className="p-0">
                      <p data-aos-duration='800'>Conclua a tarefa:</p>
                      <TaskWidgetBFinish data-aos-duration='800' task={task} callback={() => { this.checkTask() }}/>
                      <style>
                      {`@keyframes wobble {
                          33% {
                            transform: rotate(5deg);
                          }
                          66% {
                            transform: rotate(-5deg);
                          }
                        }`}
                      </style>
                      <Image className={showEnd? 'd-none': 'd-block'} data-aos-delay="2000" data-aos='fade-zoom-in' style={{ marginLeft: '-25px', top: '-30px', position: 'relative', animation: 'wobble 5s ease-in-out infinite alternate' }} src={require('../../assets/to_finish_task.svg')}/>
                      <div className={showEnd? `d-block ${styles.starDiv}`: 'd-none'}>
                        <Lottie options={{ loop: true, autoplay: true, animationData: starVideo, rendererSettings: { preserveAspectRatio: 'xMidYMid slice'}}} height={320} width={320} isStopped={!showEnd}/>
                      </div>
                      { showEnd? 
                      <Card data-aos='fade-up' data-aos-duration='800' className={styles.pointsCard}>
                        <Row>
                          <Col className={`d-none d-md-block ${styles.smallStarDiv}`}>
                            <Image height="30" width="30" src={require('../../assets/star_green.svg')}/>
                          </Col>
                          <Col className={`${styles.pointsDiv}`}>Pontos pela tarefa:</Col>
                          <Col className={`${styles.pointsNumberDiv}`}>+10</Col>
                        </Row>
                      </Card> :
                      <></> }
                      { showEnd?
                        <Button
                          marginTop='lg'
                          data-aos='fade-up'
                          data-aos-delay="800"
                          data-aos-duration='800'
                          centered
                          onClick={() => this.checkForStreak() }
                          label="continuar"
                        /> : <></> }
                    </Col>
                  :
                  <Col xs={12} md={4} className="p-0">
                    <Col>
                      <Lottie options={{
                        loop: false,
                        autoplay: true,
                        animationData: lightStreakVideo,
                        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
                      }} height={320} width={320}/>
                      <Row style={{ justifyContent: 'space-evenly', maxWidth: '100%', margin: '0 auto' }}>
                        { weekStreak.map((day) => { return this.renderStreakDay(day) }) }
                      </Row>  
                      <Row style={{ justifyContent: 'space-around', width: '100%', margin: '1rem auto 0 auto' }}>
                        <p style={{ fontSize: '24px', textAlign: 'center', fontWeight: 'bolder' }}>{ actualStreak.value }{ actualStreak.value == 1 ? ' dia' : ' dias' } de corrida!</p>
                      </Row>
                      <Row style={{ justifyContent: 'space-around', width: '100%', margin: '0 auto'}}>
                        <p style={{ maxWidth: '300px' }}>Finalize pelo menos uma tarefa nos <strong>dias do seu cronograma</strong> para continuar a sua corrida!</p>
                      </Row>
                      <Row style={{ justifyContent: 'space-around', width: '100%', margin: '0 auto' }}>
                        <Button
                          label="continuar"
                          marginTop="md"
                          centered
                          onClick={() => this.toSchedulePage() }
                        />
                      </Row>
                    </Col>
                  </Col>
                :
                <>
                  { !showStreakUpdate?
                  <Col xs={12} md={{ span: 4, offset: 2 }} className="p-0">
                    <p data-aos-duration='800'>Conclua a tarefa:</p>
                    <TaskWidgetBFinish data-aos-duration='800' task={task} callback={() => { this.checkTask() }}/>
                    <style>
                    {`@keyframes wobble {
                        33% {
                          transform: rotate(5deg);
                        }
                        66% {
                          transform: rotate(-5deg);
                        }
                      }`}
                    </style>
                    <Image className={showEnd? 'd-none': 'd-block'} data-aos-delay="2000" data-aos='fade-zoom-in' style={{ marginLeft: '-25px', top: '-30px', position: 'relative', animation: 'wobble 5s ease-in-out infinite alternate' }} src={require('../../assets/to_finish_task.svg')}/>
                    <div className={showEnd? `d-block ${styles.starDiv}`: 'd-none'}>
                      <Lottie options={{ loop: true, autoplay: true, animationData: starVideo, rendererSettings: { preserveAspectRatio: 'xMidYMid slice'}}} height={320} width={320} isStopped={!showEnd}/>
                    </div>
                    { showEnd? 
                    <Card data-aos='fade-up' data-aos-duration='800' className={styles.pointsCard}>
                      <Row>
                        <Col className={`d-none d-md-block ${styles.smallStarDiv}`}>
                          <Image height="30" width="30" src={require('../../assets/star_green.svg')}/>
                        </Col>
                        <Col className={`${styles.pointsDiv}`}>Pontos pela tarefa:</Col>
                        <Col className={`${styles.pointsNumberDiv}`}>+10</Col>
                      </Row>
                    </Card> :
                    <></> }
                    { showEnd?
                      <Button
                        data-aos='fade-up'
                        data-aos-delay="800"
                        data-aos-duration='800'
                        marginTop='lg'
                        label="continuar"
                        centered
                        onClick={() => this.checkForStreak() }
                      /> : <></> }
                  </Col>
                  :
                    <Col xs={12} md={{ span: 4, offset: 2 }} className="p-0">
                      <Col>
                        <Lottie options={{
                          loop: false,
                          autoplay: true,
                          animationData: lightStreakVideo,
                          rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
                        }} height={320} width={320}/>
                        <Row style={{ justifyContent: 'space-evenly', maxWidth: '100%', margin: '0 auto' }}>
                          { weekStreak.map((day) => { return this.renderStreakDay(day) }) }
                        </Row>  
                        <Row style={{ justifyContent: 'space-around', width: '100%', margin: '1rem auto 0 auto' }}>
                          <p style={{ fontSize: '24px', textAlign: 'center', fontWeight: 'bolder' }}>{ actualStreak.value }{ actualStreak.value == 1 ? ' dia' : ' dias' } de corrida!</p>
                        </Row>
                        <Row style={{ justifyContent: 'space-around', width: '100%', margin: '0 auto'}}>
                          <p style={{ maxWidth: '300px' }}>Finalize pelo menos uma tarefa nos <strong>dias do seu cronograma</strong> para continuar a sua corrida!</p>
                        </Row>
                        <Row style={{ justifyContent: 'space-around', width: '100%', margin: '0 auto' }}>
                          <Button
                            centered
                            marginTop="md"
                            onClick={() => this.toSchedulePage() }
                            label="continuar"  
                          />
                        </Row>
                      </Col>
                    </Col>
                  }
                  <Col md={4} className={'d-none d-md-block'}>
                    <GoogleAdsWidget/>
                  </Col>
                </>
                }
              </Row>
            }
            </Modal.Body>
          </Modal>
        <Rating show={showRatingModal} callback={() => { this.toSchedulePage() }} unityId={task.id}/>
        <LoginModal show={showLoginModal} callback={() => { this.reloadCell() }} closeLogin={() => { this.closeLoginModal() }}/>
      </>
    )
  }
}

export default withRouter(TaskPage)
import React, { Component } from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import Image from 'react-bootstrap/Image'
import Loader from 'react-loader-spinner'
import styles from './index.module.css'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import ButtonModuleRoad from '../ButtonModuleRoad'
import { CgChevronRightO } from 'react-icons/cg'
import Module from '../Module'

const modules = require('../../controllers/modules')
const unities = require('../../controllers/unities')
const api = require('../../utils/api')
const auth = require('../../utils/auth')

class Cell extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      finished: false,
      ilustracaoSalaDeAula: null,
      ilustracaoConstrucao: null,
      ilustracaoMountain: null,
      ilustracaoFog: null,
      loadingNotify: false
    }
  }

  async componentDidMount() {
    const ilustracaoSalaDeAula = require('../../assets/ilustracao_sala_de_aula.svg')
    const ilustracaoConstrucao = require('../../assets/ilustracao_construcao.svg')
    const ilustracaoMountain = require('../../assets/mountain.png')
    const ilustracaoFog = require('../../assets/fog.png')

    let CurrentUser = await api.currentUser()
    
    let finished = false
    let length = 0
    let i = 0

    this.props.road.levels.map(level => {
      level.modulesIds.map(module => {
        if (module.isComplete) {
          i++
        }
        length++
      })
    })
    
    if (i === length) {
      finished = true
    }

    this.setState({
      loading: false,
      currentUser: CurrentUser,
      finished: finished,
      ilustracaoConstrucao,
      ilustracaoSalaDeAula,
      ilustracaoMountain,
      ilustracaoFog
    })

    this.checkBlockedLevels = this.checkBlockedLevels.bind(this)
    this.scrollToBottom()
  }

  scrollToBottom = () => {
    this.roadEnd.scrollIntoView({ behavior: "smooth" });
  }

  checkBlockedLevels(levels, level, modulesIds) {
    let isFistTime = true
    for (const module of modulesIds) {
      if (module.isComplete) {
        isFistTime = false
      }
    }

    if (isFistTime) {
      let firstModuleFirstLevel = modulesIds.filter(e => { return parseInt(e.depth) == 1 && level.title == "Nível 1" })[0]
      let firstModule = modulesIds.filter(e => { return parseInt(e.depth) == 1 && level.title != "Nível 1" })[0]
      for (let [i, module] of modulesIds.filter(e => { return e != firstModule && e != firstModuleFirstLevel }).entries()) {
        modulesIds[modulesIds.indexOf(module)].blocked = true
        modulesIds[modulesIds.indexOf(module)].actual = false
        if (i == 0) {
          modulesIds[modulesIds.indexOf(module)].roadTo = "final"
        } else {
          if (i % 2) {
            modulesIds[modulesIds.indexOf(module)].roadTo = "left"
          } else {
            modulesIds[modulesIds.indexOf(module)].roadTo = "right"
          }
        }
      }
      if (firstModuleFirstLevel) {
        modulesIds[modulesIds.indexOf(firstModuleFirstLevel)].roadTo = "right"
        modulesIds[modulesIds.indexOf(firstModuleFirstLevel)].blocked = false
        modulesIds[modulesIds.indexOf(firstModuleFirstLevel)].actual = true
      }
      if (firstModule) {
        modulesIds[modulesIds.indexOf(firstModule)].roadTo = "right"

        // check if all modules before are complete

        let allModules = []
        levels.map(roadLevel => {
          roadLevel.modulesIds.map(mIds => {
            allModules.push({ levelDepth: roadLevel.depth, id: mIds.id, isComplete: mIds.isComplete })
          })
        })

        let firstModuleInAllModules = allModules.find(e => { return e.id == firstModule.id})

        let beforeModules = allModules.filter(e => { return parseInt(e.levelDepth) < parseInt(firstModuleInAllModules.levelDepth)})
        let beforeModulesComplete = allModules.filter(e => { return parseInt(e.levelDepth) < parseInt(firstModuleInAllModules.levelDepth) && e.isComplete})

        if (beforeModules.length == beforeModulesComplete.length) {
          modulesIds[modulesIds.indexOf(firstModule)].blocked = false
          modulesIds[modulesIds.indexOf(firstModule)].actual = true
        } else {
          modulesIds[modulesIds.indexOf(firstModule)].blocked = true
          modulesIds[modulesIds.indexOf(firstModule)].actual = false
        }
      }
      
    } else {
      let lastCompletedModule = { depth: "0", isComplete: true }

      for (const module of modulesIds) {
        if (module.isComplete && parseInt(module.depth) > parseInt(lastCompletedModule.depth)) {
          lastCompletedModule = module
        }
      }

      for (var j = modulesIds.length - 1; j >= 0; j--) {
        if (j % 2) {
          modulesIds[j].roadTo = "left"
        } else if (j == 0) {
          modulesIds[j].roadTo = "final"
        } else {
          modulesIds[j].roadTo = "right"
        }
      }

      for (let [i, module] of modulesIds.filter(e => { return parseInt(e.depth) < parseInt(lastCompletedModule.depth)}).entries()) {
        modulesIds[modulesIds.indexOf(module)].blocked = false
        modulesIds[modulesIds.indexOf(module)].actual = false
      }
  
      for (let [i, module] of modulesIds.filter(e => { return parseInt(e.depth) > parseInt(lastCompletedModule.depth)}).entries()) {
        modulesIds[modulesIds.indexOf(module)].blocked = true
        modulesIds[modulesIds.indexOf(module)].actual = false
      }

      if (parseInt(lastCompletedModule.depth) < modulesIds.length) {
        modulesIds[modulesIds.indexOf(lastCompletedModule) - 1].blocked = false
        modulesIds[modulesIds.indexOf(lastCompletedModule) - 1].actual = true
      }
    }

    return modulesIds
  }

  renderLevel(levels, level) {
    let modulesIds = level.modulesIds || []
    modulesIds.sort((a, b) => {
      return b.depth - a.depth
    })
    const modules = this.checkBlockedLevels(levels, level, modulesIds)

    return (
      <>
        <div className={`${styles.titleLevel} ${styles.justifyContentCenter}`}>
          <Row>
            <h5 className={`mb-3`}>{level.title}</h5><br/>
          </Row>
          {modules.map(module => { return (
            <Module module={module} blocked={module.blocked} actual={module.actual} finalModule={false} roadTo={module.roadTo} roadId={this.props.road.id}></Module>
          )})}
        </div>
      </>
    )
  }

  renderModule(module) {
    let getUnity = {}

    const reqModule = modules.get(module.id)
    const getModule = reqModule.body
  
    if (reqModule.ok) {
      const reqUnity = unities.get(getModule.module.unitiesIds[0])
      getUnity = reqUnity.body.unity
    }

    return (
      <>
        {getUnity !== {} ?
          <ButtonModuleRoad module={getUnity} progress={34}/> :
          <Loader type="TailSpin" color="#c7ef00" height={20} width={20} timeout={10000} />
        }
      </>
    )
  }
  
  renderEmptyLevel(number, md) {
    let randomX = Math.random() * 200 - 100
    return (
      <div className={`${styles.titleLevel} ${styles.justifyContentCenter} w-80`}>
        <Col sm={12} md={md} className={`${styles.finalRow}`}
        style={{
          filter: `blur(${number/3}px)`,
        }}><h5 className={`p-2`}>Nível {number}</h5><br/></Col>
        <Col sm={12} md={md} className={`${styles.finalRow}`}
          style={{
            backgroundImage: `url(${this.state.ilustracaoFog})`,
            backgroundPosition: "center",
            height: "390px",
            minWidth: "100%",
            backgroundSize: "cover",
            filter: "blur(3px)",
            transform: `translate(${randomX}px)`,
            transition: "transform 20s ease-in-out",
            margin: "0",
            opacity: "0.5"
          }}
        ></Col>
      </div>
    )
  }

  /**
   * Handles form submission
   * @param {object} evt 
   */
  async notifyUser(evt) {
    evt.preventDefault()

    this.setState({ loadingNotify: true })

    let response = await api.userUpdateNotify(auth.getToken())
    if (!response.ok) {
      this.setState({
        formError: response.error,
        loadingNotify: false
      })
    } else {
      this.setState({
        loadingNotify: false
      })
      toast.success('Enviaremos um email para você quando tivermos novidades!')
    }
  }

  render() { 
    var { loading, finished, loadingNotify } = this.state
    var { road, md, isPreview } = this.props
    return (
      <>
        <Col sm={12} className={styles.homePrincipalImage} style={{
          backgroundImage: `url(${this.state.ilustracaoMountain })`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "500px",
          filter: "blur(3px)",
          transform: `translate(50px)`,
          transition: "transform 20s ease-in-out",
          margin: "0",
          opacity: "0.5"
        }}>
        </Col>
        { finished?
          <>
            { [10, 9, 8, 7, 6, 5, 4].map(e => { return this.renderEmptyLevel(e, md) } ) }
            <Row className={`${styles.titleLevel} ${styles.justifyContentCenter} w-80`}>
              <Col sm={12} md={md} className={`${styles.titleRow} p-0`}>
                <h5 className={'w-100 p-0'}>Nível 4</h5><br/>
              </Col>
              <Col sm={12} md={md} className={`${styles.finalRow}`}>
                <Image style={{ maxWidth: "500px", margin: "30px auto", width: "100vw" }} src={this.state.ilustracaoConstrucao}/>
                <h2 style={{ maxWidth: "500px", margin: "0 auto" }}><strong>Parabéns! 🎉 Você chegou ao final da trilha!</strong></h2>
                <p style={{ maxWidth: "500px", margin: "10px auto"}}>Estamos construindo novos conteúdos toda semana!</p>
                <br/>
                <p style={{ maxWidth: "500px", margin: "10px auto" }}>Quer receber um <strong>email</strong> quando as novas <br/>unidades estiverem <strong>prontas</strong>?</p>
                <Button className={styles.primaryButton} onClick={(e) => { this.notifyUser(e) }}>Sim! Me avise
                { loadingNotify? (
                  <Loader type="TailSpin" color="#292F36" height={10} width={10} timeout={10000} />
                ):(<></>)}
                </Button>
              </Col>
            </Row>
          </>
        :<>{ [10, 9, 8, 7, 6, 5, 4].map(e => { return this.renderEmptyLevel(e, md) } ) }</>}
        <Col sm={12} md={md} ref={(el) => { this.roadEnd = el }} style={{ paddingBottom: "100px" }}>
          <Card className={isPreview? `${styles.bgDark} ${styles.borderRadius25} p-2 mt-4 ${styles.dashed}` : `${styles.bgDark} ${styles.borderRadius25} p-2 mt-4`}>
            {road.levels.sort(((a, b) => { return b.depth - a.depth })).map(level => { return this.renderLevel(road.levels, level) })}
          </Card>
        </Col>
        <div style={{ float:"left", clear: "both" }} ref={(el) => { this.roadEnd = el; }}></div>
    </>
    )
  }
}

export default withRouter(Cell)
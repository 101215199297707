import React, { Component }  from 'react'
import {
  withRouter,
} from 'react-router-dom'

import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import styles from './index.module.css'
import 'aos/dist/aos.css'
import TaskWidgetB from '../../components/TaskWidgetB'
import TaskNanoWidget from '../../components/TaskNanoWidget'
import TaskPicoWidget from '../../components/TaskPicoWidget'
import Loader from 'react-loader-spinner'
import { Button } from '../../lampejo-styles/components/Button/Button'
import { default as DefaultButton } from 'react-bootstrap/Button'
import { default as DefaultButtonGroup } from 'react-bootstrap/ButtonGroup'
import Modal from 'react-bootstrap/Modal'
import { BsChevronRight, BsChevronLeft, BsArrowRight } from 'react-icons/bs'
import UserWidget from '../../components/UserWidget'
import GoogleAdsWidget from '../../components/GoogleAdsWidget'

const api = require('../../utils/api')
const schedules = require('../../controllers/schedules')
const stickersCtrl = require('../../controllers/stickers')
const auth = require('../../utils/auth')

class SchedulePageB extends Component {
  constructor(props) {
    super(props)
    let now = new Date()

    this.state = {
      tasks: [],
      loading: true,
      currentUser: { name: '' },
      sticker: { title: '', imgUrl: '', content: '' },
      rest: null,
      showWhyModal: false,
      showStickerModal: false,
      date: now,
      isToday: false,
      dailyView: false,
      weeklyView: true,
      monthlyView: false,
      emptyBox: null,
      showWhyModal: false,
      showTutorial: false,
      clickHere: false,
      schedule: { tasks: [] },
      loadingNextTask: false,
      loadingNextMonth: false,
      loadingPreviousMonth: false
    }

    this.scrollToTodayRow()
  }


  scrollToTodayRow = () => {
    if (this.todayColumn) {
      this.todayColumn.scrollIntoView() // { behavior: "smooth" }
      setTimeout(() => { this.scrollTable(150); window.scrollTo(0, 0) }, 500)
    } else {
      setTimeout(() => { this.scrollToTodayRow() }, 1000)
    }
  }

  scrollTable(val) {
    if (this.weekTable) {
      this.weekTable.scrollBy(val, 0)
    } 
  }

  portugueseMonth (date) {
    if (date.getMonth() == 0){ return "Janeiro" }
    if (date.getMonth() == 1){ return "Fevereiro" }
    if (date.getMonth() == 2){ return "Março" }
    if (date.getMonth() == 3){ return "Abril" }
    if (date.getMonth() == 4){ return "Maio" }
    if (date.getMonth() == 5){ return "Junho" }
    if (date.getMonth() == 6){ return "Julho" }
    if (date.getMonth() == 7){ return "Agosto" }
    if (date.getMonth() == 8){ return "Setembro" }
    if (date.getMonth() == 9){ return "Outubro" }
    if (date.getMonth() == 10){ return "Novembro" }
    if (date.getMonth() == 11){ return "Dezembro" }
  }

  portugueseDay (date) {
    if (date.getDay() == 0){ return "dom" }
    if (date.getDay() == 1){ return "seg" }
    if (date.getDay() == 2){ return "ter" }
    if (date.getDay() == 3){ return "qua" }
    if (date.getDay() == 4){ return "qui" }
    if (date.getDay() == 5){ return "sex" }
    if (date.getDay() == 6){ return "sab" }
  }

  isToday (someDate) {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }

  renderCalendar(studyDays) {
    let center = this.state.date
    let calendarArray = []
    for (let i = -3; i <= 3; i++) {
      let date = new Date(center.getTime() + i * (1000 * 3600 * 24))
      let active = studyDays?.includes(date.getDay())
      if (center.getMonth() === date.getMonth()) {
        calendarArray.push({ date, active })
      }
    }
    let width = window.innerWidth
    return (
      <Row>
        { calendarArray.map(e => { return (
          <Col
            className={
              (width < 400 && calendarArray.indexOf(e) == 0) || (width < 400 && calendarArray.indexOf(e) == 6)?
              `d-none` :
              this.sameDay(e.date, center) ? `${styles.primary} ${styles.textCenter}`: `${styles.weekDay} ${styles.textCenter}`
            }
            as="a"
            style={{ cursor: 'pointer' }}
            onClick={() => { this.openDailyView(e.date) }}>
            {this.portugueseDay(e.date)}<br/>
            <h3>{e.date.getDate()}</h3>
            { e.active? <span className={styles.circle}></span>: <></>}
          </Col>
          )})
        }
      </Row>
    )
  }

  getDates (startDate, stopDate) {
    var dateArray = new Array()
    var currentDate = startDate
    while (currentDate <= stopDate) {
      dateArray.push(new Date (currentDate))
      currentDate = new Date((currentDate.getTime() + (1000 * 3600 * 24)))
    }
    return dateArray
  }

  seePreviousMonth() {
    let newMonth = new Date(this.state.fromMonth.getFullYear(), this.state.fromMonth.getMonth() - 1, 1)

    this.setState({
      fromMonth: newMonth,
      loadingPreviousMonth: true
    })
    this.updateSchedule()
  }

  async seeNextMonth() {
    const newMonth = await new Date(new Date(this.state.toMonth.getFullYear(), this.state.toMonth.getMonth() + 2, 1).getTime() - (1000 * 3600 * 24))
    console.log(this.state.fromMonth, newMonth)
    this.setState({
      toMonth: newMonth,
      loadingNextMonth: true
    })
    await this.updateSchedule()
  }

  renderDayMonthView(date, daySchedule) {
    return (
    <td className={styles.monthViewTD}>
      <div className={this.isToday(date)?`${styles.calendarTodayDay}`:`${styles.calendarDay}`} onClick={() => { this.openDailyView(date) }}>
        <div className={styles.dayNumber}>{date.getDate()}</div>
        {
          this.state.loadingNextMonth || this.state.loadingPreviousMonth?
            <span><Loader type="Rings" color="#c7ef00" height={40} width={40} timeout={20000} /> </span>:
          daySchedule.length > 0 ?
            daySchedule.length <= 6?
              daySchedule.map(task => {
                return (
                  <TaskPicoWidget task={task}/>
                )
              })
            : (
              <>
              { 
                daySchedule.splice(0,6).map(task => {
                  return (
                    <TaskPicoWidget task={task}/>
                  )
                })
              }
              <span style={{ position: 'relative', top: '-10px' }}>...</span>
            </>)
          : (
            <span className={styles.emptyWeekDay}><span className={styles.emptyWeekDayBorder}></span></span>
          )
        }
      </div>
    </td>)
  }

  renderDayWeekView(date, daySchedule) {
    return (
      <>
      { this.isToday(date)?
        <td className={styles.dayWeekViewTD} ref={(el) => { this.todayColumn = el }} onClick={() => this.openDailyView(date)}>
            <span style={{ opacity: 0.6 }}>{this.portugueseDay(date)}</span>
            <hr style={{ borderTop: "1px solid rgba(255,255,255,.1)", margin: "8px 0" }}/>
            {this.isToday(date)? <div className={styles.today}>{date.getDate()}</div> : <div className={styles.otherDays}>{date.getDate()}</div>}
            <span style={{ height: "5px", width: "100%", display: "block" }}></span>
            {
              daySchedule.length > 0 ?
                daySchedule.map(task => {
                  return (
                    <TaskNanoWidget task={task}/>
                  )
                })
              : (
                <span className={styles.emptyWeekDay}><span className={styles.emptyWeekDayBorder}></span></span>
              )
            }
          </td>
        :
          <td className={styles.dayWeekViewTD} onClick={() => this.openDailyView(date)}>
            <span style={{ opacity: 0.6 }}>{this.portugueseDay(date)}</span>
            <hr style={{ borderTop: "1px solid rgba(255,255,255,.1)", margin: "8px 0" }}/>
            {this.isToday(date)? <span className={styles.today}>{date.getDate()}</span> : <span className={styles.otherDays}>{date.getDate()}</span>}
            <span style={{ height: "5px", width: "100%", display: "block" }}></span>
            {
              daySchedule.length > 0 ?
                daySchedule.map(task => {
                  return (
                    <TaskNanoWidget task={task}/>
                  )
                })
              : (
                <span className={styles.emptyWeekDay}><span className={styles.emptyWeekDayBorder}></span></span>
              )
            }
          </td>
      }
      </>
    )
  }

  renderMonth(monthDate) {
    let now = new Date()
    let firstMonthDay = new Date(monthDate.getFullYear(), monthDate.getMonth(), 1)
    let firstDay = firstMonthDay

    let lastMonthDay = new Date(new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 1).getTime() - (1000 * 3600 * 24))
    let lastDay = lastMonthDay

    while (firstDay.getDay() != 0) {
      firstDay = new Date(firstDay.getTime() - (1000 * 3600 * 24))
    }

    while (lastDay.getDay() != 6) {
      lastDay = new Date(lastDay.getTime() + (1000 * 3600 * 24))
    }

    let dates = this.getDates(firstDay, lastDay)

    let weeks = [[]]
    let j = -1
    for (let i = 0; dates[i] != undefined; i++ ) {
      if (dates[i].getDay() == 0) {
        j += 1
        weeks.push([])
        weeks[j].push(dates[i])
      } else {
        weeks[j].push(dates[i])
      }
    }

    return (
    <>
      {monthDate.getMonth() == 0?
        <Row className={`w-100`}>
          <Col>
            <h2>{`${monthDate.getUTCFullYear()}`}</h2>
          </Col>
        </Row>
        : <></>
      }

      <Row className={`mx-auto px-0`}>
        <h3>{ this.portugueseMonth(firstMonthDay) }</h3>
      </Row>
      <Row className={`mx-0 px-0`}>
        <Col xs={12} className={`px-0 pb-5`}>
          <table className={styles.monthViewTable}>
            <thead>
              <tr style={{ textAlign: 'center' }}>
                <th>dom</th><th>seg</th><th>ter</th><th>qua</th><th>qui</th><th>sex</th><th>sab</th>
              </tr>
            </thead>
            <tbody>
            { weeks.map(week => {
              return (
                <tr>
                { week.map(day => { return this.renderDayMonthView(day, this.state.schedule.tasks.filter(task => { return this.sameDay(task.date, day) })) }) }
                </tr>
              )
            })}
            </tbody>
          </table>
        </Col>
      </Row>
    </>)

  }

  renderWeek(month, schedule) {
    let now = new Date()
    let firstMonthDay = new Date(now.getFullYear(), month, 1)
    let firstDay = firstMonthDay

    let lastMonthDay = new Date(new Date(now.getFullYear(), month + 1, 1).getTime() - (1000 * 3600 * 24))
    let lastDay = lastMonthDay

    let dates = this.getDates(firstDay, lastDay)
    // console.log(schedule)
    return (
      <>
        <DefaultButton className={`${styles.toRightDefaultButton} d-none d-md-block`} variant="link" onClick={() => { this.scrollTable(100) }}><BsChevronRight/></DefaultButton>
        <DefaultButton className={`${styles.toLeftDefaultButton} d-none d-md-block`} variant="link" onClick={() => { this.scrollTable(-100) }}><BsChevronLeft/></DefaultButton>
        <table style={{ width: "100%", overflow: "auto scroll", display: "flex", msOverflowY: 'scroll' }} ref={(el) => { this.weekTable = el }}>
          <tr className={styles.weekViewTR}>
          { dates.map(day => { return this.renderDayWeekView(day, schedule.tasks.filter(task => { return this.sameDay(task.date, day) }))}) }
          </tr>
        </table>
      </>
    )
  }

  renderYearCalendar(studyDays) {
    const { fromMonth, toMonth } = this.state
    let months = []
    
    for (let i = fromMonth; i.getTime() <= toMonth.getTime(); i = new Date(new Date(i.getFullYear(), i.getMonth() + 1, 1).getTime())) {
      months.push(i)
    }

    return (
      <>
        <Row>
          <Col className={`text-center pb-3`}>
             {fromMonth.getMonth() >= 1?
              <Button
                centered
                variant="tertiary"
                onClick={() => { this.seePreviousMonth()}}
                isLoading={this.state.loadingPreviousMonth}
                label="ver mês anterior"/>
              :<></>}
          </Col>
        </Row>
        <Row>
          { months.map(month => { return this.renderMonth(month)} ) }
        </Row>
        <Row>
          <Col className={`text-center py-md-3`} style={{ paddingBottom: '100px' }}>
            <Button
              centered
              variant="tertiary"
              onClick={() => { this.seeNextMonth()}}
              label="visualizar próximo mês"
              isLoading={this.state.loadingNextMonth}
            />
          </Col>
        </Row>
      </>
    )
  }

  async componentDidMount() {
    let now = new Date();
    let currentUser = await api.currentUser()
    const emptyBox = require('../../assets/empty_box.svg')

    let fromMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    let toMonth = new Date(new Date(now.getFullYear(), now.getMonth() + 1, 2).getTime() - (1000 * 3600 * 24))
    this.setState({
      initialTime: now,
      currentUser,
      emptyBox,
      fromMonth,
      toMonth,
    })

    await this.updateSchedule()

    if (this.props.match.params.viewType === 'tutorial') {
      this.openDailyView(now)
      this.setState({
        dailyView: true,
        weeklyView: false,
        monthlyView: false,
        showTutorial: '1'
      })
    }
    if (this.props.match.params.viewType === 'tutorial-crono') {
      this.setState({
        showScheduleTutorial: '1'
      })
    }
  }

  async updateStickers() {
    let currentUser = await api.currentUser()
    const previousStickers = await auth.getStickers()
    const getCurrentStickers = await stickersCtrl.getByUserId(currentUser.id, await auth.getToken())
    const currentStickers = getCurrentStickers.body.stickers

    if (currentStickers.filter(s => { return !previousStickers.map(a => a.id).includes(s.id) }).length > 0) {
      let stickerId = await currentStickers.filter(s => { return !previousStickers.map(a => a.id).includes(s.id) })[0].id
      const reqSticker = await stickersCtrl.getById(stickerId, await auth.getToken())
      const sticker = reqSticker.body.sticker

      auth.saveStickers(currentStickers)

      this.setState({
        sticker
      })
      this.openStickerModal()
    }

    this.setState({
      previousStickers,
      currentStickers
    }) 
  }

  async updateSchedule() {
    try {
      const reqSchedule = await schedules.getBetweenDates({ start: this.state.fromMonth, to: this.state.toMonth }, auth.getToken())
      if (reqSchedule.ok) {
        const getSchedule = reqSchedule.body.schedule

        this.setState({
          loading: false,
          schedule: getSchedule,
          loadingPreviousMonth: false,
          loadingNextMonth: false
        })
      } else {
        this.setState({
          loading: false,
          loadingPreviousMonth: false,
          loadingNextMonth: false
        })
      }

      this.updateStickers()
    } catch (error) {
      auth.logout()
      window.location.replace(`/login`)
    }
  }

  async showTutorialValue(value) {
    let clickHere = false
    if (value == 'end') {
      clickHere = true
    }
    this.setState({
      showTutorial: value,
      clickHere
    })
  }

  async showScheduleTutorialValue(value) {
    if (value == '4') {
      this.scrollToTodayRow()
    }
    this.setState({
      showScheduleTutorial: value
    })
  }

  async addHours (date, h) {
    date.setHours(date.getHours()+h)
    return date
  }

  sameDay (date, otherDate) {
    let d = new Date(date)
    let od = new Date(otherDate)

    return (d.getDate() === od.getDate() &&
      d.getMonth() === od.getMonth() &&
      d.getYear() === od.getYear())
  }

  async openWeeklyView() {
    this.setState({
      monthlyView: false,
      weeklyView: true,
      dailyView: false,
    })
    this.scrollToTodayRow()
  }

  async openMonthlyView() {
    this.setState({
      monthlyView: true,
      weeklyView: false,
      dailyView: false,
    })
  }

  async openDailyView(date) {
    if (!date) {
      date = new Date()
    }

    let tasks = this.state.schedule.tasks.filter(task => { return this.sameDay(task.date, date) })
    this.setState({
      monthlyView: false,
      weeklyView: false,
      dailyView: true,
      date,
      tasks
    })
  }

  async openWhyModal() {
    this.setState({
      showWhyModal: true
    })
  }

  async closeWhyModal() {
    this.setState({
      showWhyModal: false
    })
  }

  async openStickerModal() {
    this.setState({
      showStickerModal: true
    })
  }

  async closeStickerModal() {
    this.setState({
      showStickerModal: false
    })
  }

  async getNextTask() {
    this.setState({
      loadingNextTask: true
    })
    const reqTask = await schedules.getNextTask(auth.getToken())
    if (reqTask.ok) {
      window.location.replace(`/task/${reqTask.body.id}`)
    }
  }

  render() {
    const { currentUser, tasks, loading, loadingNextTask, isToday, date, dailyView, weeklyView, monthlyView, emptyBox, sticker, showWhyModal, showStickerModal, showTutorial, showScheduleTutorial, clickHere, schedule } = this.state
    return (
      <>
        { loading? 
          <Container className={`${styles.container} pt-4`}>
            <Row className={`justify-content-md-center`}>
              <Col md={6} xs={12} className={`mx-auto text-center pt-0 pt-md-5`}>
                <h3>Estamos consultando o seu cronograma...</h3>
                <br/><br/>
                <Loader type="TailSpin" color="#c7ef00" height={100} width={100} timeout={20000} />
                <br/><br/>
                <span>isso pode demorar cerca de 30 segundos...</span>
              </Col>
            </Row>
          </Container>
          : weeklyView?
          <Container className={`${styles.container} pt-4`}>
            <Row>
              <Col md={8} xs={12}>
                <Row>
                  <Col xs={12} className={`pt-0 pt-md-5 mx-auto`}>
                    <Row>
                      <Col>
                        <h2>{this.portugueseMonth(date)}</h2>
                      </Col>
                      <Col className={`ml-auto mr-0 d-flex justify-content-end`}>
                        <DefaultButtonGroup aria-label="First group">
                          <DefaultButton className={monthlyView? `${styles.firstDefaultButtonInGroup} ${styles.activeDefaultButtonInGroup}` : `${styles.firstDefaultButtonInGroup}`} onClick={(e) => { return this.openMonthlyView() }}>Mês</DefaultButton>
                          <DefaultButton className={weeklyView? `${styles.middleDefaultButtonInGroup} ${styles.activeDefaultButtonInGroup}` : `${styles.middleDefaultButtonInGroup}`} onClick={(e) => { return this.openWeeklyView() }}>Semana</DefaultButton>
                          <DefaultButton className={dailyView? `${styles.lastDefaultButtonInGroup} ${styles.activeDefaultButtonInGroup}`: `${styles.lastDefaultButtonInGroup}`} onClick={(e) => { return this.openDailyView() }}>Dia</DefaultButton>
                        </DefaultButtonGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={`mx-auto pt-3`}>
                    { this.renderWeek(date.getMonth(), schedule) }
                  </Col>
                </Row>
                <br/><br/><br/><br/>
              </Col>
              <Col md={4} className={'mt-5 pl-5 d-none d-md-block'}>
                <UserWidget/>
                <br/>
                <GoogleAdsWidget/>
              </Col>
            </Row>
          </Container>
          : dailyView?
          <Container className={`${styles.container} py-4`}>
            <Row>
              <Col md={8} xs={12}>
                <Row>
                  <Col xs={12} className={`pt-0 pt-md-5 mx-auto`}>
                    <Row>
                      <Col>
                        <h2>{ this.isToday(date)? `Hoje` :
                          parseInt((date.getTime() - new Date().getTime())  / (1000 * 3600 * 24)) == 0?
                          `em 1 dia...` :
                          (date.getTime() - new Date().getTime()) > 0?
                          `em ${parseInt((date.getTime() - new Date().getTime())  / (1000 * 3600 * 24)) + 1} dias...`:
                          `anteriormente...` }
                        </h2>
                      </Col>
                      <Col className={`ml-auto mr-0 d-flex justify-content-end`}>
                        <DefaultButtonGroup aria-label="First group">
                          <DefaultButton className={monthlyView? `${styles.firstDefaultButtonInGroup} ${styles.activeDefaultButtonInGroup}` : `${styles.firstDefaultButtonInGroup}`} onClick={(e) => { return this.openMonthlyView() }}>Mês</DefaultButton>
                          <DefaultButton className={weeklyView? `${styles.middleDefaultButtonInGroup} ${styles.activeDefaultButtonInGroup}` : `${styles.middleDefaultButtonInGroup}`} onClick={(e) => { return this.openWeeklyView() }}>Semana</DefaultButton>
                          <DefaultButton className={dailyView? `${styles.lastDefaultButtonInGroup} ${styles.activeDefaultButtonInGroup}`: `${styles.lastDefaultButtonInGroup}`} onClick={(e) => { return this.openDailyView() }}>Dia</DefaultButton>
                        </DefaultButtonGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={`mx-auto pt-3`}>
                    { this.renderCalendar(currentUser.studyDays) }
                  </Col>
                </Row>
              { tasks.length == 0? (
              <>
                <Row>
                  <Col md={6} xs={12} className={`mx-auto text-center pt-5`}>
                    <h2>não há nada para esse dia...</h2>
                    <br/><br/>
                    <span>utilize o calendário acima para navegar entre os dias!</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={12} className={`mx-auto pt-5 text-center`} data-aos="fade-up">
                    <p>Não há nenhuma tarefa no seu cronograma para esse dia.</p>
                    <p><DefaultButton variant="link" className={`${styles.primaryLink} ${styles.centered}`} onClick={(e) => { this.openWhyModal() }}>por quê?</DefaultButton></p>
                    <p>Se quiser adiantar a próxima tarefa, <DefaultButton variant="link" className={`${styles.secondaryLink} ${styles.centered} p-0`} onClick={(e) => { this.getNextTask() }}>clique aqui</DefaultButton>{loadingNextTask? <Loader type="TailSpin" color="#c7ef00" height={20} width={20} timeout={10000} /> : <></>}</p>
                  </Col>
                </Row>
              </>
              ) : (
              <Row className={`pb-5`}>
                <Col md={10} xs={12} className={`mx-auto`}>
                  { tasks.length > 0 ? tasks.map(task => { return (<TaskWidgetB key={task.id} task={task} md={12}/>) } ) : ''}
                  { clickHere? 
                  <>
                  <style>
                  {`@keyframes wobble {
                      33% {
                        transform: rotate(5deg);
                      }
                      66% {
                        transform: rotate(-5deg);
                      }
                    }`}
                  </style>
                  <Image style={{ top: '-65px', position: 'relative', animation: 'wobble 5s ease-in-out infinite alternate' }} src={require('../../assets/click_here.svg')}/>
                  
                  </>
                  : <></>}
                </Col>
              </Row>
            )
          }
          </Col>
          <Col md={4} className={'mt-5 pl-5 d-none d-md-block'}>
            <UserWidget/>
            <br/>
            <GoogleAdsWidget/>
          </Col>
          </Row>
          </Container>
          : monthlyView?
            <Container className={`${styles.container} pt-4`}>
              <Row>
                <Col md={8} xs={12}>
                  <Row>
                    <Col xs={12} className={`pt-0 pt-md-5 mx-auto`}>
                      <Row>
                        <Col className={`ml-auto mr-0 d-flex justify-content-end`}>
                          <DefaultButtonGroup aria-label="First group">
                            <DefaultButton className={monthlyView? `${styles.firstDefaultButtonInGroup} ${styles.activeDefaultButtonInGroup}` : `${styles.firstDefaultButtonInGroup}`} onClick={(e) => { return this.openMonthlyView() }}>Mês</DefaultButton>
                            <DefaultButton className={weeklyView? `${styles.middleDefaultButtonInGroup} ${styles.activeDefaultButtonInGroup}` : `${styles.middleDefaultButtonInGroup}`} onClick={(e) => { return this.openWeeklyView() }}>Semana</DefaultButton>
                            <DefaultButton className={dailyView? `${styles.lastDefaultButtonInGroup} ${styles.activeDefaultButtonInGroup}`: `${styles.lastDefaultButtonInGroup}`} onClick={(e) => { return this.openDailyView() }}>Dia</DefaultButton>
                          </DefaultButtonGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className={`mx-auto pt-3`}>
                      { this.renderYearCalendar(currentUser.studyDays) }
                    </Col>
                  </Row>
                </Col>
                <Col md={4} className={'mt-5 pl-5 d-none d-md-block'}>
                  <UserWidget/>
                  <br/>
                  <GoogleAdsWidget/>
                </Col>
              </Row>
            </Container>
          : <></> } 
        <Modal className={`${''}`} show={showWhyModal} backdrop={"static"} keyboard={false} >
          <Modal.Header
            style={{
              padding: "30px",
              justifyContent: "center",
              color: "#292f36",
              backgroundColor: "white",
              border: 0,
              borderRadius: "25px",
            }}
          >
            <Modal.Title style={{ fontWeight: "bolder", textAlign: "center"}}>Como funciona o cronograma personalizado?</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              padding: "0 25px 50px 25px",
              justifyContent: "left",
              textAlign: "left",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 25,
              border: 0
              }}>
              <Col sm={12}>
                O seu cronograma é criado a partir das configurações de <strong>tempo de estudos por semana</strong> e <strong>dias estudados na semana</strong>.<br/><br/>
                Caso você queira alterar essas configurações, acesse o <strong>seu perfil</strong> e altere os dias de estudo do seu cronograma.<br/><br/>
              </Col>
              <Col sm={12} style={{ textAlign: 'center', display: 'flex', margin: '0 auto' }}>
                <DefaultButton variant="link" className={`${styles.primaryDefaultButton} ${styles.centered}`} onClick={(e) => { this.closeWhyModal() }}>entendi</DefaultButton>
              </Col>
          </Modal.Body>
        </Modal>
        <style>
          {
          `.content-border-radius-25 .modal-content {
              border-radius: 25px !important;
          }` 
          }
        </style>
        <Modal className={`${''}`} show={showTutorial === '1'} backdrop={"static"} keyboard={false} dialogClassName='content-border-radius-25'>
          <Image src={require('../../assets/progress_1_4.svg')} style={{ padding: "30px 10px 10px 10px", maxWidth: '80px', margin: "0 auto" }}/>
          <Image src={require('../../assets/logo_tipo_colors.svg')} style={{ padding: "20px 30px 20px 30px", maxWidth: '300px', margin: "0 auto" }}/>
          <Modal.Header
            style={{
              padding: "10px 30px",
              justifyContent: "center",
              color: "#292f36",
              backgroundColor: "white",
              border: 0,
              borderRadius: "25px",
            }}
          >
            <Modal.Title style={{ fontWeight: "bolder", textAlign: "center"}}>Seja bem-vind@ a Lampejo!</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              padding: "0 10px 50px 10px",
              justifyContent: "left",
              textAlign: "left",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 25,
              border: 0
              }}>
              <Col sm={12} style={{ textAlign: "center"}}>
                <p>Você está em uma plataforma de ensino focada no ENEM.<br/>Somos gratuitos. Agora e sempre.<br/></p>
              </Col>
              <Col sm={12} style={{ textAlign: 'center', display: 'flex', margin: '0 auto', justifyContent: 'center' }}>
                <Button
                  centered
                  onClick={(e) => { this.showTutorialValue('2') }}
                  label="próximo"
                />
              </Col>
          </Modal.Body>
        </Modal>
        <Modal className={`${''}`} show={showTutorial === '2'} backdrop={"static"} keyboard={false} dialogClassName='content-border-radius-25'>
          <Image src={require('../../assets/progress_2_4.svg')} style={{ padding: "30px 10px 10px 10px", maxWidth: '80px', margin: "0 auto" }}/>
          <Image src={require('../../assets/ilustracao_livros.svg')} style={{ padding: "20px 30px 20px 30px", maxWidth: '300px', margin: "0 auto" }}/>
          <Modal.Body
            style={{
              padding: "0 10px 50px 10px",
              justifyContent: "left",
              textAlign: "left",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 25,
              border: 0
              }}>
              <Col sm={12} style={{ textAlign: "center"}}>
              <p>aqui você vai encontrar centenas de <strong>conteúdos</strong>, <strong>exercícios</strong> e <strong>resoluções</strong> que irão te ajudar a se <strong>preparar para a prova</strong>.<br/></p>
              </Col>
              <Col sm={12} style={{ textAlign: 'center', display: 'flex', margin: '0 auto', justifyContent: 'center' }}>
                <Button
                  centered
                  onClick={(e) => { this.showTutorialValue('3') }}
                  label="próximo"
                />
              </Col>
          </Modal.Body>
        </Modal>
        <Modal className={`${''}`} show={showTutorial === '3'} backdrop={"static"} keyboard={false} dialogClassName='content-border-radius-25'>
          <Image src={require('../../assets/progress_3_4.svg')} style={{ padding: "30px 10px 10px 10px", maxWidth: '80px', margin: "0 auto" }}/>
          <Image src={require('../../assets/ilustracao_crono.svg')} style={{ padding: "20px 30px 20px 30px", maxWidth: '300px', margin: "0 auto" }}/>
          <Modal.Body
            style={{
              padding: "0 10px 50px 10px",
              justifyContent: "left",
              textAlign: "left",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 25,
              border: 0
              }}>
              <Col sm={12} style={{ textAlign: "center"}}>
                <p>Muita coisa para estudar, né? Pra te ajudar a se organizar, fizemos um <strong>cronograma de estudos</strong>. Assim, você nunca fica perdid@.<br/></p>
              </Col>
              <Col sm={12} style={{ textAlign: 'center', display: 'flex', margin: '0 auto', justifyContent: 'center' }}>
                <Button
                  centered
                  onClick={(e) => { this.showTutorialValue('4') }}
                  label="próximo"
                />
              </Col>
          </Modal.Body>
        </Modal>
        <Modal className={`${''}`} show={showTutorial === '4'} backdrop={"static"} keyboard={false} dialogClassName='content-border-radius-25'>
          <Image src={require('../../assets/progress_4_4.svg')} style={{ padding: "30px 10px 10px 10px", maxWidth: '80px', margin: "0 auto" }}/>
          <Image src={require('../../assets/ilustracao_adapta.svg')} style={{ padding: "20px 30px 20px 30px", maxWidth: '400px', margin: "0 auto" }}/>
          
          <Modal.Body
            style={{
              padding: "0 10px 50px 10px",
              justifyContent: "left",
              textAlign: "left",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 25,
              border: 0
              }}>
              <Col sm={12} style={{ textAlign: "center"}}>
                <p>O cronograma se <strong>adapta</strong> de acordo com o tempo disponível que você tem para estudar.<br/></p>
              </Col>
              <Col sm={12} style={{ textAlign: 'center', display: 'flex', margin: '0 auto', justifyContent: 'center' }}>
                <Button
                  centered
                  onClick={(e) => { this.showTutorialValue('end') }}
                  label="próximo"
                />
              </Col>
          </Modal.Body>
        </Modal>
        <Modal className={`${''}`} show={showScheduleTutorial === '1'} centered backdrop={"static"} keyboard={false} dialogClassName='content-border-radius-25'>
          <Image src={require('../../assets/progress_1_4.svg')} style={{ padding: "30px 10px 10px 10px", maxWidth: '80px', margin: "0 auto" }}/>
          <Modal.Header
            style={{
              padding: "10px 30px",
              justifyContent: "center",
              color: "#292f36",
              backgroundColor: "white",
              border: 0,
              borderRadius: "25px",
            }}
          >
            <Modal.Title style={{ fontWeight: "bolder", textAlign: "center"}}>esse é o seu cronograma</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              padding: "0 10px 50px 10px",
              justifyContent: "left",
              textAlign: "left",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 25,
              border: 0
              }}>
              <Col sm={12} style={{ textAlign: "center" }}>
                <p>Você pode alterar as formas de visualização das tarefas para <strong>mês</strong>, <strong>semana</strong> ou <strong>dia</strong>.</p>
              </Col>
              <Col sm={12} style={{ textAlign: 'center', display: 'flex', margin: '0 auto', justifyContent: 'center' }}>
                <Button
                  centered
                  onClick={(e) => { this.showScheduleTutorialValue('2') }}
                  label="próximo"
                />
              </Col>
          </Modal.Body>
        </Modal>
        <Modal className={`${''}`} show={showScheduleTutorial === '2'} below backdrop={"static"} keyboard={false} dialogClassName='content-border-radius-25'>
          <Image src={require('../../assets/progress_2_4.svg')} style={{ padding: "30px 10px 10px 10px", maxWidth: '80px', margin: "0 auto" }}/>
          <Modal.Body
            style={{
              padding: "0 10px 50px 10px",
              justifyContent: "left",
              textAlign: "left",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 25,
              border: 0
              }}>
              <Col sm={12} style={{ textAlign: "center"}}>
              <p>Cada um desses “bloquinhos” é uma tarefa do seu cronograma.</p>
              <p>Elas só aparecem nos dias que você escolheu.</p>
              <Image src={require('../../assets/pointing_to_views.svg')} className={styles.pointingToBlocks}/>
              </Col>
              <Col sm={12} style={{ textAlign: 'center', display: 'flex', margin: '0 auto', justifyContent: 'center' }}>
                <Button
                  centered
                  onClick={(e) => { this.showScheduleTutorialValue('3') }}
                  label="próximo"
                />
              </Col>
          </Modal.Body>
        </Modal>
        <Modal className={`${''}`} show={showScheduleTutorial === '3'} centered backdrop={"static"} keyboard={false} dialogClassName='content-border-radius-25'>
          <Image src={require('../../assets/progress_3_4.svg')} style={{ padding: "30px 10px 10px 10px", maxWidth: '80px', margin: "0 auto" }}/>
          
          <Modal.Body
            style={{
              padding: "0 10px 50px 10px",
              justifyContent: "left",
              textAlign: "left",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 25,
              border: 0
              }}>
              <Modal.Title style={{ fontWeight: "bolder", textAlign: "center"}}>se cai mais é mais importante</Modal.Title>
              <Image src={require('../../assets/ilustracao_cai_mais.svg')} style={{ padding: "20px 30px 20px 30px", width: '100%', maxWidth: '400px', margin: "0 auto", display: 'flex' }}/>
              <Col sm={12} style={{ textAlign: "center"}}>
                <p>Você vai estudar da forma mais eficiente! Nós ordenamos as tarefas conforme a chance dela <strong>cair</strong> no ENEM.<br/></p>
              </Col>
              <Col sm={12} style={{ textAlign: 'center', display: 'flex', margin: '0 auto', justifyContent: 'center' }}>
                <Button
                  centered
                  onClick={(e) => { this.showScheduleTutorialValue('4') }}
                  label="próximo"
                />
              </Col>
          </Modal.Body>
        </Modal>
        <Modal className={`${''}`} show={showScheduleTutorial === '4'} centered backdrop={"static"} keyboard={false} dialogClassName='content-border-radius-25'>
          <Image src={require('../../assets/progress_4_4.svg')} style={{ padding: "30px 10px 10px 10px", maxWidth: '80px', margin: "0 auto" }}/>
          
          <Modal.Body
            style={{
              padding: "0 10px 50px 10px",
              justifyContent: "left",
              textAlign: "left",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 25,
              border: 0
              }}>
              <Col sm={12} style={{ textAlign: "center"}}>
                <p>As tarefas que você finalizou ficam marcadas com um <Image src={require('../../assets/check_sign.svg')}/><br/></p>
              </Col>
              <Col sm={12} style={{ textAlign: 'center', display: 'flex', margin: '0 auto', justifyContent: 'center' }}>
                <Button
                  centered
                  onClick={(e) => { this.showScheduleTutorialValue('end') }}
                  label="próximo"
                />
              </Col>
          </Modal.Body>
        </Modal>
        <style>
          {
          `.sticker-modal {
              top: 60px !important;
              padding-top: 20px !important;
          }
          .sticker-modal .modal-content {
            border-radius: 25px !important;
          }
          @keyframes wobble {
            33% {
              transform: rotate(5deg);
            }
            66% {
              transform: rotate(-5deg);
            }
          }`
          }
        </style>
        <Modal className={`${'sticker-modal'}`} show={showStickerModal} backdrop={"static"} keyboard={false} >
          <Modal.Body
            style={{
              padding: "0 10px 0 10px",
              justifyContent: "center",
              textAlign: "center",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 25,
              border: 0
              }}>
              <Image src={sticker.imgUrl} style={{ position: 'relative', top: '-50px', alignItems: 'center',  animation: 'wobble 5s ease-in-out infinite alternate' }}/>
              <Col sm={12} style={{ top: '-30px' }}>
                <span style={{ opacity: 0.6 }}>você recebeu um sticker:</span>
                <Modal.Title style={{ fontWeight: "bolder", textAlign: "center", fontSize: '2rem', paddingTop: '10px' }}>{sticker.title}</Modal.Title>
                <Col sm={12} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                  {sticker.content}
                </Col>
                <Col sm={12} style={{ textAlign: 'center', margin: '0 auto' }}>
                  <Button centered label="Aeee!" onClick={(e) => { this.closeStickerModal() }}/>
                </Col>
              </Col>
          </Modal.Body>
        </Modal>
        { loading? <></> :
        <div className={styles.toNextTaskDiv}>
          <Button
            centered
            size="lg"
            rightIcon="rightArrow"
            onClick={() => this.getNextTask()}
            isLoading={loadingNextTask}
            label="ir para a próxima tarefa!"
          />
          {/* <DefaultButton variant="link" className={`${styles.primaryDefaultButton} align-center`} onClick={(e) => this.getNextTask()}>{loadingNextTask? <Loader type="TailSpin" color="#292F36" height={20} width={20} timeout={10000} /> : <>ir para a próxima tarefa! <BsArrowRight style={{ height: '26px', width: '35px', paddingLeft: '10px' }}/></>}</DefaultButton> */}
        </div>
        }
        <br/><br/><br/><br/>
      </>
    )
  }
}

export default withRouter(SchedulePageB)
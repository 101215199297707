import React, { Component } from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import styles from './index.module.css'
import Question from '../Question'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import { Button } from '../../lampejo-styles/components/Button/Button';
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { CgChevronRightO } from 'react-icons/cg'
import { BiEditAlt } from 'react-icons/bi'

const resolutions = require('../../controllers/resolutions')
const answers = require('../../controllers/answers')
const attempts = require('../../controllers/attempts')
const questions = require('../../controllers/questions')
const exams = require('../../controllers/exams')
const api = require('../../utils/api')

class Cell extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      chosenOne: {},
      hasChoose: false,
      exam: { name: '', period: '' },
      resolutionList: [],
      showRightAnswerModal: false,
      showWrongAnswerModal: false,
      showResolution: this.props.showResolution,
      editResolution: this.props.editResolution,
      currentUser: { can: [] },
      question: {}
    }
  }

  async componentDidMount() {
    let CurrentUser = await api.currentUser()
    
    this.setState({
      currentUser: CurrentUser
    })
  }

  async componentWillReceiveProps(nextProps) {
    let question = {}
    if (nextProps.questionId) {
      if (nextProps.questionId !== this.props.questionId) {
        const reqQuestion = await questions.get(nextProps.questionId)
        const getQuestion = reqQuestion.body.question

        let getExam
        if (getQuestion.examId) {
          const requestExam = await exams.getById(getQuestion.examId, this.state.currentUser.id)
          getExam = requestExam.body.exam
        }

        this.setState({
          question: getQuestion,
          loading: false,
          exam: getExam
        })
      }
    }
    if (nextProps.content) {
      this.setState({
        loading: false,
      })
    }
  }

  render() { 
    var { loading } = this.state
    var { content, md, isPreview, questionId } = this.props
    return (
      <>
        <Col sm={12} md={md}>
          <Card className={isPreview? `${styles.bgDark} ${styles.borderRadius25} mt-4 ${styles.dashed}` : `${styles.bgDark} ${styles.borderRadius25} mt-4`}>
            <div className={questionId? `d-none` : `d-block p-3`}>
              <div>
                { loading ? 
                <>
                  <Skeleton width={30}/><br/>
                  <Skeleton width={380}/><br/>
                  <Skeleton width={320}/>
                </>
                :
                <div dangerouslySetInnerHTML={{ __html: content }} />
                }
                {this.render}
              </div>
              { isPreview ? (
                <Button disabled centered marginTop="lg" label="continuar"/>
              ) : (
                <Button centered disabled={loading} label="continuar" marginTop="lg" onClick={() => {
                  if (loading) {
                    return;
                  }
                  this.props.toNextCell();
                }}/>
              )}
            </div>
            <div className={questionId? `d-block` : `d-none`}>
              <Question md={12} question={this.state.question} exam={this.state.exam} isList={true} toNextQuestion={() => this.props.toNextCell()}></Question>
            </div>
          </Card>
        </Col>
    </>
    )
  }
}

export default withRouter(Cell)
import React, { Component } from 'react'
import {
  withRouter
} from 'react-router-dom'

import Image from 'react-bootstrap/Image'
import styles from './index.module.css'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { default as DefaultButton } from 'react-bootstrap/Button'
import { Button } from '../../lampejo-styles/components/Button/Button'
import Modal from 'react-bootstrap/Modal'
import Loader from 'react-loader-spinner'
import { GrClose } from 'react-icons/gr'

const stickersCtrl = require('../../controllers/stickers')
const api = require('../../utils/api')
const auth = require('../../utils/auth')

class StickersWidget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stickers: [],
      seeAll: false,
      showUserStickerModal: false,
      showDisabledStickerModal: false,
      currentUser: { actualStickerId: '' },
      actualSticker: { title: '', content: '', imgUrl: '', imgUrlDisabled: ''},
      actualDisabledSticker: { title: '', content: '', imgUrl: '', imgUrlDisabled: ''},
      loading: false
    }
  }

  async componentDidMount() {
    const currentUser = await api.currentUser()
    const currentUserStickers = await auth.getStickers().map(e => { return e.id })
    const reqStickers = await stickersCtrl.get(await auth.getToken())
    let stickers = reqStickers.body.stickers

    // movendo os stickers do usuário para o começo da lista
    let userStickers = await stickers.filter(e => { return currentUserStickers.includes(e.id) })
    stickers = await stickers.filter(e => { return !currentUserStickers.includes(e.id) })
    stickers.unshift(...userStickers)

    this.setState({
      currentUser,
      stickers
    })
  }

  async openUserStickerModal(sticker) {
    this.setState({
      actualSticker: sticker,
      showUserStickerModal: true
    })
  }

  async closeUserStickerModal() {
    this.setState({
      actualSticker: {},
      showUserStickerModal: false
    })
  }

  async openDisabledStickerModal(sticker) {
    this.setState({
      actualDisabledSticker: sticker,
      showDisabledStickerModal: true
    })
  }

  async closeDisabledStickerModal() {
    this.setState({
      actualDisabledSticker: {},
      showDisabledStickerModal: false
    })
  }

  renderSticker(sticker) {
    let userStickers = auth.getStickers()

    return (
      userStickers.find(e => e.id === sticker.id)? (
        <Col sm={4} onClick={() => this.openUserStickerModal(sticker) } className={styles.activeSticker}>
          <Image src={sticker.imgUrl} style={{ maxHeight: '110px', maxWidth: '160px'}}/><br/>
          <span style={{ fontWeight: 'bold', color: '#D8D8D8', paddingTop: '5px' }}>{sticker.title}</span>
        </Col>
      ): ( 
        <Col sm={4} onClick={() => this.openDisabledStickerModal(sticker) }  className={styles.disabledSticker}>
          <Image src={sticker.imgUrlDisabled} style={{ maxHeight: '110px', maxWidth: '160px'}}/><br/>
          <span style={{ fontWeight: 'bold', color: '#666' }}>???</span>
        </Col>
      )
    )
  }

  showStickers() {
    this.setState({
      seeAll: true
    })
  }

  async addToAvatar() {
    this.setState({ loading: true })
  
    let response
    response = await api.userUpdate({
      id: this.state.currentUser.id,
      actualSticker: { id: this.state.actualSticker.id, imgUrl: this.state.actualSticker.imgUrl },
    },
    auth.getToken()
    )
    if (!response.ok) {
      this.setState({ loading: false })
    } else {
      const currentUser = this.state.currentUser
      const actualSticker = this.state.actualSticker
      auth.saveSession({
        id: currentUser.id,
        email: currentUser.email,
        studyDays: currentUser.studyDays,
        name: currentUser.name,
        role: currentUser.role,
        actualSticker: { id: actualSticker.id, imgUrl: actualSticker.imgUrl }
      })
      this.closeUserStickerModal()
      window.location.reload()
    }
  }

  render() { 
    var { seeAll, currentUser, actualSticker, actualDisabledSticker, showUserStickerModal, showDisabledStickerModal, stickers, loading } = this.state
    
    return (
      <>
        <Col sm={12} className={`p-0`}>
          <Row className={`${styles.progressCard} mt-2`}>
              { seeAll?
                stickers.map(sticker => { return this.renderSticker(sticker) }):
                stickers.slice(0, 3).map(sticker => { return this.renderSticker(sticker) })
              }
              {!seeAll &&
                <Col sm={12} className={`mx-auto d-flex mt-4 pt-4`}>
                  <Button
                    centered
                    variant="tertiary"
                    onClick={() => { this.showStickers() }}
                    label="ver todos"  
                  />
                </Col>
              }
          </Row>
        </Col>
        <style>
          {
          `.sticker-modal {
              top: 60px !important;
              padding-top: 20px !important;
          }
          .sticker-modal .modal-content {
            border-radius: 25px !important;
          }
          @keyframes wobble {
            33% {
              transform: rotate(5deg);
            }
            66% {
              transform: rotate(-5deg);
            }
          }`
          }
        </style>
        <Modal className={`${'sticker-modal'}`} show={showUserStickerModal} onHide={() => { this.closeUserStickerModal() }}>
          <Modal.Body
            style={{
              padding: "0 10px 0 10px",
              justifyContent: "center",
              textAlign: "center",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 25,
              border: 0
              }}>
              <DefaultButton
                variant="link"
                className={`${styles.closeButton}`}
                onClick={(e) => { this.closeUserStickerModal() }}><GrClose/>
              </DefaultButton>
              <Image src={actualSticker.imgUrl} style={{ position: 'relative', top: '-50px', alignItems: 'center',  animation: 'wobble 5s ease-in-out infinite alternate' }}/>
              <Col sm={12} style={{ top: '-30px' }}>
                <Modal.Title style={{ fontWeight: "bolder", textAlign: "center", fontSize: '2rem', paddingTop: '10px' }}>{actualSticker.title}</Modal.Title>
                <Col sm={12} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                  {actualSticker.content}
                </Col>
                { actualSticker.id != currentUser.actualStickerId &&
                  <Col sm={12} style={{ textAlign: 'center', margin: '0 auto' }}>
                    <Button
                      centered
                      label="adicionar ao perfil"
                      onClick={(e) => { this.addToAvatar() }}
                      isLoading={loading}
                    />
                  </Col>
                }
              </Col>
          </Modal.Body>
        </Modal>
        <Modal className={`${'sticker-modal'}`} show={showDisabledStickerModal} onHide={() => { this.closeDisabledStickerModal() }}>
          <Modal.Body
            style={{
              padding: "0 10px 0 10px",
              justifyContent: "center",
              textAlign: "center",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 25,
              border: 0
              }}>
              <DefaultButton variant="link" className={`${styles.closeButton}`} onClick={(e) => { this.closeDisabledStickerModal() }}><GrClose/></DefaultButton>
              <Image src={actualDisabledSticker.imgUrlDisabled} style={{ position: 'relative', top: '-50px', alignItems: 'center' }}/>
              <Col sm={12} style={{ top: '-30px' }}>
                <Modal.Title style={{ fontWeight: "bolder", textAlign: "center", fontSize: '2rem', paddingTop: '10px' }}>???</Modal.Title>
                <Col sm={12} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                  <strong>Requisito: </strong>{actualDisabledSticker.requirement}
                </Col>
              </Col>
          </Modal.Body>
        </Modal>
      </>

    )
  }
}

export default withRouter(StickersWidget)
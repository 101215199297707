import React, { Component }  from 'react'
import {
  // Link,
  withRouter
} from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import ReactPixel from 'react-facebook-pixel'
import Breadcrumb from '../../components/Breadcrumb'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Badge from 'react-bootstrap/Badge'
import QuestionWidget from '../../components/QuestionWidget'
import AreaWidget from '../../components/AreaWidget'
import Loader from 'react-loader-spinner'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

const api = require('../../utils/api')
const search = require('../../controllers/search')

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      questions: [],
      exams: [],
      areas: [],
      tags: [],
      loading: true,
      authorized: false
    }
  }

  async componentDidMount() {
    let CurrentUser = await api.currentUser()

    const reqAreas = await search.getAreas(this.props.match.params.query, CurrentUser.id)
    const getAreas = reqAreas.body.areas

    this.setState({
      areas: getAreas,
      loading: false
    })

    const reqTags = await search.getTags(this.props.match.params.query, CurrentUser.id)
    const getTags = reqTags.body.tags

    this.setState({
      tags: getTags
    })

    const reqQuestions = await search.getQuestions(this.props.match.params.query, CurrentUser.id)
    const getQuestions = reqQuestions.body.questions

    this.setState({
      questions: getQuestions,
    })

    AOS.init({
      duration: 300
    })

    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  renderQuestionWidget(question) { 
    return (
      <QuestionWidget question={question} md={6}/>
    )
  }


  renderTagWidget(tag) { 
    return (
      <Badge pill variant="light" className={`mx-2 my-1`} as="a" href={`/tag/${tag.id}`}>
        {tag.title}
      </Badge>
    )
  }

  renderAreaWidget(area) { 
    return (
      <AreaWidget area={area} md={4}/>
    )
  }

  render() {
    const { questions, loading, areas, tags } = this.state
    const meta = {
      title: `Busca: ${this.props.match.params.query} | lampejo.app - Plataforma gratuita para o ENEM` ,
      description: `Somos uma comunidade de estudantes e professores que valorizam o compartilhamento do conhecimento. Você vai aprender praticando e ajudando os outros alunos.`,
      meta: {
        charset: 'utf-8',
        lang: 'pt-br',
        amp: true,
        name: {
          keywords: 'educação, vestibular, enem, questões, provas'
        }
      }
    }
    if (loading) {
      return (
        <Loader type="TailSpin" color="#c7ef00" height={20} width={20} timeout={10000} />
      )
    } else {
      return (
        <Container className={styles.container}>
          <DocumentMeta {...meta}/>
          <Breadcrumb links={[
            { label: "Busca", active: true },
          ]}/>
          <Row>
            <Col sm={{ span: 10, offset: 1}} className={'pt-5'}>
            { loading? (
              <Loader type="TailSpin" color="#c7ef00" height={20} width={20} timeout={10000} />
            ) : (
              <Row>
                <Col sm={6}><h2>Áreas</h2></Col><Col sm={6} className={`${styles.toRigth} ${styles.foundResults}`}><span>áreas encontradas: {areas.length}</span></Col>
              </Row>
            )}
            { areas.length === 0 ? (
              <span style={{ opacity: 0.5 }}>nenhuma área encontrada...</span>
            ) : (
              <Row>{areas.map(area => this.renderAreaWidget(area))}</Row>
            )}
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 10, offset: 1}} className={'pt-5'}>
            { loading? (
              <Loader type="TailSpin" color="#c7ef00" height={20} width={20} timeout={10000} />
            ) : (
              <Row>
                <Col sm={6}><h2>Tags</h2></Col><Col sm={6} className={`${styles.toRigth} ${styles.foundResults}`}><span>tags encontradas: {tags.length}</span></Col>
              </Row>
            )}
            { tags.length === 0 ? (
              <span style={{ opacity: 0.5 }}>nenhuma tag encontrada...</span>
            ) : (
              <h4 style={{ textAlign: 'center' }}>{tags.map(tag => this.renderTagWidget(tag))}</h4>
            )}
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 10, offset: 1}} className={'pt-5'}>
            { loading? (
              <Loader type="TailSpin" color="#c7ef00" height={20} width={20} timeout={10000} />
            ) : (
              <Row>
                <Col sm={6}><h2>Questões</h2></Col><Col sm={6} className={`${styles.toRigth} ${styles.foundResults}`}><span>questões encontradas: {questions.length}</span></Col>
              </Row>
            )}
            { questions.length === 0 ? 
              <span style={{ opacity: 0.5 }}>nenhuma questão encontrada...</span>
            :  
            (
              <Row>{questions.map(question => this.renderQuestionWidget(question))}</Row>
            )
            }
            
            </Col>
          </Row>
        </Container>
      )
    }
  }
}

export default withRouter(Search)
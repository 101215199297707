import React, { Component } from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import styles from './index.module.css'
import Badge from 'react-bootstrap/Badge'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { CgChevronRightO } from 'react-icons/cg'

const resolutions = require('../../controllers/resolutions')
const api = require('../../utils/api')
const exams = require('../../controllers/exams')

class QuestionWidget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  render() { 
    var { loading } = this.state
    var { area, md } = this.props
    return (
      <Col sm={12} md={md}>
        <Card className={`${styles.bgWhite} ${styles.borderRadius25} ${styles.areaCard} p-4 mb-3`} data-aos="fade-up" as="a" href={
          this.props.examId?
          `/area/${area.id}/byExam/${this.props.examId}`:
          `/area/${area.id}`
        }>
          <Row>
            <Col sm={9}>
              <Card.Title>{area.title}</Card.Title>                 
            </Col>
            <Col sm={3} style={{ verticalAlign: "middle", margin: "auto" }}>
              <CgChevronRightO size={"30px"}/>
            </Col>
          </Row>
        </Card>
      </Col>
    )
  }
}

export default withRouter(QuestionWidget)
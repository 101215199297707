import React, { Component } from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import styles from './index.module.css'

class Breadcrumb extends Component {
  renderLink(link) {
    if (!link.label || link.label.includes("undefined")) {
      return ( <li key={link.label}><Skeleton width={80}/></li> )
    } else if (link.active) {
      return ( <li key={link.label}><span>{link.label}</span></li> )
    } else {
      return ( <li key={link.label}><Link to={link.to}>{link.label}</Link></li> )
    }
  }

  render() {
    const { links } = this.props
    return (
      <ul className={styles.customBreadcrumb}>
        <li><Link to={'/'}>Início</Link></li>
        {links.map(this.renderLink)}
      </ul>
    )
  }
}

export default withRouter(Breadcrumb)
import React, { Component }  from 'react'
import {
  withRouter,
  // Link,
} from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import ReactPixel from 'react-facebook-pixel'
import Container from 'react-bootstrap/Container'
import Breadcrumb from '../../components/Breadcrumb'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import Skeleton from 'react-loading-skeleton'
import Loader from 'react-loader-spinner'
import Dropdown from 'react-bootstrap/Dropdown'
import { MdChevronRight, MdTimer } from 'react-icons/md'
import { BsEye } from 'react-icons/bs'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import AreaWidget from '../../components/AreaWidget'

import { secondsToHm } from '../../utils/converter'

const exams = require('../../controllers/exams')
const questionsListsCtrl = require('../../controllers/questionsLists')
const attempts = require('../../controllers/attempts')
const api = require('../../utils/api')
const auth = require('../../utils/auth')
// const questions = require('../../controllers/questions')
// const visits = require('../../controllers/visits')
// const resolutions = require('../../controllers/resolutions')

class Exam extends Component {
  constructor(props) {
    super(props)

    this.state = {
      exam: {
        questions: [{
            id: ""
          }],
        resolutions: [{
          id: ""
        }],
        questionsLists: []
      },
      currentUser: { can: [] },
      numTags: 10,
      loading: true,
      loadingUpdate: false
    }

    this.runSimulation = this.runSimulation.bind(this)
  }

  async componentDidMount() {
    const id = this.props.match.params.id
    const CurrentUser = await api.currentUser()
    const request = await exams.getById(id, CurrentUser.id || '')
    let getExam = {
      questions: [{
        id: ""
      }],
      resolutions: [{
        id: ""
      }],
      questionsLists: []
    }
    if (request.ok) {
      getExam = request.body.exam
      getExam.tags = getExam.tags.sort((a,b) => { return b.count - a.count })
      
      const getQuestionsLists = await questionsListsCtrl.getByExamId(id)      
      getExam.questionsLists = getQuestionsLists.body.content.filter(e => { return e.subtype === 'blind' })

      this.setState({
        id: id,
        exam: getExam,
        loading: false
      })
    }

    if (CurrentUser) {
      this.setState({
        currentUser: CurrentUser
      })
    }

    AOS.init({
      duration: 300
    })

    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  renderQuestionWidgetSkeleton() {
    let arrayWith10Elements = ['','','','','','','','','','']
    return arrayWith10Elements.map(() => {
      return (
        <div className={styles.questionWidget} data-aos="fade-up">
          <span><Skeleton width={200} height={16}/></span>
          <Button className={`${styles.roundButton}`}><MdChevronRight/></Button>
        </div>
      )
    })
  }

  renderTags(tag) {
    if (tag) {
      return (
        <Badge pill variant="light" className={`mx-2 ${styles.textDecorationNone}`} as="a" href={`/tag/${tag.id}`}>
          {tag.title}
        </Badge>
      )
    }
  }

  showMoreTags() {
    const numTags = this.state.numTags
    this.setState({
      numTags: numTags + 10
    })
  }

  renderAreaWidget(area) {
    if (area) {
      return (
        <AreaWidget area={area} examId={this.state.exam.id} md={4}/>
      )
    }
  }

  renderQuestionsListsWidget(questionsList) {
    return (
      <Card className={`${styles.bgWhite} ${styles.borderRadius25} ${styles.toCenter} p-5 mt-3 w-100`} data-aos="fade-up">
        <Card.Title><strong>{questionsList.title}</strong></Card.Title>
        { !questionsList.questionsIds ?
        <Card.Title><Skeleton width={30}/> questões</Card.Title>
        :
        <>
          <Card.Text><MdTimer/> tempo: <strong>{secondsToHm(questionsList.maxTime)}</strong></Card.Text>
          <Card.Title>{questionsList.questionsIds.length} questões</Card.Title>
        </>
        }
        <Card.Text className={styles.dFlex}><Button variant="link" className={`${styles.primaryButton} ${styles.toBottomCenter}`} onClick={() => { this.runSimulation(questionsList.id)}}>iniciar</Button></Card.Text>
      </Card>
    )
  }

  async runSimulation(id) {
    const request = await attempts.register({
      userId: this.state.currentUser.id || null,
      questionsListId: id
    })

    this.props.history.push(`/attempt/${request.body.id}/${id}`)
  }

  async updateExam() {
    this.setState({
      loadingUpdate: true
    })
    const request = await exams.updateById(this.state.exam.id, auth.getToken())
    if (request.ok) {
      window.location.replace(`/exam/${this.state.exam.id}`)
    }
  }

  render() {
    const { exam, loading, currentUser, loadingUpdate, numTags } = this.state
    const meta = {
      title: `${exam.name} - ${exam.period} | lampejo.app - Plataforma gratuita para o ENEM` ,
      description: `Somos uma comunidade de estudantes e professores que valorizam o compartilhamento do conhecimento. Você vai aprender praticando e ajudando os outros alunos.`,
      meta: {
        charset: 'utf-8',
        lang: 'pt-br',
        amp: true,
        name: {
          keywords: 'educação, vestibular, enem, questões, provas'
        }
      }
    }
    return (
      <Container className={styles.container}>
        { loading? (<></>) : (<DocumentMeta {...meta}/>)}
        <Breadcrumb links={[
          { label: "Provas", to: "/exams" },
          { label: exam.name + " - " + exam.period, active: true }
        ]}/>
        <Row className="justify-content-md-center">
          <Col sm={{ span: 7 }} className={'pt-5'}>
            { loading ? 
            <h2><Skeleton width={400}/></h2>
            :
            <h2>{exam.name} - {exam.period}</h2>
            }
          </Col>
          <Col sm={3} className={`pt-5 ${styles.toRight}`}>
          { currentUser.can.includes('see-admin-dashboard') ?
            <Dropdown>
              <Dropdown.Toggle variant="link" className={styles.primaryButton} id="dropdown-basic">
                área admin
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href={`/exam/${exam.id}/edit`}>editar prova</Dropdown.Item>
                <Dropdown.Item href={`/questions/byExam/${exam.id}`}>ver questões</Dropdown.Item>
                <Dropdown.Item href={`/question/edit/${exam.id}`}>adicionar questão</Dropdown.Item>
                <Dropdown.Item href={`/questionsLists/byExam/${exam.id}`}>ver listas/simulados</Dropdown.Item>
                <Dropdown.Item onClick={() => { this.updateExam() }}>
                  { loadingUpdate?
                    <Loader type="TailSpin" color="#292F36" height={20} width={20} timeout={10000} />
                    : (<>atualizar prova</>)
                  }
                </Dropdown.Item>  
                <Dropdown.Item href={`/questionsLists/edit/${exam.id}`}>adicionar simulado/lista</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            : <></>}
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          { exam.questionsLists.length > 0?
            <Col sm={12} md={{ span: 4 }}>
              <Row className={`mt-2`}>
                {exam.questionsLists.map((questionsList) => this.renderQuestionsListsWidget(questionsList))}
              </Row>
            </Col>
          :<></>}
          <Col sm={12} md={{ span: 6 }} className={exam.questionsLists.length > 0? `` : `mx-auto`}>
            <Card className={`${styles.bgWhite} ${styles.borderRadius25} p-5 mt-4 ${styles.infoCard}`} data-aos="fade-up">
              <Row className={`${styles.toRight} ml-auto`}>
                { exam.numVisits ? (
                  <span className={'pr-3'}><BsEye/> visitas: {exam.numVisits}</span>
                ):(
                  <span className={'pr-3'}><BsEye/> visitas: <Skeleton width={20}/></span>
                )}
              </Row>
              <Card.Title><strong>Informações sobre a prova:</strong></Card.Title>
              { loading ?
              <>
                <Card.Text><Skeleton/></Card.Text>
              </>
              :
              <>
                <Card.Text><div dangerouslySetInnerHTML={{ __html: exam.details }} /></Card.Text>
                <Card.Text></Card.Text>
              </>
              }
              <Card.Text>
                {/* <MdTimer/> tempo médio: 
                { loading ? 
                <strong className={'pl-2'}><Skeleton width={30}/></strong>
                :
                <strong className={'pl-2'}>5h</strong>
                } */}
                <div className={`${styles.examTags} py-2`}>
                { exam.tags ?
                  <>
                    {exam.tags.slice(0, numTags).map(tag => this.renderTags(tag))}
                    { exam.tags.length > numTags ?
                    <>
                      <br/>
                      <Button variant="link" as="a" onClick={() => { this.showMoreTags()} }>mostrar mais</Button>
                    </>
                      : (<></>)
                    }
                  </>
                : (
                  <>
                    <Badge pill variant="light" className={`mx-2 ${styles.textDecorationNone}`}>
                      <Skeleton width={20}/>
                    </Badge>
                    <Badge pill variant="light" className={`mx-2 ${styles.textDecorationNone}`}>
                      <Skeleton width={20}/>
                    </Badge>
                    <Badge pill variant="light" className={`mx-2 ${styles.textDecorationNone}`}>
                      <Skeleton width={20}/>
                    </Badge>
                    <Badge pill variant="light" className={`mx-2 ${styles.textDecorationNone}`}>
                      <Skeleton width={20}/>
                    </Badge>
                  </>
                )}
              </div>
              </Card.Text>
              {/* <Card.Text className={styles.dFlex}><Button variant="link" className={`${styles.secondaryButton} ${styles.toBottomCenter}`} onClick={this.runSimulation}>ver todas</Button></Card.Text> */}
            </Card>
          </Col>
        </Row>
        <Row className={'pt-5'}>
          <Col sm={12} md={{ span: 10, offset: 1 }}>
          { loading ? (
            <>
              {/* {this.renderQuestionWidgetSkeleton()} */}
            </>
            ) :
              exam.areas ? (
                <>
                  <Row>
                    <Col><h3>Áreas de conhecimento</h3><br/></Col>
                  </Row>
                  <Row>
                    {exam.areas.map(area => this.renderAreaWidget(area))}
                  </Row>
                </>
              ) : (<></>)
            }
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(Exam)
import React, { Component } from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import styles from './index.module.css'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import { default as DefaultButton } from 'react-bootstrap/Button'
import { Button } from '../../lampejo-styles/components/Button/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Badge from 'react-bootstrap/Badge'
import Loader from 'react-loader-spinner'
import { CgChevronRightO } from 'react-icons/cg'
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri'
import { BiEditAlt, BiUndo } from 'react-icons/bi'
import AOS from 'aos'
import 'aos/dist/aos.css'

const resolutions = require('../../controllers/resolutions')
const answers = require('../../controllers/answers')
const tryAgainAnswers = require('../../controllers/tryAnswerAgain')
const attempts = require('../../controllers/attempts')
const api = require('../../utils/api')
const auth = require('../../utils/auth')

class Question extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      chosenOne: {},
      hasChoose: false,
      resolutionList: [],
      showRightAnswerModal: false,
      showWrongAnswerModal: false,
      showResolution: this.props.showResolution,
      editResolution: this.props.editResolution,
      currentUser: { can: [] },
      loadingAnswer: false,
      loadingResolution: false,
      removedOptions: [],
    }
    this.isRemoved = this.isRemoved.bind(this)
    this.chooseOption = this.chooseOption.bind(this)
    this.isChosen = this.isChosen.bind(this)
    this.handleCloseRightModal = this.handleCloseRightModal.bind(this)
    this.removeOption = this.removeOption.bind(this)
  }

  rightAnswerSound = new Audio(require('../../assets/right_answer.mp3'))
  wrongAnswerSound = new Audio(require('../../assets/wrong_answer.mp3'))

  async componentDidMount() {
    let CurrentUser = await api.currentUser()
    
    this.setState({
      currentUser: CurrentUser
    })

    AOS.init({
      duration: 300
    })
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.question) {
      if (nextProps.question !== this.props.question) {
        let resolutionList = []
        if (this.state.showResolution) {
          this.setState({
            loadingResolution: true
          })
          resolutionList = await this.getResolutions(nextProps.question.id)
        }
        
        this.setState({
          loadingResolution: false,
          questionId: nextProps.question.id || null,
          resolutionList: resolutionList,
          loading: false,
        })
      }
    }
  }

  renderTags(tag) {
    if (tag) {
      return (
        <Badge pill variant="light" className={`mx-2 ${styles.textDecorationNone}`} as="a" href={`/tag/${tag.id}`}>
          {tag.title}
        </Badge>
      )
    }
  }

  renderOption(option) {
    if (this.state.showResolution) {
      return (
        <Card key={option.position} className={this.isChosen(option) ? `${styles.optionActive} p-3 mt-2` : `${styles.option} p-3 mt-2`}>
          <Row>
            <Col className={styles.minWidth}>
            { option.position?
              (<div className={styles.optionPosition}>{option.position.toUpperCase()}</div>)
            : (<></>)
            }
            </Col>
            <Col className={styles.optionContent}>
              <div dangerouslySetInnerHTML={{ __html: option? option.content: '' }} />
            </Col>
          </Row>
        </Card>
      )
    } else {
      return (
        <Card key={option.position} onClick={() => { this.chooseOption(option) }} className={
          this.isRemoved(option) ? `${styles.removedOption} p-3 mt-2` :
          this.isChosen(option) ? `${styles.optionActive} p-3 mt-2` : `${styles.option} p-3 mt-2`}>
          <Row>
            <Col className={styles.minWidth}>
            { option.position?
              (<div className={this.isChosen(option) ? styles.optionPositionActive : styles.optionPosition}>{option.position.toUpperCase()}</div>)
            : (<></>)
            }
            </Col>
            <Col className={styles.optionContent}>
              <div dangerouslySetInnerHTML={{ __html: option? option.content: '' }} />
            </Col>
            <Col onClick={() => { this.removeOption(option) }} className={
              this.isRemoved(option) ? `d-none` :
              this.isChosen(option) ? `${styles.optionTrash}` : `d-none`
              }>
              <RiEyeLine color={'#292F36'}/>
            </Col>
            <Col onClick={() => { this.undoRemoveOption(option) }} className={
              this.isRemoved(option) ? `${styles.optionUndoTrash}` : `d-none`
              }>
              <RiEyeCloseLine color={'#fff'} size={20}/>
            </Col>
          </Row>
        </Card>
      )
    }
  }

  renderResolution(resolution) {
    if (resolution) {
      return (
        <Col sm={12} md={this.props.md}>
          <Card className={`${styles.bgWhite} ${styles.borderRadius25} p-4 p-md-5 mt-4`} data-aos="fade-up" data-aos-duration='500'>
            <Card.Text>
              { resolution.isCorrectPosition? <><span className={styles.rightAnswer}>Alternativa correta: <span className={styles.rightAnswerOption}>{resolution.isCorrectPosition}</span></span><br/><br/></> : <></> }
              {resolution.body?
                <div dangerouslySetInnerHTML={{ __html: resolution.body }} />
                : <p>Ops... parece que ainda não existem resoluções para essa questão 😢</p>
              }
            </Card.Text>
          </Card>
        </Col>
      )
    } else {
      return false
    }
  }

  chooseOption(option) {
    if (!this.isRemoved(option)) {
      this.setState({
        chosenOne: option,
        hasChoose: true
      })
    }
  }

  isChosen(option) {
    return this.state.chosenOne === option
  }

  isRemoved(option) {
    return this.state.removedOptions.includes(option)
  }

  removeOption(option) {
    let removedOptions = this.state.removedOptions
    if (!removedOptions.includes(option)) {
      removedOptions.push(option)
    }
    if (this.isChosen(option)) {
      setTimeout(() => {
        this.setState({
          chosenOne: {},
          hasChoose: false,
          removedOptions: removedOptions
        })
      }, 50)
    }
  }

  undoRemoveOption(option) {
    let removedOptions = this.state.removedOptions
    if (removedOptions.includes(option)) {
      let index = removedOptions.indexOf(option)
      removedOptions = removedOptions.splice(index, 1)
    }
    if (this.isChosen(option)) {
      setTimeout(() => {
        this.setState({
          removedOptions: removedOptions
        })
      }, 50)
    }
  }

  async sendAnswer() {
    if (this.state.chosenOne.content) {
      this.setState({
        loadingAnswer: true
      })
      const token = auth.getToken()
      if (token) {
        const reqAnswer = await answers.register({
          questionId: this.state.questionId,
          position: this.state.chosenOne.position
        }, auth.getToken())
  
        const answer = reqAnswer.body.answer
  
        if (this.props.attemptId && answer.body) {
          attempts.updateAnswers({ id: this.props.attemptId, answer: answer.body })
        }
      
        if (this.props.isBlindList) {
          this.props.toNextQuestion()
        } else {
          if (answer.isCorrect) {
            this.rightAnswerSound.play()
            
            this.setState({
              loadingAnswer: false,
              showRightAnswerModal: true
            })
          } else {
            this.wrongAnswerSound.play()
            this.setState({
              loadingAnswer: false,
              showWrongAnswerModal: true
            })
          }
        }
      }
    }
  }

  handleCloseRightModal () {
    this.setState({
      showRightAnswerModal: false
    })
  }

  async openResolution() {
    if (this.state.currentUser.isLoggedIn) {
      api.visitRegister(this.state.currentUser, { type: 'resolutionsByQuestionId', toId: this.state.questionId })
    } else {
      api.userRegister(null, { type: 'resolutionsByQuestionId', toId: this.state.questionId })
    }

    this.setState({
      loadingResolution: true
    })

    const resolutionList = await this.getResolutions(this.state.questionId)

    this.setState({
      loadingResolution: false,
      showRightAnswerModal: false,
      showWrongAnswerModal: false,
      showResolution: true,
      resolutionList: resolutionList
    })
  }

  async closeWrongModal() {
    if (this.state.currentUser.isLoggedIn) {
      await tryAgainAnswers.register({
        questionId: this.state.questionId
      }, auth.getToken())
    }

    this.setState({
      chosenOne: {},
      hasChoose: false,
      showRightAnswerModal: false,
      showWrongAnswerModal: false      
    })
  }

  hideResolution() {
    this.setState({
      showResolution: false,
      resolutions: []
    })
  }

  async getResolutions(questionId) {
    if (questionId) {
      const request = await resolutions.getByQuestionId(questionId)
      if (request.ok) {
        return request.body.contents
      }
    }
  }

  renderClusterName(tag) {
    return (
      <span><strong>{tag.title}</strong></span>
    )
  }

  render() { 
    var { loading, loadingAnswer, hasChoose, showRightAnswerModal, showWrongAnswerModal, showResolution, resolutionList, editResolution, chosenOne, loadingResolution } = this.state
    var { question, md, exam, isPreview, isBlindList, isList } = this.props
    return (
      <>
        <Col sm={12} md={md} style={{ padding: 0 }}>
          <Card className={`${styles.bgWhite} ${styles.borderRadius25} p-4 mt-4 mb-2`}>
            { loading ? 
            <Row>
              <Col>
                <Card.Title><Skeleton/></Card.Title>
              </Col>
              <Col style={{ marginLeft: 'auto', marginRight: '0' }}>
                <Card.Title><Skeleton/></Card.Title>
              </Col>
            </Row>
            : exam ?
              <Row className={`pb-2`}>
                <Col>
                  <Card.Title style={{ lineHeight: '30px', marginBottom: 0, paddingTop: '5px' }}><strong>{question.title}</strong></Card.Title>
                </Col>
                <Col style={{ marginLeft: 'auto', marginRight: '0', display: 'flex' }}>
                  <Badge pill variant="light" style={{ marginLeft: 'auto', marginRight: '0', display: 'flex', lineHeight: '30px' }}>
                    <span>{exam.name} - {exam.period}</span>
                  </Badge>
                </Col>
              </Row>
            :
              <Card.Title><strong>{question.title}</strong></Card.Title>
            }
            { showResolution ? (
              <Row>
                <Col className={`pl-0 pb-2`}>
                  { question.tags? question.tags.map(tag => { return this.renderTags(tag) }) : '' }
                </Col>
              </Row>
            ) : (<></>)}
            <Card.Text>
              { loading ? 
              <Skeleton width={30}/>
              :
              <div dangerouslySetInnerHTML={{ __html: question? question.content: '' }} />
              }
              {this.render}
            </Card.Text>
          </Card>
          { question?
            question.options ?
            question.options.map(option => this.renderOption(option) ) :
            null
          : (<></>)
          }

          { isPreview && !showResolution ? (
            <Button centered disabled label="responder" />
          ) : (
            <></>
          )}
          { !isPreview && !showResolution && chosenOne ? (
            <Button
              label="responder"
              centered
              marginTop="md"
              disabled={!hasChoose}
              onClick={() => { if (!hasChoose) return; this.sendAnswer() } }
              isLoading={loadingAnswer}
            />
          ) : (
            <></>
          )}
        </Col>
        { !isPreview && showResolution ? (
        <Col sm={12} md={md} data-aos="fade-up" data-aos-duration='500'>
          <hr style={{ margin: "40px 0", borderTop: "1px solid rgba(255, 255, 255, .3)"}}/>
          <Row>
            <Col sm={7}><h2>Resoluções</h2></Col>
            { !isList && !isBlindList ?
              (<Col sm={5}>
                <Button
                  variant="tertiary"
                  label="ocultar"
                  onClick={() => this.hideResolution()}/>
                </Col>) :
              (<></>)
            }
          </Row>
        </Col>
        ) : (
          false
        ) }
        { !isPreview && !showResolution && !isBlindList ? (
          <Col sm={12} md={md} data-aos="fade-up" data-aos-duration='500'>
            <Row>
              <Button
                centered
                marginTop="md"
                variant="tertiary" 
                onClick={(e) => this.openResolution()}
                label="ver resoluções?"
                isLoading={loadingResolution}
              />
            </Row>
          </Col>
        ) : (
          false
        ) }
        
        { showResolution && !editResolution ? <>
          {resolutionList.map(resolution => this.renderResolution(resolution))}
          { isList ?
            (<Col sm={12} md={md}>
              <Button
                variant="primary"
                centered
                marginTop="md"
                label="continuar!"
                onClick={() => { this.handleCloseRightModal(); this.props.toNextQuestion() }}/>
            </Col>
            ) :
            (<></>)
          }
          </>
           : false}
        { showResolution && !editResolution && !isList ? (
          <>
          { question?
            question.areas?
              question.areas.length > 0 ? (
                <Col sm={12} md={md} data-aos="fade-up" data-aos-duration='500'>
                  <Card className={`${styles.tagBanner} ${styles.borderRadius25} p-5 mt-4`} as="a" href={`/area/${question.areas[0].id}`}>
                    <Row>
                      <Col sm={10}>
                        <Card.Title>Quer ver mais questões de {this.renderClusterName(question.areas[0])}?</Card.Title>
                      </Col>
                      <Col sm={2} style={{ verticalAlign: "middle", margin: "auto" }}>
                        <CgChevronRightO size={"30px"}/>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ):(<></>) : (<></>)
            : (<></>)}
            {exam ? (
              <Col sm={12} md={md} data-aos="fade-up" data-aos-duration='500'>
                <Card className={`${styles.examBanner} ${styles.borderRadius25} p-5 mt-4`} as="a" href={`/exam/${exam.id}`}>
                  <Row>
                    <Col sm={10}>
                      <Card.Title>Saiba mais sobre a prova {`${exam.name} - ${exam.period}`}</Card.Title>
                    </Col>
                    <Col sm={2} style={{ verticalAlign: "middle", margin: "auto" }}>
                      <CgChevronRightO size={"30px"}/>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ):(<></>)}
            {question?
              question.tags?
                question.tags.length > 0 ? (
                  <Col sm={12} md={md} data-aos="fade-up" data-aos-duration='500'>
                    <Card className={`${styles.areaBanner} ${styles.borderRadius25} p-5 mt-4`} as="a" href={`/tag/${question.tags[0].id}`}>
                      <Row>
                        <Col sm={10}>
                          <Card.Title>Quer estudar sobre {this.renderClusterName(question.tags[0])}?</Card.Title>
                        </Col>
                        <Col sm={2} style={{ verticalAlign: "middle", margin: "auto" }}>
                          <CgChevronRightO size={"30px"}/>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ):(<></>) : (<></>)
            : (<></>)}
          </>
        ) : (<></>)}
        <Modal
          bsPrefix={'modalRigthAnswer modal'}
          className={`${styles.rightModal}`}
          show={showRightAnswerModal}
          backdrop={"static"}
          keyboard={false}
          centered
        >
          <Modal.Header
            style={{
              paddingTop: "50px",
              justifyContent: "center",
              color: "#292f36",
              backgroundColor: "#c7ef00",
              border: 0,
              borderRadius: 0,
            }}
          >
            <Modal.Title style={{ fontWeight: "bolder", fontSize: "35px"}}>Parabéns</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              padding: "40px",
              justifyContent: "center",
              textAlign: "center",
              color: "#292f36",
              backgroundColor: "#c7ef00",
              borderRadius: 0,
              border: 0
              }}>
            <Image style={{
              maxWidth: "110px",
              display: "flex",
              margin: "0 auto 30px auto"
            }} src={require("../../assets/confete.svg")}></Image>
            <p><strong>Você acertou!</strong></p><p>Continue avançando para finalizar esse conteúdo.</p><br/>
            { this.props.toNextQuestion?
              (<><DefaultButton className={`${styles.roundButton}`} variant="link" onClick={() => { this.handleCloseRightModal(); this.props.toNextQuestion() }}>continuar!</DefaultButton><br/></>)
            : (<></>)
            }
            <DefaultButton className={`${styles.outlineSecondaryButton}`} variant="link" onClick={() => this.openResolution()}>ver resolução
            { loadingResolution? <Loader type="TailSpin" color="white" height={20} width={20} timeout={10000} />: <></>}
            </DefaultButton>
          </Modal.Body>
        </Modal>
        <Modal
          bsPrefix={'modalWrongAnswer modal'}
          className={`${styles.wrongModal}`}
          show={showWrongAnswerModal}
          backdrop={"static"}
          keyboard={false}
          centered
        >
          <Modal.Header
            style={{
              paddingTop: "50px",
              justifyContent: "center",
              color: "#d81111",
              backgroundColor: "#fff",
              border: 0,
              borderRadius: 0,
            }}
          >
            <Modal.Title style={{ fontWeight: "bolder", fontSize: "35px"}}>Ops!</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              padding: "40px",
              justifyContent: "center",
              textAlign: "center",
              color: "#d81111",
              backgroundColor: "#fff",
              borderRadius: 0,
              border: 0
              }}>
            <Image style={{
              maxWidth: "110px",
              display: "flex",
              margin: "0 auto 30px auto"
            }} src={require("../../assets/incorreto.svg")}></Image>
            <p><strong>Resposta incorreta...</strong></p><p>Veja a resolução dessa questão.</p><br/>
            <DefaultButton className={`${styles.roundButton}`} variant="link" onClick={() => this.closeWrongModal()}>tentar novamente!</DefaultButton><br/>
            <DefaultButton className={`${styles.outlineSecondaryButton}`} variant="link" onClick={() => this.openResolution()}>ver resolução
            { loadingResolution? <Loader type="TailSpin" color="white" height={20} width={20} timeout={10000} />: <></>}
            </DefaultButton>
          </Modal.Body>
        </Modal>
    </>
    )
  }
}

export default withRouter(Question)
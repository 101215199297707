import React, { Component }  from 'react'
import { withRouter } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import styles from './index.module.css'
import 'aos/dist/aos.css'

class Home extends Component {
  async componentDidMount() {
    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  render() {    
    return (
      <>
        <Container className={`${styles.homeContainer} center`}>
          <Row>
            <Col sm={12} md={{ span: 6, offset: 3 }}>
              <Card className={`${styles.missionCard}`} >
                <Row>
                  <Col sm={12}>
                    <Card.Text>
                      <img style={{ maxWidth: '200px', margin: '30px auto', display: 'flex' }} src="https://ci3.googleusercontent.com/proxy/b9p7NyOnx3z1XshmjPC63kbeEQDsImXpUplpJJw16ocUl_PuQc-U--uVOgQqPtJvcOQldcWE-NPXhtTK1yeH9U-oFNzTyrBsO-vXZ9h3CdCY99xsZXyk7QWqYJIfxCqINRoKofG777pI6MO8I1sTn3UdxUDwBQ=s0-d-e1-ft#https://mcusercontent.com/d423278e97126a24ac7ee8052/images/739e3978-ac1c-4909-8d79-b1079cc174b1.png"/>
                      <hr/>
                      <br/>
                      <h1><span style={{ fontFamily:'open sans,helvetica neue,helvetica,arial,sans-serif' }}>Seja bem-vind@!</span></h1>
                      <p><span style={{ fontFamily:'open sans,helvetica neue,helvetica,arial,sans-serif' }}>Você está entre os primeiros estudantes que se cadastraram na Lampejo e queremos estar ao seu lado nessa jornada para o vestibular!</span></p>
                      <br/>
                      <p><img data-file-id="5402495" src="https://equilibrista-imagens.s3.amazonaws.com/icon-passo.png" style={{ border: '0px', width: '100px', margin: '0px'}} width="100"/></p>
                      <p><span style={{ fontFamily:'open sans,helvetica neue,helvetica,arial,sans-serif' }}><strong>Estamos dando nossos primeiros passos com a plataforma</strong></span></p>
                      <p><span style={{ fontFamily:'open sans,helvetica neue,helvetica,arial,sans-serif' }}>Construindo a trilha com os conteúdos de física que mais caem em todos os vestibulares.</span></p>
                      <br/>
                      <p><img data-file-id="5402495" src="https://equilibrista-imagens.s3.amazonaws.com/icon-novidade.png" style={{ border: '0px', width: '100px', margin: '0px'}} width="100"/></p>
                      <p><span style={{ fontFamily:'open sans,helvetica neue,helvetica,arial,sans-serif' }}><strong>Toda semana teremos novidades</strong></span></p>
                      <p><span style={{ fontFamily:'open sans,helvetica neue,helvetica,arial,sans-serif' }}>Nossa meta é criar um novo módulo por semana e melhorar sempre os que já foram criados. Estamos focados em fazer o que é melhor para você!</span></p>
                      <br/>
                      <p><img data-file-id="5402495" src="https://equilibrista-imagens.s3.amazonaws.com/icon-conversation.png" style={{ border: '0px', width: '100px', margin: '0px'}} width="100"/></p>
                      <p><span style={{ fontFamily:'open sans,helvetica neue,helvetica,arial,sans-serif' }}><strong>E o que você acha?</strong></span></p>
                      <p><span style={{ fontFamily:'open sans,helvetica neue,helvetica,arial,sans-serif' }}>Fizemos uma pesquisa com duas perguntinhas para entender como foi a sua experiência com a plataforma. Você pode responder diretamente nesse email </span></p>
                      <form action="https://docs.google.com/forms/u/2/d/e/1FAIpQLSfVHDP88lIyw624LVga4ykgxcsK4NvdR2Wr_KKn31evqCpTXA/formResponse" method="POST" id="m_8849879308757697453ss-form" target="_blank">
                        <ol role="list" style={{ paddingLeft: 0, listStyleType: 'none' }}>
                          <div role="listitem">
                            <div dir="auto" style={{ margin: '12px 0', overflowX: 'auto' }}>
                              <div style={{ marginBottom: '1.5em', verticalAlign: 'middle', marginLeft:0, marginTop:0, maxWidth: '100%' }}>
                                <label for="m_8849879308757697453entry_193574675">
                                  <div style={{ display: 'block', fontWeight:'bold', marginTop: '.83em', marginBottom: '.83em'}}>Em uma escala de 0 a 10, o quanto você recomendaria a Lampejo a um amig@ ou colega?
                                    <label for="m_8849879308757697453itemView.getDomIdToLabel()" aria-label="(campo obrigatório)"></label>
                                  </div>
                            
                                  <div dir="auto" style={{ display:'block', margin: '.1em 0 .25em 0', color:'#666' }}></div>
                                </label>
                        
                                <table border="0" cellpadding="5" cellspacing="0" id="m_8849879308757697453entry_366340186">
                                  <tbody>
                                    <tr aria-hidden="true">
                                      <td style={{ textAlign: 'center' }}></td>
                                      <td style={{ textAlign: 'center' }}>
                                        <label for="m_8849879308757697453group_366340186_1" style={{ display: 'block', padding: '0.5em 0 .5em' }}>0</label>
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        <label for="m_8849879308757697453group_366340186_2" style={{ display: 'block', padding: '0.5em 0 .5em' }}>1</label>
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        <label for="m_8849879308757697453group_366340186_3" style={{ display: 'block', padding: '0.5em 0 .5em' }}>2</label>
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        <label for="m_8849879308757697453group_366340186_4" style={{ display: 'block', padding: '0.5em 0 .5em' }}>3</label>
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        <label for="m_8849879308757697453group_366340186_5" style={{ display: 'block', padding: '0.5em 0 .5em' }}>4</label>
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        <label for="m_8849879308757697453group_366340186_6" style={{ display: 'block', padding: '0.5em 0 .5em' }}>5</label>
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        <label for="m_8849879308757697453group_366340186_7" style={{ display: 'block', padding: '0.5em 0 .5em' }}>6</label>
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        <label for="m_8849879308757697453group_366340186_8" style={{ display: 'block', padding: '0.5em 0 .5em' }}>7</label>
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        <label for="m_8849879308757697453group_366340186_9" style={{ display: 'block', padding: '0.5em 0 .5em' }}>8</label>
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        <label for="m_8849879308757697453group_366340186_10" style={{ display: 'block', padding: '0.5em 0 .5em' }}>9</label>
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        <label for="m_8849879308757697453group_366340186_11" style={{ display: 'block', padding: '0.5em 0 .5em' }}>10</label>
                                      </td>
                                      <td style={{ textAlign: 'center' }}></td>
                                    </tr>
                                    <tr role="radiogroup" aria-label="Em uma escala de 0 a 10, o quanto você recomendaria a Lampejo a um amig@ ou colega?  Selecione um valor no intervalo de 0,Pouco provável, a 10,Muito provável,.">
                                      <td style={{ textAlign: 'right', color:'#666', border:'1px solid #d3d8d3', borderLeft:0, borderRight:0, padding:'.5em .25em', paddingLeft:0 }}>
                                        <div aria-hidden="true">Pouco provável</div>
                                      </td>
                                      <td style={{ textAlign: 'center', color:'#666', border: '1px solid #d3d8d3', borderLeft:0, borderRight:0, padding:'.5em .25em' }} >
                                        <div>
                                          <input type="radio" name="entry.366340186" value="0" id="m_8849879308757697453group_366340186_1" role="radio" aria-label="0" aria-required="true"/>
                                        </div>
                                      </td>
                                      <td style={{ textAlign: 'center', color:'#666', border: '1px solid #d3d8d3', borderLeft:0, borderRight:0, padding:'.5em .25em' }} >
                                        <div>
                                          <input type="radio" name="entry.366340186" value="1" id="m_8849879308757697453group_366340186_2" role="radio" aria-label="1" aria-required="true"/>
                                          </div>
                                        </td>
                                        <td style={{ textAlign: 'center', color:'#666', border: '1px solid #d3d8d3', borderLeft:0, borderRight:0, padding:'.5em .25em' }} >
                                          <div>
                                            <input type="radio" name="entry.366340186" value="2" id="m_8849879308757697453group_366340186_3" role="radio" aria-label="2" aria-required="true"/>
                                          </div>
                                        </td>
                                        <td style={{ textAlign: 'center', color:'#666', border: '1px solid #d3d8d3', borderLeft:0, borderRight:0, padding:'.5em .25em' }} >
                                          <div>
                                            <input type="radio" name="entry.366340186" value="3" id="m_8849879308757697453group_366340186_4" role="radio" aria-label="3" aria-required="true"/>
                                          </div>
                                        </td>
                                        <td style={{ textAlign: 'center', color:'#666', border: '1px solid #d3d8d3', borderLeft:0, borderRight:0, padding:'.5em .25em' }} >
                                          <div>
                                            <input type="radio" name="entry.366340186" value="4" id="m_8849879308757697453group_366340186_5" role="radio" aria-label="4" aria-required="true"/>
                                          </div>
                                        </td>
                                        <td style={{ textAlign: 'center', color:'#666', border: '1px solid #d3d8d3', borderLeft:0, borderRight:0, padding:'.5em .25em' }} >
                                          <div>
                                            <input type="radio" name="entry.366340186" value="5" id="m_8849879308757697453group_366340186_6" role="radio" aria-label="5" aria-required="true"/>
                                          </div>
                                        </td>
                                        <td style={{ textAlign: 'center', color:'#666', border: '1px solid #d3d8d3', borderLeft:0, borderRight:0, padding:'.5em .25em' }} >
                                          <div>
                                            <input type="radio" name="entry.366340186" value="6" id="m_8849879308757697453group_366340186_7" role="radio" aria-label="6" aria-required="true"/>
                                          </div>
                                        </td>
                                        <td style={{ textAlign: 'center', color:'#666', border: '1px solid #d3d8d3', borderLeft:0, borderRight:0, padding:'.5em .25em' }} >
                                          <div>
                                            <input type="radio" name="entry.366340186" value="7" id="m_8849879308757697453group_366340186_8" role="radio" aria-label="7" aria-required="true"/>
                                          </div>
                                        </td>
                                        <td style={{ textAlign: 'center', color:'#666', border: '1px solid #d3d8d3', borderLeft:0, borderRight:0, padding:'.5em .25em' }} >
                                          <div>
                                            <input type="radio" name="entry.366340186" value="8" id="m_8849879308757697453group_366340186_9" role="radio" aria-label="8" aria-required="true"/>
                                          </div>
                                        </td>
                                        <td style={{ textAlign: 'center', color:'#666', border: '1px solid #d3d8d3', borderLeft:0, borderRight:0, padding:'.5em .25em' }} >
                                          <div>
                                            <input type="radio" name="entry.366340186" value="9" id="m_8849879308757697453group_366340186_10" role="radio" aria-label="9" aria-required="true"/>
                                          </div>
                                        </td>
                                        <td style={{ textAlign: 'center', color:'#666', border: '1px solid #d3d8d3', borderLeft:0, borderRight:0, padding:'.5em .25em' }} >
                                          <div>
                                            <input type="radio" name="entry.366340186" value="10" id="m_8849879308757697453group_366340186_11" role="radio" aria-label="10" aria-required="true"/>
                                          </div>
                                        </td>
                                        <td aria-hidden="true" style={{ textAlign: 'left', color:'#666', border:'1px solid #d3d8d3', borderLeft:0, borderRight:0, padding:'.5em .25em', paddingRight:0 }}>Muito provável</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div role="listitem">
                              <div dir="auto" style={{ margin:'12px 0'}}>
                                <div style={{ marginBottom:'1.5em', verticalAlign:'middle', marginLeft:0, marginTop:0, maxWidth: '100%'}}>
                                  <label for="m_8849879308757697453entry_1038273534">
                                    <div style={{ display: 'block', fontWeight:'bold', marginTop:'.83em', marginBottom:'.83em' }}>O que fizemos que poderia ser melhor? O que deveríamos começar a fazer? (opcional)</div>
                                    <div dir="auto" style={{ display: 'block', margin: '.1em 0 .25em 0', color: '#666' }}></div>
                                  </label>
                                  <textarea name="entry.1038273534" rows="8" cols="0" id="m_8849879308757697453entry_1038273534" dir="auto" aria-label="O que fizemos que poderia ser melhor? O que deveríamos começar a fazer?  " style={{ width:'70%'}}></textarea>
                                </div>
                              </div>
                            </div>
                            <div role="listitem"></div>
                            <input type="hidden" name="draftResponse" value="[null,null,&quot;7013281063428437132&quot;]"/>
                            <input type="hidden" name="pageHistory" value="0"/>
                            <input type="hidden" name="usp" value="mail_form_submit"/>                        
                            <input type="hidden" name="fbzx" value="7013281063428437132"/>
                            <div style={{ marginBottom:'4px' }} dir="ltr"><label for="m_8849879308757697453emailReceipt" style={{ display: 'inline' }}></label></div>
                            <div style={{ margin:'12px 0' }}><table id="m_8849879308757697453navigation-table"><tbody><tr><td id="m_8849879308757697453navigation-buttons" dir="ltr" style={{ marginBottom: '1.5em', verticalAlign: 'middle', marginLeft:0, marginTop:0, maxWidth:'100%', display:'inline-block' }}/>
                              <input type="submit" name="submit" value="Enviar" id="m_8849879308757697453ss-submit"/>
                            </tr></tbody></table>
                          </div>
                        </ol>
                      </form>
                      <span style={{ fontFamily:'open sans,helvetica neue,helvetica,arial,sans-serif' }}>Obrigado<br/>--</span><br/>
                      <a href="https://www.lampejo.app/?utm_source=mail&amp;utm_medium=mail&amp;utm_campaign=signature-rafael" target="_blank">
                        <img data-file-id="5402495" height="128" src="https://equilibrista-imagens.s3.amazonaws.com/signature-rafael.png" style={{ border: '0px', width: '460px', height: '128px', margin: '0px'}} width="460"/>
                      </a>
                      <Row style={{ maxWidth: '200px', display: 'flex', margin: '20px auto 0 auto' }}>
                        <Col>
                          <a href="http://www.twitter.com/Lampejo_app" target="_blank">
                            <img src="https://cdn-images.mailchimp.com/icons/social-block-v2/color-twitter-48.png" alt="Twitter" style={{ display:'block' }} height="24" width="24" class=""/>
                          </a>
                        </Col>
                        <Col>
                          <a href="https://www.facebook.com/Lampejo-112782786946394" target="_blank">
                            <img src="https://cdn-images.mailchimp.com/icons/social-block-v2/color-facebook-48.png" alt="Facebook" style={{ display: 'block' }} height="24" width="24" class=""/>
                          </a>
                        </Col>
                        <Col>
                          <a href="https://www.lampejo.app/?utm_source=mail&amp;utm_medium=mail&amp;utm_campaign=link" target="_blank">
                            <img src="https://cdn-images.mailchimp.com/icons/social-block-v2/color-link-48.png" alt="Website" style={{ display: 'block' }} height="24" width="24" class=""/>
                          </a>
                        </Col>
                      </Row>
                      
                    </Card.Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default withRouter(Home)
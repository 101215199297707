import React, { Component }  from 'react'
import {
  Link,
  withRouter,
} from 'react-router-dom'
import { FaKey, FaRegEnvelope } from 'react-icons/fa'
import { Button } from '../../lampejo-styles/components/Button/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import styles from './index.module.css'

import AOS from 'aos'
import 'aos/dist/aos.css'

const api = require('../../utils/api')
const { domains } = require('../../config.js')

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      currentUser: { can: [] },
      error: null,
      formEmail: '',
      formPassword: '',
      currentUser: {
        isLoggedIn: false,
        dataLetters: '',
        name: '',
        scholarity: '',
        intendedCourse: ''
      },
    }
    
    this.handleFormInput = this.handleFormInput.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  async componentDidMount() {
    const currentUser = await api.currentUser()

    if (currentUser.isLoggedIn) {
      window.location.replace('/schedule/')
    }

    AOS.init({
      duration: 300
    })
  }

  googleLogin() {
    window.open(domains().api + '/auth/google', "_self")
  }

  facebookLogin() {
    window.open(domains().api + '/auth/facebook', "_self")
  }

  handleFormTypeChange(type) {
    this.setState({
      state: type
    })
  }

  handleFormInput(field, value) {
    const nextState = {}
    nextState[field] = value

    this.setState(Object.assign(this.state, nextState))
  }

  async handleFormSubmit(evt) {
    evt.preventDefault()
    this.setState({ isLoading: true })

    const response = await api.userLogin({ "email": this.state.formEmail, "password": this.state.formPassword })
    if (!response.ok) {
      this.setState({
        formError: response.error,
        isLoading: false
      })
    } else {
      this.setState({
        isLoading: false
      })

      const currentUser = await api.currentUser()
      window.location.replace('/schedule')      
    }
  }

  render() {
    const { isLoading } = this.state
    const { history } = this.props
    return (
      <Col sm={12} md={{ span: 4, offset: 4 }} className={`${styles.login} pt-5`} style={{ marginTop: "80px" }}>
        <div className={styles.containerRegister}>
          <h2>Acessar</h2>
          <div className={`${styles.card}`}>
            <Button
              centered
              variant="google"
              leftIcon="google"
              full
              label="Acessar com Google"
              onClick={() => { this.googleLogin() }}
            />
            <Button
              full
              centered
              marginTop="md"
              variant="facebook"
              leftIcon="facebook"
              label="Acessar com Facebook"
              onClick={() => { this.facebookLogin() }}
            />
            <div style={{ paddingTop: 30, paddingBottom: 20, color: '#999' }}>
              <span className={styles.or}>ou</span>
            </div> 
            <div className={styles.containerSignIn}>
              <Form className={styles.form} onSubmit={this.handleFormSubmit}>
                <Form.Group className={styles.formField}>
                  <Form.Label className={styles.formLabel}><FaRegEnvelope style={{marginRight: '0.5em'}}/>email</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='seu-email@exemplo.com'
                    className={styles.formInput}
                    value={this.state.formEmail}
                    onChange={(e) => { this.handleFormInput('formEmail', e.target.value) }}
                  />
                </Form.Group>
                <Form.Group className={styles.formField}>
                  <Form.Label className={styles.formLabel}><FaKey style={{marginRight: '0.5em'}}/>senha</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='sua senha'
                    className={styles.formInput}
                    value={this.state.formPassword}
                    onChange={(e) => { this.handleFormInput('formPassword', e.target.value) }}
                  />
                </Form.Group>

                {this.state.formError && (
                  <div className={styles.formError}>{this.state.formError}</div>
                )}

                <Button isLoading={isLoading} label="entrar" type="submit" value='Sign In'/>
              </Form>
              <br/>
              <Button onClick={() => { history.push('/passwordrecover') }} variant="tertiary" label="esqueceu sua senha?"/>
            </div>
          </div>
          <p>ainda não se cadastrou?</p> 
          <div><Button centered onClick={() => { history.push('/register') }} variant="tertiary" label="cadastre-se agora!"/></div>
        </div>
      </Col>
    )
  }
}

export default withRouter(Login)
import Cookies from 'js-cookie'
const { domains } = require('../config.js')

/**
 * Format Org and Username correctly for the Serverless Platform backend
 */
export const formatOrgAndUsername = (name = '') => {
   name = name.toString().toLowerCase().replace(/[^a-z\d-]+/gi, '-')
   // Remove multiple instances of hyphens
   name = name.replace(/-{2,}/g, '-')
   if (name.length > 40) {
     name = name.substring(0, 40)
   }
   return name
}

/**
 * Parse query parameters in a URL
 * @param {*} searchString 
 */
export const parseQueryParams = (searchString = null) => {
  if (!searchString) {
    return null
  }

  // Clone string
  let clonedParams = (' ' + searchString).slice(1)

  return clonedParams
    .substr(1)
    .split('&')
    .filter((el) => el.length)
    .map((el) => el.split('='))
    .reduce(
      (accumulator, currentValue) =>
        Object.assign(accumulator, {
          [decodeURIComponent(currentValue.shift())]: decodeURIComponent(currentValue.pop())
        }),
      {}
    )
}

/**
 * Parse hash fragment parameters in a URL
 */
export const parseHashFragment = (hashString) => {
  const hashData = {}
  let hash = decodeURI(hashString)
  hash = hash.split('&')
  hash.forEach((val) => {
    val = val.replace('#', '')
    hashData[val.split('=')[0]] = val.split('=')[1]
  })
  return hashData
}

/**
 * Save session in browser cookie
 */
export const saveSession = (userId, userEmail, userToken) => {
  Cookies.set('eq-app', { userId, userEmail, userToken })
}

/**
 * Get session in browser cookie
 */
export const getSession = () => {
  const data = Cookies.get('serverless')
  return data ? JSON.parse(data) : null
}

/**
 * Delete session in browser cookie
 */
export const deleteSession = () => {
  Cookies.remove('serverless')
}


/**
 * API request to call the backend
 */
export const requestApi = async (path = '', method = 'GET', data = null, headers = {}) => {

  // Check if API URL has been set
  if (!domains().api) {
    throw new Error(`Error: Missing API Domain – Please add the API domain from your serverless Express.js back-end to this front-end application.  You can do this in the "./config.js" file.  Instructions are listed there and in the documentation.`)
  }

  // Prepare URL
  if (!path.startsWith('/')) {
    path = `/${path}`
  }
  const url = `${domains().api}${path}`

  // Set headers
  headers = Object.assign(
    { 'Content-Type': 'application/json' },
    headers
  )

  // Default options are marked with *
  const response = await fetch(url, {
    method: method.toUpperCase(),
    mode: 'cors',
    cache: 'no-cache',
    headers: headers,
    body: data ? JSON.stringify(data) : null
  })

  if (response.status < 200 || response.status >= 300) {
    let res = await response.json()
    res.status = response.status
    return await res
  }

  return {
    status: 200,
    ok: true,
    body: await response.json()
  }
}
import React, { Component }  from 'react'
import {
  // Link,
  withRouter
} from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import ReactPixel from 'react-facebook-pixel'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import ButtonModuleRoad from '../../components/ButtonModuleRoad'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

const api = require('../../utils/api')
const roads = require('../../controllers/roads')

class Road extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: window.innerWidth,
      currentUser: { can: [] },
      roadIcon: '',
      roads: []
    }
  }

  async componentDidMount() {
    AOS.init({duration: 300})
    const roadIcon = require('../../assets/route.svg')

    const reqRoads = await roads.getAll()
    const getRoads = await reqRoads.body.roads

    this.setState({
      roadIcon: roadIcon,
      roads: getRoads
    })

    
    const currentUser = await api.currentUser()
    if (currentUser.isLoggedIn) {
      api.visitRegister(currentUser, { type: 'page', to: '/roads' })
    } else {
      api.userRegister(null, { type: 'page', to: '/roads' })
    }
    
    if (currentUser) {
      this.setState({
        currentUser
      })
    }

    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  render() {
    const { width, currentUser } = this.state
    const meta = {
      title: `Minha Trilha | Lampejo - Plataforma gratuita para o ENEM` ,
      description: `Somos uma comunidade de estudantes e professores que valorizam o compartilhamento do conhecimento. Você vai aprender praticando e ajudando os outros alunos.`,
      meta: {
        charset: 'utf-8',
        lang: 'pt-br',
        amp: true,
        name: {
          keywords: 'educação, vestibular, enem, questões, provas'
        }
      }
    }
    return (
      <>
        <Container className={styles.homeContainer}>
          { currentUser.can.includes('register-edit-road') /* refactor to currentUser.can('register-exam') or currentUser.can['register-exam'] */  ? (
            <Col style={{padding: '50px 0 0 0'}} sm={12} md={12} className={styles.toRight}>
              <Button variant="link" className={`${styles.primaryButton} ${styles.toRight}`} href={`road/edit`}>nova trilha</Button>
            </Col>
          ) : (
            <></>
          ) }
          <Row  style={{padding: '50px 0 25px 0', textAlign: "center" }}>
            <Col md={{ offset: 3, span: 6 }} sm={12}>
              <h2><Image style={{ height: "30px", paddingBottom: "5px" }} src={this.state.roadIcon}/> Minha Trilha</h2>
            </Col>
          </Row>
          <Row className={styles.roadPrincipal}>
            <Col  md={{ offset: 3, span: 6 }} sm={12} data-aos='fade-up'>
              Sua trilha de estudos personalizada!<br/> Escolha uma matéria abaixo para continuar!
            </Col>
          </Row>
          <Row className={`${styles.justifyContentCenter} ${styles.pt3}`}>
            {this.state.roads.map(road => { return (<ButtonModuleRoad href={`/road/${road.id}`} module={road}/>)})}
          </Row>
        </Container>
      </>
    )
  }
}

export default withRouter(Road)
import React, { Component } from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import Cell from '../../components/Cell'
import Skeleton from 'react-loading-skeleton'
import styles from './index.module.css'
import Question from '../Question'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
// import Button from 'react-bootstrap/Button'
import { Button } from '../../lampejo-styles/components/Button/Button';
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { CgChevronRightO } from 'react-icons/cg'
import { BsCardList } from 'react-icons/bs'
import Badge from 'react-bootstrap/Badge'
import AOS from 'aos'
import { BiRevision } from 'react-icons/bi'
import YouTube from 'react-youtube'
import 'aos/dist/aos.css'

const cellsCtrl = require('../../controllers/cells')
const unity = require('../../controllers/unities')
const visits = require('../../controllers/visits')
const api = require('../../utils/api')

class Task extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      chosenOne: {},
      hasChoose: false,
      exam: { name: '', period: '' },
      resolutionList: [],
      showRightAnswerModal: false,
      showWrongAnswerModal: false,
      showResolution: this.props.showResolution,
      editResolution: this.props.editResolution,
      currentUser: { can: [] },
      questionsIds: [],
      youtubeLogo: null,
      googleLogo: null,
      error: null
    }
  }

  async componentDidMount() {
    let currentUser = await api.currentUser()

    AOS.init({
      duration: 500
    })

    let type
    if (!this.props.task.unityId) {
      type = 'questions'
    } else {
      type = 'content'
    }

    if (this.props.task.unityId) {
      this.setState({
        loading: true
      })
      const reqUnity = await unity.get(this.props.task.unityId, currentUser.id)
      if (reqUnity.ok) {
        const getUnity = reqUnity.body.unity
      
        const reqCells = await cellsCtrl.getByUnityId(getUnity.id)
        if (reqCells.ok) {
          getUnity.cells = reqCells.body.cells.sort((a, b) => { return a.order - b.order }) || []

          let order = 1
          let progress
          let actualCell = {}
          if (this.props.match.params.order) {
            order = this.props.match.params.order
            actualCell = await getUnity.cells.filter(e => { return e.order === this.props.match.params.order })[0]
            progress = (getUnity.cells.indexOf(actualCell)) * 100 / getUnity.cells.length
          } else {
            actualCell = getUnity.cells[0]
            progress = 10
          }
      
          this.setState({
            unity: getUnity,
            actualCell,
            progress: progress,
            order,
            type: 'content'
          })
        }
      } else {
        this.setState({
          error: 'Houve um problema...'
        })
      }
    }

    let youtubeLogo = require('../../assets/youtube-icon.svg')
    let googleLogo = require('../../assets/google-icon.svg')
    
    this.setState({
      currentUser,
      type,
      youtubeLogo,
      googleLogo
    })
  }

  async componentWillReceiveProps(nextProps) {
    let currentUser = await api.currentUser()

    if (nextProps.task) {
      if (nextProps.task !== this.props.task) {
        this.setState({
          loading: true
        })
        const reqUnity = await unity.get(this.props.task.unityId, currentUser.id)
        if (reqUnity.ok) {
          const getUnity = reqUnity.body.unity

          if (getUnity != null) {
            const reqCells = await cellsCtrl.getByUnityId(getUnity.id)
            if (reqCells.ok) {
              getUnity.cells = reqCells.body.cells.sort((a, b) => { return a.order - b.order }) || []
  
              let order = 1
              let progress
              let actualCell = {}
              if (this.props.match.params.order) {
                order = this.props.match.params.order
                actualCell = await getUnity.cells.filter(e => { return e.order === this.props.match.params.order })[0]
                progress = (getUnity.cells.indexOf(actualCell)) * 100 / getUnity.cells.length
              } else {
                actualCell = getUnity.cells[0]
                progress = 10
              }
          
              this.setState({
                unity: getUnity,
                actualCell,
                progress: progress,
                order,
                type: 'content'
              })
            }
          }
        } else {
          this.setState({
            error: 'Houve um problema...'
          })
        }
      }
    }
  }

  renderTags(tag) {
    if (tag) {
      return (
        <h5>
          <Badge pill variant="light" className={`mx-2 ${styles.textDecorationNone} ${styles.badge}`}>
            {tag.title}
          </Badge>
        </h5>
      )
    }
  }

  videoOnReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  videoOnPlay = async () => {
    let currentUser = await api.currentUser()
    visits.register({ type: 'video-play', to: '/task/' + this.props.task.id, userId: currentUser.id || null })
  }

  videoOnEnd = async () => {
    let currentUser = await api.currentUser()
    visits.register({ type: 'video-end', to: '/task/' + this.props.task.id, userId: currentUser.id || null })
  }

  parseVideoId(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }

  render() { 
    var { loading, error, type, youtubeLogo, googleLogo, actualCell, currentUser } = this.state
    var { content, md, isPreview, questionId, task, nextOrder } = this.props
    return (
      <>
        <Col sm={12} md={md}>
          { error? (
            <Card className={isPreview? `${styles.bgTransparent} ${styles.borderRadius25} mt-4 py-5 px-3 ${styles.dashed}` : `${styles.borderRadius25} mt-4`}>
              {error}
            </Card>
          )
          : type == 'questions' ?
          (
            <Card className={isPreview? `${styles.bgTransparent} ${styles.borderRadius25} mt-4 py-5 px-3 ${styles.dashed}` : `${styles.bgTransparent} ${styles.borderRadius25} mt-4 py-5 px-3`} data-aos="fade-up">
              <div className={styles.textCenter}>
                <p><h2>{task.title}</h2></p>
                <p><h5>{task.subtitle}</h5></p>
              </div>
              { task.youtubeVideoUrl?
                (
                  <>
                    <p>Olá estudante,</p>
                    {task.reviewVideo?
                      <p>Relembre o conteúdo assistindo o vídeo novamente:</p>
                    : <p>Para iniciar os seus estudos sobre esse tema, selecionamos esse conteúdo para você:</p>
                    }
                    <YouTube
                      videoId={this.parseVideoId(task.youtubeVideoUrl)}
                      opts={{ height: 255, width: '100%' }}
                      onReady={this.videoOnReady}
                      onPlay={this.videoOnPlay}
                      onEnd={this.videoOnEnd}
                      />
                    { task.reviewVideo && <p className={styles.reviewVideoP}><BiRevision style={{ paddingBottom: 4 }}/> Você pode ir direto para as questões se já estiver familiarizado com o conteúdo.</p> }
                    <br/>
                    <p>Agora você irá realizar <strong>{task.questionsIds.length} questões</strong>. Seus objetivos são:</p>
                    <ul>
                      <li>Se familiarizar com o <strong>formato</strong> em que esse assunto é cobrado nos vestibulares;</li>
                      <li>Entender o <strong>seu nível</strong> de conhecimento sobre a matéria;</li>
                      <li><strong>Revisar</strong> o conteúdo.</li>
                    </ul>
                    <p>Os conteúdos que serão abordados são:</p>
                    <Row className={`pb-3`}>
                    { task.tags && task.tags.map(tag => { return this.renderTags(tag) }) }
                    </Row>
                    <Button
                      marginTop="lg"
                      centered
                      label="continuar"
                      onClick={() => { window.location.replace(`/task/${task.id}/${nextOrder}`) }}
                    />
                  </>
                ) : (
                  <>
                    <p>Olá estudante,</p>
                    <p>Nessa tarefa você irá realizar <strong>{task.questionsIds.length} questões</strong>. Seus objetivos são:</p>
                    <ul>
                      <li>Se familiarizar com o <strong>formato</strong> em que esse assunto é cobrado nos vestibulares;</li>
                      <li>Entender o <strong>seu nível</strong> de conhecimento sobre a matéria;</li>
                      <li><strong>Revisar</strong> o conteúdo.</li>
                    </ul>
                    <p>Os conteúdos que serão abordados são:</p>
                    <Row className={`pb-3`}>
                    { task.tags.map(tag => { return this.renderTags(tag) })  }
                    </Row>
                    <Button
                      marginTop="lg"
                      centered
                      label="continuar"
                      onClick={() => { window.location.replace(`/task/${task.id}/${nextOrder}`) }}
                    />
                      
                    <br/><br/><br/>
                  </>
                )
              }
            </Card>
          ) : type == 'content' ? (
            <Card className={isPreview? `${styles.bgTransparent} ${styles.borderRadius25} mt-4 py-1 px-3 ${styles.dashed}` : `${styles.bgDark} ${styles.borderRadius25} mt-4`}>
              <Cell content={actualCell.content} questionId={actualCell.questionId} toNextCell={() => this.toNextCell()}/>
            </Card>
          )
          : (<></>)
        }
        </Col>
    </>
    )
  }
}

export default withRouter(Task)
import React from 'react';
import { buttonStyle, buttonIconStyle, buttonTextStyle, buttonLoadingIcon, buttonLeftIconStyle } from './Button.style';
import {
  IoArrowForwardOutline,
  IoLogOutOutline,
  IoCreateOutline,
  IoLogoApple,
  IoLogoGoogle,
  IoLogoFacebook,
  IoMailOutline
} from "react-icons/io5";
import { SpinnerCircular } from 'spinners-react';

interface ButtonProps {
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'facebook' | 'google' ;
  label: string;
  full?: boolean;
  rightIcon?: 'rightArrow' | 'edit' | 'logout' | false;
  leftIcon?: 'google' | 'facebook' | 'apple' | 'mail' | false;
  disabled?: boolean;
  isLoading?: boolean;
  centered?: boolean;
  marginTop?: 'sm' | 'md' | 'lg';
  onClick?: () => void;
}

export const Button = ({
  size = 'md',
  variant = 'primary',
  label,
  full = false,
  disabled = false,
  rightIcon = false,
  leftIcon = false,
  isLoading = false,
  centered = false,
  marginTop,
  ...props
}: ButtonProps) => {
  return (
    <button
      type="button"
      className={
        buttonStyle({
          size,
          variant,
          full: full? 'true': 'false',
          disabled: disabled? 'true': 'false',
          centered: centered? 'true' : 'false',
          marginTop })
        }
      {...props}
    >
      { !! isLoading &&
        <div className={buttonLoadingIcon()}>
          <SpinnerCircular size={size == 'sm' ? 14 : 22} thickness={260} speed={200}
            color={
              variant == 'secondary' ? '#C7EF00' :
              variant == 'tertiary' ? '#C7EF00' :
              variant == 'danger' ? '#FF4823' : '#292F36'
            }
            secondaryColor={'#7F828699'}
          />
        </div>
      }
      { !!leftIcon &&
        <div className={buttonLeftIconStyle({ isLoading: isLoading? 'true' : 'false' })}>
        { leftIcon == 'facebook' ?
            <IoLogoFacebook/>
          : leftIcon == 'google' ?
            <IoLogoGoogle/>
          : leftIcon == 'apple' ?
            <IoLogoApple/>
          :
            <IoMailOutline/>
        }
        </div>
      }
      <div className={buttonTextStyle({ isLoading: isLoading? 'true' : 'false' })}>
        {label}
      </div>
      { !!rightIcon &&
        <div className={buttonIconStyle({ isLoading: isLoading? 'true' : 'false' })}>
        { rightIcon == 'rightArrow' ?
            <IoArrowForwardOutline/>
          : rightIcon == 'edit' ?
            <IoCreateOutline/>
          : rightIcon == 'logout' ?
            <IoLogOutOutline/>
          :
            <IoArrowForwardOutline/>
        }
        </div>
      }
        
    </button>
  );
};

import React, { Component }  from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import Select from 'react-select'
import ReactPixel from 'react-facebook-pixel'
import { FaBook, FaMapSigns, FaKey, FaRegEnvelope, FaRegUser } from 'react-icons/fa'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Loader from 'react-loader-spinner'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

const api = require('../../utils/api')
const auth = require('../../utils/auth')

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      currentUser: { can: [] },
      progress1: null,
      progress2: null,
      progress3: null,
      error: null,
      formEmail: '',
      formPassword: '',
      formScholarity: '',
      formIntendedCourse: '',
      formStudyTime: 3,
      selectedScholarity: null,
      selectedIntendedCourse: null,
      currentUser: {
        isLoggedIn: false,
        dataLetters: '',
        name: '',
        scholarity: '',
        intendedCourse: ''
      },
      scholarityOptions: [
        {value: 'Ensino Fundamental Incompleto', label: 'Ensino Fundamental Incompleto'},
        {value: 'Ensino Fundamental Completo', label: 'Ensino Fundamental Completo'},
        {value: '1º Ano do Ensino Médio', label: '1º Ano do Ensino Médio'},
        {value: '2º Ano do Ensino Médio', label: '2º Ano do Ensino Médio'},
        {value: '3º Ano do Ensino Médio', label: '3º Ano do Ensino Médio'},
        {value: 'Ensino Médio Completo', label: 'Ensino Médio Completo'},
        {value: 'Ensino Superior Incompleto', label: 'Ensino Superior Incompleto'},
        {value: 'Ensino Superior Completo', label: 'Ensino Superior Completo'}
      ],
      intendedCourseOptions: [
        {value: 'Administração', label: 'Administração'},
        {value: 'Administração Pública', label: 'Administração Pública'},
        {value: 'Agronegócios e Agropecuária', label: 'Agronegócios e Agropecuária'},
        {value: 'Ciências Aeronáuticas', label: 'Ciências Aeronáuticas'},
        {value: 'Ciências Atuariais', label: 'Ciências Atuariais'},
        {value: 'Ciências Contábeis', label: 'Ciências Contábeis'},
        {value: 'Ciências Econômicas', label: 'Ciências Econômicas'},
        {value: 'Comércio Exterior', label: 'Comércio Exterior'},
        {value: 'Defesa e Gestão Estratégica Internacional', label: 'Defesa e Gestão Estratégica Internacional'},
        {value: 'Gastronomia', label: 'Gastronomia'},
        {value: 'Gestão Comercial', label: 'Gestão Comercial'},
        {value: 'Gestão de Recursos Humanos', label: 'Gestão de Recursos Humanos'},
        {value: 'Gestão de Segurança Privada', label: 'Gestão de Segurança Privada'},
        {value: 'Gestão de Seguros', label: 'Gestão de Seguros'},
        {value: 'Gestão de Turismo', label: 'Gestão de Turismo'},
        {value: 'Gestão Financeira', label: 'Gestão Financeira'},
        {value: 'Gestão Pública', label: 'Gestão Pública'},
        {value: 'Hotelaria', label: 'Hotelaria'},
        {value: 'Logística', label: 'Logística'},
        {value: 'Marketing', label: 'Marketing'},
        {value: 'Negócios Imobiliários', label: 'Negócios Imobiliários'},
        {value: 'Pilotagem profissional de aeronaves', label: 'Pilotagem profissional de aeronaves'},
        {value: 'Processos Gerenciais', label: 'Processos Gerenciais'},
        {value: 'Segurança Pública', label: 'Segurança Pública'},
        {value: 'Turismo', label: 'Turismo'},
        {value: 'Animação', label: 'Animação'},
        {value: 'Arquitetura e Urbanismo', label: 'Arquitetura e Urbanismo'},
        {value: 'Artes Visuais', label: 'Artes Visuais'},
        {value: 'Comunicação das Artes do Corpo', label: 'Comunicação das Artes do Corpo'},
        {value: 'Conservação e Restauro', label: 'Conservação e Restauro'},
        {value: 'Dança', label: 'Dança'},
        {value: 'Design', label: 'Design'},
        {value: 'Design de Games', label: 'Design de Games'},
        {value: 'Design de Interiores', label: 'Design de Interiores'},
        {value: 'Design de Moda', label: 'Design de Moda'},
        {value: 'Fotografia', label: 'Fotografia'},
        {value: 'História da Arte', label: 'História da Arte'},
        {value: 'Jogos Digitais', label: 'Jogos Digitais'},
        {value: 'Luteria', label: 'Luteria'},
        {value: 'Música', label: 'Música'},
        {value: 'Produção Cênica', label: 'Produção Cênica'},
        {value: 'Produção Fonográfica', label: 'Produção Fonográfica'},
        {value: 'Teatro', label: 'Teatro'},
        {value: 'Agroecologia', label: 'Agroecologia'},
        {value: 'Agronomia', label: 'Agronomia'},
        {value: 'Alimentos', label: 'Alimentos'},
        {value: 'Biocombustíveis', label: 'Biocombustíveis'},
        {value: 'Biotecnologia', label: 'Biotecnologia'},
        {value: 'Biotecnologia e Bioquímica', label: 'Biotecnologia e Bioquímica'},
        {value: 'Ciência e Tecnologia de Alimentos', label: 'Ciência e Tecnologia de Alimentos'},
        {value: 'Ciências Agrárias', label: 'Ciências Agrárias'},
        {value: 'Ciências Biológicas', label: 'Ciências Biológicas'},
        {value: 'Ciências Naturais e Exatas', label: 'Ciências Naturais e Exatas'},
        {value: 'Ecologia', label: 'Ecologia'},
        {value: 'Geofísica', label: 'Geofísica'},
        {value: 'Geologia', label: 'Geologia'},
        {value: 'Gestão Ambiental', label: 'Gestão Ambiental'},
        {value: 'Medicina Veterinária', label: 'Medicina Veterinária'},
        {value: 'Meteorologia', label: 'Meteorologia'},
        {value: 'Oceanografia', label: 'Oceanografia'},
        {value: 'Produção de Bebidas', label: 'Produção de Bebidas'},
        {value: 'Produção Sucroalcooleira', label: 'Produção Sucroalcooleira'},
        {value: 'Rochas Ornamentais', label: 'Rochas Ornamentais'},
        {value: 'Zootecnia', label: 'Zootecnia'},
        {value: 'Ciências Exatas e Informática', label: 'Ciências Exatas e Informática'},
        {value: 'Astronomia', label: 'Astronomia'},
        {value: 'Banco de Dados', label: 'Banco de Dados'},
        {value: 'Ciência da Computação', label: 'Ciência da Computação'},
        {value: 'Ciência e Tecnologia', label: 'Ciência e Tecnologia'},
        {value: 'Computação', label: 'Computação'},
        {value: 'Estatística', label: 'Estatística'},
        {value: 'Física', label: 'Física'},
        {value: 'Gestão da Tecnologia da Informação', label: 'Gestão da Tecnologia da Informação'},
        {value: 'Informática Biomédica', label: 'Informática Biomédica'},
        {value: 'Matemática', label: 'Matemática'},
        {value: 'Nanotecnologia', label: 'Nanotecnologia'},
        {value: 'Química', label: 'Química'},
        {value: 'Redes de Computadores', label: 'Redes de Computadores'},
        {value: 'Segurança da Informação', label: 'Segurança da Informação'},
        {value: 'Sistemas de Informação', label: 'Sistemas de Informação'},
        {value: 'Sistemas para Internet', label: 'Sistemas para Internet'},
        {value: 'Arqueologia', label: 'Arqueologia'},
        {value: 'Ciências do Consumo', label: 'Ciências do Consumo'},
        {value: 'Ciências Humanas', label: 'Ciências Humanas'},
        {value: 'Ciências Sociais', label: 'Ciências Sociais'},
        {value: 'Cooperativismo', label: 'Cooperativismo'},
        {value: 'Direito', label: 'Direito'},
        {value: 'Escrita Criativa', label: 'Escrita Criativa'},
        {value: 'Estudos de Gênero e Diversidade', label: 'Estudos de Gênero e Diversidade'},
        {value: 'Filosofia', label: 'Filosofia'},
        {value: 'Geografia', label: 'Geografia'},
        {value: 'Gestão de Cooperativas', label: 'Gestão de Cooperativas'},
        {value: 'História', label: 'História'},
        {value: 'Letras', label: 'Letras'},
        {value: 'Libras', label: 'Libras'},
        {value: 'Linguística', label: 'Linguística'},
        {value: 'Museologia', label: 'Museologia'},
        {value: 'Pedagogia', label: 'Pedagogia'},
        {value: 'Psicopedagogia', label: 'Psicopedagogia'},
        {value: 'Relações Internacionais', label: 'Relações Internacionais'},
        {value: 'Serviço Social', label: 'Serviço Social'},
        {value: 'Serviços Judiciários e Notariais', label: 'Serviços Judiciários e Notariais'},
        {value: 'Teologia', label: 'Teologia'},
        {value: 'Tradutor e Intérprete', label: 'Tradutor e Intérprete'},
        {value: 'Arquivologia', label: 'Arquivologia'},
        {value: 'Biblioteconomia', label: 'Biblioteconomia'},
        {value: 'Cinema e Audiovisual', label: 'Cinema e Audiovisual'},
        {value: 'Comunicação em Mídias Digitais', label: 'Comunicação em Mídias Digitais'},
        {value: 'Comunicação Institucional', label: 'Comunicação Institucional'},
        {value: 'Comunicação Organizacional', label: 'Comunicação Organizacional'},
        {value: 'Educomunicação', label: 'Educomunicação'},
        {value: 'Estudos de Mídia', label: 'Estudos de Mídia'},
        {value: 'Eventos', label: 'Eventos'},
        {value: 'Gestão da Informação', label: 'Gestão da Informação'},
        {value: 'Jornalismo', label: 'Jornalismo'},
        {value: 'Produção Audiovisual', label: 'Produção Audiovisual'},
        {value: 'Produção Cultural', label: 'Produção Cultural'},
        {value: 'Produção Editorial', label: 'Produção Editorial'},
        {value: 'Produção Multimídia', label: 'Produção Multimídia'},
        {value: 'Produção Publicitária', label: 'Produção Publicitária'},
        {value: 'Publicidade e Propaganda', label: 'Publicidade e Propaganda'},
        {value: 'Rádio, TV e Internet', label: 'Rádio, TV e Internet'},
        {value: 'Relações Públicas', label: 'Relações Públicas'},
        {value: 'Secretariado', label: 'Secretariado'},
        {value: 'Secretariado Executivo', label: 'Secretariado Executivo'},
        {value: 'Agrimensura', label: 'Agrimensura'},
        {value: 'Aquicultura', label: 'Aquicultura'},
        {value: 'Automação Industrial', label: 'Automação Industrial'},
        {value: 'Construção Civil', label: 'Construção Civil'},
        {value: 'Construção Naval', label: 'Construção Naval'},
        {value: 'Eletrônica Industrial', label: 'Eletrônica Industrial'},
        {value: 'Eletrotécnica Industrial', label: 'Eletrotécnica Industrial'},
        {value: 'Energias Renováveis', label: 'Energias Renováveis'},
        {value: 'Engenharia Acústica', label: 'Engenharia Acústica'},
        {value: 'Engenharia Aeronáutica', label: 'Engenharia Aeronáutica'},
        {value: 'Engenharia Agrícola', label: 'Engenharia Agrícola'},
        {value: 'Engenharia Ambiental e Sanitária', label: 'Engenharia Ambiental e Sanitária'},
        {value: 'Engenharia Biomédica', label: 'Engenharia Biomédica'},
        {value: 'Engenharia Bioquímica, de Bioprocessos e Biotecnologia', label: 'Engenharia Bioquímica, de Bioprocessos e Biotecnologia'},
        {value: 'Engenharia Cartográfica e de Agrimensura', label: 'Engenharia Cartográfica e de Agrimensura'},
        {value: 'Engenharia Civil', label: 'Engenharia Civil'},
        {value: 'Engenharia da Computação', label: 'Engenharia da Computação'},
        {value: 'Engenharia de Alimentos', label: 'Engenharia de Alimentos'},
        {value: 'Engenharia de Biossistemas', label: 'Engenharia de Biossistemas'},
        {value: 'Engenharia de Controle e Automação', label: 'Engenharia de Controle e Automação'},
        {value: 'Engenharia de Energia', label: 'Engenharia de Energia'},
        {value: 'Engenharia de Inovação', label: 'Engenharia de Inovação'},
        {value: 'Engenharia de Materiais', label: 'Engenharia de Materiais'},
        {value: 'Engenharia de Minas', label: 'Engenharia de Minas'},
        {value: 'Engenharia de Pesca', label: 'Engenharia de Pesca'},
        {value: 'Engenharia de Petróleo', label: 'Engenharia de Petróleo'},
        {value: 'Engenharia de Produção', label: 'Engenharia de Produção'},
        {value: 'Engenharia de Segurança no Trabalho', label: 'Engenharia de Segurança no Trabalho'},
        {value: 'Engenharia de Sistemas', label: 'Engenharia de Sistemas'},
        {value: 'Engenharia de Software', label: 'Engenharia de Software'},
        {value: 'Engenharia de Telecomunicações', label: 'Engenharia de Telecomunicações'},
        {value: 'Engenharia de Transporte e da Mobilidade', label: 'Engenharia de Transporte e da Mobilidade'},
        {value: 'Engenharia Elétrica', label: 'Engenharia Elétrica'},
        {value: 'Engenharia Eletrônica', label: 'Engenharia Eletrônica'},
        {value: 'Engenharia Física', label: 'Engenharia Física'},
        {value: 'Engenharia Florestal', label: 'Engenharia Florestal'},
        {value: 'Engenharia Hídrica', label: 'Engenharia Hídrica'},
        {value: 'Engenharia Industrial Madeireira', label: 'Engenharia Industrial Madeireira'},
        {value: 'Engenharia Mecânica', label: 'Engenharia Mecânica'},
        {value: 'Engenharia Mecatrônica', label: 'Engenharia Mecatrônica'},
        {value: 'Engenharia Metalúrgica', label: 'Engenharia Metalúrgica'},
        {value: 'Engenharia Naval', label: 'Engenharia Naval'},
        {value: 'Engenharia Nuclear', label: 'Engenharia Nuclear'},
        {value: 'Engenharia Química', label: 'Engenharia Química'},
        {value: 'Engenharia Têxtil', label: 'Engenharia Têxtil'},
        {value: 'Fabricação Mecânica', label: 'Fabricação Mecânica'},
        {value: 'Geoprocessamento', label: 'Geoprocessamento'},
        {value: 'Gestão da Produção Industrial', label: 'Gestão da Produção Industrial'},
        {value: 'Gestão da Qualidade', label: 'Gestão da Qualidade'},
        {value: 'Irrigação e Drenagem', label: 'Irrigação e Drenagem'},
        {value: 'Manutenção de aeronaves', label: 'Manutenção de aeronaves'},
        {value: 'Manutenção Industrial (T/L)', label: 'Manutenção Industrial (T/L)'},
        {value: 'Materiais', label: 'Materiais'},
        {value: 'Mecatrônica Industrial', label: 'Mecatrônica Industrial'},
        {value: 'Mineração', label: 'Mineração'},
        {value: 'Papel e Celulose', label: 'Papel e Celulose'},
        {value: 'Petróleo e Gás', label: 'Petróleo e Gás'},
        {value: 'Processos Metalúrgicos', label: 'Processos Metalúrgicos'},
        {value: 'Processos Químicos', label: 'Processos Químicos'},
        {value: 'Produção Têxtil', label: 'Produção Têxtil'},
        {value: 'Saneamento Ambiental', label: 'Saneamento Ambiental'},
        {value: 'Segurança no Trabalho', label: 'Segurança no Trabalho'},
        {value: 'Silvicultura', label: 'Silvicultura'},
        {value: 'Sistemas Biomédicos', label: 'Sistemas Biomédicos'},
        {value: 'Sistemas de Telecomunicações', label: 'Sistemas de Telecomunicações'},
        {value: 'Sistemas Elétricos', label: 'Sistemas Elétricos'},
        {value: 'Sistemas Embarcados', label: 'Sistemas Embarcados'},
        {value: 'Transporte', label: 'Transporte'},
        {value: 'Biomedicina', label: 'Biomedicina'},
        {value: 'Educação Física', label: 'Educação Física'},
        {value: 'Enfermagem', label: 'Enfermagem'},
        {value: 'Esporte', label: 'Esporte'},
        {value: 'Estética e Cosmética', label: 'Estética e Cosmética'},
        {value: 'Farmácia', label: 'Farmácia'},
        {value: 'Fisioterapia', label: 'Fisioterapia'},
        {value: 'Fonoaudiologia', label: 'Fonoaudiologia'},
        {value: 'Gerontologia', label: 'Gerontologia'},
        {value: 'Gestão Desportiva e de Lazer', label: 'Gestão Desportiva e de Lazer'},
        {value: 'Gestão em Saúde', label: 'Gestão em Saúde'},
        {value: 'Gestão Hospitalar', label: 'Gestão Hospitalar'},
        {value: 'Medicina', label: 'Medicina'},
        {value: 'Musicoterapia', label: 'Musicoterapia'},
        {value: 'Naturologia', label: 'Naturologia'},
        {value: 'Nutrição', label: 'Nutrição'},
        {value: 'Obstetrícia', label: 'Obstetrícia'},
        {value: 'Odontologia', label: 'Odontologia'},
        {value: 'Oftálmica', label: 'Oftálmica'},
        {value: 'Optometria', label: 'Optometria'},
        {value: 'Psicologia', label: 'Psicologia'},
        {value: 'Quiropraxia', label: 'Quiropraxia'},
        {value: 'Radiologia', label: 'Radiologia'},
        {value: 'Saúde Coletiva', label: 'Saúde Coletiva'},
        {value: 'Terapia Ocupacional', label: 'Terapia Ocupacional'},
        {value: 'Outro', label: 'Outro'}
      ]
    }
    
    this.handleFormInput = this.handleFormInput.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleFormTypeChange = this.handleFormTypeChange.bind(this)
    this.handleSelectIntendedCourseChange = this.handleSelectIntendedCourseChange.bind(this)
    this.handleSelectScholarityChange = this.handleSelectScholarityChange.bind(this)
  }

  async componentDidMount() {
    const currentUser = await api.currentUser()
    const token = await auth.getToken()

    let step = parseInt(this.props.match.params.step)
    if (!step || step > 3) {
      step = 0
    }

    const progress1 = require('../../assets/progress_1_3.svg')
    const progress2 = require('../../assets/progress_2_3.svg') 
    const progress3 = require('../../assets/progress_3_3.svg')
    
    let expiredInvites
    let inviterName

    if (currentUser.isLoggedIn) {
      window.location.replace('/schedule/')
    } else {
      if (!token) {
        await api.userRegister(null, { type: 'page', to: '/invite/' + this.props.match.params.inviterId + '/' + step })
      }
      const reqInvites = await api.getAvailableInvites(this.props.match.params.inviterId)
      if (reqInvites.ok) {
        const numInvites = reqInvites.body.invites
        inviterName = reqInvites.body.inviterName

        if (numInvites > 0) {
          expiredInvites = false
        } else {
          expiredInvites = true
        }
      } else {
        expiredInvites = true
      }
    }

    this.setState({
      loading: false,
      inviterId: this.props.match.params.inviterId,
      expiredInvites,
      inviterName,
      progress1,
      progress2,
      progress3,
      step
    })
    AOS.init({
      duration: 300
    })

    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  /**
   * Handles a form change
   */
  handleFormTypeChange(type) {
    this.setState({
      state: type
    })
  }

  /**
   * Handle text changes within form fields
   */
  handleFormInput(field, value) {
    const nextState = {}
    nextState[field] = value

    this.setState(Object.assign(this.state, nextState))
  }

  handleSelectScholarityChange(selectedOption) {
    this.setState({ selectedScholarity: selectedOption, formScholarity: selectedOption.value })
  }
  handleSelectIntendedCourseChange(selectedOption) {
    this.setState({ selectedIntendedCourse: selectedOption, formIntendedCourse: selectedOption.value })
  }

  selectStudyTime(selectedOption) {
    this.setState({ formStudyTime: selectedOption })
  }

  /**
   * Handles form submission
   * @param {object} evt 
   */
  async handleFormSubmit(evt) {
    evt.preventDefault()

    this.setState({ loading: true })
    const token = auth.getToken()

    if (this.state.step === 1) {
      if (!this.state.inviterId) {
        return this.setState({
          loading: false,
          formError: 'Ocorreu um erro com o convite. Tente novamente.'
        })
      }
      if (token) {
        const response = await api.userUpdate({
          "intendedCourse": this.state.formIntendedCourse,
          "scholarity": this.state.formScholarity
        }, token)
        if (!response.ok) {
          this.setState({
            formError: response.error,
            loading: false
          })
        } else {
          let step = parseInt(this.state.step) + 1
          window.location.replace("/invite/" + this.state.inviterId + '/' + step)
        }
      } else {
        const response = await api.userRegister({
          "intendedCourse": this.state.formIntendedCourse,
          "scholarity": this.state.formScholarity
        })
        if (!response.ok) {
          this.setState({
            formError: response.error,
            loading: false
          })
        } else {
          let step = parseInt(this.state.step) + 1
          window.location.replace("/invite/" + this.state.inviterId + '/' + step)
        }
      }  
    } else if (this.state.step === 2) {
      if (!this.state.inviterId) {
        return this.setState({
          loading: false,
          formError: 'Ocorreu um erro com o convite. Tente novamente.'
        })
      }

      const response = await api.userUpdate({
        "dailyStudyTime": this.state.formStudyTime
      }, token)
      if (!response.ok) {
        this.setState({
          formError: response.error,
          loading: false
        })
      } else {
        let step = parseInt(this.state.step) + 1
        window.location.replace("/invite/" + this.state.inviterId + '/' + step)
      }      
    } else if (this.state.step === 3) {
      if (!this.state.inviterId) {
        return this.setState({
          loading: false,
          formError: 'Ocorreu um erro com o convite. Tente novamente.'
        })
      }

      if (!this.state.formEmail) {
        return this.setState({
          loading: false,
          formError: 'Email é obrigatório'
        })
      }
  
      if (!this.state.formPassword) {
        return this.setState({
          loading: false,
          formError: 'Senha é obrigatória'
        })
      }
      if (!this.state.formConfirmPassword) {
        return this.setState({
          loading: false,
          formError: 'Confirmar Senha é obrigatório'
        })
      }
      if (!this.state.formName) {
        return this.setState({
          loading: false,
          formError: 'Nome é obrigatório'
        })
      }

      if (this.state.formPassword !== this.state.formConfirmPassword) {
        return this.setState({
          loading: false,
          formError: 'Senha e a confirmação de senha são diferentes. Por favor, insira novamente.'
        })
      }
      
      let response = await api.userUpdate({
        "email": this.state.formEmail,
        "password": this.state.formPassword,
        "name": this.state.formName,
        "inviterId": this.state.inviterId
      }, token)
      if (!response.ok) {
        this.setState({
          formError: response.error,
          loading: false
        })
      } else {
        window.location.replace('/schedule')
      }
    }
  }

  render() {
    const { step, selectedScholarity, selectedIntendedCourse, progress1, progress2, progress3, expiredInvites, inviterName } = this.state

    return (
      <Col className={`${styles.login}`}>
        <Image fluid src={require('../../assets/logo_lampejo.png')} href="/" className={`${styles.imgLogo} align-top pt-2 d-block d-md-none`} alt='lampejo.app'/>
        { step === 0 && !this.state.loading ? (
        <div className={styles.containerInvite} data-aos="fade-up">
          
            { expiredInvites?
              <Card className={styles.invite}>
                <Card.Text>
                  <h2>Ops!</h2>
                  <p>Parece que esses convites expiraram ou já foram utilizados...</p>
                </Card.Text>
                <span className={styles.line}/>
                <span className={styles.inviteIcon}>
                  <Image fluid src={require('../../assets/invite.svg')}/>
                </span>
              </Card>
            :
              <>
              <Card className={styles.invite}>
                <Card.Text className={`text-center`}>
                  <h2><strong>{inviterName}</strong><br/>te enviou um convite</h2>
                  <p>Participe agora e tenha acesso ao <strong>cronograma</strong></p>
                  <Button variant="link" className={`${styles.primaryButton} ${styles.centered}`} onClick={(e) => { window.location.replace(`/invite/${this.props.match.params.inviterId}/1`) }}>
                    vamos lá!
                  </Button>
                  <hr/>
                  <p >Já possui uma conta? <Button variant="link" className={styles.secondaryLink} onClick={(e) => { window.location.replace('/login') }}>Acessar agora!</Button></p>
                </Card.Text>
                <span className={styles.line}/>
                <span className={styles.inviteIcon}>
                  <Image fluid src={require('../../assets/invite.svg')}/>
                </span>
              </Card>
              </>
            }
            
            
        </div>
      ) : (<></>) }
      { step === 1 && !this.state.loading ? (
        <Col className={`${styles.login}`} sm={12} md={{ span: 4, offset: 4 }}>
          <div className={styles.containerRegister}>
            <Image className={styles.progressImage} src={progress1}></Image>
            <h2>...antes de começar</h2>
            <p style={{ maxWidth: "400px", margin: "10px auto 40px auto" }}>para conserguirmos sugerir para você a melhor trilha possível, precisamos saber em que etapa você está</p>
              <Form className={`${styles.form}`} onSubmit={this.handleFormSubmit}>
                <div className={`${styles.card}`}>
                  <Form.Group className={styles.formField}>
                    <Form.Label className={styles.formLabel}><FaBook style={{marginRight: '0.5em', color: 'white' }}/>escolaridade</Form.Label>
                    <Select
                      className={styles.formSelect}
                      placeholder={'onde você está hoje?'}
                      value={selectedScholarity}
                      options={this.state.scholarityOptions}
                      onChange={this.handleSelectScholarityChange}
                      styles={{
                        control: (styles, { data, isFocused, isSelected, isActive }) => {
                          return {
                            ...styles,
                            backgroundColor: '#292F36',
                            borderColor: isFocused
                            ? '#c7ef00'
                            : "rgba(255, 255, 255, 0.2)",
                            boxShadow: isFocused
                            ? '#c7ef00'
                            : null,
                            color: "white",
                            ':hover': {
                              borderColor: '#c7ef00',
                            },
                            }
                          },
                          menu: (styles, { data }) => {
                            return {
                              ...styles,
                              backgroundColor: '#292F36'
                            }
                          },
                          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            const color = '#292F36';
                            return {
                              ...styles,
                              backgroundColor: isDisabled
                                ? null
                                : isSelected
                                ? data.color
                                : isFocused
                                ? "#c7ef00"
                                : null,
                              color: isDisabled
                                ? '#ccc'
                                : isSelected
                                ? data.color
                                : isFocused
                                ? "#292F36"
                                : null,
                              cursor: isDisabled ? 'not-allowed' : 'default',
                              ':active': {
                                ...styles[':active'],
                                backgroundColor: !isDisabled && (isSelected ? data.color : "#292F36"),
                              },
                            };
                          },
                          singleValue: (styles, { data }) => ({ ...styles, color: "white" })
                        }}
                      ></Select>
                  </Form.Group>
                  <Form.Group className={styles.formField}>
                    <Form.Label className={styles.formLabel}><FaMapSigns style={{marginRight: '0.5em'}}/>curso desejado</Form.Label>
                    <Select
                      className={styles.formSelect}
                      placeholder={'busque pelo seu curso!'}
                      value={selectedIntendedCourse}
                      options={this.state.intendedCourseOptions}
                      onChange={this.handleSelectIntendedCourseChange}
                      styles={{
                        control: (styles, { data, isFocused, isSelected, isActive }) => {
                          return {
                            ...styles,
                            backgroundColor: '#292F36',
                            borderColor: isFocused
                            ? '#c7ef00'
                            : "rgba(255, 255, 255, 0.2)",
                            boxShadow: isFocused
                            ? '#c7ef00'
                            : null,
                            color: "white",
                            ':hover': {
                              borderColor: '#c7ef00',
                            },
                            }
                          },
                          menu: (styles, { data }) => {
                            return {
                              ...styles,
                              backgroundColor: '#292F36'
                            }
                          },
                          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            const color = '#292F36';
                            return {
                              ...styles,
                              backgroundColor: isDisabled
                                ? null
                                : isSelected
                                ? data.color
                                : isFocused
                                ? "#c7ef00"
                                : null,
                              color: isDisabled
                                ? '#ccc'
                                : isSelected
                                ? data.color
                                : isFocused
                                ? "#292F36"
                                : null,
                              cursor: isDisabled ? 'not-allowed' : 'default',
                              ':active': {
                                ...styles[':active'],
                                backgroundColor: !isDisabled && (isSelected ? data.color : "#292F36"),
                              },
                            };
                          },
                          singleValue: (styles, { data }) => ({ ...styles, color: "white" })
                        }}
                      ></Select>
                  </Form.Group>
                </div>

              {this.state.formError && (
                <div className={styles.formError}>{this.state.formError}</div>
              )}

              <Button variant="link" className={`${styles.primaryButton} ${styles.centered}`} type="submit" value='Register' disabled={ this.state.formIntendedCourse === '' || this.state.formScholarity === '' }>
                próximo
              </Button>            
            </Form>
          </div>
        </Col>
      ) : (<></>)}

      { step === 2 && !this.state.loading ? (
        <Col  className={`${styles.login}`} sm={12} md={{ span: 4, offset: 4 }}>
          <div className={styles.containerRegister}>
            <Image className={styles.progressImage} src={progress2}></Image>
            <h2 style={{ marginBottom: "30px" }}>quanto tempo você irá estudar por dia?</h2>
              <Form className={`${styles.form}`} onSubmit={this.handleFormSubmit}>
                <div className={`${styles.card} mx-auto ${styles.maxWidth400}`}>
                <Form.Group controlId="formBasicRange">
                    <style>
                      {
                      `
                      .custom-range::-webkit-slider-thumb {
                        background: #c7ef00;
                        color: #c7ef00;
                        background-color: #c7ef00;
                      }
                      
                      .custom-range::-moz-range-thumb {
                        background: #c7ef00;
                        color: #c7ef00;
                        background-color: #c7ef00;
                      }
                      
                      .custom-range::-ms-thumb {
                        background: #c7ef00;
                        color: #c7ef00;
                        background-color: #c7ef00;
                      }
                      `
                    }
                    </style>
                  <Form.Label><h3>{this.state.formStudyTime} {this.state.formStudyTime > 1? 'horas' : 'hora'}</h3></Form.Label>
                  <Form.Control className={`custom-range`} type="range" min="1" max="12" onChange={e => this.selectStudyTime(e.target.value)} /><br/>
                  {this.state.formStudyTime == 1? <span>caso você possa estudar menos do que 1h por dia, selecione 1h.</span>: <></> }
                </Form.Group>
              </div>

              {this.state.formError && (
                <div className={styles.formError}>{this.state.formError}</div>
              )}

              <Button variant="link" className={`${styles.primaryButton} ${styles.centered}`} type="submit" value='Register' disabled={ this.state.formStudyTime === '' }>
                próximo
              </Button>
            </Form>
          </div>
        </Col>
      ) : (<></>)}

      { step === 3 && !this.state.loading ? (
        <Col className={`${styles.login}`} sm={12} md={{ span: 4, offset: 4 }}>
          <div className={styles.containerRegister}>
            <Image className={styles.progressImage} src={progress3}></Image>
            <h2>acompanhe seu progresso</h2>
            <p style={{ maxWidth: "400px", margin: "10px auto 40px auto" }}>através do seu perfil, você vai conseguir saber quais passos já percorreu e quais são os seus próximos passos!</p>
              <Form className={`${styles.form}`} onSubmit={this.handleFormSubmit}>
              <div className={`${styles.card}`}>
                <Form.Group className={styles.formField}>
                  <Form.Label className={styles.formLabel}><FaRegUser style={{marginRight: '0.5em'}}/>nome</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='seu nome :)'
                    className={styles.formInput}
                    value={this.state.formName}
                    onChange={(e) => { this.handleFormInput('formName', e.target.value) }}
                  />
                </Form.Group> 
                <Form.Group className={styles.formField}>
                  <Form.Label className={styles.formLabel}><FaRegEnvelope style={{marginRight: '0.5em'}}/>email</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='seu-email@exemplo.com'
                    className={styles.formInput}
                    value={this.state.formEmail}
                    onChange={(e) => { this.handleFormInput('formEmail', e.target.value) }}
                  />
                </Form.Group>
                <Form.Group className={styles.formField}>
                  <Form.Label className={styles.formLabel}><FaKey style={{marginRight: '0.5em'}}/>senha</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='sua senha'
                    className={styles.formInput}
                    value={this.state.formPassword}
                    onChange={(e) => { this.handleFormInput('formPassword', e.target.value) }}
                  />
                </Form.Group>
                <Form.Group className={styles.formField}>
                  <Form.Label className={styles.formLabel}><FaKey style={{marginRight: '0.5em'}}/>confirme sua senha</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='pra garantir que esteja certa...'
                    className={styles.formInput}
                    value={this.state.formConfirmPassword}
                    onChange={(e) => { this.handleFormInput('formConfirmPassword', e.target.value) }}
                  />
                </Form.Group>
              </div>

              {this.state.formError && (
                <div className={styles.formError}>{this.state.formError}</div>
              )}

              <Button variant="link" className={`${styles.primaryButton} ${styles.centered}`} type="submit" value='Register'>
                vamos lá
              </Button>
            </Form>
          </div>
        </Col>
      ):(<></>)}

      {this.state.loading? (
        <div className={styles.containerRegister} style={{ paddingTop: "100px" }}>
          <Loader type="TailSpin" color="#c7ef00" height={100} width={100} timeout={10000} />
        </div>
      ) : (<></>)}

      {this.state.state === 'login' && !this.state.loading && !this.state.currentUser.isLoggedIn && (
        <div className={styles.containerSignIn}>
          <h2>Acessar</h2>
          <Form className={styles.form} onSubmit={this.handleFormSubmit}>
            <Form.Group className={styles.formField}>
              <Form.Label className={styles.formLabel}><FaRegEnvelope style={{marginRight: '0.5em'}}/>email</Form.Label>
              <Form.Control
                type='text'
                placeholder='seu-email@exemplo.com'
                className={styles.formInput}
                value={this.state.formEmail}
                onChange={(e) => { this.handleFormInput('formEmail', e.target.value) }}
              />
            </Form.Group>
            <Form.Group className={styles.formField}>
              <Form.Label className={styles.formLabel}><FaKey style={{marginRight: '0.5em'}}/>senha</Form.Label>
              <Form.Control
                type='password'
                placeholder='sua senha'
                className={styles.formInput}
                value={this.state.formPassword}
                onChange={(e) => { this.handleFormInput('formPassword', e.target.value) }}
              />
            </Form.Group>

            {this.state.formError && (
              <div className={styles.formError}>{this.state.formError}</div>
            )}

            <Button variant="link" className={`${styles.secButton} ${styles.centered}`} type="submit" value='Sign In'>
              Entrar
            </Button>

          </Form>
          <p><Link className={styles.primaryLink} to={'/passwordrecover'}>esqueceu sua senha?</Link></p>

          <hr/>
          <p>ainda não se cadastrou? <Button variant="link" className={styles.secondaryLink} onClick={(e) => { this.handleFormTypeChange('register')} }>cadastre-se agora!</Button></p>
        </div>
      )}
    </Col>
    )
  }
}

export default withRouter(Register)
import React, { Component }  from 'react'
import {
  withRouter
} from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Loader from 'react-loader-spinner'

class Loading extends Component {
  render() {
    return (
      <Container>
        <Row style={{ justifyContent: 'center', width: '100%' }}>
          <Loader style={{ marginTop: "135px" }} type="TailSpin" color="#c7ef00" height={100} width={100} timeout={10000} />
          <p>carregando...</p>
        </Row>
      </Container>
    )
  }
}

export default withRouter(Loading)
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import styles from './index.module.css'
import Badge from 'react-bootstrap/Badge'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { CgTime } from 'react-icons/cg'
import { BsCheckCircle, BsCircle } from 'react-icons/bs'


const unity = require('../../controllers/unities')
const api = require('../../utils/api')

class TaskWidget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      title: null,
      defaultIcon: null,
      type: ''
    }
  }

  async componentDidMount() {
    const defaultIcon = await require('../../assets/ilustracao_task.svg')
    
    let currentUser = await api.currentUser()
    let type
    if (!this.props.task.unityId) {
      type = 'questions'
    } else {
      type = 'content'
    }

    if (this.props.task.unityId) {
      this.setState({
        loading: true
      })
      const reqUnity = await unity.get(this.props.task.unityId, currentUser.id)
      if (reqUnity.ok) {
        const getUnity = reqUnity.body.unity
      
        if (getUnity != null) {
          this.setState({
            unity: getUnity,
            icon: getUnity.icon,
            title: getUnity.title,
            type: 'content',
            loading: false
          })
        }
      } else {
        this.setState({
          error: 'Houve um problema...',
          type: 'content'
        })
      }
    } else {
      this.setState({
        icon: this.props.task.icon || null,
        type,
        loading: false,
        defaultIcon
      })
    }
  }

  async componentWillReceiveProps(nextProps) {
    let currentUser = await api.currentUser()

    if (nextProps.task) {
      if (nextProps.task !== this.props.task) {
        this.setState({
          loading: true
        })
        if (this.props.task.unityId) {
          const reqUnity = await unity.get(this.props.task.unityId, currentUser.id)
          if (reqUnity.ok) {
            const getUnity = reqUnity.body.unity
          
            if (getUnity != null) {
              this.setState({
                unity: getUnity,
                icon: getUnity.icon,
                title: getUnity.title,
                type: 'content',
                loading: false
              })
            }
          }
        } else {
          this.setState({
            type: 'questions',
            loading: false
          })
        }
      }
    }
  }

  render() { 
    var { task, md } = this.props
    return (
      <Col sm={12} md={md}>
        { task.isComplete?
        <>
          <Card className={`${styles.bgDark} ${styles.linkCard} ${styles.borderRadius25} p-3 mt-4`}>
            <Row>
              <Col xs={2} style={{ verticalAlign: "middle", margin: "auto" }}>
                { task.isComplete? <BsCheckCircle size={"30px"} className={styles.buttonIcon}/> : <BsCircle size={"30px"} className={styles.buttonIcon}/> }
              </Col>
              <Col className={'py-2'} xs={10}>
                { task.title ? (
                  <Card.Title className={`mb-0`}><strong>{task.title}</strong></Card.Title>
                ) : (
                  <Card.Title><strong><Skeleton width={200}/></strong></Card.Title>
                ) }
                { task.subtitle ? (
                  <Card.Text><strong>{task.subtitle}</strong></Card.Text>
                ) : ( <></> ) }
              </Col>
            </Row>
          </Card>
        </> :
        <>
         <Card className={`${styles.bgDark} ${styles.linkCard} ${styles.borderRadius25} p-3 mt-4`} as="a" href={`/task/${task.id}`}>
            <Row>
              <Col xs={2} style={{ verticalAlign: "middle", margin: "auto" }}>
                { task.isComplete? <BsCheckCircle size={"30px"} className={styles.buttonIcon}/> : <BsCircle size={"30px"} className={styles.buttonIcon}/> }
              </Col>
              <Col className={'py-2'} xs={10}>
                { task.title ? (
                  <Card.Title className={`mb-0`}><strong>{task.title}</strong></Card.Title>
                ) : (
                  <Card.Title><strong><Skeleton width={200}/></strong></Card.Title>
                ) }
                { task.subtitle ? (
                  <Card.Text><strong>{task.subtitle}</strong></Card.Text>
                ) : ( <></> ) }
              </Col>
            </Row>
          </Card>
          <span className={`${styles.alwaysGrey} my-1 ml-auto mr-0 d-table`}><CgTime style={{ marginBottom: '3px' }}/> {task.spectedTime}</span>
        </>
        }
      </Col>
    )
  }
}

export default withRouter(TaskWidget)
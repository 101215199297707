import React, { Component }  from 'react'
import {
  // Link,
  withRouter
} from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import ReactPixel from 'react-facebook-pixel'
import Breadcrumb from '../../components/Breadcrumb'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Skeleton from 'react-loading-skeleton'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { BsEye, BsListUl } from 'react-icons/bs'
import { CgChevronRightO } from 'react-icons/cg'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

const api = require('../../utils/api')
const exams = require('../../controllers/exams')
const transparentImg = require('../../fragments/examBlankImage').base64()

// const ilustracaoEstudantesELivros = lazy(() =>

class Exams extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ilustracaoSalaDeAula: null,
      exams: [],
      loading: true,
      currentUser: { can: [] }
    }

    this.renderExam = this.renderExam.bind(this)
  }

  async componentDidMount() {
    const request = await exams.get()
    const getExams = request.body.exams

    AOS.init({
      duration: 300
    })
    
    const ilustracaoSalaDeAula = await require('../../assets/ilustracao_sala_de_aula.svg')
    let CurrentUser = await api.currentUser()
    
    if (CurrentUser) {
      this.setState({
        currentUser: CurrentUser
      })
    }

    this.setState({
      exams: getExams,
      loading: false,
      ilustracaoSalaDeAula: ilustracaoSalaDeAula
    })
    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  renderExam(exam) {
    return (
      <Col sm={12} md={4} key={`${exam.name}-${exam.period}`}>
        <Card className={`${styles.bgNone} ${styles.examCard} text-white mt-4`} as="a" href={`/exam/${exam.id}`}>
          <Card.Img className={`${styles.brightness05}`} src={!exam.imgSrc ? this.state.ilustracaoSalaDeAula : exam.imgSrc}  alt="Card image" />
          <Card.ImgOverlay>
            <Row>
              <Col sm={9}>
                <Card.Text style={{ fontSize: "18px" }}><strong className={'pr-3'}>{exam.name}</strong>{exam.period}</Card.Text>
                <Card.Text style={{ fontSize: "16px", opacity: "0.8" }}>
                  <OverlayTrigger trigger={['focus', 'hover']} overlay={
                    <Tooltip id={`tooltip-num-questions`}>
                      Questões
                    </Tooltip>
                  }>
                    { exam.numQuestions ? (
                      <span className={'pr-3'}><BsListUl/> {exam.numQuestions}</span>
                    ):(
                      <span className={'pr-3'}><BsListUl/> <Skeleton width={20}/></span>
                    )}
                  </OverlayTrigger>
                  <OverlayTrigger trigger={['focus', 'hover']} overlay={
                    <Tooltip id={`tooltip-visits`}>
                      Visualizações
                    </Tooltip>
                  }>
                    { exam.numVisits ? (
                      <span className={'pr-3'}><BsEye/> {exam.numVisits}</span>
                    ):(
                      <span className={'pr-3'}><BsEye/> <Skeleton width={20}/></span>
                    )}
                  </OverlayTrigger>
                </Card.Text>
              </Col>
              <Col sm={3} style={{ verticalAlign: "middle", margin: "auto" }}>
                <CgChevronRightO size={"30px"} className={styles.buttonIcon}/>
              </Col>
            </Row>
            {/* <Card.Text className={styles.dFlex}><Button variant="link" className={`${styles.secondaryButton} ${styles.toBottomCenter}`} >ver prova</Button></Card.Text> */}
          </Card.ImgOverlay>
        </Card>
      </Col>
    )
  }

  render() {
    const { exams, loading, currentUser } = this.state
    const meta = {
      title: `Provas | lampejo.app - Plataforma gratuita para o ENEM` ,
      description: `Somos uma comunidade de estudantes e professores que valorizam o compartilhamento do conhecimento. Você vai aprender praticando e ajudando os outros alunos.`,
      meta: {
        charset: 'utf-8',
        lang: 'pt-br',
        amp: true,
        name: {
          keywords: 'educação, vestibular, enem, questões, provas'
        }
      }
    }
    return (
      <Container className={styles.container}>
        <DocumentMeta {...meta}/>
        <Breadcrumb links={[
          { label: "Provas", active: true },
        ]}/>
        { currentUser.can.includes('register-exam') /* refactor to currentUser.can('register-exam') or currentUser.can['register-exam'] */  ? (
          <Row>
            <Col sm={12} className={styles.toRight}>
              <Button variant="link" className={`${styles.primaryLink} ${styles.toRight}`} href={`exam/edit`}>nova prova</Button>
            </Col>
          </Row>
        ) : (
          <></>
        ) }
        <Row>
        { loading ? (
          <>
          <Col sm={12} md={4} data-aos="fade-up">
            <Card className={`${styles.bgNone} text-white mt-4`}>
              <Card.Img className={`${styles.maxminHeight215}`}
                style={{ backgroundColor: "rgba(67,80,94,1)" }}
                src={transparentImg} alt="Card image" />
              <Card.ImgOverlay>
                <Row>
                  <Col sm={9}>
                    <Card.Title><Skeleton/></Card.Title>
                    <Card.Text>
                      <span className={'pr-3'}><BsListUl/> <Skeleton width={20}/></span>
                      <span className={'pr-3'}><BsEye/> <Skeleton width={20}/></span>
                      </Card.Text>
                    <Card.Text className={styles.dFlex}><Skeleton/></Card.Text>
                  </Col>
                </Row>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col sm={12} md={4} data-aos="fade-up">
            <Card className={`${styles.bgNone} text-white mt-4`}>
              <Card.Img className={`${styles.maxminHeight215}`}
                style={{ backgroundColor: "rgba(67,80,94,1)" }}
                src={transparentImg} alt="Card image" />
              <Card.ImgOverlay>
                <Row>
                  <Col sm={9}>
                    <Card.Title><Skeleton/></Card.Title>
                    <Card.Text>
                      <span className={'pr-3'}><BsListUl/> <Skeleton width={20}/></span>
                      <span className={'pr-3'}><BsEye/> <Skeleton width={20}/></span>
                      </Card.Text>
                    <Card.Text className={styles.dFlex}><Skeleton/></Card.Text>
                  </Col>
                </Row>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col sm={12} md={4} data-aos="fade-up">
            <Card className={`${styles.bgNone} text-white mt-4`}>
              <Card.Img className={`${styles.maxminHeight215}`}
                style={{ backgroundColor: "rgba(67,80,94,1)" }}
                src={transparentImg} alt="Card image" />
              <Card.ImgOverlay>
                <Row>
                  <Col sm={9}>
                    <Card.Title><Skeleton/></Card.Title>
                    <Card.Text>
                      <span className={'pr-3'}><BsListUl/> <Skeleton width={20}/></span>
                      <span className={'pr-3'}><BsEye/> <Skeleton width={20}/></span>
                      </Card.Text>
                    <Card.Text className={styles.dFlex}><Skeleton/></Card.Text>
                  </Col>
                </Row>
              </Card.ImgOverlay>
            </Card>
          </Col>
          </>
        )
        :
        exams.map(this.renderExam)
        }
        </Row>
      </Container>
    )
  }
}

export default withRouter(Exams)
import React, { Component }  from 'react'
import {
  // Link,
  withRouter
} from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import ReactPixel from 'react-facebook-pixel'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Media from 'react-bootstrap/Media'
import { AiOutlineArrowRight } from 'react-icons/ai'
import PublicNavBar from '../../components/PublicNavBar'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

class Home extends Component {

  constructor(props) {
    super(props)
    this.state = {
      road: null,
      diversity: null,
      data: null,
      organization: null,
      problem: null,
      student: null
    }
  }

  async componentDidMount() {
    const road = require('../../assets/road.svg')
    const diversity = require('../../assets/diversity.svg')
    const data = require('../../assets/data.svg')
    const organization = require('../../assets/organization.svg')
    const problem = require('../../assets/problem.svg')
    const student = require('../../assets/student.svg')

    this.setState({
      road,
      diversity,
      data,
      organization,
      problem,
      student
    })

    AOS.init({duration: 300})

    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  render() {
    const { road, diversity, data, organization, problem, student } = this.state
    const meta = {
      title: `Carreiras | Lampejo - Plataforma gratuita para o ENEM` ,
      description: `Somos uma comunidade de estudantes e professores que valorizam o compartilhamento do conhecimento. Você vai aprender praticando e ajudando os outros alunos.`,
      meta: {
        charset: 'utf-8',
        lang: 'pt-br',
        amp: true,
        name: {
          keywords: 'educação, vestibular, enem, questões, provas'
        }
      }
    }
    return (
      <>
        <DocumentMeta {...meta}/>
        <PublicNavBar active="jobs"></PublicNavBar>
        <Container className={styles.homeContainer}>
          <Row className={styles.homePrincipal}>
            <Col md={12} className={`text-center`}>
              <Container style={{ maxWidth: "1000px" }}>
              <br/><br/><br/><br/>
              <h2>Nos ajude a melhorar a educação brasileira</h2><br/>
              <h4>Na Lampejo nós desenvolvemos uma forma<br/> instigante, acessível e divertida de aprender!</h4><br/>
              <a href="#open" className={`${styles.primaryWhiteLink}`}><h5>Ver vagas em aberto</h5></a><br/><br/>
                <Row>
                  <Col sm={12} md={6} className={`text-left`}>
                    <Media>
                      <img
                        width={64}
                        height={64}
                        className="mr-3"
                        src={road}
                        alt="Generic placeholder"
                      />
                      <Media.Body>
                        <h5 className={styles.mediaTitle}>Próposito em primeiro lugar</h5>
                        <p>
                          Nossa missão é nosso maior objetivo. Fazer com que todos os brasileiros tenham acesso à educação é o que nos faz acordar empolgados todos os dias!
                        </p>
                      </Media.Body>
                    </Media>
                    <br/>
                  </Col>
                  <Col sm={12} md={6} className={`text-left`}>
                    <Media>
                      <img
                        width={64}
                        height={64}
                        className="mr-3"
                        src={data}
                        alt="Generic placeholder"
                      />
                      <Media.Body>
                        <h5 className={styles.mediaTitle}>Mais dados, menos opiniões</h5>
                        <p>
                          Observar, criar hipóteses, experimentar, medir e, então, concluir. Nenhuma nova ideia é invalida porque alguém não gosta dela.
                        </p>
                      </Media.Body>
                    </Media>
                    <br/>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} className={`text-left`}>
                    <Media>
                      <img
                        width={64}
                        height={64}
                        className="mr-3"
                        src={problem}
                        alt="Generic placeholder"
                      />
                      <Media.Body>
                        <h5 className={styles.mediaTitle}>Amamos resolver problemas difíceis</h5>
                        <p>
                          Tentamos ver o quebra-cabeça de formas diferentes todos os dias. "Eureka!" Buscando novas soluções, mas nunca nos apaixonando por elas.
                        </p>
                      </Media.Body>
                    </Media>
                  </Col>
                  <Col sm={12} md={6} className={`text-left`}>
                    <Media>
                      <img
                        width={64}
                        height={64}
                        className="mr-3"
                        src={organization}
                        alt="Generic placeholder"
                      />
                      <Media.Body>
                        <h5 className={styles.mediaTitle}>!#@💀 a politicagem</h5>
                        <p>
                          Reconhecimentos são feitos pela qualidade das entregas, não porque alguém tem mais presença nas reuniões. 
                        </p>
                      </Media.Body>
                    </Media>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col sm={12} md={6} className={`text-left`}>
                    <Media>
                      <img
                        width={64}
                        height={64}
                        className="mr-3"
                        src={diversity}
                        alt="Generic placeholder"
                      />
                      <Media.Body>
                        <h5 className={styles.mediaTitle}>Diversidade!</h5>
                        <p>
                          Buscamos ativamente como promover a diversidade na Lampejo. Pensamentos diferentes nos tornam mais fortes e a responsabilide é de todos.
                        </p>
                      </Media.Body>
                    </Media>
                    <br/>
                  </Col>
                  <Col sm={12} md={6} className={`text-left`}>
                    <Media>
                      <img
                        width={64}
                        height={64}
                        className="mr-3"
                        src={student}
                        alt="Generic placeholder"
                      />
                      <Media.Body>
                        <h5 className={styles.mediaTitle}>Também somos estudantes</h5>
                        <p>
                          O processo de aprender é algo que levamos para toda a vida. É com ele que somos capazes de nos reconstruir sempre.
                        </p>
                      </Media.Body>
                    </Media>
                  </Col>
                </Row>
              </Container>
              </Col>
              <Col>
                <br/><br/>
                <h2 id="open">Vagas em aberto</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card sm={12} className={`${styles.missionCard}`}>
                <Row>
                  <Col sm={12}>
                    <Card.Text>
                      <h3>Marketing e Comunicação</h3>
                      <Button variant="link" className={`${styles.primaryLink} py-2`} href={'https://forms.gle/WHzZvE1apg1iiyVL8'} target="_blank"><h5>Estágio em Comunicação e Marketing Digital <AiOutlineArrowRight/></h5></Button><br/>
                      
                      {/* <br/><br/> */}
                      {/* <h3>Time de Conteúdos</h3> */}
                      {/* <Button variant="link" className={`${styles.primaryLink} py-2`} href={'https://forms.gle/cKjpV1pikgDxP6Jg9'} target="_blank"><h5>Estágio em Desenvolvimento de Conteúdo Pedagógico para o Ensino Médio <AiOutlineArrowRight/></h5></Button><br/> */}
                    </Card.Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default withRouter(Home)
import React, { Component }  from 'react'
import { withRouter } from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import ReactPixel from 'react-facebook-pixel'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import UserArea from '../../components/UserArea'
import PublicNavBar from '../../components/PublicNavBar'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

class Home extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showLogin: false,
      width: window.innerWidth,
      illustrationPriority: null,
      illustrationGame: null,
      illustrationChosen: null,
      illustrationTimeManagement: null,
      illustrationUpgrade: null,
    }

    this.closeLogin = this.closeLogin.bind(this)
  }

  async componentDidMount() {

    const illustrationPriority = require('../../assets/illustration_priority.svg')
    const illustrationGame = require('../../assets/illustration_game.svg')
    const illustrationChosen = require('../../assets/illustration_chosen.svg')
    const illustrationTimeManagement = require('../../assets/illustration_time_management.svg')
    const illustrationUpgrade = require('../../assets/illustration_upgrade.svg')

    this.setState({
      illustrationPriority,
      illustrationGame,
      illustrationChosen,
      illustrationTimeManagement,
      illustrationUpgrade
    })

    AOS.init({duration: 300})
    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  openLogin() {
    this.setState({
      showLogin: true
    })
  }

  closeLogin() {
    this.setState({
      showLogin: false
    })
  }

  render() {
    const { width, illustrationPriority, illustrationGame, illustrationChosen, illustrationTimeManagement, illustrationUpgrade } = this.state
    const meta = {
      title: `Metodologia | lampejo.app - Plataforma gratuita para o ENEM` ,
      description: `Somos uma comunidade de estudantes e professores que valorizam o compartilhamento do conhecimento. Você vai aprender praticando e ajudando os outros alunos.`,
      meta: {
        charset: 'utf-8',
        lang: 'pt-br',
        amp: true,
        name: {
          keywords: 'educação, vestibular, enem, questões, provas'
        }
      }
    }
    return (
      <>
        <DocumentMeta {...meta}/>
        { width < 780 ? 
          <></>  
        :
          <PublicNavBar active="method"></PublicNavBar>
        }
        <Container className={styles.homeContainer}>
          { this.state.showLogin ?
            <UserArea closeLogin={this.closeLogin}/>
            :
            <></>
          }
          <br/><br/><br/><br/>
          <h2>Metodologia</h2>
          <br/><br/>
          <Row className={styles.homePrincipal}>
            <Col sm={12} data-aos='fade-up'>
              <Card className={`${styles.missionCard}`}>
                  <Card.Text>
                  <p>Construímos um método <strong>eficiente</strong>, <strong>cativante</strong> e <strong>personalizado</strong> de se estudar para a prova do ENEM e outros vestibulares.</p>
                  </Card.Text>

                  <hr/>
                  <Card.Title className={`${styles.h2TextTitle}`}>O que é mais importante vem primeiro</Card.Title>
                  <Row>
                    <Col style={{ maxWidth: '230px' }}>
                      <Image src={illustrationPriority} style={{ maxWidth: '200px', marginTop: 20, marginBottom: 20 }}/>
                    </Col>
                    <Col style={{ alignItems: 'center', height: '100%', margin: 'auto' }}>
                      <Card.Text>
                      <p>Dividimos todo o conteúdo que cai no ENEM e vestibulares em <strong>tarefas</strong> de cerca de 30 minutos e as disponibilizamos aos alunos conforme a sua dificuldade e as chances desses assuntos caírem nas provas. Se o conteúdo é fácil e tem altas chances de cair nas provas, com certeza ele vai aparecer primeiro para você.</p>
                      </Card.Text>
                    </Col>
                  </Row>

                  
                  <hr/>
                  <Card.Title className={`${styles.h2TextTitle}`}>Escolhidas a dedo</Card.Title>
                  <Row>
                    <Col style={{ alignItems: 'center', height: '100%', margin: 'auto' }}>
                      <Card.Text>
                        <p>As <strong>tarefas</strong> são (normalmente) compostas de <strong>videoaulas</strong>, <strong>questões de construção de base</strong> e <strong>questões de vestibular</strong>. Escolhemos os melhores vídeos disponíveis já que o formato tem diversas vantagens por utilizar tanto de elementos visuais e narração quanto do conhecimento imprescindível dos professores. A partir disso, desenvolvemos uma série de questões que ajudam a assimilar esse conteúdo e agregamos exercícios de vestibulares, para que o aluno se familiarize com o modelo exigido nas provas.</p>
                      </Card.Text>
                    </Col>
                    <Col style={{ maxWidth: '230px' }}>
                      <Image src={illustrationChosen} style={{ maxWidth: '200px', marginTop: 20, marginBottom: 20 }}/>
                    </Col>
                  </Row>


                  <hr/>
                  <Card.Title className={`${styles.h2TextTitle}`}>Estrelas, Pontos, Corridas</Card.Title>
                  <Row>
                    <Col style={{ maxWidth: '230px' }}>
                      <Image src={illustrationGame} style={{ maxWidth: '200px', marginTop: 20, marginBottom: 20 }}/>
                    </Col>
                    <Col style={{ alignItems: 'center', height: '100%', margin: 'auto' }}>
                      <Card.Text>
                      <p>Utilizamos diversos elementos da área dos jogos para que os alunos se mantenham animados para o próximo dia de estudos. Cada tarefa te proporciona uma quantidade de pontos. Para cada 10, 50 ou 100 tarefas você recebe um <em>sticker</em> colecionável que pode colocar em seu perfil. Conseguiu estudar todos os dias do seu cronograma nesta semana? Uau! Continue a sua corrida! Está preparado para o próximo desafio?</p>
                      </Card.Text>
                    </Col>
                  </Row>

                  <hr/>
                  <Card.Title className={`${styles.h2TextTitle}`}>Você escolhe o seu tempo</Card.Title>
                  <Row>
                    <Col style={{ alignItems: 'center', height: '100%', margin: 'auto' }}>
                      <Card.Text>
                        <p>Cada pessoa tem um tempo próprio de absorção dos conteúdos e é por isso que cada aluno define o seu próprio ritmo de estudos. Vai estudar 4 horas por dia, todos os dias? Ok! Seu cronograma vai estar pronto para isso. Precisa pular às quartas-feiras? Opa! Estamos sempre prontos ;)</p>
                      </Card.Text>
                    </Col>
                    <Col style={{ maxWidth: '230px' }}>
                      <Image src={illustrationTimeManagement} style={{ maxWidth: '200px', marginTop: 20, marginBottom: 20 }}/>
                    </Col>
                  </Row>

                  <hr/>
                  <Card.Title className={`${styles.h2TextTitle}`}>Evoluindo todos os dias</Card.Title>
                  <Row>
                    <Col style={{ maxWidth: '230px' }}>
                      <Image src={illustrationUpgrade} style={{ maxWidth: '200px', marginTop: 20, marginBottom: 20 }}/>
                    </Col>
                    <Col style={{ alignItems: 'center', height: '100%', margin: 'auto' }}>
                      <Card.Text>
                      <p>A partir dos comentários dos alunos e professores que utilizam o app e a plataforma, nós alteramos, adaptamos e melhoramos as nossas tarefas diariamente. Dessa forma promovemos a melhoria constante da nossa metodologia.</p>
                      </Card.Text>
                    </Col>
                  </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default withRouter(Home)
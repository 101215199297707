import React, { Component } from 'react'
import {
 Link,
 withRouter
} from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { FiUser } from 'react-icons/fi'
// import UserArea from '../UserArea'
import SearchBar from '../SearchBar'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import styles from './index.module.css'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { RiLightbulbLine, RiLightbulbFill } from 'react-icons/ri'

const api = require('../../utils/api')
const auth = require('../../utils/auth')
const streakEvents = require('../../controllers/streakEvents')

class NavigationBar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      currentUser: {
        name: null,
        email: null,
        type: null,
        isLoggedIn: false,
        dataLetters: null,
        can: [],
      },
      showLogin: false,
      roadIcon: null,
      calendarIcon: null,
      scheduleIcon: null,
      actualStreak: { value: null, status: 'inactive' }
    }
  }

  async componentDidMount() {
    const roadIcon = require('../../assets/route.svg')
    const calendarIcon = require('../../assets/calendar.svg')
    const scheduleIcon = require('../../assets/schedule.svg')

    this.setState({    
      roadIcon,
      calendarIcon,
      scheduleIcon
    })
    let currentUser = await api.currentUser()

    if (currentUser.isLoggedIn) {
      const actualStreakResp = await streakEvents.getActualStreak(auth.getToken())
      const actualStreak = actualStreakResp.body

      this.setState({ currentUser, actualStickerUrl: currentUser.actualSticker?.imgUrl, actualStreak })
    }
  }

  async toUser() {
    if (this.state.currentUser.isLoggedIn) {
      window.location.replace('/user/' + this.state.currentUser.id)
    } else {
      window.location.replace('/login')
    }
  }

  render() {
    const { currentUser, actualStickerUrl, actualStreak } = this.state
    return (
      <>
        <Navbar fixed="top" className={`${styles.navbar} justify-content-between d-none d-md-flex`}>
          <Container>
            <Navbar.Brand href="/">
              <Image fluid src={require('../../assets/logo_lampejo.png')} className={`${styles.imgLogo} d-inline-block align-top`} alt='lampejo.app'/>
            </Navbar.Brand>
            { this.state.currentUser.isLoggedIn ? (
              <div className={`${styles.rightComponent}`}>
                <SearchBar/>
                  <>
                    <OverlayTrigger placement={'bottom'} overlay={<Tooltip id="tooltip-disabled">cronograma</Tooltip>}>
                      <Button variant="link" className={`${styles.scheduleButton}`} href={'/schedule'}><Image style={{ height: "18px" }} src={this.state.calendarIcon}/></Button>
                    </OverlayTrigger>
                    {/* <OverlayTrigger placement={'bottom'} overlay={<Tooltip id="tooltip-disabled">calendário</Tooltip>}>
                      <Button variant="link" className={`${styles.calendarButton}`} href={'/calendar'}><Image style={{ height: "18px" }} src={this.state.calendarIcon}/></Button>
                    </OverlayTrigger> */}
                  </>
                {/* <OverlayTrigger placement={'bottom'} overlay={<Tooltip id="tooltip-disabled">trilha</Tooltip>}>
                  <Button variant="link" className={`${styles.roadButton}`} href={'/road'}><Image style={{ height: "18px" }} src={this.state.roadIcon}/></Button>
                </OverlayTrigger> */}
                { this.state.currentUser.isLoggedIn ?
                  <>
                    <Button className={`${styles.btnNoBackground}`} style={{ marginRight: "6px", marginTop: "3px", cursor: 'inherit' }} onClick={() => { return }}>
                      { actualStreak.status == 'active'?
                      <div style={{ display: 'inline-flex'  }}>
                        <span className={`${styles.activeActualStreakVal} d-flex`}>{actualStreak.value}</span>
                        <div style={{ marginTop: '3px', display: 'flex' }}>
                          <RiLightbulbFill color="#c7ef00"/>
                        </div>
                      </div>
                      :
                      <div style={{ display: 'inline-flex'  }}>
                        <span className={`${styles.inactiveActualStreakVal} d-flex`}>{actualStreak.value}</span>
                        <div style={{ marginTop: '3px', display: 'flex' }}>
                          <RiLightbulbLine color="#666"/>
                        </div>
                      </div>
                      }
                    </Button>
                    <Button variant="link" className={`${styles.btnNoBackground}`} onClick={() => { this.toUser() }}>
                      <span className={`${styles.userCircle}`}>
                        <span className={`${styles.userLettersName}`}>{this.state.currentUser.dataLetters}</span>
                        {!!actualStickerUrl &&
                        <span>
                          <Image src={actualStickerUrl} style={{ position: 'absolute', paddingTop: '20px', marginLeft: '-10px', width: '20px' }}/>
                        </span>}
                      </span>
                    </Button>
                  </>
                  :
                  <Button variant="link" className={`${styles.btnNoBackground}`} onClick={() => { this.toUser() }}>
                    <span className={`${styles.userCircle}`}><FiUser/></span>
                  </Button>
                }
              </div>
            ) : (<></>) }
          </Container>
        </Navbar>
        { this.state.currentUser.isLoggedIn ? (
        <Navbar fixed="bottom" className={`${styles.navbar} ${styles.navbarBottom} justify-content-between d-flex d-md-none`}>
          <Container>
            <div className={`${styles.bottomComponent}`}>
              <Button variant="link" className={`${styles.scheduleButton}`} href={'/schedule'}><Image style={{ height: "18px", marginTop: '2px' }} src={this.state.calendarIcon}/></Button>
              {/* <Button variant="link" className={`${styles.calendarButton}`} href={'/calendar'}><Image style={{ height: "18px", marginTop: '2px' }} src={this.state.calendarIcon}/></Button> */}
              {/* <Button variant="link" className={`${styles.roadButton}`} href={'/road'}><Image style={{ height: "18px" }} src={this.state.roadIcon}/></Button> */}
              <Button variant="link" className={`${styles.btnNoBackground}`} style={{ display: 'inline-flex' }} onClick={() => { this.toUser() }}>
                <div className={`${styles.btnNoBackground}`} style={{ marginRight: "6px", marginTop: "8px", cursor: 'inherit' }} onClick={() => { return }}>
                  { actualStreak.status == 'active'?
                  <div style={{ display: 'inline-flex'  }}>
                    <span className={`${styles.activeActualStreakVal} d-flex`}>{actualStreak.value}</span>
                    <div style={{ marginTop: '3px', display: 'flex' }}>
                      <RiLightbulbFill color="#c7ef00"/>
                    </div>
                  </div>
                  :
                  <div style={{ display: 'inline-flex'  }}>
                    <span className={`${styles.inactiveActualStreakVal} d-flex`}>{actualStreak.value}</span>
                    <div style={{ marginTop: '3px', display: 'flex' }}>
                      <RiLightbulbLine color="#666"/>
                    </div>
                  </div>
                  }
                </div>
                <span className={`${styles.userCircle}`}>
                  <span className={`${styles.userLettersName}`}>{this.state.currentUser.dataLetters}</span>
                </span>
                {!!actualStickerUrl &&
                <span>
                  <Image src={actualStickerUrl} style={{ position: 'relative', top: '15px', left: '-15px', width: '20px' }}/>
                </span>}
              </Button>
            </div>
          </Container>
        </Navbar>
        ) : (<></>) }
      </>
    )
  }
}

export default withRouter(NavigationBar)
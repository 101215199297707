import React, { Component }  from 'react'
import { withRouter } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Loader from 'react-loader-spinner'
import styles from './index.module.css'

import { BiBook, BiRocket, BiStar, BiLogOut} from 'react-icons/bi'

const users = require('../../controllers/users')
const api = require('../../utils/api')
const auth = require('../../utils/auth')

class User extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {
      },
      attempts: [],
      currentUser: { can: [] },
      numTags: 10,
      loading: true,
      loadingUpdate: false,
      dataLetters: null,
      road: { levels: [] },
      authorized: false
    }
  }

  async componentDidMount() {
    const CurrentUser = await api.currentUser()

    if (CurrentUser) {
      const request = await users.getById(CurrentUser.id, auth.getToken())
      let getUser = {}
      if (request.ok) {
        getUser = request.body.user
        const dataLetters = await api.getDataLetters(getUser.name)
  
        this.setState({
          user: getUser,
          loading: false,
          dataLetters,
          actualStickerUrl: CurrentUser.actualSticker?.imgUrl
        })
      } else {
        this.setState( {
          loading: false
        })
      }
  
      this.setState({
        currentUser: CurrentUser
      })
    } else {
      this.setState({
        loading: false
      })
    }
  }

  render() {
    const { user, actualStickerUrl, authorized, loading, currentUser, dataLetters } = this.state
    if (!loading) {
      return (
        <Card className={styles.bgDark}>
          <Col className={`mx-auto`}>
            <Row sm={12} md={12}>
              <Col sm={2} className={'p-0'} style={{ alignSelf: 'center' }}>
                <span className={`${styles.userCircle}`}>
                  <span className={`${styles.userLettersName}`}>{dataLetters}</span>
                </span>
                {!!actualStickerUrl &&
                <span>
                  <Image src={actualStickerUrl} style={{ position: 'absolute', left: '23px', top: '23px', width: '30px' }}/>
                </span>}
              </Col>
              <Col sm={10} style={{ alignSelf: 'center' }}>
                <Row className={'d-inline-flex justify-content-between'}><Col><h5 className={styles.userName}><strong>{user.name}</strong></h5></Col><Col className={styles.maxWidthMaxContent}><span style={{ color: "#c7ef00" }}><span style={{ fontSize: "16px", fontWeight: "bolder" }}><BiStar style={{ color: "#c7ef00", marginBottom: "5px", fontSize: "16px" }}/>{user.points}</span></span></Col></Row>
                <p className={'mb-0'}>
                  <BiRocket style={{marginRight: '0.5em', color: "white" }}/>{user.intendedCourse}<br/>
                  <BiBook style={{marginRight: '0.5em', color: "white" }}/>{user.scholarity}
                </p>
              </Col>
            </Row>
          </Col>
        </Card>
      )
    } else {
      return (
        <Card className={styles.bgDark}>
          <Row className="justify-content-md-center">
            <Col sm={12} md={{ span: 6 }} className={`mx-auto`}>
              <Loader type="TailSpin" color="#c7ef00" height={100} width={100} timeout={10000} />
            </Col>
          </Row>
        </Card>            
      )
    }
  }
}

export default withRouter(User)
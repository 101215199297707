import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import Loading from './pages/Loading'
import Exams from './pages/Exams'
import Exam from './pages/Exam'
import Question from './pages/Question'
import Search from './pages/Search'

import Area from './pages/Area'
import AreaByExam from './pages/Area/byExam'

import User from './pages/User'
import Register from './pages/Register'
import RegisterBeta from './pages/RegisterBeta'
import Login from './pages/Login'
import UserEdit from './pages/User/Edit'

import Tag from './pages/Tag'

import Attempt from './pages/Attempt'
import Unity from './pages/Unity'

import Task from './pages/Task/'

import ScheduleB from './pages/ScheduleB/'

// import RegisterSchedule from './pages/RegisterSchedule'
import RegisterScheduleB from './pages/RegisterScheduleB'

import Calendar from './pages/Calendar/'

import Road from './pages/Road'
import Roads from './pages/Roads'

import LeadRegister from './pages/LeadRegister'
import Obrigado from './pages/Obrigado'

import WelcomeEmail from './pages/Email/Welcome/index'

import Mission from './pages/Mission'
import Method from './pages/Method'
import AboutUs from './pages/AboutUs'
import Jobs from './pages/Jobs'
import Terms from './pages/Terms'
import RedirectComponent from './pages/Redirect'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import { ToastContainer } from 'react-toastify'
import NavigationBar from './components/NavigationBar'
import Footer from './components/Footer'
import 'react-toastify/dist/ReactToastify.css'
// eslint-disable-next-line
import styles from  './App.module.css'

import katex from 'katex'
import 'katex/dist/katex.min.css'

import ScrollToTop from './components/ScrollToTop'
import PasswordRecover from './pages/PasswordRecover'

window.katex = katex
const api = require('./utils/api')

export default class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      currentUser: api.currentUser()
    }
    
  }

  async componentDidMount() {
    const currentUser = await api.currentUser()
    if (currentUser) {
      this.setState({
        currentUser
      })
    }
    this.setState({
      loading: false
    })
  }

  render() {
    const { loading, currentUser } = this.state
    return (
      <Router>
        <ScrollToTop/>
        <ToastContainer/>
        <NavigationBar/>
        <Switch>
          <Route path='/register/:step?'>
            <Register />
          </Route>

          <Route path='/invite/:inviterId/:step?'>
            <RegisterBeta />
          </Route>

          <Route path='/login'>
            <>
              <Login />
              <Footer/>
            </>
          </Route>

          <Route path='/exams'>
            <>
              <Exams />
              <Footer/>
            </>
          </Route>

          <Route path='/exam/:id'>
            <>
              <Exam />
              <Footer/>
            </>
          </Route>

          <Route path='/area/:id/byExam/:examId'>
            <>
              <AreaByExam />
              <Footer/>
            </>
          </Route>

          <Route path='/area/:id/'>
            <>
              <Area />
              <Footer/>
            </>
          </Route>

          <Route path='/tag/:id/'>
            <>
              <Tag />
              <Footer/>
            </>
          </Route>

          <Route path='/question/:id/resolution'>
            <>
              <Question showResolution/>
              <Footer/>
            </>
          </Route>

          <Route path='/question/:id'>
            <>
              <Question />
              <Footer/>
            </>
          </Route>

          <Route path='/attempt/:id/:questionsListId/:questionId?'>
            <Attempt />
          </Route>

          <Route path='/user/:id/edit'>
            { loading ? 
                <Loading/> :
              currentUser.isLoggedIn? 
                <>
                  <UserEdit />
                  <Footer/>
                </> :
                <Redirect to="/login"/>
            }
          </Route>
          
          <Route path='/user/:id'>
            { loading ? 
              <Loading/>
            : (
              <>
                <User />
                <Footer/>
              </>
            )}
          </Route>

          <Route path='/road/:roadId/unity/:id/:order?'>
            <Unity />
          </Route>

          <Route path='/unity/:id/:order?'>
            <Unity />
          </Route>

          <Route path='/road/:id'>
            <>
              <Road />
              <Footer/>
            </>
          </Route>

          <Route path='/road'>
            <>
              <Roads />
              <Footer/>
            </>
          </Route>

          <Route path='/search/:query'>
            <>
              <Search />
              <Footer/>
            </>
          </Route>

          <Route path='/task/register-schedule/:step?'>
          { loading ? 
              <Loading/> :
              currentUser.isLoggedIn ?
                <RegisterScheduleB /> :
                <Redirect to="/login"/>
          }
          </Route>

          <Route path='/task/:id/:order?'>
            <Task/>
          </Route>

          <Route path='/schedule/:viewType?'>
            { loading ? 
              <Loading/> :
              currentUser.isLoggedIn?
                <ScheduleB />
                :
                <Redirect to="/login"/>
            }
          </Route>

          <Route path='/calendar/:date?'>
            { loading ? 
              <Loading/> :
              currentUser.isLoggedIn?
                <>
                  <Calendar />
                  <Footer/>
                </> :
                <Redirect to="/login"/>
            }
          </Route>

          <Route path='/mission'>
            <>
              <Mission />
              <Footer/>
            </>
          </Route>

          <Route path='/terms'>
            <>
              <Terms />
              <Footer/>
            </>
          </Route>

          <Route path='/about-us'>
            <>
              <AboutUs />
              <Footer/>
            </>
          </Route>

          <Route path='/jobs'>
            <>
              <Jobs />
              <Footer/>
            </>
          </Route>

          <Route path='/method'>
            <>
              <Method />
              <Footer/>
            </>
          </Route>

          <Route path='/email/welcome'>
            <>
              <WelcomeEmail />
              <Footer/>
            </>
          </Route>

          <Route path='/passwordrecover/:token?'>
            <>
              <PasswordRecover />
              <Footer/>
            </>
          </Route>

          <Route path='/lead/:origin'>
            <>
              <LeadRegister />
            </>
          </Route>

          <Route path='/obrigado'>
            <>
              <Obrigado />
              <Footer/>
            </>
          </Route>

          <Route path='/blog/:article' render={(props) => <RedirectComponent url="blog/artigo" {...props} /> }/>

          <Route path='/blog'>
            <RedirectComponent url="https://www.lampejo.app/blog" />
          </Route>

          <Route exact path="/">
            { currentUser.isLoggedIn ?
              <Redirect to="/schedule" /> :
              <Redirect to="/login" />
            }
          </Route>

        </Switch>
      </Router>
    )
  }
}

/**
 * A component to protect routes.
 * Shows Auth page if the user is not authenticated
 */
// const PrivateRoute = ({ component, ...options }) => {

//   const session = getSession()

//   const finalComponent = session ? Dashboard : Home
//   return <Route {...options} component={finalComponent} />
// }
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import styles from './index.module.css'
import Image from 'react-bootstrap/Image'
import Badge from 'react-bootstrap/Badge'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { BsCheckCircle, BsCircle, BsArrowRight, BsLockFill } from 'react-icons/bs'

import { CgChevronRightO, CgTime } from 'react-icons/cg'

const unity = require('../../controllers/unities')
const api = require('../../utils/api')

class TaskWidgetB extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      title: null,
      defaultIcon: null,
      type: '',
      isComplete: false
    }
  }

  async componentDidMount() {
    this.setState({
      loading: true,
      unity: {},
      icon: null,
      title: null,
      type: ''
    })

    const defaultIcon = await require('../../assets/ilustracao_task.svg')
    
    let currentUser = await api.currentUser()
    let type = ''
    if (!this.props.task.unityId && this.props.task.questionsIds) {
      if (this.props.task.questionsIds.lenght > 0) {
        type = 'questions'
      }
    } else if (this.props.task.unityId) {
      type = 'content'
    }

    if (this.props.task.unityId != null) {
      const reqUnity = await unity.get(this.props.task.unityId, currentUser.id)
      if (reqUnity.ok) {
        const getUnity = reqUnity.body.unity
      
        if (getUnity != null) {
          this.setState({
            unity: getUnity,
            icon: getUnity.icon,
            title: getUnity.title,
            type: 'content',
            loading: false
          })
        }
      } else {
        this.setState({
          error: 'Houve um problema...',
          type: 'content'
        })
      }
    } else {
      this.setState({
        icon: this.props.task.icon,
        type,
        loading: false,
        defaultIcon
      })
    }
  }

  async componentWillReceiveProps(nextProps) {
    let currentUser = await api.currentUser()

    if (nextProps.task) {
      this.setState({
        loading: true,
        unity: {},
        icon: null,
        title: null,
        type: ''
      })
      if (nextProps.task.unityId != null) {
        const reqUnity = await unity.get(nextProps.task.unityId, currentUser.id)
        if (reqUnity.ok) {
          const getUnity = reqUnity.body.unity
        
          if (getUnity != null) {
            this.setState({
              unity: getUnity,
              icon: getUnity.icon,
              title: getUnity.title,
              type: 'content',
              loading: false
            })
          }
        }
      } else {
        let type = null
        if (nextProps.task.questionsIds) {
          if (nextProps.task.questionsIds.length > 0) {
            type = 'questions'
          } else {
            type = ''
          }
        } else {
          type = ''
        }
        
        this.setState({
          icon: nextProps.task.icon || null,
          type,
          loading: false
        })
      }
    }
  }

  completeTask() {
    this.setState({
      isComplete: true
    })
    if (this.props.callback) {
      this.props.callback()
    }
  }

  render() { 
    var { type, defaultIcon, loading, isComplete } = this.state
    var { task, actual, md } = this.props

    const notAvailable = task.questionsIds? task.questionsIds.length == 0 && !task.unityId : false
    return (
      <Col sm={12} md={md}>
        <Card className={`${styles.borderRadius15} p-1 mt-4 ${styles.clickableCard}`}
          style={{ backgroundColor: task.subject ? `#${task.subject.color}` : '#f1f1f1', opacity: isComplete? 0.6: 1, animation: 'opacity 5s linear' }}
          onClick={() => { this.completeTask() }}
          >
          <Row style={{ minHeight: "62px" }}>
            <Col className={styles.toBeCompleted} >
              <Image src={require('../../assets/not_completed_task.svg')} size={"30px"} className={styles.buttonIcon} style={{cursor: 'pointer'}}/>
              { isComplete?
              <>
                <style>
                  {
                  `.animated-check {
                    top: -12px;
                    left: 20px;
                    position: absolute;
                    height: 3em;
                    width: 3em;
                  }

                  .animated-check path {
                    fill: none;
                    stroke: #c7ef00;
                    -webkit-filter: drop-shadow( 0 0 1px #c7ef00);
                    stroke-width: 2px;
                    stroke-dasharray: 23;
                    stroke-dashoffset: 23;
                    animation: draw 250ms linear forwards;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }

                  @keyframes draw {
                    to {
                      stroke-dashoffset: 0;
                    }
                  }
                  @keyframes strike{
                    0%   { width : 0; }
                    100% { width: 100%; }
                  }
                  .strike {
                    position: relative;
                  }
                  .strike::after {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    max-width: 50vw;
                    height: 2px;
                    -webkit-filter: drop-shadow( 0 0 1px #c7ef00);
                    background: #c7ef00;
                    animation-name: strike;
                    animation-duration: 250ms;
                    animation-timing-function: linear;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                  }`}
                </style>
                <svg class="animated-check" viewBox="0 0 24 24">
                  <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none"/>
                </svg>
              </> :  <></>}
            </Col>
            <Col className={`d-flex align-items-center pl-0`}>
              { task.title ? (
                <Card.Title className={`mb-0 strike`} style={{ padding: '15px 0', textOverflow: 'clip', height: '54px', position: 'absolute', whiteSpace: 'nowrap' }}><strong>{task.subject? task.subject.title? `${task.subject.title} - `: '' : '' }{task.title}</strong></Card.Title>
              ) : (
                <Card.Title><strong><Skeleton width={200}/></strong></Card.Title>
              ) }
            </Col>
          </Row>
        </Card>
      </Col>
    )
  }
}

export default withRouter(TaskWidgetB)
import React, { Component } from 'react'
import {
  Link,
  withRouter
} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import styles from './index.module.css'
import Badge from 'react-bootstrap/Badge'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { CgChevronRightO, CgFileDocument } from 'react-icons/cg'

const resolutions = require('../../controllers/resolutions')
const api = require('../../utils/api')
const exams = require('../../controllers/exams')

class QuestionWidget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      exam: { title: '' }
    }
  }

  async componentDidMount() {
    const request = await exams.getById(this.props.question.examId  )
    if (request.ok) {
      let reqExam = request.body.exam
      let getExam = null
      if (reqExam) {
        getExam = { title: reqExam.name + ' - ' + reqExam.period, id: reqExam.id }
      }
      
      this.setState({
        exam: getExam,
        loading: false
      })
    }
  }

  renderTags(tag) {
    if (tag) {
      return (
        <Badge pill variant="light" className={`mx-2`} as="a" href={`/tag/${tag.id}`}>
          {tag.title}
        </Badge>
      )
    }
  }

  renderAreas(area) {
    if (area) {
      return (
        <Badge variant="dark" styles={{ color: "white" }}className={`mx-2`} as="a" href={`/area/${area.id}`}>
          {area.title}
        </Badge>
      )
    }
  }

  resume(content) {
    if (this.props.isResume) {
      return content.replace(/<[^>]*>?/gm, '').substring(0,100) + '...'
    } else {
      return content
    }
  }

  render() { 
    var { loading, exam } = this.state
    var { question, md } = this.props
    return (
      <>
        <Col sm={12} md={md}>
          <Card className={`${styles.bgWhite} ${styles.borderRadius25} p-5 mt-4 ${styles.clickableCard}`} as="a" href={`/question/${question.id}`}>
            <Row>
              <Col sm={10}>
                { loading ? 
                <Card.Title><Skeleton/></Card.Title>
                :
                <Row>
                  <Col sm={5}>
                    <Card.Title><strong>{question.title}</strong></Card.Title>
                  </Col>
                  <Col className={`${styles.toRight}`} sm={7}>
                    { exam ? (<Button variant="link" className={`${styles.secondaryLink}`} href={`/exam/${exam.id}`}><CgFileDocument/> {exam.title}</Button>) : (<></>) }
                  </Col>
                </Row>
                }
                <Card.Text>
                  { loading ? (
                    <></>
                  ) : (
                    <>
                      <span>{question.areas? question.areas.map(area => this.renderAreas(area)): ''}</span>
                      <span>{question.tags? question.tags.map(tag => this.renderTags(tag)): ''}</span><br/><br/>
                    </>
                  ) }
                  { loading ? 
                  <Skeleton width={30}/>
                  :
                  <div dangerouslySetInnerHTML={{ __html: this.resume(question.content) }} />
                  }
                </Card.Text>
              </Col>
              <Col sm={2} style={{ verticalAlign: "middle", margin: "auto" }}>
                <CgChevronRightO size={"30px"} className={styles.buttonIcon}/>
              </Col>
            </Row>
          </Card>
        </Col>
    </>
    )
  }
}

export default withRouter(QuestionWidget)
import { requestApi } from '../utils'

export const getWeekStreak = async (authToken) => {
  const response = await requestApi('/streakEvents/week', 'GET', null, { Authorization: `Bearer ${authToken}`})
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}

export const getActualStreak = async (authToken) => {
  const response = await requestApi('/streakEvents/actual', 'GET', null, { Authorization: `Bearer ${authToken}`})
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}
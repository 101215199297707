import React, { Component }  from 'react'
import {
  withRouter,
  // Link,
} from 'react-router-dom'

import Question from '../../components/Question'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
// import Card from 'react-bootstrap/Card'
// import Badge from 'react-bootstrap/Badge'
// import Skeleton from 'react-loading-skeleton'
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
// import Tooltip from 'react-bootstrap/Tooltip'
// import UserArea from '../../components/UserArea'
// import SearchBar from '../../components/SearchBar'
// import { MdTimer, MdChevronRight } from 'react-icons/md'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

import { secondsToHm } from '../../utils/converter'

// const exams = require('../../controllers/exams')
const api = require('../../utils/api')
const questionsListsCtrl = require('../../controllers/questionsLists')
const attempts = require('../../controllers/attempts')
const questions = require('../../controllers/questions')

class Attempt extends Component {
  constructor(props) {
    super(props)

    this.state = {
      question: { title: '' },
      exam: { id: '', name: '', pediod: ''},
      currentUser: { can: [] },
      loading: true,
      showResolution: false,
      showTutorial: false,
      questionsList: [],
      isBlindList: '',
      isList: '',
      questionsListId: '',
      questionsList: { questionsIds: [] },
      attemptId: '',
      progress: '',
      question: '',
      answers: [],
      showFinishModalBlindList: false,
      showModalTutorialBlindTest: false,
      rightAnswers: []
    }

    this.toNextQuestion = this.toNextQuestion.bind(this)
  }

  async componentDidMount() {
    let CurrentUser = await api.currentUser()

    const attemptId = this.props.match.params.id
    const questionsListId = this.props.match.params.questionsListId
    const reqQuestionsList = await questionsListsCtrl.get(questionsListId, CurrentUser.id)
    if (reqQuestionsList.ok) {
      const getQuestionsList = reqQuestionsList.body.content
      
      let isBlindList = false
      let isList = false
      if (getQuestionsList.subtype == 'blind') {
        isBlindList = true
      }
      if (getQuestionsList.subtype == 'list') {
        isList = true
      }

      let questionId = ''
      if (this.props.match.params.questionId) {
        questionId = this.props.match.params.questionId
      } else {
        this.openTutorial(getQuestionsList.subtype)
        questionId = getQuestionsList.questionsIds[0]
      }

      let progress = (getQuestionsList.questionsIds.indexOf(questionId) + 1) * 100 / getQuestionsList.questionsIds.length
      const reqQuestion = await questions.get(questionId, CurrentUser.id)
      const getQuestion = reqQuestion.body.question

      this.setState({
        isBlindList: isBlindList,
        isList: isList,
        questionsListId: questionsListId,
        questionsList: getQuestionsList,
        attemptId: attemptId,
        progress: progress,
        question: getQuestion
      })
    }
    
    this.setState({
      currentUser: CurrentUser
    })

    AOS.init({
      duration: 300
    })
  }

  openTutorial(type) {
    if (type === 'blind') {
      this.setState({
        showModalTutorialBlindTest: true
      })
    } else {
      this.setState({
        showModalTutorial: true
      })
    }
  }

  closeTutorial() {
    this.setState({
      showModalTutorialBlindTest: false,
      showModalTutorial: false
    })
  }

  openResolution() {
    return true
  }

  async toNextQuestion() {
    const { questionsList, question, questionsListId } = this.state
    const nextQuestionId = questionsList.questionsIds[questionsList.questionsIds.indexOf(question.id) + 1]
   
    if (nextQuestionId) {
      window.location.replace(`/attempt/${this.state.attemptId}/${questionsListId}/${nextQuestionId}`)
    } else {
      let request = await attempts.getById(this.state.attemptId)
      if (request.ok) {
        const getAnswers = request.body.response.answers
        this.setState({
          answers: getAnswers,
          rightAnswers: getAnswers.filter((answer) => { return answer.isCorrect })
        })
      }
      attempts.updateStatus({ id: this.state.attemptId, status: 'complete' })
      this.showFinishModal()
    }
  }

  showFinishModal() {
    if (this.state.isBlindList) {
      this.setState({
        showFinishModalBlindList: true
      })
    } else {
      this.setState({
        showFinishModal: true
      })
    }
  }


  render() {
    const { question, rightAnswers, progress, loading, currentUser, isBlindList, isList, showModalTutorialBlindTest, showFinishModal, showFinishModalBlindList, attemptId } = this.state
    return (
      <>
        <Container className={`${styles.container} pt-4`}>
          <Row className={`justify-content-md-center`}>
            <style type="text/css">
              {`
                .bg-custom {
                  background-color: #c7ef00;
                  background-image: linear-gradient(45deg,rgba(0,0,0,.1) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.1) 50%,rgba(0,0,0,.1) 75%,transparent 75%,transparent);
                }
              `}
            </style>
            <Col sm={12} md={6} className={`${styles.toRight}`}>
              <ProgressBar animated className={styles.progressBar} now={progress} variant="custom" />
            </Col>
          </Row>
          <Row>
            <Question md={{offset: 3, span: 6}} question={question} toNextQuestion={this.toNextQuestion} isBlindList={isBlindList} isList={isList} currentUser={currentUser} attemptId={attemptId}></Question>
          </Row>
        </Container>
        <Modal
          bsPrefix={'tutorialModal modal'}
          show={showModalTutorialBlindTest}
          backdrop={"static"}
          keyboard={false}
          centered
        >
          <Modal.Title style={{ fontWeight: "bolder", textAlign: "center", fontSize: "35px", color: '#292f36', paddingTop: "30px"}}>
            Instruções
          </Modal.Title>
          <Modal.Body
            style={{
              padding: "40px",
              justifyContent: "center",
              textAlign: "left",
              color: "#292f36",
              backgroundColor: "white",
              border: 0,
              borderRadius: 0
              }}>
            <p>_ você irá realizar um simulado de uma prova;</p>
            <p>_ esse simulado tem a duração de {secondsToHm(this.state.questionsList.maxTime)};</p>
            <p>_ encontre um local calmo, sem distrações;</p>
            <p>_ coloque o celular em modo "Não perturbe";</p>
            <p>_ tenha um lápis e algumas folhas para utilizar de rascunho.</p>
            <Button className={`${styles.primaryButton} mx-auto d-flex`} variant="link" onClick={() => this.closeTutorial()}>vamos lá!</Button><br/>
          </Modal.Body>
        </Modal>
        <Modal
          bsPrefix={'modalRigthAnswer modal'}
          className={`${styles.rightModal}`}
          show={showFinishModalBlindList}
          backdrop={"static"}
          keyboard={false}
          centered
        >
          <Modal.Header
            style={{
              paddingTop: "50px",
              justifyContent: "center",
              color: "#292f36",
              backgroundColor: "white",
              border: 0,
              borderRadius: 0,
            }}
          >
            <Modal.Title style={{ fontWeight: "bolder", textAlign: "center", fontSize: "35px"}}>
              <Image style={{
                maxWidth: "110px",
                display: "flex",
                margin: "0 auto 30px auto"
              }} src={require("../../assets/confete.svg")}></Image>
              Parabéns por finalizar o simulado!</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              padding: "40px",
              justifyContent: "center",
              textAlign: "center",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 0,
              border: 0
              }}>
           
            <p><strong>seu resultado:</strong></p><p><span className={styles.result}>{rightAnswers.length}</span><span> / {this.state.questionsList.questionsIds.length}</span></p><br/>
            <Button className={`${styles.primaryButton}`} variant="link" href={`/exam/${this.state.questionsList.examId}`}>voltar para a prova</Button><br/>
          </Modal.Body>
        </Modal>
        <Modal
          bsPrefix={'modalRigthAnswer modal'}
          className={`${styles.rightModal}`}
          show={showFinishModal}
          backdrop={"static"}
          keyboard={false}
          centered
        >
          <Modal.Header
            style={{
              paddingTop: "50px",
              justifyContent: "center",
              color: "#292f36",
              backgroundColor: "white",
              border: 0,
              borderRadius: 0,
            }}
          >
            <Modal.Title style={{ fontWeight: "bolder", textAlign: "center", fontSize: "35px"}}>
              <Image style={{
                maxWidth: "110px",
                display: "flex",
                margin: "0 auto 30px auto"
              }} src={require("../../assets/confete.svg")}></Image>
              Parabéns por finalizar a lista de exercícios!</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              padding: "40px",
              justifyContent: "center",
              textAlign: "center",
              color: "#292f36",
              backgroundColor: "white",
              borderRadius: 0,
              border: 0
              }}>
            <Button className={`${styles.primaryButton}`} variant="link" href={`/exam/${this.state.questionsList.examId}`}>voltar</Button><br/>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default withRouter(Attempt)
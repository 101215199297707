import React, { Component }  from 'react'
import { withRouter } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Loader from 'react-loader-spinner'
import Road from '../../components/Road'
import styles from './index.module.css'
import AOS from 'aos'
import { toast } from 'react-toastify'
import 'aos/dist/aos.css'
import LoginModal from '../../components/LoginModal'

const api = require('../../utils/api')
const roads = require('../../controllers/roads')

class RoadPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      width: window.innerWidth,
      currentUser: { can: [] },
      roadIcon: '',
      roads: [],
      road: { levels: [] },
      showRegisterOrLoginModal: false,
      state: 'login',
      loading: false
    }

    this.handleFormInput = this.handleFormInput.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  async componentDidMount() {
    AOS.init({duration: 300})

    const id = this.props.match.params.id
    const currentUser = await api.currentUser()
    const request = await roads.get(id, currentUser.id || null)

    let getRoad = {}
    if (request.ok) {
      getRoad = request.body.road
    }

    if (currentUser.isLoggedIn) {
      api.visitRegister(currentUser, { type: 'page', to: `/road/${id}` })
    } else {
      this.setState({
        showRegisterOrLoginModal: true
      })

      api.userRegister(null, { type: 'page', to: `/road/${id}` })
    }

    this.setState({
      road: getRoad
    })

    if (currentUser) {
      this.setState({
        currentUser
      })
    }
  }

  closeLogin() {
    this.setState({
      showRegisterOrLoginModal: false
    })
  }

  /**
   * Handle text changes within form fields
   */
  handleFormInput(field, value) {
    value = value.trim()

    const nextState = {}
    nextState[field] = value

    this.setState(Object.assign(this.state, nextState))
  }

  /**
   * Handles form submission
   * @param {object} evt 
   */
  async handleFormSubmit(evt) {
    evt.preventDefault()

    this.setState({ loading: true })

    // Validate email
    if (!this.state.formEmail) {
      return this.setState({
        loading: false,
        formError: 'email é obrigatório'
      })
    }

    // Validate password
    if (!this.state.formPassword) {
      return this.setState({
        loading: false,
        formError: 'senha é obrigatória'
      })
    }

    let response = await api.userLogin({ "email": this.state.formEmail, "password": this.state.formPassword })
    if (!response.ok) {
      this.setState({
        formError: response.error,
        loading: false
      })
    } else {
      this.setState({
        loding: false
      })
      this.closeLogin()
      toast.success('Logado com sucesso!')
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  }

  render() {
    const { showRegisterOrLoginModal } = this.state
    return (
      <>
        <LoginModal show={showRegisterOrLoginModal} callback={() => { window.location.reload(false) }} closeLogin={() => this.closeLogin() }/>
        <Container className={styles.homeContainer}>
          <Row sm={12} className={`${styles.justifyContentCenter} ${styles.pt3}`}>
            { this.state.road.levels.length > 0?
              <Road road={this.state.road}/>:
              <Loader type="TailSpin" color="#c7ef00" height={100} width={100} timeout={10000} />
            }
          </Row>
        </Container>
      </>
    )
  }
}

export default withRouter(RoadPage)

/**
 * Utils: Back-end
 */
const { domains } = require('../config.js')

/**
 * List users
 */
export const get = async () => {
  const response = await requestApi('/users')
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}

/**
 * Get user by id
 */
export const getById = async (id, authToken) => {
  const response = await requestApi('/users/' + id, 'GET', null, { Authorization: `Bearer ${authToken}`})
  
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}

/**
 * API request to call the backend
 */
const requestApi = async (path = '', method = 'GET', data = null, headers = {}) => {

  // Check if API URL has been set
  if (!domains().api) {
    throw new Error(`Error: Missing API Domain – Please add the API domain from your serverless Express.js back-end to this front-end application.  You can do this in the "./config.js" file.  Instructions are listed there and in the documentation.`)
  }

  // Prepare URL
  if (!path.startsWith('/')) {
    path = `/${path}`
  }
  const url = `${domains().api}${path}`

  // Set headers
  headers = Object.assign(
    { 'Content-Type': 'application/json' },
    headers
  )

  // Default options are marked with *
  const response = await fetch(url, {
    method: method.toUpperCase(),
    mode: 'cors',
    cache: 'no-cache',
    headers: headers,
    body: data ? JSON.stringify(data) : null
  })

  if (response.status < 200 || response.status >= 300) {
    let res = await response.json()
    res.status = response.status
    return await res
  }

  return {
    status: 200,
    ok: true,
    body: await response.json()
  }
}
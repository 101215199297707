import React, { Component }  from 'react'
import { withRouter } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import styles from './index.module.css'
import AdSense from 'react-adsense'

class GoogleAdsWidget extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Card className={`${styles.bgDark} mx-auto py-2`}>
        <AdSense.Google
          client='ca-pub-3489479913967923'
          slot='1309917200'
          style={{ display: 'block' }}
          // style={{ width: 300, height: 600, margin: 'auto' }}
          format='fluid'
        />
      </Card>
    )
  }
}

export default withRouter(GoogleAdsWidget)
import React, { Component }  from 'react'
import {
  withRouter
} from 'react-router-dom'

import { FaKey, FaRegEnvelope } from 'react-icons/fa'
import { Button } from '../../lampejo-styles/components/Button/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

const api = require('../../utils/api')
const auth = require('../../utils/auth')

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      currentUser: { can: [] },
      error: null,
      formEmail: '',
      formPassword: '',
      currentUser: {
        isLoggedIn: false,
        dataLetters: '',
        name: '',
        scholarity: '',
        intendedCourse: ''
      },
      state: 'email'
    }
    
    this.handleFormInput = this.handleFormInput.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  async componentDidMount() {
    const token = this.props.match.params.token
    if (token) {
      this.setState({
        token,
        state: 'password'
      })
    }

    AOS.init({
      duration: 300
    })
  }

  handleFormTypeChange(type) {
    this.setState({
      state: type
    })
  }

  handleFormInput(field, value) {
    const nextState = {}
    nextState[field] = value

    this.setState(Object.assign(this.state, nextState))
  }

  async handleFormSubmit(evt) {
    this.setState({ isLoading: true })
    evt.preventDefault()

    if (this.state.state == 'email') {
      const response = await api.userPasswordRecover({ "email": this.state.formEmail }) 
      this.setState({
        formError: response.body.message,
        isLoading: false
      })
    } else if (this.state.state == 'password') {
      if (this.state.formPassword != this.state.formConfirmPassword) {
        return this.setState({
          isLoading: false,
          formError: 'Senha e a confirmação de senha são diferentes. Por favor, insira novamente.'
        })
      } else {
        const response = await api.userEditPassword({ "password": this.state.formPassword, "token": this.state.token }) 
        let message
        if (response.ok) {
          message = response.body.message
          if (message == 'Usuário atualizado!') {
            message += '\nVocê será redirecionado em instantes...'
            
            auth.saveToken(response.body.token)
            setTimeout(() => {
              window.location.replace('/schedule')
            }, 5000)
          }
        } else {
          message = 'Algo não ocorreu como o esperado. Por favor, realize a recuperação de senha novamente.'
        }
        
        this.setState({
          formError: message,
          isLoading: false
        })
      }
    }
  }

  render() {
    const { state, isLoading } = this.state
    return (
      <Col sm={12} md={{ span: 4, offset: 4 }} className={`${styles.login} pt-5`} style={{ marginTop: "80px" }}>
        <div className={styles.containerRegister}>
          { state == 'email' ? (
            <>
              <h2>Esqueci a Senha</h2>
              <div className={`${styles.card}`}>
                <div className={styles.containerSignIn}>
                  <Form className={styles.form} onSubmit={this.handleFormSubmit}>
                    <Form.Group className={styles.formField}>
                      <Form.Label className={styles.formLabel}><FaRegEnvelope style={{marginRight: '0.5em'}}/>email</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='seu-email@exemplo.com'
                        className={styles.formInput}
                        value={this.state.formEmail}
                        onChange={(e) => { this.handleFormInput('formEmail', e.target.value) }}
                      />
                    </Form.Group>

                    {this.state.formError && (
                      <div className={styles.formError}>{this.state.formError}<br/><br/></div>
                    )}

                    <Button isLoading={isLoading} centered label="recuperar senha" type="submit" value='Sign In'/>
                  </Form>
                </div>
              </div>
            </>
          ):(
            <>
              <h2>Defina sua nova senha</h2>
              <div className={`${styles.card}`}>
                <div className={styles.containerSignIn}>
                  <Form className={styles.form} onSubmit={this.handleFormSubmit}>
                    <Form.Group className={styles.formField}>
                      <Form.Label className={styles.formLabel}><FaKey style={{marginRight: '0.5em'}}/>senha</Form.Label>
                      <Form.Control
                        type='password'
                        placeholder='sua senha'
                        className={styles.formInput}
                        value={this.state.formPassword}
                        onChange={(e) => { this.handleFormInput('formPassword', e.target.value) }}
                      />
                    </Form.Group>
                    <Form.Group className={styles.formField}>
                      <Form.Label className={styles.formLabel}><FaKey style={{marginRight: '0.5em'}}/>confirme sua senha</Form.Label>
                      <Form.Control
                        type='password'
                        placeholder='pra garantir que esteja certa...'
                        className={styles.formInput}
                        value={this.state.formConfirmPassword}
                        onChange={(e) => { this.handleFormInput('formConfirmPassword', e.target.value) }}
                      />
                    </Form.Group>

                    {this.state.formError && (
                      <div className={styles.formError}>{this.state.formError}<br/><br/></div>
                    )}

                    <Button isLoading={isLoading} centered label="recuperar senha" type="submit" value='Sign In'/>
                  </Form>
                </div>
              </div>
            </>
          )}
        </div>
      </Col>
    )
  }
}

export default withRouter(Login)
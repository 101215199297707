import React, { Component }  from 'react'
import {
  withRouter,
  // Link,
} from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import ReactPixel from 'react-facebook-pixel'
import Container from 'react-bootstrap/Container'
import Breadcrumb from '../../components/Breadcrumb'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import Skeleton from 'react-loading-skeleton'
import Loader from 'react-loader-spinner'
import Dropdown from 'react-bootstrap/Dropdown'
import { MdChevronRight, MdTimer } from 'react-icons/md'
import { CgChevronRightO } from 'react-icons/cg'
import { BsEye } from 'react-icons/bs'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import AreaWidget from '../../components/AreaWidget'
import QuestionWidget from '../../components/QuestionWidget'


import { secondsToHm } from '../../utils/converter'

const exams = require('../../controllers/exams')
const tags = require('../../controllers/tags')
const questionsListsCtrl = require('../../controllers/questionsLists')
const questionsCtrl = require('../../controllers/questions')
const attempts = require('../../controllers/attempts')
const api = require('../../utils/api')
const auth = require('../../utils/auth')

class Tag extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tag: { title: '', questionsLists: [], questions: [] },
      currentUser: { can: [] },
      numTags: 10,
      loading: true,
      loadingUpdate: false
    }

    this.runSimulation = this.runSimulation.bind(this)
  }

  async componentDidMount() {
    const id = this.props.match.params.id
    const CurrentUser = await api.currentUser()
    const request = await tags.getById(id, CurrentUser.id || '')
    let getTag = {}
    if (request.ok) {
      getTag = request.body.cluster

      const reqQuestionsLists = await questionsListsCtrl.getByTag(id)
      if (reqQuestionsLists.ok) {
        getTag.questionsLists = reqQuestionsLists.body.content
      }

      const reqQuestions = await questionsCtrl.getByTag(id)
      if (reqQuestions.ok) {
        getTag.questions = reqQuestions.body.questions
      }

      this.setState({
        id: id,
        tag: getTag,
        loading: false
      })
    }

    if (CurrentUser) {
      this.setState({
        currentUser: CurrentUser
      })
    }

    AOS.init({
      duration: 300
    })
    
    ReactPixel.init('312214643404112')
    ReactPixel.pageView()
  }

  renderQuestionWidgetSkeleton() {
    let arrayWith10Elements = ['','','','','','','','','','']
    return arrayWith10Elements.map(() => {
      return (
        <div className={styles.questionWidget} data-aos="fade-up">
          <span><Skeleton width={200} height={16}/></span>
          <Button className={`${styles.roundButton}`}><MdChevronRight/></Button>
        </div>
      )
    })
  }

  renderTags(tag) {
    if (tag) {
      return (
        <Badge pill variant="light" className={`mx-2 ${styles.textDecorationNone}`} as="a" href={`/tag/${tag.id}`}>
          {tag.title}
        </Badge>
      )
    }
  }

  renderQuestionWidget(question) { 
    return (
      <QuestionWidget question={question} isResume={true} md={6}/>
    )
  }

  renderQuestionsListsWidget(questionsList) {
    return (
      <Col sm={12} md={4}>
        <Card className={`${styles.bgWhite} ${styles.borderRadius25} ${styles.toCenter} p-5 mt-3 w-100`} data-aos="fade-up">
          <Card.Title><strong>{questionsList.title}</strong></Card.Title>
          { !questionsList.questionsIds ?
          <Card.Title><Skeleton width={30}/> questões</Card.Title>
          :
          <>
            <Card.Text><MdTimer/> tempo: <strong>{secondsToHm(questionsList.maxTime)}</strong></Card.Text>
            <Card.Title>{questionsList.questionsIds.length} questões</Card.Title>
          </>
          }
          <Card.Text className={styles.dFlex}><Button variant="link" className={`${styles.primaryButton} ${styles.toBottomCenter}`} onClick={() => { this.runSimulation(questionsList.id)}}>iniciar</Button></Card.Text>
        </Card>
      </Col>
    )
  }

  async runSimulation(id) {
    const request = await attempts.register({
      userId: this.state.currentUser.id || null,
      questionsListId: id
    })

    this.props.history.push(`/attempt/${request.body.id}/${id}`)
  }

  render() {
    const { tag, exam, loading, currentUser, loadingUpdate, numTags } = this.state
    const meta = {
      title: `${tag.title} | lampejo.app - Plataforma gratuita para o ENEM` ,
      description: `Somos uma comunidade de estudantes e professores que valorizam o compartilhamento do conhecimento. Você vai aprender praticando e ajudando os outros alunos.`,
      meta: {
        charset: 'utf-8',
        lang: 'pt-br',
        amp: true,
        name: {
          keywords: 'educação, vestibular, enem, questões, provas'
        }
      }
    }
    return (
      <Container className={styles.container}>
        <DocumentMeta {...meta}/>
        <Breadcrumb links={[
          { label: tag.title, active: true }
        ]}/>
        <Row className="justify-content-md-center">
          <Col sm={{ span: 7 }} className={'pt-5'}>
            { loading ? 
            <h2><Skeleton width={400}/></h2>
            :
            <h2>{tag.title}</h2>
            }
          </Col>
          <Col sm={3} className={`pt-5 ${styles.toRight}`}>
          { currentUser.can.includes('see-admin-dashboard') ?
            <></>
            // <Dropdown>
            //   <Dropdown.Toggle variant="link" className={styles.primaryButton} id="dropdown-basic">
            //     área admin
            //   </Dropdown.Toggle>

            //   <Dropdown.Menu>
            //     <Dropdown.Item href={`/exam/${exam.id}/edit`}>editar prova</Dropdown.Item>
            //     <Dropdown.Item href={`/questions/byExam/${exam.id}`}>ver questões</Dropdown.Item>
            //     <Dropdown.Item href={`/question/edit/${exam.id}`}>adicionar questão</Dropdown.Item>
            //     <Dropdown.Item href={`/questionsLists/byExam/${exam.id}`}>ver listas/simulados</Dropdown.Item>
            //     <Dropdown.Item onClick={() => { this.updateExam() }}>
            //       { loadingUpdate?
            //         <Loader type="TailSpin" color="#292F36" height={20} width={20} timeout={10000} />
            //         : (<>atualizar prova</>)
            //       }
            //     </Dropdown.Item>  
            //     <Dropdown.Item href={`/questionsLists/edit/${exam.id}`}>adicionar simulado/lista</Dropdown.Item>
            //   </Dropdown.Menu>
            // </Dropdown>
            : <></>}
          </Col>
        </Row>
        <Row className={'pt-5'}>
          <Col sm={12} md={{ span: 10, offset: 1 }}>
          { loading ? (
            <>
              {/* {this.renderQuestionWidgetSkeleton()} */}
            </>
            ) :
              tag.questionsLists ? (
                <>
                  <Row>
                    <Col><h3>Listas de Exercícios</h3><br/></Col>
                  </Row>
                  <Row>
                  { tag.questionsLists.length > 0 ?
                    tag.questionsLists.map(questionsList => this.renderQuestionsListsWidget(questionsList)) :
                    <Col><p>ops... parece que não tem nenhuma lista disponível :( mas estamos trabalhando nisso!</p></Col>
                  }
                  </Row>
                </>
              ) : (<></>)
            }
          </Col>
        </Row>
        <Row className={'pt-5'}>
          <Col sm={12} md={{ span: 10, offset: 1 }}>
          { loading ? (
            <>
              {/* {this.renderQuestionWidgetSkeleton()} */}
            </>
            ) :
              tag.questions ? (
                <>
                  <Row>
                    <Col><h3>Questões</h3><br/></Col>
                  </Row>
                  <Row>
                    {tag.questions.map(question => this.renderQuestionWidget(question))}
                  </Row>
                </>
              ) : (<></>)
            }
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(Tag)
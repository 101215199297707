import { css } from '@stitches/core';

const buttonStyle = css({
  flexDirection: 'row',
  fontFamily: 'nunito, sans-serif',
  fontWeight: 'bolder',
  transition: 'all 200ms',
  
  variants: {
    size: {
      sm: {
        fontSize: '10px',
        padding: '10px',
        borderRadius: '20px',
      },
      md: {
        fontSize: '16px',
        padding: '10px 24px',
        borderRadius: '24px',
      },
      lg: {
        fontSize: '16px',
        padding: '24px',
        borderRadius: '50px',
      }
    },

    variant: {
      primary: {
        color: '#292F36',
        backgroundColor: '#c7ef00',
        border: 'solid 1px #c7ef00',
        textDecorationColor: '#292F36',
        textDecoration: 'underline',
        '&:hover': {
          boxShadow: '0px 0px 10px #C7EF00'
        }
      },
      secondary: {
        backgroundColor: 'transparent',
        color: '#ffffffdd',
        border: 'solid 1px #c7ef00',
        textDecorationLine: 'underline',
        textDecorationColor: '#C7EF00',
        '&:hover': {
          backgroundColor: '#c7ef00',
          color: '#292F36',
        }
      },
      tertiary: {
        backgroundColor: 'transparent',
        color: '#ffffffdd',
        border: 'solid 1px transparent',
        textDecorationLine: 'underline',
        textDecorationColor: '#C7EF00',
        '&:hover': {
          color: '#fff',
          border: 'solid 1px #c7ef00',
        }
      },
      danger: {
        border: 'solid 1px #FF4823',
        color: '#FF4823',
        borderColor: '#FF4823',
        textDecorationColor: '#FF4823',
        textDecoration: 'underline',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: '#FF482340'
        }
      },
      google: {
        border: 'solid 1px rgb(234, 67, 53)',
        color: '#fff',
        borderColor: 'rgb(234, 67, 53)',
        // textDecorationColor: 'rgb(234, 67, 53)',
        // textDecoration: 'underline',
        backgroundColor: 'rgb(234, 67, 53)',
        '&:hover': {
          backgroundColor: 'rgba(234, 67, 53, 80)'
        }
      },
      facebook: {
        border: 'solid 1px #1877f2',
        color: '#fff',
        borderColor: '#1877f2',
        // textDecorationColor: '#FF4823',
        // textDecoration: 'underline',
        backgroundColor: '#1877f2',
        '&:hover': {
          backgroundColor: '#1877f2dd'
        }
      },
    },
    full: {
      'true': {
        display: 'flex',
        textAlign: 'center',
        width: '100%'
      },
      'false': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
      }
    },
    disabled: {
      'true': {
        opacity: 0.6,
        cursor: 'not-allowed',
      },
      'false': {
        opacity: 1,
        cursor: 'pointer',
      }
    },

    centered: {
      'true': {
        margin: '0 auto',
      },
      'false': {}
    },

    marginTop: {
      sm: {
        marignTop: 10
      },
      md: {
        marginTop: 24
      },
      lg: {
        marginTop: 40
      }
    }
  }
});

const buttonTextStyle = css({
  flex: 1,
  variants: {
    isLoading: {
      'true': {
        display: 'none',
      },
      'false': {
        display: 'initial'
      }
    }
  }
});

const buttonIconStyle = css({
  width: 'min-content',  
  paddingTop: 3,
  paddingLeft: 10,
  paddingRight: 0,
  marginRight: 0,
  marginBottom: -3,
  variants: {
    isLoading: {
      'true': {
        display: 'none'
      },
      'false': {
        display: 'inherit'
      }
    }
  }
});

const buttonLeftIconStyle = css({
  width: 'min-content',  
  paddingTop: 3,
  paddingRight: 10,
  paddingLeft: 0,
  marginBottom: -3,
  variants: {
    isLoading: {
      'true': {
        display: 'none'
      },
      'false': {
        display: 'inherit'
      }
    }
  }
});


const buttonLoadingIcon = css({
  minWidth: '100%',
  position: 'relative',
});

export { buttonStyle, buttonIconStyle, buttonTextStyle, buttonLoadingIcon, buttonLeftIconStyle }

/**
 * Utils: Back-end
 */
const { domains } = require('../config.js')
// const auth = require('../utils/auth')

/**
 * Register a new exan
 */
export const register = async (task, authToken) => {  
  if (!authToken) {
    return { status: "401", error: "Não autorizado. Necessário token de autenticação." }
  }
  
  const response = await requestApi('/tasks/register', 'POST', task, { Authorization: `Bearer ${authToken}`})
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response
  }
  return response
}

/**
 * Register a new exan
 */

/* Get a Question
 */
export const get = async (id, userId = '') => {
  let response
  if (userId) {
    response = await requestApi('/tasks/' + id, 'GET', null, { uid: userId })
  } else {
    response = await requestApi('/tasks/' + id)
  }
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}

export const numTodaysTask = async (authToken) => {
  if (!authToken) {
    return { status: "401", error: "Não autorizado. Necessário token de autenticação." }
  }

  let response = await requestApi('/numFinishedTasksToday/', 'GET', null, { Authorization: `Bearer ${authToken}`})
  
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}
/**
 * Get a Question
 */
 export const search = async (query, authToken) => {
  const response = await requestApi('/tasks/search/' + query, 'GET', null, { Authorization: `Bearer ${authToken}`})
  
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}
/**
 * Get a Question
 */
// export const getByUnityId = async (id, userId = '') => {
//   let response
//   if (userId) {
//     response = await requestApi('/tasks/byUnity/' + id, 'GET', null, { uid: userId })
//   } else {
//     response = await requestApi('/tasks/byUnity/' + id)
//   }
//   if (response.status < 200 || response.status >= 300) {
//     response.ok = false
//     return response  
//   }
//   return response
// }


/**
 * Get a Question
 */
// export const getAll = async (id) => {
//   const response = await requestApi('/cells/')
//   if (response.status < 200 || response.status >= 300) {
//     response.ok = false
//     return response  
//   }
//   return response
// }

/**
 * API request to call the backend
 */
const requestApi = async (
  path = '',
  method = 'GET',
  data = null,
  headers = {}) => {

  // Check if API URL has been set
  if (!domains().api) {
    throw new Error(`Error: Missing API Domain – Please add the API domain from your serverless Express.js back-end to this front-end application.  You can do this in the "./config.js" file.  Instructions are listed there and in the documentation.`)
  }

  // Prepare URL
  if (!path.startsWith('/')) {
    path = `/${path}`
  }
  const url = `${domains().api}${path}`

  // Set headers
  headers = Object.assign(
    { 'Content-Type': 'application/json' },
    headers
  )

  // Default options are marked with *
  const response = await fetch(url, {
    method: method.toUpperCase(),
    mode: 'cors',
    cache: 'no-cache',
    headers: headers,
    body: data ? JSON.stringify(data) : null
  })

  if (response.status < 200 || response.status >= 300) {
    let res = await response.json()
    res.status = response.status
    return await res
  }


  return {
    status: 200,
    ok: true,
    body: await response.json()
  }
}
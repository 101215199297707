import React, { Component }  from 'react'
import {
  // Link,
  withRouter
} from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import styles from './index.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

// const ilustracaoEstudantesELivros = lazy(() =>

class Home extends Component {

  constructor(props) {
    super(props)
    this.state = {
      ilustracaoNaoAutorizado: null,
    }
  }

  async componentDidMount() {
    AOS.init({duration: 300})
    
    const ilustracaoNaoAutorizado = require('../../assets/ilustracao_nao_autorizado.svg')

    this.setState({ ilustracaoNaoAutorizado })
    
  }

  render() {
    return (
      <Container>
        <Row className={styles.homePrincipal} style={{
          backgroundImage: `url(${this.state.ilustracaoNaoAutorizado})`,
          backgroundPosition: "right",
          height: "750px",
          backgroundRepeat: "no-repeat"
        }}>
          <Col sm={12} md={4} className={styles.spaceUp} data-aos='fade-up'>
            <h2>Não autorizado</h2>
            <br/><br/>
            <p>Você não está autorizado a acessar essa página.</p>
            <br/>
            <Button className={styles.primaryButton} href={"/"}>voltar para a home</Button>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(Home)